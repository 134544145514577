import {SubscriptionStatus} from "module/subscription/domain/model/SubscriptionStatus";
import Subscription from "module/subscription/domain/model/Subscription";

export default interface SubscriptionResponse {
  status: SubscriptionStatus;
  ownerEmail: string;
  endOfTrialPeriod: string;
  workspaceId: string;
  cancellationEffectiveDate: string;
}

export function subscriptionResponseToModel(subscriptionResponse: SubscriptionResponse): Subscription {
  return new Subscription(
    subscriptionResponse.workspaceId,
    subscriptionResponse.status,
    subscriptionResponse.ownerEmail,
    subscriptionResponse.endOfTrialPeriod,
    subscriptionResponse.cancellationEffectiveDate
  );
}
