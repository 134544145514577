import OnboardingSequence from "module/onboarding/domain/model/OnboardingSequence";
import OnboardingStep from "module/onboarding/domain/model/OnboardingStep";
import {ArrowPositions} from "asset/component/global/dialogs/Tooltip";
import {OnboardingSequenceNames} from "module/onboarding/domain/model/SeedData/OnboardingSequenceNames";
import {Routes} from "router/Routes";

const CoacheesSequence = new OnboardingSequence(
  [
    new OnboardingStep({
      name: "add",
      sequenceName: OnboardingSequenceNames.COACHEES,
      anchorID: "add-coachee-anchor",
      arrowPosition: ArrowPositions.RIGHT.TOP,
      adjustments: {x: -135, y: -15},
      link: Routes.add.coachee.path
    }),
    new OnboardingStep({
      name: "coachee",
      sequenceName: OnboardingSequenceNames.COACHEES,
      anchorID: "see-coachee-anchor",
      arrowPosition: ArrowPositions.BOTTOM.LEFT,
      imageUrl: "coachee-page",
      adjustments: {x: -38, y: -35},
      link: (param) => Routes.coachee.profileTeam.path(param)
    }),
    new OnboardingStep({
      name: "team",
      sequenceName: OnboardingSequenceNames.COACHEES,
      anchorID: "see-team-anchor",
      arrowPosition: ArrowPositions.RIGHT.TOP,
      adjustments: {x: -160, y: -23},
      imageUrl: "team-page",
      link: (param) => Routes.team.profile.path(param)
    })
  ]
);

export default CoacheesSequence;
