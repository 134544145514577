import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import {CleanStore} from "store";
import {ErrorNames} from "core/error/ErrorNames";
import AppError from "core/error/AppError";
import TenantServices from "module/profile/dal/service/TenantServices";

type AuthenticationStoreStateType = {
  login: {
    isLoading: boolean,
    error?: ErrorNames
  }
}

export const AuthenticationStore = createModel<RootModel>()({
  state: {} as AuthenticationStoreStateType,
  effects: (dispatch) => ({
    async login({email, password}) {
      return await clients.Authentication.login(email, password).then(() => {
        CleanStore(dispatch);
      }).catch((error: AppError) => {
        throw error.name;
      });
    },
    async register({email, firstName, lastName, password, locale}) {
      return await clients.Authentication.register(email, firstName, lastName, password, locale).then(() => {
        CleanStore(dispatch);
      }).catch((error: AppError) => {
        throw error.name;
      });
    },
    async resetPassword(email?: string) {
      return await clients.Authentication.resetPassword(email)
        .catch((error: AppError) => {
          throw error.name;
        });
    },
    async logout() {
      return await clients.Authentication.logout()
        .then(() => {
          CleanStore(dispatch);
          TenantServices.removeCurrentTenant();
        })
        .catch((error: AppError) => {
          throw error.name;
        });
    }
  }),
});
