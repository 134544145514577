import React, {useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import i18n from "translation/config";
import styles from "asset/component/asset/auth/Recaptcha.module.scss";
import {Alert} from "asset/component/index";
import {useTranslation} from "react-i18next";

function Recaptcha({
  onCaptchaResolved,
  showRequired
}: {
  onCaptchaResolved: (value: boolean) => void,
  showRequired?: boolean
}) {
  const {t} = useTranslation();
  const [recaptchaValue, setRecaptchaValue] = useState("");
  
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    
    if (value) {
      onCaptchaResolved(value);
    }
  };
  
  return (
    <div className={styles.recaptcha}>
      {showRequired &&
          <Alert data-testid="captcha-error" severity="error">{t('form.validation.captchaRequired')}</Alert>}
      <div className={styles.recaptchaContainer}>
        <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_KEY} onChange={handleRecaptchaChange} hl={i18n.language} />
      </div>
    </div>
  );
}

export default Recaptcha;
