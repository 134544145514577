import CommunicationLanguageRepository from "module/user/domain/CommunicationLanguageRepository";
import CommunicationLanguage from "module/user/domain/model/CommunicationLanguage";
import RESTClient from "core/client/RESTClient";
import CommunicationLanguageResponse, {
  communicationLanguageResponseToModel
} from "module/user/dal/dto/CommunicationLanguageResponse";
import {createUpdateCommunicationLanguageRequest} from "module/user/dal/dto/UpdateCommunicationLanguageRequest";

export default class RESTCommunicationLanguageRepository implements CommunicationLanguageRepository {
  private static readonly BASE_URL: string = "api/me";
  private static readonly COMMUNICATION_LANGUAGE_URL: string = "/communicationLanguage";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchCommunicationLanguage(): Promise<CommunicationLanguage> {
    const communicationLanguageResponse = await this.client.apiGet<CommunicationLanguageResponse>(
      `${RESTCommunicationLanguageRepository.BASE_URL}` +
      `${RESTCommunicationLanguageRepository.COMMUNICATION_LANGUAGE_URL}`
    );
    return communicationLanguageResponseToModel(communicationLanguageResponse);
  }
  
  public async updateCommunicationLanguage(updateComminucationLanguage: CommunicationLanguage): Promise<CommunicationLanguage> {
    const communicationLanguageResponse = await this.client.apiPut<CommunicationLanguageResponse>(
      `${RESTCommunicationLanguageRepository.BASE_URL}` +
      `${RESTCommunicationLanguageRepository.COMMUNICATION_LANGUAGE_URL}`,
      createUpdateCommunicationLanguageRequest(updateComminucationLanguage)
    );
    
    return communicationLanguageResponseToModel(communicationLanguageResponse);
  }
}

