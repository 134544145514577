import SubObjective from "module/objective/domain/model/SubObjective";

export default interface SubObjectiveResponse {
  id: string;
  text: string;
  createdAt: string;
  completed: boolean;
}

export function subObjectiveResponseToModel(subObjectiveResponse: SubObjectiveResponse): SubObjective {
  return new SubObjective(
    subObjectiveResponse.id,
    subObjectiveResponse.text,
    subObjectiveResponse.createdAt,
    subObjectiveResponse.completed,
  );
}

export function subObjectivesResponseToModel(subObjectivesResponse: SubObjectiveResponse[]): SubObjective[] {
  return subObjectivesResponse !== undefined ? subObjectivesResponse.map(subObjectiveResponse => subObjectiveResponseToModel(subObjectiveResponse)) : [];
}
