import TeamMotivator from "../../domain/model/TeamMotivator";

export default interface TeamMotivatorResponse {
  id: string;
  title: string;
  teamId: string;
}

export function teamMotivatorResponseToModel(
  motivatorResponse: TeamMotivatorResponse,
  teamId: string
): TeamMotivator {
  return new TeamMotivator(
    motivatorResponse.id,
    motivatorResponse.title,
    teamId,
  );
}

export function teamMotivatorsResponseToModel(
  motivatorResponse: TeamMotivatorResponse[],
  teamId: string
): TeamMotivator[] {
  return motivatorResponse.map(motivatorResponse => teamMotivatorResponseToModel(motivatorResponse, teamId));
}
