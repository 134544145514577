import link from "style/variable/_link.scss";

export default {
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        li: {
          color: link.linkBreadcrumbsColor,
          '.MuiLink-root': {
            color: link.linkBreadcrumbsColor,
            textTransform: link.linkBreadcrumbsTextTransform,
            fontSize: link.linkBreadcrumbsTextSize,
            fontWeight: link.linkBreadcrumbsFontWeight,
          },
          '.MuiLink-underlineHover': {
            cursor: 'pointer'
          },
          '.selected': {
            textDecoration: 'underline'
          }
        }
      }
    }
  }
};
