import {ILinkableEntity, LinkableEntityType} from "module/mandate/domain/model/LinkableEntity";

export interface ILinkedEntity extends ILinkableEntity {
  mandateId: string;
}

export default class LinkedEntity implements ILinkedEntity {
  private readonly _id: string;
  private readonly _objectId: string;
  private readonly _name: string;
  private readonly _type: LinkableEntityType;
  private readonly _mandateId: string;
  
  constructor(id: string, objectId: string, name: string, type: LinkableEntityType, mandateId: string) {
    this._id = id;
    this._objectId = objectId;
    this._name = name;
    this._type = type;
    this._mandateId = mandateId;
  }
  
  get id(): string {
    return this._id;
  }
  
  get objectId(): string {
    return this._objectId;
  }
  
  get name(): string {
    return this._name;
  }
  
  get type(): LinkableEntityType {
    return this._type;
  }
  
  get mandateId(): string {
    return this._mandateId;
  }
}
