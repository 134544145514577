import React from 'react';
import styles from 'asset/component/asset/form/SaveState.module.scss';
import {Typography} from "@mui/material";
import Checkmark from "asset/icon/Checkmark";
import {ErrorIcon} from "asset/component/Icons";
import {useTranslation} from "react-i18next";

const SaveState = ({isSaving, isSaved, isError, noText = false}: {
  isSaving: boolean,
  isSaved: boolean,
  isError: boolean,
  noText?: boolean
}) => {
  const {t} = useTranslation();
  
  const [hasSavedOnce, setHasSavedOnce] = React.useState(false);
  
  React.useEffect(() => {
    setHasSavedOnce(hasSavedOnce || isSaving);
  });
  
  const [currentStyles, setCurrentStyles] = React.useState([styles.box, styles.hide]);
  const [currentIcon, setCurrentIcon] = React.useState(<></>);
  const [currentText, setCurrentText] = React.useState('');
  
  React.useEffect(() => {
    if (isError) {
      setCurrentStyles([styles.box, styles.error]);
      setCurrentIcon(<ErrorIcon data-testid="error" />);
      setCurrentText(t('common:saveState.savingError'));
    } else if (!hasSavedOnce) {
      setCurrentStyles([styles.box, styles.hide]);
      setCurrentIcon(<></>);
      setCurrentText('');
    } else if (isSaving) {
      setCurrentStyles([styles.box, styles.saving]);
      setCurrentIcon(
        <Typography variant="body1" className={styles.text}>...</Typography>);
      setCurrentText(t('common:saveState.saving'));
    } else if (isSaved) {
      setCurrentStyles([styles.box]);
      setCurrentIcon(<Checkmark data-testid="checkmark" />);
      setCurrentText(t('common:saveState.saved'));
    }
  }, [isSaving, isSaved, isError, hasSavedOnce]);
  
  return (
    <div className={`${currentStyles.join(' ')} save-state`}
         data-testid="save-state-container">
      {currentIcon}
      {!noText &&
          <Typography variant="body1" className={styles.text}>
            {currentText}
          </Typography>
      }
    </div>
  );
};

export default SaveState;
