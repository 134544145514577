import muiSwitch from "../switch.module.scss";

export default {
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: "68px !important"
      },
      track: {
        height: "24px",
        opacity: 1,
        backgroundColor: muiSwitch.switchTrackColor,
        borderRadius: "12px",
        transform: "translate(-5px, -5px)",
      },
      thumb: {
        backgroundColor: muiSwitch.switchThumbColor,
      },
      switchBase: {
        "&.Mui-checked": {
          "& + .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: muiSwitch.switchTrackColorToggled,
          }
        }
      }
    }
  }
};
