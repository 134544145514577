import React, {useEffect, useState} from "react";
import AddCoacheeFormComponent, {AddCoacheeStateProps} from "module/coachee/view/component/AddCoacheeFormComponent";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import {useNavigate} from "react-router-dom";
import {Routes} from "router/Routes";
import AddCoachee from "module/coachee/domain/model/AddCoachee";
import {InputOption} from "asset/component/form/inputs/InputSelect";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import BodySectionLayout from "asset/component/layout/BodySectionLayout";
import AddSomething from "asset/component/form/AddSomething";
import FormHeader from "asset/component/form/FormHeader";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {Accesses} from "module/authorization/domain/model/accesses";
import HadAccess from "module/authorization/HadAccess";

const AddCoacheeFormContainer = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [teamId, setTeamId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [teamName, setTeamName] = useState("");
  
  const [companyOptions, setCompanyOptions] = useState<InputOption[]>([]);
  const [teamOptions, setTeamOptions] = useState<InputOption[]>([]);
  
  const {companies} = useSelector(
    (rootState: RootState) => rootState.CompanyStore);
  const teams = useSelector(
    (rootState: RootState) => rootState.TeamStore?.getters(rootState)
      .getTeamsByCompanyId(companyId));
  
  const companyState = useLoadingAndError(
    () => dispatch.CompanyStore.fetchCompanies()
  );
  const fetchTeamsState = useLoadingAndError(
    () => dispatch.TeamStore.fetchTeamsByCompanyId(companyId),
    [companyId !== ""],
    companyId
  );
  
  const addCoacheeState = {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    teamName,
    setTeamName,
    teamId,
    setTeamId,
    companyName,
    setCompanyName,
    companyId,
    setCompanyId
  } as AddCoacheeStateProps;
  
  const [addCoacheeLoading, addCoacheeErrorName, addCoacheeDispatch] =
    usePromiseLoadingAndError((isCompleted, text) =>
      dispatch.CoacheeStore.addCoachee(
        new AddCoachee(
          firstName,
          lastName,
          teamName,
          teamId,
          companyName,
          companyId
        )).then((addedCoachee) => {
        navigate(
          addedCoachee.teamIds[0] ?
            Routes.coachee.profileTeam.path({coacheeId: addedCoachee.id, teamId: addedCoachee.teamIds[0]}) :
            Routes.coachee.profile.path({coacheeId: addedCoachee.id})
        );
      })
    );
  
  useEffect(() => {
    setTeamOptions(teams.map(team => ({value: team.id, label: team.name})));
  }, [fetchTeamsState.isCompleted]);
  
  useEffect(() => {
    setCompanyOptions(companies.map(company => ({
      value: company.id,
      label: company.name
    })));
  }, [companies]);
  
  return (
    <>
      <HadAccess accesses={[Accesses.COACHEES_MANAGEMENT]}>
        <BodySectionLayout>
          <FormHeader navigateTo={Routes.coachees.path()} />
          <AddSomething />
          <AddCoacheeFormComponent addCoachee={addCoacheeDispatch}
                                   addCoacheeState={addCoacheeState}
                                   companyOptions={companyOptions}
                                   teamOptions={teamOptions}
                                   isLoading={addCoacheeLoading}
                                   errorName={addCoacheeErrorName} />
        </BodySectionLayout>
      </HadAccess>
    </>
  );
};

export default AddCoacheeFormContainer;
