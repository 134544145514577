import {Tag} from "asset/component/tag/Tag";
import Skill from "module/skill/domain/model/Skill";

const buildTagsFromSkills = (skills: Skill[], defaultSelectedValue: boolean = false): Tag[] => {
  return skills ? skills.map(
    (skill: Skill) => {
      return {
        id: skill.id,
        name: skill.name,
        isSelected: defaultSelectedValue
      };
    }) : [];
};

export default buildTagsFromSkills;
