import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import LinkedMandateListComponent from "module/mandate/view/component/listing/LinkedMandateListComponent";

const TeamLinkedMandateListContainer = ({
  teamId
}: {
  teamId: string
}) => {
  const dispatch = useDispatch<Dispatch>();
  
  const mandates = useSelector(
    (rootState: RootState) =>
      rootState.MandateStore?.getters(rootState).getMandatesByTeamId(teamId)
  );
  
  const fetchMandatesForTenantState = useLoadingAndError(
    () => dispatch.MandateStore.fetchMandatesForTenant()
  );
  return (
    <>
      {mandates.length !== 0 &&
          <LinkedMandateListComponent mandates={mandates}
                                      isLoading={fetchMandatesForTenantState.isLoading}
                                      errorNames={fetchMandatesForTenantState.errorName}
                                      data-testid="linked-mandate-list-component"
                                      theme={'linkedDark'} />}
    </>
  );
};

export default TeamLinkedMandateListContainer;
