import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import type {Dispatch, RootState} from "store";
import HomePageTeamsListingComponent from "module/home/view/component/HomePageTeamsListingComponent";
import CoacheesListingByTeamContainer from "module/coachee/view/container/CoacheesListingByTeamContainer";
import Team from "module/team/domain/model/Team";
import {ErrorNames} from "core/error/ErrorNames";

const HomePageTeamsListingContainer = ({
  companyId,
  teamsId,
  expanded
}: {
  companyId: string,
  teamsId: string[],
  expanded?: boolean
}) => {
  const dispatch = useDispatch<Dispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorName, setErrorName] = useState<ErrorNames | undefined>(undefined);
  const teams = useSelector((rootState: RootState) => rootState.TeamStore?.getters(rootState).getTeamsByIds(teamsId));
  
  useEffect(() => {
    setIsLoading(true);
    setErrorName(undefined);
    
    dispatch.TeamStore.fetchTeamsByCompanyId(companyId).then(() => {
      setIsLoading(false);
    }).catch((errorName) => {
      setErrorName(errorName);
    });
  }, []);
  
  return (
    <HomePageTeamsListingComponent expanded={expanded}
                                   teams={teams}
                                   isLoading={isLoading}
                                   errorName={errorName}>
      {
        (team: Team) => <CoacheesListingByTeamContainer hasAnchor={expanded} teamId={team.id} />
      }
    </HomePageTeamsListingComponent>
  );
};

export default HomePageTeamsListingContainer;
