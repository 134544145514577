const SendArrow = ({
  width,
  height
} : {
  width?: number,
  height?: number
}) => {
  return <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="fl&#195;&#168;che droite">
      <path id="Fill"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.8 3.2998L10.8 4.2998L16.2 9.7998H1V11.1998H16.2L10.8 16.6998L11.8 17.6998L19 10.4998L11.8 3.2998Z"
            fill="#123029" />
    </g>
  </svg>
}

export default SendArrow;
