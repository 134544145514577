import AddCoachee from "module/coachee/domain/model/AddCoachee";

export default interface AddCoacheeRequest {
  firstName: string;
  lastName: string;
  teamName: string;
  teamId: string | undefined;
  companyName: string | undefined;
  companyId: string | undefined;
}

export function createAddCoacheeRequest(addCoachee: AddCoachee): AddCoacheeRequest {
  const addCoacheeRequest = {
    firstName: addCoachee.firstName,
    lastName: addCoachee.lastName,
  } as AddCoacheeRequest;
  
  if (addCoachee.teamName) addCoacheeRequest.teamName = addCoachee.teamName;
  if (addCoachee.teamId) addCoacheeRequest.teamId = addCoachee.teamId;
  if (addCoachee.companyName) addCoacheeRequest.companyName = addCoachee.companyName;
  if (addCoachee.companyId) addCoacheeRequest.companyId = addCoachee.companyId;
  
  return addCoacheeRequest;
}
