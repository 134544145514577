import React from "react";
import {Grid} from "asset/component";
import styles from "asset/component/asset/form/FormSection.module.scss";

const FormSection = ({
  anchorId,
  isActivated = true,
  children
}: {
  anchorId?: string,
  isActivated?: boolean,
  children: JSX.Element | JSX.Element[]
}) => {
  return <>
    <span id={anchorId} />
    {
      isActivated &&
        <Grid className={styles.formSection}>
          {children}
        </Grid>
    }
  </>;
};

export default FormSection;
