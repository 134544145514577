export default class AddObjectiveState {
  private readonly _status: string;
  
  constructor(status: string) {
    this._status = status;
  }
  
  get status(): string {
    return this._status;
  }
}
