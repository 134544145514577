import UpdateLinkableEntity from "module/mandate/domain/model/UpdateLinkableEntity";
import {LinkableEntityType} from "module/mandate/domain/model/LinkableEntity";

export default interface IUpdateLinkableEntityRequest {
  objectId: string;
  type: LinkableEntityType;
}

export function createUpdateLinkableEntityRequest(updateLinkableEntity: UpdateLinkableEntity): IUpdateLinkableEntityRequest {
  return {
    objectId: updateLinkableEntity.objectId,
    type: updateLinkableEntity.type,
  } as IUpdateLinkableEntityRequest;
}
