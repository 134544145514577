import UpdateSubObjective from "module/objective/domain/model/UpdateSubObjective";

export default interface UpdateSubObjectiveRequest {
  completed: boolean;
}

export function createUpdateSubObjectiveRequest(updateSubObjective: UpdateSubObjective): UpdateSubObjectiveRequest {
  return {
    completed: updateSubObjective.completed,
  } as UpdateSubObjectiveRequest;
}
