import colors from "style/variable/_colors.scss";

export default {
  palette: {
    primary: {
      veryDark: colors.themePrimaryVeryDark,
      dark: colors.themePrimaryDark,
      middle: colors.themePrimaryMiddle,
      main: colors.themePrimary,
      light: colors.themePrimaryLight,
      veryLight: colors.themePrimaryVeryLight,
    },
    secondary: {
      veryDark: colors.themeSecondaryVeryDark,
      dark: colors.themeSecondaryDark,
      middle: colors.themeSecondaryMiddle,
      main: colors.themeSecondary,
      light: colors.themeSecondaryLight,
      veryLight: colors.themeSecondaryVeryLight,
    },
    special: {
      danger: colors.themeDanger,
      dangerLight: colors.themeDangerLight,
    },
    error: {
      main: colors.themeDanger,
    },
    white: {
      main: colors.themeWhite,
    } as any,
    primaryLight: {
      main: colors.themePrimaryLight,
    } as any,
    primaryDark: {
      main: colors.themePrimaryDark,
    } as any,
    secondaryDark: {
      main: colors.themeSecondaryDark,
    } as any,
    secondaryVeryDark: {
      main: colors.themeSecondaryVeryDark,
    } as any,
    transparent: {
      main: colors.themeTransparent,
    } as any,
    text: {
      primaryDark: colors.themePrimaryDark,
      secondaryDark: colors.themeSecondaryDark,
      secondaryVeryDark: colors.secondaryVeryDark
    } as any
  }
};
