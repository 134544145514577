import {Cookies} from 'react-cookie';

export default class MobileServices {
  private static readonly COOKIES = new Cookies();
  private static readonly MOBILE_COOKIE_NAME = 'mobile';
  
  public static showMobileMessage(): boolean {
    return MobileServices.COOKIES.get(MobileServices.MOBILE_COOKIE_NAME) === undefined;
  }
  
  public static showedMobileMessage(): void {
    MobileServices.COOKIES.set(MobileServices.MOBILE_COOKIE_NAME, true);
  }
}
