import React from 'react';
import FormSection from "asset/component/form/FormSection";
import {Typography} from "asset/component";
import {useTranslation} from "react-i18next";
import usePaletteColors from "style/usePaletteColors";
import styles from "module/mandate/view/asset/add/AddMandateInfoFormComponent.module.scss";
import MandateTitleInput from "module/mandate/view/component/add/inputs/MandateTitleInput";
import MandateDescriptionInput from "module/mandate/view/component/add/inputs/MandateDescriptionInput";
import MandateDatePicker from "module/mandate/view/component/add/inputs/MandateDatePicker";
import useNavigationFormSections from "asset/component/form/hook/useNavigationFormSections";
import FormNavigationButtons from "asset/component/form/FormNavigationButtons";
import {Moment} from "moment/moment";

const AddMandateInfoFormComponent = ({
  title,
  setTitle,
  description,
  setDescription,
  startDate,
  setStartDate,
  deadline,
  setDeadline,
  anchorId,
  useNavigation,
  nextSectionFormId,
  disabledButton
}: {
  title: string,
  setTitle: (title: string) => void,
  description: string,
  setDescription: (description: string) => void,
  startDate: Moment | null,
  setStartDate: (date: Moment | null) => void,
  deadline: Moment | null,
  setDeadline: (date: Moment | null) => void,
  anchorId: string,
  useNavigation: typeof useNavigationFormSections.arguments,
  nextSectionFormId: string,
  disabledButton?: boolean
}) => {
  const {t} = useTranslation('mandate');
  const palette = usePaletteColors();
  
  return (
    <FormSection anchorId={anchorId}
                 isActivated={useNavigation.isSectionActivated(anchorId)}>
      <Typography component="h3" variant="h3" color={palette.primary.veryLight}>
        {t('form.add.info.title')}
      </Typography>
      <Typography component="p" variant="sm" color={palette.primary.veryLight}>
        {t('form.add.info.subtitle')}
      </Typography>
      <div className={styles.formContainer}>
        <MandateTitleInput title={title} setTitle={setTitle} />
        <MandateDescriptionInput description={description} setDescription={setDescription} />
        <MandateDatePicker placeHolderDeadline={t("form.datePicker.deadline.placeholder")}
                           placeHolderStartDate={t("form.datePicker.startDate.placeholder")}
                           startDate={startDate}
                           deadline={deadline}
                           setStartDate={setStartDate}
                           setDeadline={setDeadline} />
        <FormNavigationButtons nextAnchor={nextSectionFormId}
                               nextIsSubmit={true}
                               isDisabled={disabledButton} />
      </div>
    </FormSection>
  );
};

export default AddMandateInfoFormComponent;
