import type RESTClient from "core/client/RESTClient";
import SubscriptionRepository from "module/subscription/domain/SubscriptionRepository";
import SubscriptionResponse, {subscriptionResponseToModel} from "module/subscription/dal/dto/SubscriptionResponse";
import Subscription from "module/subscription/domain/model/Subscription";
import CancelUrlSubscriptionResponse from "module/subscription/dal/dto/CancelUrlSubscriptionResponse";

export default class RESTSubscriptionRepository implements SubscriptionRepository {
  private static readonly BASE_URL: string = "/api/subscription";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchSubscription(): Promise<Subscription> {
    const subscriptionResponse = await this.client.apiGet<SubscriptionResponse>(
      `${RESTSubscriptionRepository.BASE_URL}`
    );
    
    return subscriptionResponseToModel(subscriptionResponse);
  }
  
  public async fetchCancelUrlSubscription(): Promise<string> {
    const cancelUrlSubscriptionResponse = await this.client.apiGet<CancelUrlSubscriptionResponse>(
      `${RESTSubscriptionRepository.BASE_URL}/cancelUrl`
    );
    
    return cancelUrlSubscriptionResponse.cancelUrl;
  }
}
