import AddTeamMotivator from "../../domain/model/AddTeamMotivator";

export default interface AddTeamMotivatorRequest {
  title: string;
  teamId: string;
}

export function createAddTeamMotivatorRequest(addMotivator: AddTeamMotivator): AddTeamMotivatorRequest {
  return {
    title: addMotivator.title,
    teamId: addMotivator.teamId,
  } as AddTeamMotivatorRequest;
}
