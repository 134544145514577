import * as React from "react";
import {Button} from "@mui/material";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import {MuiButtonColors} from "style/props/MuiButtonColors";
import {useTranslation} from "react-i18next";
import TempoFeatureFlagServices from "core/helper/TempoFeatureFlagServices";
import {useEffect} from "react";

const SubscriptionAction = ({
  action,
  cancelAction = false,
  cancellationEffectiveDate
}: {
  action?: () => void,
  cancelAction?: boolean,
  cancellationEffectiveDate?: Date
}) => {
  const {t} = useTranslation("common");

  return <Button onClick={action}
                 variant={MuiButtonVariants.OUTLINED}
                 disabled={cancellationEffectiveDate && !(cancellationEffectiveDate.getTime() <= Date.now())}
                 color={cancelAction ? 'error' : MuiButtonColors.SECONDARY}>
    {t(cancelAction ? 'cancel' : 'activate')} {t('subscription.subscription')}
  </Button>;
};

export default SubscriptionAction;
