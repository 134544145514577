import OnboardingSequence from "module/onboarding/domain/model/OnboardingSequence";
import OnboardingStep from "module/onboarding/domain/model/OnboardingStep";
import {ArrowPositions} from "asset/component/global/dialogs/Tooltip";
import {OnboardingSequenceNames} from "module/onboarding/domain/model/SeedData/OnboardingSequenceNames";
import {Routes} from "router/Routes";

const MandatesSequence = new OnboardingSequence(
  [
    new OnboardingStep({
      name: "add",
      sequenceName: OnboardingSequenceNames.MANDATES,
      anchorID: "add-mandate-anchor",
      arrowPosition: ArrowPositions.RIGHT.TOP,
      adjustments: {x: -265, y: -15},
      link: Routes.add.mandate.path
    }),
    new OnboardingStep({
      name: "toggle",
      sequenceName: OnboardingSequenceNames.MANDATES,
      anchorID: "toggle-mandates-anchor",
      arrowPosition: ArrowPositions.LEFT.MIDDLE,
      adjustments: {x: 17, y: -69}
    }),
    new OnboardingStep({
      name: "mandate",
      sequenceName: OnboardingSequenceNames.MANDATES,
      anchorID: "first-mandate-anchor",
      arrowPosition: ArrowPositions.BOTTOM.MIDDLE,
      link: (param) => Routes.mandate.path(param),
      adjustments: {x: 0, y: -35}
    }),
  ]
);

export default MandatesSequence;
