import React from 'react';
import DialogMessage from "asset/component/global/dialogs/DialogMessage";
import CoolGuy from "asset/image/cool-guy.png";
import {useTranslation} from "react-i18next";
import {FEEDBACK_URL_EN, FEEDBACK_URL_FR} from "config";

const FeedbackModal = ({
  open,
  setOpen
}: {
  open: boolean,
  setOpen: (opened: boolean) => void
}) => {
  const {t, i18n} = useTranslation("common");
  
  const giveFeedback = () => {
    const url = i18n.language === "fr" ? FEEDBACK_URL_FR : FEEDBACK_URL_EN;
    window.open(url, '_blank');
  };
  
  return (
    <DialogMessage open={open}
                   setOpen={() => setOpen(false)}
                   title={t('modalFeedback.title')}
                   text={t('modalFeedback.text')}
                   image={CoolGuy}
                   buttonText={t('modalFeedback.button')}
                   customAction={() => giveFeedback()} />
  );
};

export default FeedbackModal;
