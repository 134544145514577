import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import {useParams} from "react-router";
import TeamProfileHeaderComponent from "module/team/view/component/teamProfile/TeamProfileHeaderComponent";
import TeamProfileBodyComponent from "module/team/view/component/teamProfile/TeamProfileBodyComponent";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import TeamProfileBreadcrumbsContainer from "module/team/view/container/TeamProfileBreadcrumbsContainer";
import {Accesses} from "module/authorization/domain/model/accesses";
import HadAccess from "module/authorization/HadAccess";

const TeamProfileContainer = () => {
  const {teamId, companyId} = useParams() as {
    teamId: string,
    companyId: string
  };
  const dispatch = useDispatch<Dispatch>();
  
  const team = useSelector((rootState: RootState) =>
    rootState.TeamStore?.getters(rootState).getTeamById(teamId));
  
  const teams = useSelector((rootState: RootState) =>
    rootState.TeamStore?.getters(rootState).getTeamsByCompanyId(companyId));
  
  const company = useSelector((rootState: RootState) =>
    team && rootState.CompanyStore?.getters(rootState).getCompanyById(companyId));
  
  let fetchTeamByIdState = useLoadingAndError(
    () => dispatch.TeamStore.fetchTeamById(teamId),
    [teamId !== undefined],
    teamId
  );
  
  let fetchTeamsByCompanyIdState = useLoadingAndError(
    () => dispatch.TeamStore.fetchTeamsByCompanyId(companyId),
    [companyId !== undefined],
    companyId
  );
  
  let fetchCompanyByIdState = useLoadingAndError(
    () => dispatch.CompanyStore.fetchCompanyById(companyId),
    [companyId !== undefined],
    companyId
  );
  
  return <>
    <HadAccess accesses={[Accesses.COACHEES_MANAGEMENT]}>
      <TeamProfileBreadcrumbsContainer companyId={companyId} teamId={teamId} />
      <TeamProfileHeaderComponent data-testid="team_profile_header"
                                  team={team}
                                  teams={teams}
                                  isLoading={
                                    fetchTeamByIdState.isLoading ||
                                    fetchTeamsByCompanyIdState.isLoading ||
                                    fetchCompanyByIdState.isLoading
                                  }
                                  errorName={
                                    fetchTeamByIdState.errorName ||
                                    fetchTeamsByCompanyIdState.errorName ||
                                    fetchCompanyByIdState.errorName
                                  }
                                  company={company}
      />
      <TeamProfileBodyComponent data-testid="team_profile_body" team={team} />
    </HadAccess>
  </>;
};

export default TeamProfileContainer;
