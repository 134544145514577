import React from "react";
import {Grid} from "asset/component/index";
import {
  NavigationNextButton,
  NavigationPreviousButton,
  NavigationSkipButton
} from "asset/component/form/NavigationFormButtons";

const FormNavigationButtons = ({
  previousAnchor,
  nextAnchor,
  skipButtonText,
  activateSection,
  nextIsSubmit,
  onSkipButtonClick,
  isDisabled
}:
  {
    previousAnchor?: string,
    nextAnchor?: string,
    skipButtonText?: string,
    activateSection?: (nextAnchor: string) => void,
    nextIsSubmit?: boolean,
    onSkipButtonClick?: () => void,
    isDisabled?: boolean
  }) => {
  
  return <Grid container spacing={1} justifyContent="center">
    {
      previousAnchor &&
        <Grid item>
            <NavigationPreviousButton previousSectionId={previousAnchor} />
        </Grid>
    }
    {
      nextAnchor &&
        <Grid item>
            <NavigationNextButton nextSectionId={nextAnchor}
                                  onClick={activateSection ? () => activateSection(nextAnchor) : () => {
                                  }}
                                  isDisabled={isDisabled}
                                  isSubmit={nextIsSubmit} />
        </Grid>
    }
    {
      nextAnchor && skipButtonText &&
        <Grid item>
            <NavigationSkipButton text={skipButtonText}
                                  className="skipButton"
                                  nextSectionId={nextAnchor}
                                  onClick={activateSection ? () => {
                                    onSkipButtonClick?.();
                                    activateSection(nextAnchor);
                                  } : () => {
                                  }} />
        </Grid>
    }
  </Grid>;
};

export default FormNavigationButtons;
