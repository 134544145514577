import UpdateFollowUp from "module/followUp/domain/model/UpdateFollowUp";

export default interface UpdateFollowUpRequest {
  text: string;
  completed: boolean;
}

export function createUpdateFollowUpRequest(updateFollowUp: UpdateFollowUp): UpdateFollowUpRequest {
  return {
    text: updateFollowUp.text,
    completed: updateFollowUp.completed,
  } as UpdateFollowUpRequest;
}
