import React from "react";
import {Typography} from "asset/component";
import Coachee from "module/coachee/domain/model/Coachee";
import styles from "module/coachee/view/asset/CoacheeProfileThumbnailComponent.module.scss";
import enterpriseImage from "asset/image/enterprise.svg";
import coacheeProfileImage from "asset/image/coachee-profile.svg";
import Team from "module/team/domain/model/Team";
import UpdateCoacheeDialogContainer from "module/coachee/view/container/UpdateCoacheeDialogContainer";

const CoacheeProfileThumbnailComponent = ({
  coachee,
  companyName,
  teams,
  navigateOnTeamClick,
  isSelected = false
}: {
  coachee: Coachee,
  companyName?: string,
  teams?: Team[],
  navigateOnTeamClick: (team: Team) => void,
  isSelected?: boolean
}) => {
  return (
    <div className={`${isSelected ? styles.isSelected : styles.isNotSelected} ${styles.thumbnailProfileContainer}`}>
      <span className={styles.editIcon}>
        <UpdateCoacheeDialogContainer coachee={coachee} />
      </span>
      <img src={coacheeProfileImage}
           className={styles.avatar}
           alt="Panoramix" />
      <Typography className={styles.text} component="span" variant="h3">
        {coachee.firstName} {coachee.lastName}
      </Typography>
      <Typography className={styles.enterprise} component="span" variant="h5">
        <img src={enterpriseImage} alt="enterprise" height="20" />
        <span className={styles.infos}>
          <Typography className={styles.companyName}
                      component="span"
                      variant="xs">
            {companyName}
          </Typography>
          <Typography className={styles.teamsName}
                      component="span"
                      variant="xs">
            {
              teams?.map<React.ReactNode>(
                team => <span className="test"
                              key={team.id}
                              onClick={() => navigateOnTeamClick(team)}>{team.name}</span>
              ).map((teamName, index) => [index > 0 && '/', teamName])
            }
          </Typography>
        </span>
      </Typography>
    </div>
  );
};

export default CoacheeProfileThumbnailComponent;
