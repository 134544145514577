import React from 'react';
import MandateLinkedEntitiesComponent from "module/mandate/view/component/edit/MandateLinkedEntitiesComponent";
import {linkedEntityManager} from "module/mandate/view/container/MandateMethods";

const MandateLinkedEntitiesContainer = ({
  mandateId,
}: {
  mandateId: string
}) => {
  const {
    linkableEntities,
    isLinkableEntitiesLoading,
    errorLinkableEntities,
    isAddLinkedEntityLoading,
    addLinkedEntityErrorName,
    isRemoveLinkedEntityLoading,
    removeLinkedEntityErrorName,
    linkedEntities,
    isLinkedEntitiesLoading,
    errorLinkedEntities,
    addLinkedEntity,
    removeLinkedEntity
  } = linkedEntityManager(mandateId);
  
  return (
    <MandateLinkedEntitiesComponent
      linkableEntities={linkableEntities}
      linkedEntities={linkedEntities}
      isLoading={isLinkableEntitiesLoading || isAddLinkedEntityLoading || isLinkedEntitiesLoading || isRemoveLinkedEntityLoading}
      errorName={errorLinkableEntities || addLinkedEntityErrorName || errorLinkedEntities || removeLinkedEntityErrorName}
      addLinkedEntity={(id: string) => addLinkedEntity(id)}
      removeLinkedEntity={(id: string) => removeLinkedEntity(id)}
    />
  );
};

export default MandateLinkedEntitiesContainer;
