import React from 'react';
import {CircularProgress, Typography} from "@mui/material";
import LinkedMandate from "module/mandate/view/component/listing/LinkedMandate";
import styles from "module/mandate/view/asset/listing/LinkedMandateListComponent.module.scss";
import {ErrorNames} from "core/error/ErrorNames";
import {Alert} from "asset/component";
import Mandate from "module/mandate/domain/model/Mandate";
import {useTranslation} from "react-i18next";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import MandatePreviewContainer from "module/mandate/view/container/listing/MandatePreviewContainer";

const LinkedMandateListComponent = ({
  mandates,
  isLoading,
  errorNames,
  theme = 'linkedLight'
}: {
  mandates: Mandate[],
  isLoading?: boolean,
  errorNames?: ErrorNames,
  theme?: "linkedDark" | "linkedLight"
}) => {
  const {t} = useTranslation("mandate");
  const {getErrorMessage} = useErrorTranslation();
  
  const [displayedMandate, setDisplayedMandate] = React.useState<Mandate>(mandates[0]);
  const [isMandatePreviewOpen, setIsMandatePreviewOpen] = React.useState<boolean>(false);
  
  const displayMandate = (id: string) => {
    const mandate = mandates.find((mandate) => mandate.id === id);
    
    if (!mandate) return;
    
    setDisplayedMandate(mandate);
    setIsMandatePreviewOpen(true);
  };
  
  return (
    <>
      {errorNames && <Alert severity="error" data-testid="alert">{getErrorMessage(errorNames)}</Alert>}
      {isLoading ? <CircularProgress /> :
        <div className={`${styles.container} ${theme}`}>
          <Typography variant="h3" component="h3" marginBottom={1}>
            {t('titles.main')}
          </Typography>
          {mandates.map((mandate) => (
            <LinkedMandate key={mandate.id}
                           label={mandate.title}
                           showMandatePreview={() => displayMandate(mandate.id)} />
          ))}
        </div>}
      {mandates && <MandatePreviewContainer mandate={displayedMandate}
                                            isDialogOpen={isMandatePreviewOpen}
                                            close={() => setIsMandatePreviewOpen(false)} />}
    </>
  );
};

export default LinkedMandateListComponent;
