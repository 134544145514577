import React from 'react';
import styles from 'asset/component/asset/module/ModuleCard.module.scss';

export enum ModuleTheme {
  WHITE = 'white'
}

const ModuleCard = ({
  children,
  theme,
  injectedStyles
}: {
  children: JSX.Element | JSX.Element[],
  theme?: ModuleTheme,
  injectedStyles?: React.CSSProperties
}) => {
  return <div className={`${styles.moduleCardContainer} ${theme}`} style={injectedStyles}>
    {children}
  </div>;
};

export default ModuleCard;
