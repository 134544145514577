import NeedStats from "module/objective/domain/model/need/NeedStats";
import {NeedsCoachee} from "module/objective/domain/model/need/NeedsCoachee";

export default class CoacheeNeedsStats {
  private _autonomy: NeedStats;
  private _competence: NeedStats;
  private _social: NeedStats;
  
  constructor() {
    this._autonomy = new NeedStats(0, 0);
    this._competence = new NeedStats(0, 0);
    this._social = new NeedStats(0, 0);
  }
  
  get autonomy(): NeedStats {
    return this._autonomy;
  }
  
  get competence(): NeedStats {
    return this._competence;
  }
  
  get social(): NeedStats {
    return this._social;
  }
  
  set autonomy(value: NeedStats) {
    this._autonomy = value;
  }
  
  set competence(value: NeedStats) {
    this._competence = value;
  }
  
  set social(value: NeedStats) {
    this._social = value;
  }
  
  public static setCoacheeNeedsStatsFromNeed(
    coacheeNeedsStats: CoacheeNeedsStats,
    need: NeedsCoachee,
    needStats: NeedStats
  ): CoacheeNeedsStats {
    switch (need) {
      case NeedsCoachee.AUTONOMY:
        coacheeNeedsStats.autonomy = needStats;
        break;
      case NeedsCoachee.COMPETENCE:
        coacheeNeedsStats.competence = needStats;
        break;
      case NeedsCoachee.SOCIAL:
        coacheeNeedsStats.social = needStats;
        break;
    }
    
    return coacheeNeedsStats;
  }
}
