import RESTAuthenticationRepository from "module/authentication/dal/RESTAuthenticationRepository";
import FirebaseAuthRESTClient from "module/authentication/dal/FirebaseAuthRESTClient";
import {connectAuthEmulator, getAuth} from "firebase/auth";
import {initializeApp} from "firebase/app";
import RESTCoacheeRepository from "module/coachee/dal/RESTCoacheeRepository";
import AuthRequestInterceptor from "module/authentication/dal/AuthRequestInterceptor";
import AxiosInstanceFactory from "core/client/AxiosInstanceFactory";
import RESTClient from "core/client/RESTClient";
import RESTCompanyRepository from "module/company/dal/RESTCompanyRepository";
import RESTTeamRepository from "module/team/dal/RESTTeamRepository";
import EnvManager from "core/helper/EnvManager";
import RESTObjectiveCoacheeRepository from "module/objective/dal/RESTObjectiveCoacheeRepository";
import RESTFollowUpCoacheeRepository from "module/followUp/dal/RESTFollowUpCoacheeRepository";
import RESTObjectiveTeamRepository from "module/objective/dal/RESTObjectiveTeamRepository";
import RESTFollowUpTeamRepository from "module/followUp/dal/RESTFollowUpTeamRepository";
import LanguageManager from "core/helper/LanguageManager";
import RESTUserRepository from "module/user/dal/RESTUserRepository";
import RESTCoacheeMotivatorRepository from "module/motivator/coachee/dal/RESTCoacheeMotivatorRepository";
import RESTCoacheeDemotivatorRepository from "module/demotivator/coachee/dal/RESTCoacheeDemotivatorRepository";
import RESTTeamMotivatorRepository from "module/motivator/team/dal/RESTTeamMotivatorRepository";
import RESTTeamDemotivatorRepository from "module/demotivator/team/dal/RESTTeamDemotivatorRepository";
import RESTMandateRepository from "module/mandate/dal/RESTMandateRepository";
import RESTObjectiveMandateRepository from "module/objective/dal/RESTObjectiveMandateRepository";
import RESTSkillRepository from "module/skill/dal/RESTSkillRepository";
import RESTInformalRoleRepository from "module/skill/dal/RESTInformalRoleRepository";
import RESTLinkableEntityRepository from "module/mandate/dal/RESTLinkableEntityRepository";
import RESTCultureTagRepository from "module/culture/dal/RESTCultureTagRepository";
import TenantRequestInterceptor from "module/profile/dal/TenantRequestInterceptor";
import RESTProfileRepository from "module/profile/dal/RESTProfileRepository";
import ApiUrlServices from "core/helper/ApiUrlServices";
import RESTSubscriptionRepository from "module/subscription/dal/RESTSubscriptionRepository";
import RESTCommunicationLanguageRepository from "module/user/dal/RESTCommunicationLanguageRepository";

const firebaseAuthentication = getAuth(initializeApp(FirebaseAuthRESTClient.FIREBASE_CONFIG));

const configureServices = () => {
  const API_URL = ApiUrlServices.getApiUrlOverride() || process.env.REACT_APP_API_URL as string;
  
  let authRestClient;
  
  firebaseAuthentication.languageCode = LanguageManager.getCurrentLanguage();
  
  if (EnvManager.isDevMode()) {
    connectAuthEmulator(firebaseAuthentication, process.env.REACT_APP_FIREBASE_EMULATOR_API_URL as string);
  }
  
  authRestClient = new FirebaseAuthRESTClient(firebaseAuthentication);
  
  const authRequestInterceptor = new AuthRequestInterceptor(authRestClient);
  
  const axiosInstance = AxiosInstanceFactory.create(
    API_URL,
    [authRequestInterceptor],
    []);
  
  const tenantRequestInterceptor = new TenantRequestInterceptor();
  
  const axiosTenantedInstance = AxiosInstanceFactory.create(
    API_URL,
    [authRequestInterceptor, tenantRequestInterceptor],
    []);
  
  const restClient = new RESTClient(axiosInstance);
  const restTenantedClient = new RESTClient(axiosTenantedInstance);
  
  const clients = {
    Authentication: new RESTAuthenticationRepository(authRestClient, restClient),
    User: new RESTUserRepository(restClient),
    Profile: new RESTProfileRepository(restClient)
  };
  
  const tenantedClients = {
    Coachee: new RESTCoacheeRepository(restTenantedClient),
    Company: new RESTCompanyRepository(restTenantedClient),
    Team: new RESTTeamRepository(restTenantedClient),
    ObjectiveCoachee: new RESTObjectiveCoacheeRepository(restTenantedClient),
    ObjectiveTeam: new RESTObjectiveTeamRepository(restTenantedClient),
    ObjectiveMandate: new RESTObjectiveMandateRepository(restTenantedClient),
    FollowUpCoachee: new RESTFollowUpCoacheeRepository(restTenantedClient),
    FollowUpTeam: new RESTFollowUpTeamRepository(restTenantedClient),
    CoacheeMotivator: new RESTCoacheeMotivatorRepository(restTenantedClient),
    TeamMotivator: new RESTTeamMotivatorRepository(restTenantedClient),
    CoacheeDemotivator: new RESTCoacheeDemotivatorRepository(restTenantedClient),
    TeamDemotivator: new RESTTeamDemotivatorRepository(restTenantedClient),
    Mandate: new RESTMandateRepository(restTenantedClient),
    Skill: new RESTSkillRepository(restTenantedClient),
    InformalRole: new RESTInformalRoleRepository(restTenantedClient),
    LinkableEntity: new RESTLinkableEntityRepository(restTenantedClient),
    Subscription: new RESTSubscriptionRepository(restTenantedClient),
    CultureTag: new RESTCultureTagRepository(restTenantedClient),
    CommunicationLanguage: new RESTCommunicationLanguageRepository(restTenantedClient),
  };
  
  return {
    ...clients,
    ...tenantedClients
  };
};

const clients = configureServices();

export {firebaseAuthentication};

export default clients;
