
export default interface UpdateObjectiveStateCommentRequest {
  comment: string;
}

export function createUpdateObjectiveStateCommentRequest(updateObjectiveStateComment : UpdateObjectiveStateCommentRequest): UpdateObjectiveStateCommentRequest {
  return {
    comment: updateObjectiveStateComment.comment,
  } as UpdateObjectiveStateCommentRequest
}
