import AddSubObjective from "module/objective/domain/model/AddSubObjective";

export default interface AddSubObjectiveRequest {
  text: string;
}

export function createAddSubObjectiveRequest(addSubObjective: AddSubObjective): AddSubObjectiveRequest {
  return {
    text: addSubObjective.text,
  } as AddSubObjectiveRequest;
}
