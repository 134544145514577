import React from 'react';
import CardButton, {CardButtonSelectedColors} from "asset/component/global/buttons/CardButton";
import coacheeImage from "asset/image/wink-person.png";
import mandateImage from "asset/image/bullseye.png";
import usePaletteColors from "style/usePaletteColors";
import {MuiSizes} from "style/props/MuiSizes";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import styles from "module/dashboardNavigation/view/asset/DashboardNavigationCards.module.scss";
import {useTranslation} from "react-i18next";
import {Routes} from "router/Routes";
import {useNavigate} from "react-router-dom";
import {HomeSequence} from "module/onboarding/domain/model/SeedData/OnboardingSequencesSeed";
import {Accesses} from "module/authorization/domain/model/accesses";
import HadAccess from "module/authorization/HadAccess";

const DashboardNavigationCards = () => {
  const palette = usePaletteColors();
  const {t} = useTranslation("dashboardNavigation");
  const navigate = useNavigate();
  
  const navigateTo = (path: string) => {
    navigate(path);
  };
  
  const mandateIsSelected = () => {
    return location.pathname.includes(Routes.mandate.path());
  };
  
  return (
    <div className={styles.container}>
      <HadAccess data-testid="choice_coachees" accesses={[Accesses.COACHEES_MANAGEMENT]}>
        <CardButton text={t("choices.coachees")}
                    id={HomeSequence.steps[0].anchorID}
                    image={coacheeImage}
                    textColor={palette.primary.light}
                    textVariant={MuiTypographyVariants.SM}
                    isSelected={location.pathname === Routes.coachees.path()}
                    onClick={() => navigateTo(Routes.coachees.path())}
                    selectedColor={CardButtonSelectedColors.SECONDARY}
                    size={MuiSizes.LARGE}
                    data-testid="coachees-card" />
      </HadAccess>
      <HadAccess data-testid="choice_mandates" accesses={[Accesses.COACHEES_MANAGEMENT]}>
        <CardButton text={t("choices.mandates")}
                    id={HomeSequence.steps[1].anchorID}
                    image={mandateImage}
                    textColor={palette.primary.light}
                    textVariant={MuiTypographyVariants.SM}
                    isSelected={mandateIsSelected()}
                    onClick={() => navigateTo(Routes.mandate.path())}
                    selectedColor={CardButtonSelectedColors.SECONDARY}
                    size={MuiSizes.LARGE}
                    data-testid="mandates-card" />
      </HadAccess>
    </div>
  );
};

export default DashboardNavigationCards;
