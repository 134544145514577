import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import {RootState} from "store";
import AppError from "core/error/AppError";
import ReducerServices from "store/service/ReducerServices";
import AddSubObjective from "module/objective/domain/model/AddSubObjective";
import UpdateSubObjective from "module/objective/domain/model/UpdateSubObjective";
import ObjectiveMandate from "module/objective/domain/model/ObjectiveMandate";
import UpdateObjectiveMandate from "module/objective/domain/model/UpdateObjectiveMandate";
import AddObjectiveMandate from "module/objective/domain/model/AddObjectiveMandate";
import ObjectiveState from "module/objective/domain/model/ObjectiveState";
import AddObjectiveState from "module/objective/domain/model/AddObjectiveState";
import UpdateObjectiveStateComment from "module/objective/domain/model/UpdateObjectiveStateComment";
import AddObjectiveStateComment from "module/objective/domain/model/AddObjectiveStateComment";
import ObjectiveStateComment from "module/objective/domain/model/ObjectiveStateComment";
import ObjectiveReducers from "module/objective/view/reducers/ObjectiveReducers";

export const ObjectiveMandateStore = createModel<RootModel>()({
  state: {
    objectivesMandate: [] as ObjectiveMandate[],
    objectiveStateHistory: [] as ObjectiveState[],
    getters: (state: RootState) => ({
      getObjectivesByMandateId: (id: string) => {
        return state.ObjectiveMandateStore
          .objectivesMandate
          .filter(objective => objective.mandateId == id);
      },
      getCompletedSubObjectivesMandatesCount: (objectiveId: string) => {
        return state.ObjectiveMandateStore
          .objectivesMandate
          .find(objective => objective.id == objectiveId)
          ?.subObjectives
          .filter(subObjective => subObjective.completed).length || 0;
      },
      getObjectiveStateHistory: () => {
        return state.ObjectiveMandateStore.objectiveStateHistory
      }
    })
  },
  reducers: {
    removeObjectiveById(
      state,
      objectiveId: string
    ) {
      return {
        ...state,
        objectivesMandate: ReducerServices.removeItemFromList<ObjectiveMandate>(
          state.objectivesMandate,
          objectiveId)
      };
    },
    setObjectiveMandate(
      state,
      objectiveMandate: ObjectiveMandate
    ) {
      return {
        ...state,
        objectivesMandate: ReducerServices.setItemToList<ObjectiveMandate>(
          state.objectivesMandate,
          objectiveMandate)
      };
    },
    setObjectivesMandate(
      state,
      objectivesMandate: ObjectiveMandate[]
    ) {
      return {
        ...state,
        objectivesMandate: ReducerServices.setItemsToList<ObjectiveMandate>(
          state.objectivesMandate,
          objectivesMandate
        )
      };
    },
    setObjectiveStateHistory(
      state,
      objectiveStateHistory: ObjectiveState[]
    ) {
      return {
        ...state,
        objectiveStateHistory: ReducerServices.setItemsToList<ObjectiveState>(
          state.objectiveStateHistory,
          objectiveStateHistory)
      }
    },
    setObjectiveStateHistoryToEmpty(state) {
      return {
        ...state,
        objectiveStateHistory: []
      };
    },
    addCommentToObjectiveStateFoundById(
      state,
      objectiveStateId: string,
      objectiveStateComment: ObjectiveStateComment
    ) {
      return {
        ...state,
        ObjectiveStateHistory : ObjectiveReducers.addCommentToObjectiveStateFoundById(state, objectiveStateId, objectiveStateComment)
      }
    }
  },
  effects: (dispatch) => ({
    async getObjectivesByMandateId(mandateId: string) {
      await clients.ObjectiveMandate
        .fetchObjectivesByMandateId(mandateId)
        .then((objectives: ObjectiveMandate[]) => {
          dispatch.ObjectiveMandateStore.setObjectivesMandate(
            objectives);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async addObjectiveForMandate(payload: { addObjectiveMandate: AddObjectiveMandate, mandateId: string }) {
      await clients.ObjectiveMandate
        .addObjectiveForMandate(payload.addObjectiveMandate, payload.mandateId)
        .then((objective: ObjectiveMandate) => {
          dispatch.ObjectiveMandateStore.setObjectiveMandate(objective);
          dispatch.ObjectiveMandateStore.setObjectiveStateHistory([]);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async updateObjectiveForMandate(payload: {
      updateObjectiveMandate: UpdateObjectiveMandate,
      objectiveId: string,
      mandateId: string
    }) {
      await clients.ObjectiveMandate
        .updateObjectiveForMandate(payload.updateObjectiveMandate, payload.objectiveId, payload.mandateId)
        .then((objective: ObjectiveMandate) => {
          dispatch.ObjectiveMandateStore.setObjectiveMandate(
            objective);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteObjectiveForMandate(payload: { objectiveId: string, mandateId: string }) {
      await clients.ObjectiveMandate
        .deleteObjectiveForMandate(payload.objectiveId, payload.mandateId)
        .then(() => {
          dispatch.ObjectiveMandateStore.removeObjectiveById(payload.objectiveId);
          dispatch.ObjectiveMandateStore.setObjectiveStateHistoryToEmpty();
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async addSubObjectiveForMandate(payload: {
      addSubObjective: AddSubObjective,
      objectiveId: string,
      mandateId: string
    }) {
      await clients.ObjectiveMandate
        .addSubObjectiveForMandate(payload.addSubObjective, payload.objectiveId, payload.mandateId)
        .then(() => {
          dispatch.ObjectiveMandateStore.getObjectivesByMandateId(
            payload.mandateId);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async updateSubObjectiveForMandate(
      payload: {
        updateSubObjective: UpdateSubObjective,
        subObjectiveId: string,
        objectiveId: string,
        mandateId: string
      }) {
      await clients.ObjectiveMandate
        .updateSubObjectiveForMandate(
          payload.updateSubObjective,
          payload.subObjectiveId,
          payload.objectiveId,
          payload.mandateId)
        .then(() => {
          dispatch.ObjectiveMandateStore.getObjectivesByMandateId(
            payload.mandateId);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteSubObjectiveForMandate(
      payload: {
        subObjectiveId: string,
        objectiveId: string,
        mandateId: string
      }) {
      await clients.ObjectiveMandate
        .deleteSubObjectiveForMandate(payload.subObjectiveId, payload.objectiveId, payload.mandateId)
        .then(() => {
          dispatch.ObjectiveMandateStore.getObjectivesByMandateId(
            payload.mandateId);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async addObjectiveState(payload: {
      addObjectiveState: AddObjectiveState,
      mandateId: string,
      objectiveId: string,
    }) {
      return await clients.ObjectiveMandate.addObjectiveStateForMandate(payload.addObjectiveState, payload.mandateId, payload.objectiveId)
        .then((createdObjectiveState) => {
          dispatch.ObjectiveMandateStore.getObjectivesByMandateId(payload.mandateId);
          dispatch.ObjectiveMandateStore.fetchObjectiveStateHistory({mandateId: payload.mandateId, objectiveId: payload.objectiveId});
          return createdObjectiveState;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async updateObjectiveStateComment(payload: {
      updateObjectiveStateComment: UpdateObjectiveStateComment,
      mandateId: string,
      objectiveId: string,
    }) {
      return await clients.ObjectiveMandate.updateObjectiveStateCommentForMandate(payload.updateObjectiveStateComment, payload.mandateId, payload.objectiveId)
        .then((updatedObjectiveStateComment) => {
          dispatch.ObjectiveMandateStore.getObjectivesByMandateId(payload.mandateId);
          return updatedObjectiveStateComment;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async fetchObjectiveStateHistory(payload: {
      mandateId: string,
      objectiveId: string
    }) {
      return await clients.ObjectiveMandate.fetchObjectiveStateHistory(payload.mandateId, payload.objectiveId).then((objectiveStateHistory: ObjectiveState[] ) => {
        dispatch.ObjectiveMandateStore.setObjectiveStateHistory(objectiveStateHistory)
      }).catch((error: AppError) => {
        throw error.name;
      });
    },
    async addObjectiveStateComment(payload: {
      addObjectiveStateComment: AddObjectiveStateComment,
      mandateId: string,
      objectiveId: string,
      objectiveStateId: string,
    }) {
      return await clients.ObjectiveMandate.addObjectiveStateCommentForMandate(payload.addObjectiveStateComment, payload.mandateId, payload.objectiveId, payload.objectiveStateId)
        .then((createdObjectiveStateComment) => {
          dispatch.ObjectiveMandateStore.addCommentToObjectiveStateFoundById(payload.objectiveStateId, createdObjectiveStateComment);
          return createdObjectiveStateComment;
        }).catch((error: AppError) => {
          throw error.name;
        });
    }
  })
});
