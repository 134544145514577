import * as React from "react";
import {Container} from "asset/component";
import styles from 'module/common/layout/component/assets/HeaderComponent.module.scss';
import Greeting from "asset/component/global/Greeting";
import DashboardNavigationComponent from "module/dashboardNavigation/view/component/DashboardNavigationComponent";

const HeaderComponent = ({userName}: { userName?: string }) => {
  return (
    <div id={styles.headerComponent}>
      <Container className={styles.contaier}>
        <Greeting name={userName} />
        <DashboardNavigationComponent />
      </Container>
    </div>
  );
};

export default HeaderComponent;
