import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import {ModuleCard, ModuleHeader, ModuleListing} from "asset/component";
import {useTranslation} from "react-i18next";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import ObjectivesListingComponentStyles from "module/objective/view/asset/ObjectivesListingComponent.module.scss";
import AddObjectiveMandate from "module/objective/domain/model/AddObjectiveMandate";
import ObjectiveMandate from "module/objective/domain/model/ObjectiveMandate";
import MandateObjectiveContainer from "module/objective/view/container/mandate/MandateObjectiveContainer";
import {ModuleTheme} from "asset/component/module/ModuleCard";
import styles from "module/objective/view/asset/ObjectivesContainer.module.scss";
import InputEnter from "asset/component/form/inputs/InputEnter";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";

const MandateObjectivesContainer = ({
  mandateId,
  isEditable = true
}: {
  mandateId: string,
  isEditable?: boolean
}) => {
  const {t} = useTranslation('objective');
  const dispatch = useDispatch<Dispatch>();
  const {getErrorMessage} = useErrorTranslation();
  
  const mandateObjectives = useSelector((rootState: RootState) =>
    rootState.ObjectiveMandateStore?.getters(rootState).getObjectivesByMandateId(mandateId));
  
  const getObjectivesByMandateIdState =
    useLoadingAndError(() =>
      dispatch.ObjectiveMandateStore.getObjectivesByMandateId(mandateId), [], mandateId);
  
  const [isAddObjectiveLoading, addObjectiveErrorName, dispatchAddObjective] =
    usePromiseLoadingAndError((name: string) =>
      dispatch.ObjectiveMandateStore.addObjectiveForMandate({
        addObjectiveMandate: new AddObjectiveMandate(name),
        mandateId: mandateId,
      })
    );
  
  return (
    <ModuleCard theme={ModuleTheme.WHITE} injectedStyles={{padding: '3px', paddingTop: '30px', paddingBottom: '30px'}}>
      <ModuleHeader title={t('moduleTitle')} subtitle={t('mandate.subtitle')} textAlign={'left'} />
      <ModuleListing<ObjectiveMandate>
        data-testid="listing-objective"
        elements={mandateObjectives}
        injectedStyle={ObjectivesListingComponentStyles}
        isAddElementLoading={isAddObjectiveLoading}
        isLoading={getObjectivesByMandateIdState.isLoading}
        errorName={getObjectivesByMandateIdState.errorName || addObjectiveErrorName}
      >
        {
          (objective: ObjectiveMandate) => <MandateObjectiveContainer isEditable={isEditable}
                                                                      key={objective.id}
                                                                      objectiveMandate={objective} />
        }
      </ModuleListing>
      {isEditable ?
        <div className={styles.input}>
          <InputEnter data-testid="add-objective"
                      placeholder={t('addObjective')}
                      errorMessage={addObjectiveErrorName && getErrorMessage(addObjectiveErrorName)}
                      setValue={(value) => dispatchAddObjective(value)}
          />
        </div> : <></>}
    </ModuleCard>
  );
};

export default MandateObjectivesContainer;
