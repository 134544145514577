import React, {useRef, useState} from 'react';
import styles from "asset/component/asset/form/inputs/InputEnter.module.scss";
import {TextValidator, ValidatorForm} from "asset/validator";
import {InputValidator} from "asset/validator/InputValidator";
import ActionButton from "asset/component/global/buttons/ActionButton";

const MAX_LENGTH = 200;

export enum InputEnterTheme {
  LIGHT = "light",
  DARK = "dark"
}

const InputEnter = ({
  setValue,
  isVisible = true,
  placeholder = "",
  errorMessage = "",
  alwaysShow,
  isOnNewLine = false,
  theme = InputEnterTheme.LIGHT,
  id
}: {
  setValue: (value: string) => void,
  placeholder?: string,
  errorMessage?: string,
  alwaysShow?: boolean,
  isVisible?: boolean,
  isOnNewLine?: boolean,
  theme?: InputEnterTheme,
  id?: string
}) => {
  const [inputIsActivated, setInputIsActivated] = useState(false);
  const formRef = useRef<HTMLFormElement>();
  const [inputValue, setInputValue] = useState('');
  
  const createMotivator = () => {
    setValue(inputValue);
    setInputValue('');
  };
  
  const leaveField = () => {
    setInputIsActivated(false);
    setInputValue('');
  };
  
  return (
    <div className={`${styles.container} ${!isVisible && styles.isNotVisible} ${isOnNewLine && styles.isOnNewLine}`} data-testid="input-enter" id={id}>
      <div className={`${theme} ${styles.inputContainer} ${(alwaysShow || inputIsActivated) ? styles.show : ''}`}>
        <div data-testid="add-button"
             className={`${styles.addButton} ${(alwaysShow || inputIsActivated) ? styles.show : ''}`}
             onClick={() => setInputIsActivated(true)}>
          <ActionButton
            iconButtonProps={{
              className: `${styles.addButton} ${inputIsActivated ? styles.show : ''}`,
              iconSize: '22px',
              buttonSize: '34px'
            }} />
        </div>
        {(alwaysShow || inputIsActivated) &&
            <ValidatorForm className={styles.addInput}
                           id="input-enter-form"
                           data-testid="add-form"
                           ref={formRef}
                           onSubmit={() => createMotivator()}>
                <TextValidator required
                               type="text"
                               data-testid="title-input"
                               id="title"
                               placeholder={placeholder}
                               name="title"
                               value={inputValue}
                               validators={[InputValidator.REQUIRED]}
                               errorMessages={[errorMessage]}
                               autoFocus
                               className={`${styles.input} ${(alwaysShow || inputIsActivated) ? styles.show : ''}`}
                               onBlur={() => leaveField()}
                               onChange={(e) => setInputValue(e.target.value)}
                               inputProps={{maxLength: MAX_LENGTH}}
                />
            </ValidatorForm>
        }
      </div>
    </div>
  );
};

export default InputEnter;
