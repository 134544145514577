export default class UpdateObjective {
  private readonly _name: string;
  private readonly _text: string;
  private readonly _needs: string[];
  
  constructor(name: string, text: string, needs?: string[]) {
    this._name = name;
    this._text = text;
    this._needs = needs || [];
  }
  
  get name(): string {
    return this._name;
  }
  
  get text(): string {
    return this._text;
  }
  
  get needs(): string[] {
    return this._needs;
  }
}
