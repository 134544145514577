import UpdateObjective from "module/objective/domain/model/UpdateObjective";
import UpdateObjectiveRequest, {createUpdateObjectiveRequest} from "module/objective/dal/dto/UpdateObjectiveRequest";

export default interface UpdateObjectiveTeamRequest extends UpdateObjectiveRequest {
  needs: string[];
}

export function createUpdateObjectiveTeamRequest(updateObjective: UpdateObjective): UpdateObjectiveTeamRequest {
  return {
    ...createUpdateObjectiveRequest(updateObjective),
    needs: updateObjective.needs
  } as UpdateObjectiveTeamRequest;
}
