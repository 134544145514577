import CommunicationLanguage from "module/user/domain/model/CommunicationLanguage";

export default interface UpdateCommunicationLanguageRequest {
  chosenLanguage: string;
}

export function createUpdateCommunicationLanguageRequest(updateCommunicationLanguage: CommunicationLanguage): UpdateCommunicationLanguageRequest {
  return {
    chosenLanguage: updateCommunicationLanguage.name,
  } as UpdateCommunicationLanguageRequest;
}
