import type RESTClient from "core/client/RESTClient";
import UpdateInformalRole from "module/skill/domain/model/UpdateInformalRole";
import {createUpdateInformalRoleRequest} from "module/skill/dal/dto/UpdateInformalRoleRequest";
import InformalRoleRepository from "module/skill/domain/InformalRoleRepository";
import InformalRole from "module/skill/domain/model/InformalRole";
import InformalRoleResponse, {informalRoleResponseToModel} from "module/skill/dal/dto/InformalRoleResponse";

export default class RESTInformalRoleRepository implements InformalRoleRepository {
  private static readonly BASE_URL: string = "/api/coachees";
  private static readonly SKILL_URL: string = "/skills";
  private static readonly INFORMAL_ROLE_URL: string = "/informalRole";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchInformalRole(coacheeId: string): Promise<InformalRole> {
    const informalRoleResponse = await this.client.apiGet<InformalRoleResponse>(
      `${RESTInformalRoleRepository.BASE_URL}` +
      `/${coacheeId}` +
      `${RESTInformalRoleRepository.SKILL_URL}` +
      `${RESTInformalRoleRepository.INFORMAL_ROLE_URL}`
    );
    
    return informalRoleResponseToModel(informalRoleResponse, coacheeId);
  }
  
  public async updateInformalRole(updateInformalRole: UpdateInformalRole, coacheeId: string): Promise<InformalRole> {
    const informalRoleResponse = await this.client.apiPut<InformalRoleResponse>(
      `${RESTInformalRoleRepository.BASE_URL}` +
      `/${coacheeId}` +
      `${RESTInformalRoleRepository.SKILL_URL}` +
      `${RESTInformalRoleRepository.INFORMAL_ROLE_URL}`,
      createUpdateInformalRoleRequest(updateInformalRole)
    );
    
    return informalRoleResponseToModel(informalRoleResponse, coacheeId);
  }
}
