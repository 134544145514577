import React from "react";
import ChangePasswordComponent from "module/user/view/component/ChangePasswordComponent";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {useDispatch} from "react-redux";

const ChangePasswordContainer = () => {
  const dispatch = useDispatch();
  
  const [resetPasswordLoading, resetPasswordErrorName, resetPasswordDispatch, resetPasswordCompleted] =
    usePromiseLoadingAndError(() => dispatch.AuthenticationStore.resetPassword());
  
  const changePasswordComponentProps = {
    resetPassword: {
      loading: resetPasswordLoading,
      errorName: resetPasswordErrorName,
      dispatch: resetPasswordDispatch,
      completed: resetPasswordCompleted
    }
  };
  
  return <ChangePasswordComponent {...changePasswordComponentProps} />;
};

export default ChangePasswordContainer;
