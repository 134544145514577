import React from "react";
import {Grid, Typography} from "asset/component";
import {TextValidator, ValidatorForm} from "asset/validator";
import {InputValidator} from "asset/validator/InputValidator";
import {useTranslation} from "react-i18next";
import FormSection from "asset/component/form/FormSection";
import useNavigationFormSections from "asset/component/form/hook/useNavigationFormSections";
import FormNavigationButtons from "asset/component/form/FormNavigationButtons";

const AddCoacheeNamesFormComponent = ({
  nameFormSectionId,
  nextFormSectionId,
  useNavigation,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  formRef,
  submitForm,
  isValid
}: {
  nameFormSectionId: string,
  nextFormSectionId: string,
  useNavigation: typeof useNavigationFormSections.arguments,
  firstName: string,
  setFirstName: (firstName: string) => void,
  lastName: string,
  setLastName: (lastName: string) => void,
  formRef?: any,
  submitForm: () => void,
  isValid?: boolean
}) => {
  const {t} = useTranslation('coachee');
  
  const submit = () => {
    submitForm();
    useNavigation.activateSection(nextFormSectionId);
  };
  
  return (
    <ValidatorForm ref={formRef} onSubmit={() => useNavigation.activateSection(nextFormSectionId)}>
      <FormSection anchorId={nameFormSectionId}
                   isActivated={useNavigation.isSectionActivated(nameFormSectionId)}>
        <Typography component="h3" variant="h3">
          {t('form.add.firstNameLastNameTitle')}
        </Typography>
        <Typography component="p" variant="sm">
          {t('form.add.firstNameLastNameLabel')} *
        </Typography>
        <Grid container spacing={1} justifyContent="center">
          <Grid item>
            <TextValidator
              type="text"
              data-testid="first-name-input"
              id="firstName"
              placeholder={t('form.firstName')}
              name="firstName"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              validators={[InputValidator.REQUIRED]}
              errorMessages={[t('form.validation.firstNameRequired')]}
              autoFocus
            />
          </Grid>
          <Grid item>
            <TextValidator
              type="text"
              data-testid="last-name-input"
              id="lastName"
              placeholder={t('form.lastName')}
              name="lastName"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              validators={[InputValidator.REQUIRED]}
              errorMessages={[t('form.validation.lastNameRequired')]}
              onKeyPress={event => useNavigation.nextSectionOnEnterPress(event, nextFormSectionId)}
            />
          </Grid>
        </Grid>
        <FormNavigationButtons nextAnchor={nextFormSectionId}
                               activateSection={() => submit()}
                               isDisabled={!isValid} />
      </FormSection>
    </ValidatorForm>
  );
};

export default AddCoacheeNamesFormComponent;
