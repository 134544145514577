import {Navigate} from 'react-router-dom';
import React from 'react';
import {Routes} from 'router/Routes';
import {useAuthenticationState} from "module/authentication/hook/useAuthenticationState";

type PrivateRouteProps = {
  element: JSX.Element;
};

export const PrivateRoute = ({element}: PrivateRouteProps) => {
  const [user, userLoading] = useAuthenticationState();

  if (!user) {
    return <Navigate to={Routes.login.path()} />;
  }
  
  return element;
};

export default PrivateRoute;
