import {init, RematchDispatch, RematchRootState} from "@rematch/core";
import {rootModel, RootModel} from "store/RootModel";
import selectPlugin from "@rematch/select";

export const store = init<RootModel>({
  models: rootModel,
  redux: {
    rootReducers: {
      RESET_APP: (state, action) => undefined,
    }
  },
  plugins: [selectPlugin()],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
export const CleanStore = (dispatch) => dispatch({type: 'RESET_APP'});
