import React from 'react';
import styles from 'asset/component/asset/module/ModuleSectionLayout.module.scss';
import {Alert, Typography} from "asset/component/index";
import {CircularProgress} from "@mui/material";
import usePaletteColors from "style/usePaletteColors";
import {ErrorNames} from "core/error/ErrorNames";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";

const ModuleSectionLayout = ({
  title,
  errorName,
  isLoading,
  injectedStyles,
  children,
  theme = "transparent",
  id
}: {
  title?: string,
  errorName?: ErrorNames,
  isLoading?: boolean,
  injectedStyles?: React.CSSProperties,
  children: JSX.Element,
  theme?: "transparent" | "cream",
  id?: string
}) => {
  const palette = usePaletteColors();
  const {getErrorMessage} = useErrorTranslation();
  
  return (
    <div className={`${styles.moduleSectionLayout} ${theme}`} style={injectedStyles} id={id}>
      <Typography className={styles.title}
                  variant="h6"
                  component="div"
                  color={palette.secondary.dark}>
        {title}
      </Typography>
      {errorName && <Alert severity="error" data-testid="error_alert">{getErrorMessage(errorName)}</Alert>}
      {isLoading && <CircularProgress data-testid="loading" className={styles.loading} size={30} />}
      {children}
    </div>
  );
};

export default ModuleSectionLayout;
