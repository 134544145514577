import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import {RootState} from "store";
import AppError from "core/error/AppError";
import ReducerServices from "store/service/ReducerServices";
import CoacheeMotivator from "module/motivator/coachee/domain/model/CoacheeMotivator";
import AddCoacheeMotivator from "module/motivator/coachee/domain/model/AddCoacheeMotivator";

export const CoacheeMotivatorStore = createModel<RootModel>()({
  state: {
    motivators: [] as CoacheeMotivator[],
    getters: (state: RootState) => ({
      getMotivatorsByCoacheeId: (id: string) => {
        return state.CoacheeMotivatorStore.motivators.filter(
          motivator => motivator.coacheeId == id);
      }
    })
  },
  reducers: {
    setMotivator(
      state,
      motivator: CoacheeMotivator
    ) {
      return {
        ...state,
        motivators: ReducerServices.setItemToList<CoacheeMotivator>(
          state.motivators,
          motivator
        )
      };
    },
    setMotivators(
      state,
      motivators: CoacheeMotivator[]
    ) {
      return {
        ...state,
        motivators: ReducerServices.setItemsToList<CoacheeMotivator>(
          state.motivators,
          motivators
        )
      };
    },
    removeMotivatorById(
      state,
      motivatorId: string
    ) {
      return {
        ...state,
        motivators: ReducerServices.removeItemFromList<CoacheeMotivator>(
          state.motivators,
          motivatorId
        )
      };
    },
  },
  effects: (dispatch) => ({
    async addMotivator(payload: { addMotivator: AddCoacheeMotivator }) {
      return await clients.CoacheeMotivator
        .addMotivatorForCoachee(payload.addMotivator, payload.addMotivator.coacheeId)
        .then((addedMotivator: CoacheeMotivator) => {
          dispatch.CoacheeMotivatorStore.setMotivator(addedMotivator);
          
          return addedMotivator;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteMotivator(payload: { motivatorId: string, coacheeId: string }) {
      return await clients.CoacheeMotivator
        .deleteMotivatorForCoachee(payload.motivatorId, payload.coacheeId)
        .then(() => {
          dispatch.CoacheeMotivatorStore.removeMotivatorById(payload.motivatorId);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async fetchMotivatorsByCoacheeId(id: string) {
      await clients.CoacheeMotivator.fetchMotivatorsByCoacheeId(id).then(
        (motivators: CoacheeMotivator[]) => {
          dispatch.CoacheeMotivatorStore.setMotivators(motivators);
        }).catch((error: AppError) => {
        throw error.name;
      });
    }
  })
});
