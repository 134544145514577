import type RESTClient from "core/client/RESTClient";
import UserRepository from "module/user/domain/UserRepository";
import UserResponse, {userResponseToModel} from "module/user/dal/dto/UserResponse";
import User from "module/user/domain/model/User";
import UpdateUser from "module/user/domain/model/UpdateUser";
import {createUpdateUserRequest} from "module/user/dal/dto/UpdateUserRequest";

export default class RESTUserRepository implements UserRepository {
  private static readonly BASE_URL: string = "/api/me";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchCurrentUser(): Promise<User> {
    const userResponse = await this.client.apiGet<UserResponse>(
      `${RESTUserRepository.BASE_URL}`
    );
    
    return userResponseToModel(userResponse);
  }
  
  public async updateCurrentUser(updateUser: UpdateUser): Promise<User> {
    const userResponse = await this.client.apiPut<UserResponse>(
      `${RESTUserRepository.BASE_URL}`,
      createUpdateUserRequest(updateUser)
    );
    
    return userResponseToModel(userResponse);
  }
}
