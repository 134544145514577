import React from "react";
import {Alert, Box, CircularProgress, Slider} from "asset/component";
import Coachee from "module/coachee/domain/model/Coachee";
import CoacheeThumbnailComponent from "module/coachee/view/component/CoacheeThumbnailComponent";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import {ErrorNames} from "core/error/ErrorNames";
import {CoacheesSequence} from "module/onboarding/domain/model/SeedData/OnboardingSequencesSeed";

const CoacheesListingComponent = ({
  coachees,
  teamId,
  isLoading,
  errorName,
  slidesNumber,
  hasAnchor
}: {
  coachees: Coachee[],
  teamId?: string,
  isLoading: boolean,
  errorName?: ErrorNames,
  slidesNumber?: number,
  hasAnchor?: boolean
}) => {
  const {getErrorMessage} = useErrorTranslation();
  
  const coacheesListing = (
    <Box data-testid="coachees_listing_component">
      <Slider slidesToShow={slidesNumber}>
        {coachees.map((coachee, index) => (
          index === 0 ? <CoacheeThumbnailComponent
              key={coachee.id}
              id={hasAnchor ? CoacheesSequence.steps[1].anchorID : ""}
              dataParamId={JSON.stringify({coacheeId: coachee.id, teamId})}
              data-testid={`coachee_${coachee.id}`}
              coachee={coachee}
              teamId={teamId} /> :
            <CoacheeThumbnailComponent
              key={coachee.id}
              data-testid={`coachee_${coachee.id}`}
              coachee={coachee}
              teamId={teamId} />
        ))}
      </Slider>
    </Box>
  );
  
  const coacheesLoading = <CircularProgress data-testid="loading_coachees" />;
  
  const coacheesError = <Alert severity="error"
                               data-testid="error_coachees">{errorName && getErrorMessage(errorName)}</Alert>;
  
  const displayLoading = () => {
    return isLoading && !coachees.length;
  };
  
  const renderCoacheesListing = () => {
    if (errorName) {
      return coacheesError;
    } else if (displayLoading()) {
      return coacheesLoading;
    } else {
      return coacheesListing;
    }
  };
  
  return renderCoacheesListing();
};

export default CoacheesListingComponent;
