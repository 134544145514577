import Profile from "module/profile/domain/model/Profile";
import RoleResponse, {rolesResponseToModel} from "module/profile/dal/dto/RoleResponse";

export default interface ProfileResponse {
  tenantId: string;
  roles: RoleResponse[];
}

export function profileResponseToModel(profileResponse: ProfileResponse): Profile {
  return new Profile(
    profileResponse.tenantId,
    rolesResponseToModel(profileResponse.roles)
  );
}

export function profilesResponseToModel(profilesResponse: ProfileResponse[]): Profile[] {
  return profilesResponse.map(profileResponse => profileResponseToModel(profileResponse));
}

