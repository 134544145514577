import {Cookies} from 'react-cookie';

export default class ApiUrlServices {
  public static readonly COOKIES = new Cookies();
  private static readonly CURRENT_TENANT_COOKIE_NAME = 'api_url_override';
  
  public static getApiUrlOverride(): string {
    return ApiUrlServices.COOKIES.get(ApiUrlServices.CURRENT_TENANT_COOKIE_NAME);
  }
}
