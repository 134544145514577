import {Tag} from "asset/component/tag/Tag";
import Skill from "module/skill/domain/model/Skill";
import i18n from "i18next";

const PREDEFINED_SKILL_TRANSLATION_KEY = "skill:predefinedSkills";

export const PredefinedSkills = [
  "autonomous",
  "cohesion",
  "collaborative",
  "flexible",
  "innovative",
  "commonVision",
  "proactive"
];

const getTranslationSkill = (predefinedSkill) =>{
  return i18n.t(`${PREDEFINED_SKILL_TRANSLATION_KEY}.${predefinedSkill}`)
}

export const getFilteredPredefinedSkills = (actualSkills: Skill[]) => {
  return PredefinedSkills.filter((predefinedSkill) => {
    return !actualSkills.some((skill) => skill.name === getTranslationSkill(predefinedSkill));
  }).map(predefinedSkill => ({
    id: predefinedSkill,
    name: getTranslationSkill(predefinedSkill),
    isSelected: false
  } as Tag));
};
