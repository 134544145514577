import React from 'react';

const SearchIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.4998 21.4433L16.8373 15.7508C19.6493 12.3579 19.2985 7.35412 16.0405 4.38689C12.7825 1.41966 7.76774 1.53671 4.65173 4.65271C1.53573 7.76871 1.41868 12.7835 4.38591 16.0414C7.35315 19.2994 12.357 19.6503 15.7498 16.8383L21.4423 22.5008L22.4998 21.4433ZM3.74985 10.5008C3.74985 6.7729 6.77193 3.75083 10.4998 3.75083C14.2278 3.75083 17.2498 6.7729 17.2498 10.5008C17.2498 14.2287 14.2278 17.2508 10.4998 17.2508C6.77193 17.2508 3.74985 14.2287 3.74985 10.5008Z"
        fill="#20463D" />
    </svg>
  );
};

export default SearchIcon;
