import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import {RootState} from "store";
import AppError from "core/error/AppError";
import ObjectiveTeam from "./domain/model/ObjectiveTeam";
import ReducerServices from "store/service/ReducerServices";
import AddObjective from "module/objective/domain/model/AddObjective";
import UpdateObjective from "module/objective/domain/model/UpdateObjective";
import AddSubObjective from "module/objective/domain/model/AddSubObjective";
import UpdateSubObjective from "module/objective/domain/model/UpdateSubObjective";
import {NeedsTeam} from "module/objective/domain/model/need/NeedsTeam";
import NeedStats from "module/objective/domain/model/need/NeedStats";
import TeamNeedsStats from "module/objective/domain/model/need/TeamNeedsStats";
import ObjectiveState from "module/objective/domain/model/ObjectiveState";
import AddObjectiveState from "module/objective/domain/model/AddObjectiveState";
import UpdateObjectiveStateComment from "module/objective/domain/model/UpdateObjectiveStateComment";
import AddObjectiveStateComment from "module/objective/domain/model/AddObjectiveStateComment";
import ObjectiveStateComment from "module/objective/domain/model/ObjectiveStateComment";
import ObjectiveReducers from "module/objective/view/reducers/ObjectiveReducers";

export const ObjectiveTeamStore = createModel<RootModel>()({
  state: {
    objectivesTeam: [] as ObjectiveTeam[],
    needsStats: {} as TeamNeedsStats,
    objectiveStateHistory: [] as ObjectiveState[],
    getters: (state: RootState) => ({
      getObjectivesByTeamId: (id: string) => {
        return state.ObjectiveTeamStore
          .objectivesTeam
          .filter(objective => objective.teamId == id);
      },
      getObjectivesTeamByNeed: (id: string, need: NeedsTeam) => {
        return state.ObjectiveTeamStore
          .objectivesTeam
          .filter(objective => objective.teamId == id)
          .filter(objective => objective.needs.includes(need));
      },
      getCompletedSubObjectivesTeamsCount: (objectiveId: string) => {
        return state.ObjectiveTeamStore
          .objectivesTeam
          .find(objective => objective.id == objectiveId)
          ?.subObjectives
          .filter(subObjective => subObjective.completed).length || 0;
      },
      getStatsForNeed: (need: NeedsTeam) => {
        return state.ObjectiveTeamStore.needsStats[need.toLowerCase()];
      },
      getObjectiveStateHistory: () => {
        return state.ObjectiveTeamStore.objectiveStateHistory
      }
    })
  },
  reducers: {
    removeObjectiveById(
      state,
      objectiveId: string
    ) {
      return {
        ...state,
        objectivesTeam: ReducerServices.removeItemFromList<ObjectiveTeam>(
          state.objectivesTeam,
          objectiveId)
      };
    },
    setObjectivesTeam(
      state,
      objectivesTeam: ObjectiveTeam[]
    ) {
      return {
        ...state,
        objectivesTeam: ReducerServices.setItemsToList<ObjectiveTeam>(
          state.objectivesTeam,
          objectivesTeam)
      };
    },
    setObjectiveTeam(
      state,
      objectiveTeam: ObjectiveTeam
    ) {
      return {
        ...state,
        objectivesTeam: ReducerServices.setItemToList<ObjectiveTeam>(
          state.objectivesTeam,
          objectiveTeam)
      };
    },
    setNeedStats(
      state,
      need: NeedsTeam,
      needStats: NeedStats
    ) {
      return {
        ...state,
        needsStats: TeamNeedsStats.setTeamNeedsStatsFromNeed(state.needsStats, need, needStats)
      };
    },
    setObjectiveStateHistory(
      state,
      objectiveStateHistory: ObjectiveState[]
    ) {
      return {
        ...state,
        objectiveStateHistory: ReducerServices.setItemsToList<ObjectiveState>(
          state.objectiveStateHistory,
          objectiveStateHistory)
      }
    },
    setObjectiveStateHistoryToEmpty(state) {
      return {
        ...state,
        objectiveStateHistory: []
      };
    },
    addCommentToObjectiveStateFoundById(
      state,
      objectiveStateId: string,
      objectiveStateComment: ObjectiveStateComment
    ) {
      return {
        ...state,
        ObjectiveStateHistory : ObjectiveReducers.addCommentToObjectiveStateFoundById(state, objectiveStateId, objectiveStateComment)
      }
    }
  },
  effects: (dispatch) => ({
    async fetchObjectivesByTeamId(payload: { id: string, need?: NeedsTeam }) {
      await clients.ObjectiveTeam
        .fetchObjectivesByTeamId(payload.id, payload.need)
        .then((objectivesTeam: ObjectiveTeam[]) => {
          dispatch.ObjectiveTeamStore.setObjectivesTeam(
            objectivesTeam);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async addObjectiveForTeam(payload: { addObjective: AddObjective, teamId: string }) {
      return await clients.ObjectiveTeam
        .addObjectiveForTeam(payload.addObjective, payload.teamId)
        .then((addedObjective) => {
          dispatch.ObjectiveTeamStore.setObjectiveTeam(addedObjective);
          dispatch.ObjectiveTeamStore.setObjectiveStateHistory([]);
          return addedObjective;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async updateObjectiveForTeam(payload: {
      updateObjective: UpdateObjective,
      objectiveId: string,
      teamId: string
    }) {
      return await clients.ObjectiveTeam
        .updateObjectiveForTeam(payload.updateObjective, payload.objectiveId, payload.teamId)
        .then((updatedObjective) => {
          dispatch.ObjectiveTeamStore.setObjectiveTeam(updatedObjective);
          dispatch.ObjectiveTeamStore.fetchNeedsStats({teamId: payload.teamId});
          return updatedObjective;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteObjectiveForTeam(payload: { objectiveId: string, teamId: string }) {
      return await clients.ObjectiveTeam
        .deleteObjectiveForTeam(payload.objectiveId, payload.teamId)
        .then(() => {
          dispatch.ObjectiveTeamStore.removeObjectiveById(payload.objectiveId);
          dispatch.ObjectiveTeamStore.fetchNeedsStats({teamId: payload.teamId});
          dispatch.ObjectiveTeamStore.setObjectiveStateHistoryToEmpty();
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async addSubObjectiveForTeam(payload: {
      addSubObjective: AddSubObjective,
      objectiveId: string,
      teamId: string
    }) {
      return await clients.ObjectiveTeam
        .addSubObjectiveForTeam(payload.addSubObjective, payload.objectiveId, payload.teamId)
        .then((addedSubObjective) => {
          dispatch.ObjectiveTeamStore.fetchObjectivesByTeamId({id: payload.teamId});
          dispatch.ObjectiveTeamStore.fetchNeedsStats({teamId: payload.teamId});
          
          return addedSubObjective;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async updateSubObjectiveForTeam(payload: {
      updateSubObjective: UpdateSubObjective,
      subObjectiveId: string,
      objectiveId: string,
      teamId: string
    }) {
      return await clients.ObjectiveTeam
        .updateSubObjectiveForTeam(payload.updateSubObjective, payload.subObjectiveId, payload.objectiveId, payload.teamId)
        .then((updatedSubObjective) => {
          dispatch.ObjectiveTeamStore.fetchObjectivesByTeamId({id: payload.teamId});
          dispatch.ObjectiveTeamStore.fetchNeedsStats({teamId: payload.teamId});
          
          return updatedSubObjective;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteSubObjectiveForTeam(payload: {
      subObjectiveId: string,
      objectiveId: string,
      teamId: string
    }) {
      return await clients.ObjectiveTeam
        .deleteSubObjectiveForTeam(payload.subObjectiveId, payload.objectiveId, payload.teamId)
        .then(() => {
          dispatch.ObjectiveTeamStore.fetchObjectivesByTeamId({id: payload.teamId});
          dispatch.ObjectiveTeamStore.fetchNeedsStats({teamId: payload.teamId});
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async fetchNeedStats(payload: { teamId: string, need: NeedsTeam }) {
      return await clients.ObjectiveTeam
        .fetchObjectiveNeedsStatsByNeed(payload.teamId, payload.need)
        .then((needStats) => {
          dispatch.ObjectiveTeamStore.setNeedStats(payload.need, needStats);
          
          return needStats;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async fetchNeedsStats(payload: {
      teamId: string,
    }) {
      Object.values(NeedsTeam).forEach(
        need =>
          dispatch.ObjectiveTeamStore.fetchNeedStats({
            teamId: payload.teamId,
            need
          }));
    },
    async addObjectiveState(payload: {
      addObjectiveState: AddObjectiveState,
      teamId: string,
      objectiveId: string,
    }) {
      return await clients.ObjectiveTeam.addObjectiveStateForTeam(payload.addObjectiveState, payload.teamId, payload.objectiveId)
        .then((createdObjectiveState) => {
          dispatch.ObjectiveTeamStore.fetchObjectivesByTeamId({id: payload.teamId});
          dispatch.ObjectiveTeamStore.fetchObjectiveStateHistory({teamId: payload.teamId, objectiveId: payload.objectiveId});
          return createdObjectiveState;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async updateObjectiveStateComment(payload: {
      updateObjectiveStateComment: UpdateObjectiveStateComment,
      teamId: string,
      objectiveId: string,
    }) {
      return await clients.ObjectiveTeam.updateObjectiveStateCommentForTeam(payload.updateObjectiveStateComment, payload.teamId, payload.objectiveId)
        .then((updatedObjectiveStateComment) => {
          dispatch.ObjectiveTeamStore.fetchObjectivesByTeamId({id: payload.teamId});
          return updatedObjectiveStateComment;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async fetchObjectiveStateHistory(payload: {
      teamId: string,
      objectiveId: string
    }) {
      return await clients.ObjectiveTeam.fetchObjectiveStateHistory(payload.teamId, payload.objectiveId).then((objectiveStateHistory: ObjectiveState[] ) => {
        dispatch.ObjectiveTeamStore.setObjectiveStateHistory(objectiveStateHistory)
      }).catch((error: AppError) => {
        throw error.name;
      });
    },
    async addObjectiveStateComment(payload: {
      addObjectiveStateComment: AddObjectiveStateComment,
      teamId: string,
      objectiveId: string,
      objectiveStateId: string,
    }) {
      return await clients.ObjectiveTeam.addObjectiveStateCommentForTeam(payload.addObjectiveStateComment, payload.teamId, payload.objectiveId, payload.objectiveStateId)
        .then((createdObjectiveStateComment) => {
          dispatch.ObjectiveTeamStore.addCommentToObjectiveStateFoundById(payload.objectiveStateId, createdObjectiveStateComment);
          return createdObjectiveStateComment;
        }).catch((error: AppError) => {
          throw error.name;
        });
    }
  })
});
