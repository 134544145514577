import {Tag} from "asset/component/tag/Tag";
import CoacheeMotivator from "module/motivator/coachee/domain/model/CoacheeMotivator";
import TeamMotivator from "module/motivator/team/domain/model/TeamMotivator";

const convertMotivatorsToTags = (motivators: CoacheeMotivator[] | TeamMotivator[]): Tag[] => {
  return motivators ? motivators.map(
    (motivator: CoacheeMotivator | TeamMotivator) => {
      return {
        id: motivator.id,
        name: motivator.title,
        isSelected: true
      };
    }) : [];
};

export default convertMotivatorsToTags;
