import {createModel} from "@rematch/core";
import Mandate from "module/mandate/domain/model/Mandate";
import {RootState} from "store";
import {RootModel} from "store/RootModel";
import ReducerServices from "store/service/ReducerServices";
import AppError from "core/error/AppError";
import clients from "module/common/client";
import AddMandate from "module/mandate/domain/model/AddMandate";
import {
  applyDisplayFinished,
  getFilteredMandates,
  orderByDeadlineDescending
} from "module/mandate/view/container/MandateMethods";

export const MandateStore = createModel<RootModel>()({
  state: {
    mandates: [] as Mandate[],
    getters: (state: RootState) => ({
      getMandates: (displayFinish: boolean = false, sortedByDeadLine?: boolean, searchValue?: string) => {
        let mandates = state.MandateStore.mandates;
        
        if (searchValue) mandates = getFilteredMandates(mandates, searchValue);
        
        if (!displayFinish) mandates = applyDisplayFinished(mandates);
        
        if (sortedByDeadLine) mandates = orderByDeadlineDescending(mandates);
        
        return mandates;
      },
      getMandateById: (id: string) => {
        return state.MandateStore.mandates.find((mandate: Mandate) => mandate.id === id);
      },
      getMandatesByCoacheeId: (coacheeId: string) => {
        return state.MandateStore.mandates.filter((mandate: Mandate) => mandate.coachees.includes(coacheeId));
      },
      getMandatesByTeamId: (teamId: string) => {
        return state.MandateStore.mandates.filter((mandate: Mandate) => mandate.teams.includes(teamId));
      }
    }),
  },
  reducers: {
    setMandate(
      state,
      mandate: Mandate
    ) {
      return {
        ...state,
        mandates: ReducerServices.setItemToList<Mandate>(
          state.mandates,
          mandate
        )
      };
    },
    setMandates(
      state,
      mandates: Mandate[]
    ) {
      return {
        ...state,
        mandates: ReducerServices.setItemsToList<Mandate>(
          state.mandates,
          mandates
        )
      };
    },
    removeMandateById(
      state,
      mandateId: string
    ) {
      if (!state.mandates.find((mandate: Mandate) => mandate.id === mandateId) || !mandateId) {
        return state;
      }
      
      return {
        ...state,
        mandates: ReducerServices.removeItemFromList<Mandate>(
          state.mandates,
          mandateId
        )
      };
    },
  },
  effects: (dispatch) => ({
    async addMandate(payload: {
      addMandate: AddMandate
    }) {
      return await clients.Mandate
        .addMandate(payload.addMandate)
        .then((mandate: Mandate) => {
          dispatch.MandateStore.setMandate(mandate);
          return mandate;
        })
        .catch((error: AppError) => {
          throw error;
        });
    },
    async updateMandate(payload: {
      mandate: Mandate
    }) {
      return await clients.Mandate
        .updateMandate(payload.mandate)
        .then((mandate: Mandate) => {
          dispatch.MandateStore.setMandate(mandate);
          return mandate;
        })
        .catch((error: AppError) => {
          throw error;
        });
    },
    async fetchMandatesForTenant() {
      await clients.Mandate
        .fetchMandatesForTenant()
        .then((mandates: Mandate[]) => {
          dispatch.MandateStore.setMandates(mandates);
        })
        .catch((error: AppError) => {
          throw error;
        });
    },
    async fetchMandateById(id: string) {
      await clients.Mandate
        .fetchMandateById(id)
        .then((mandate: Mandate) => {
          dispatch.MandateStore.setMandate(mandate);
        })
        .catch((error: AppError) => {
          throw error;
        });
    },
    async deleteMandate(id: string) {
      await clients.Mandate
        .deleteMandate(id)
        .then(() => {
          dispatch.MandateStore.removeMandateById(id);
        })
        .catch((error: AppError) => {
          throw error;
        });
    },
  })
});
