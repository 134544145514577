import AddTeamDemotivator from "module/demotivator/team/domain/model/AddTeamDemotivator";

export default interface AddTeamDemotivatorRequest {
  title: string;
  teamId: string;
}

export function createAddTeamDemotivatorRequest(addDemotivator: AddTeamDemotivator): AddTeamDemotivatorRequest {
  return {
    title: addDemotivator.title,
    teamId: addDemotivator.teamId,
  } as AddTeamDemotivatorRequest;
}
