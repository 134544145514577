import * as React from "react";

const ExpandIcon = () => {
  return (
    <svg width="100%"
         height="100%"
         viewBox="0 0 20 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M18 6L10.5 13L3 6"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round" />
    </svg>
  );
};

export default ExpandIcon;
