import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import AppError from "core/error/AppError";
import ReducerServices from "store/service/ReducerServices";
import Profile from "module/profile/domain/model/Profile";
import TenantServices from "module/profile/dal/service/TenantServices";
import {RootState} from "store";

export const ProfileStore = createModel<RootModel>()({
  state: {
    profiles: [] as Profile[],
    selectedProfile: undefined as Profile | undefined,
    getters: (state: RootState) => ({
      getSelectedRoles: () => {
        return state.ProfileStore.selectedProfile?.defaultRole;
      }
    }),
  },
  reducers: {
    setProfiles(state, profiles: Profile[]) {
      return {
        ...state,
        profiles: ReducerServices.setItemsToList<Profile>(
          state.profiles,
          profiles
        )
      };
    },
    setDefaultProfile(state) {
      const defaultProfile = state.profiles[0];
      
      defaultProfile && TenantServices.setCurrentTenantId(defaultProfile.tenantId);
      
      return {
        ...state,
        selectedProfile: defaultProfile
      };
    }
  },
  effects: (dispatch) => ({
    async fetchProfiles() {
      await clients.Profile
        .fetchProfiles()
        .then((profiles: Profile[]) => {
          dispatch.ProfileStore.setProfiles(profiles);
          dispatch.ProfileStore.setDefaultProfile();
        })
        .catch((error: AppError) => {
          throw error.name;
        });
    }
  })
});
