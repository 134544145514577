import {ErrorNames} from 'core/error/ErrorNames';

export default abstract class AppError extends Error {
  private readonly _stackable: boolean;
  private readonly _name: ErrorNames;
  
  protected constructor(message: string, name: ErrorNames, stackable: boolean) {
    super(message);
    
    this._name = name;
    this._stackable = stackable;
  }
  
  get stackable(): boolean {
    return this._stackable;
  }
  
  get name(): ErrorNames {
    return this._name;
  }
}
