import React from 'react';
import styles from "module/mandate/view/asset/edit/MandateDeleteCloseSidebar.module.scss";
import DeleteConfirmation from "asset/component/global/dialogs/DeleteConfirmation";
import {CloseIcon} from "asset/component/Icons";
import {IconButton} from "asset/component";
import {MuiButtonColors} from "style/props/MuiButtonColors";
import {useTranslation} from "react-i18next";
import usePaletteColors from "style/usePaletteColors";

const MandateDeleteCloseSidebar = ({
  close,
  deleteMandate
}: {
  close: () => void,
  deleteMandate: () => void
}) => {
  const {t} = useTranslation('mandate');
  const palette = usePaletteColors();
  
  return (
    <div className={styles.deleteClose}>
      <DeleteConfirmation deleteAction={deleteMandate} confirmationText={t("form.delete.confirmation")} />
      <IconButton
        color={MuiButtonColors.SECONDARY_VERY_DARK}
        data-testid="close-button"
        className={styles.closeButton}
        onClick={close}
        iconColor={palette.secondary.veryDark}
        backgroundColor="transparent"
        buttonSize="40px"
        iconSize="22px"
        icon={<CloseIcon />}
      />
    </div>
  );
};

export default MandateDeleteCloseSidebar;
