import * as React from "react";
import {useEffect, useRef} from "react";
import {Alert, Grid} from "asset/component";
import {TextValidator, useFormValidation, ValidatorForm} from "asset/validator";
import {InputValidator} from "asset/validator/InputValidator";
import {MuiInputColors} from "style/props/MuiInputColors";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import styles from "module/user/view/asset/PersonalInformationsComponent.module.scss";
import SaveState from "asset/component/form/SaveState";
import useAutoSave from "asset/component/hook/useAutoSave";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import {ErrorNames} from "core/error/ErrorNames";

interface PersonalInformationsComponentProps {
  updatePersonalInformations: () => void;
  formState: {
    firstName: string
    setFirstName: (firstName: string) => void
    lastName: string
    setLastName: (lastName: string) => void
    email: string
    setEmail: (email: string) => void
  };
  errorName?: ErrorNames;
}

const PersonalInformationsComponent = ({
  updatePersonalInformations,
  formState,
  errorName
}: PersonalInformationsComponentProps) => {
  const {t} = useTranslation('user');
  const formRef = useRef<HTMLFormElement>();
  const {getErrorMessage} = useErrorTranslation();
  const {isFormValid, submitForm} = useFormValidation(formRef, []);
  const {
    value: firstName,
    isSaving: isSavingFirstName,
    isError: isErrorFirstName,
    onChange: onFirstNameChange,
    
  } = useAutoSave({
    initialValue: formState.firstName,
    saveAction: formState.setFirstName
  });
  
  const {
    value: lastName,
    isSaving: isSavingLastName,
    isError: isErrorLastName,
    onChange: onLastNameChange,
    
  } = useAutoSave({
    initialValue: formState.lastName,
    saveAction: formState.setLastName
  });
  
  useEffect(() => {
    submitForm();
  }, [formState.firstName, formState.lastName]);
  
  const errorAlert =
    errorName &&
      <Alert severity="error"
             data-testid="error-message">
        {errorName && getErrorMessage(errorName)}
      </Alert>;
  
  return <div id={styles.personalInformationsComponent}>
    {errorAlert}
    <Typography className={styles.title} variant={MuiTypographyVariants.H3}>
      {t('personalInformations')}
    </Typography>
    <ValidatorForm
      data-testid="update-personal-informations"
      ref={formRef}
      onSubmit={updatePersonalInformations}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12}>
          <Typography className={styles.label} variant={MuiTypographyVariants.XS}>{t('form.email')}</Typography>
          <TextValidator
            className={styles.textField}
            required
            disabled
            fullWidth
            data-testid="email-input"
            id="email"
            placeholder={t('form.email')}
            name="email"
            autoComplete="email"
            value={formState.email}
            color={MuiInputColors.SECONDARY}
            onChange={(event) => formState.setEmail(event.target.value)}
            validators={[InputValidator.REQUIRED, InputValidator.IS_EMAIL]}
            errorMessages={[t('form.validation.emailRequired'), t('form.validation.emailIsValid')]}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className={styles.label} variant={MuiTypographyVariants.XS}>{t('form.firstName')}</Typography>
          <div className={styles.inputContainer}>
            <TextValidator
              className={styles.textField}
              required
              fullWidth
              type="text"
              data-testid="first-name-input"
              id="firstName"
              placeholder={t('form.firstName')}
              name="firstName"
              value={firstName}
              onChange={(event) => onFirstNameChange(event.target.value)}
              validators={[InputValidator.REQUIRED]}
              errorMessages={[t('form.validation.firstNameRequired')]}
              color={MuiInputColors.SECONDARY}
            />
            {isFormValid &&
                <SaveState isSaving={isSavingFirstName}
                           isError={isErrorFirstName}
                           isSaved={!isErrorFirstName && !isSavingFirstName}
                           noText
                />
            }
          </div>
        </Grid>
        <Grid item xs={6}>
          <Typography className={styles.label} variant={MuiTypographyVariants.XS}>{t('form.lastName')}</Typography>
          <div className={styles.inputContainer}>
            <TextValidator
              className={styles.textField}
              required
              fullWidth
              type="text"
              data-testid="last-name-input"
              id="lastName"
              placeholder={t('form.lastName')}
              name="lastName"
              value={lastName}
              onChange={(event) => onLastNameChange(event.target.value)}
              validators={[InputValidator.REQUIRED]}
              color={MuiInputColors.SECONDARY}
              errorMessages={[t('form.validation.lastNameRequired')]}
            />
            {isFormValid &&
                <SaveState isSaving={isSavingLastName}
                           isError={isErrorLastName}
                           isSaved={!isErrorLastName && !isSavingLastName}
                           noText
                />
            }
          </div>
        </Grid>
      </Grid>
    </ValidatorForm>
  </div>;
};

export default PersonalInformationsComponent;
