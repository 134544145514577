import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import {RootState} from "store";
import AppError from "core/error/AppError";
import ReducerServices from "store/service/ReducerServices";
import FollowUpTeam from "module/followUp/domain/model/FollowUpTeam";
import AddFollowUp from "module/followUp/domain/model/AddFollowUp";
import UpdateFollowUp from "module/followUp/domain/model/UpdateFollowUp";

export const FollowUpTeamStore = createModel<RootModel>()({
  state: {
    followUpsTeam: [] as FollowUpTeam[],
    getters: (state: RootState) => ({
      getFollowUpsByTeamId: (id: string) => {
        return state.FollowUpTeamStore.followUpsTeam.filter(
          followUp => followUp.teamId == id);
      }
    })
  },
  reducers: {
    removeFollowUpTeamById(
      state,
      followUpId: string
    ) {
      return {
        ...state,
        followUpsTeam: ReducerServices.removeItemFromList<FollowUpTeam>(
          state.followUpsTeam,
          followUpId)
      };
    },
    setFollowUpsTeam(
      state,
      followUpsTeam: FollowUpTeam[]
    ) {
      return {
        ...state,
        followUpsTeam: ReducerServices.setItemsToList<FollowUpTeam>(
          state.followUpsTeam,
          followUpsTeam)
      };
    },
    setFollowUpTeam(
      state,
      followUpTeam: FollowUpTeam
    ) {
      return {
        ...state,
        followUpsTeam: ReducerServices.setItemToList<FollowUpTeam>(
          state.followUpsTeam,
          followUpTeam)
      };
    },
  },
  effects: (dispatch) => ({
    async fetchFollowUpsByTeamId(id: string) {
      await clients.FollowUpTeam.fetchFollowUpsByTeamId(id).then(
        (followUpsTeam: FollowUpTeam[]) => {
          dispatch.FollowUpTeamStore.setFollowUpsTeam(
            followUpsTeam);
        }).catch((error: AppError) => {
        throw error.name;
      });
    },
    async addFollowUpForTeam(payload: { addFollowUp: AddFollowUp, teamId: string }) {
      return await clients.FollowUpTeam
        .addFollowUpForTeam(payload.addFollowUp, payload.teamId)
        .then((addedFollowUp) => {
          dispatch.FollowUpTeamStore.setFollowUpTeam(addedFollowUp);
          
          return addedFollowUp;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async updateFollowUpForTeam(payload: {
      updateFollowUp: UpdateFollowUp,
      followUpId: string,
      teamId: string
    }) {
      return await clients.FollowUpTeam
        .updateFollowUpForTeam(payload.updateFollowUp, payload.followUpId, payload.teamId)
        .then((updatedFollowUp) => {
          dispatch.FollowUpTeamStore.setFollowUpTeam(updatedFollowUp);
          
          return updatedFollowUp;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteFollowUpForTeam(payload: { followUpId: string, teamId: string }) {
      return await clients.FollowUpTeam
        .deleteFollowUpForTeam(payload.followUpId, payload.teamId)
        .then(() => {
          dispatch.FollowUpTeamStore.removeFollowUpTeamById(payload.followUpId);
        }).catch((error: AppError) => {
          throw error.name;
        });
    }
  })
});
