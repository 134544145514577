import React from 'react';
import UpdateInformalRole from "module/skill/domain/model/UpdateInformalRole";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import InformalRoleComponent from "module/skill/view/component/InformalRoleComponent";

const InformalRoleContainer = ({coacheeId, id}: { coacheeId: string, id?: string }) => {
  const dispatch = useDispatch<Dispatch>();
  
  const informalRole = useSelector(
    (rootState: RootState) => rootState.InformalRoleStore?.getters(rootState).getInformalRoleByCoacheeId(coacheeId)
  );
  
  const fetchInformalRoleState = useLoadingAndError(
    () => dispatch.InformalRoleStore.fetchInformalRole({coacheeId}), [], coacheeId
  );
  
  const [isUpdateInformalRoleLoading, updateInformalRoleErrorName, dispatchUpdateInformalRole] =
    usePromiseLoadingAndError((title) =>
      dispatch.InformalRoleStore.updateInformalRole({
        updateInformalRole: new UpdateInformalRole(title),
        coacheeId
      })
    );
  
  return <InformalRoleComponent informalRole={informalRole}
                                updateInformalRole={dispatchUpdateInformalRole}
                                errorName={fetchInformalRoleState.errorName || updateInformalRoleErrorName}
                                isLoading={fetchInformalRoleState.isLoading || isUpdateInformalRoleLoading}
                                id={id}
  />;
};

export default InformalRoleContainer;
