import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Typography} from "asset/component/index";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import usePaletteColors from "style/usePaletteColors";

const MAX_MORNING_VALUE = 12;
const MAX_AFTER_NOON_VALUE = 17;

const Greeting = ({name}: { name?: string }) => {
  const {t} = useTranslation('common');
  const palette = usePaletteColors();
  const [greetingText, setGreetingText] = useState('');
  
  useEffect(() => {
    const updateTimeOfDay = () => {
      const currentHour = new Date(Date.now()).getHours();
      
      if (currentHour < MAX_MORNING_VALUE) {
        setGreetingText(t('welcome.morning', {name: name}));
      } else if (currentHour < MAX_AFTER_NOON_VALUE) {
        setGreetingText(t('welcome.afternoon', {name: name}));
      } else {
        setGreetingText(t('welcome.evening', {name: name}));
      }
    };
    
    updateTimeOfDay();
    
    const timerID = setInterval(updateTimeOfDay, 1000);
    
    return () => {
      clearInterval(timerID);
    };
  }, [name]);
  
  return <Typography component="h1" variant={MuiTypographyVariants.H1} color={palette.primary.main}>
    {greetingText}
  </Typography>;
};

export default Greeting;
