import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import ServerIsDownError from 'core/error/ServerIsDownError';
import AxiosInstanceFactory from 'core/client/AxiosInstanceFactory';
import {mapRESTResponseToError} from "core/helper/ErrorMapper";
import AppError from "core/error/AppError";

export default class RESTClient {
  protected readonly axiosInstance: AxiosInstance;
  
  constructor();
  constructor(axiosInstance: AxiosInstance);
  constructor(axiosInstance?: AxiosInstance) {
    this.axiosInstance = axiosInstance || AxiosInstanceFactory.create();
  }
  
  protected static mapToError(response: AxiosResponse): AppError {
    if (!response) {
      return new ServerIsDownError();
    }
    
    return mapRESTResponseToError(response);
  }
  
  public async apiGet<T>(url: string, queryParams?: object): Promise<T> {
    try {
      const result: AxiosResponse<T> = await this.axiosInstance.get<T>(url, {
        params: queryParams,
      });
      
      return result.data;
    } catch (error: any) {
      throw RESTClient.mapToError(error.response);
    }
  }
  
  public async apiPost<T>(url: string, data: object, config?: AxiosRequestConfig): Promise<T> {
    try {
      const result: AxiosResponse<T> = await this.axiosInstance.post<T>(url, data, config);
      return result.data;
    } catch (error: any) {
      throw RESTClient.mapToError(error.response);
    }
  }
  
  public async apiPut<T>(url: string, data: object): Promise<T> {
    try {
      const result: AxiosResponse<T> = await this.axiosInstance.put<T>(url, data);
      return result.data;
    } catch (error: any) {
      throw RESTClient.mapToError(error.response);
    }
  }
  
  public async apiDelete<T>(url: string, queryParams?: object): Promise<T> {
    try {
      const result: AxiosResponse<T> = await this.axiosInstance.delete<T>(url, {
        params: queryParams,
      });
      return result.data;
    } catch (error: any) {
      throw RESTClient.mapToError(error.response);
    }
  }
}
