import AddFollowUp from "module/followUp/domain/model/AddFollowUp";

export default interface AddFollowUpRequest {
  text: string;
}

export function createAddFollowUpRequest(addFollowUp: AddFollowUp): AddFollowUpRequest {
  return {
    text: addFollowUp.text,
  } as AddFollowUpRequest;
}
