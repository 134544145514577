import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import {useDispatch, useSelector} from "react-redux";
import {useAuthenticationState} from "module/authentication/hook/useAuthenticationState";
import {usePermify} from '@permify/react-role';
import {useEffect} from "react";
import {RootState} from "store";

const useProfileManager = () => {
  const dispatch = useDispatch();
  const {setUser} = usePermify();
  
  const [user, userLoading] = useAuthenticationState();
  
  const selectedRole = useSelector((rootState: RootState) => rootState.ProfileStore?.getters(rootState).getSelectedRoles());
  
  let fetchProfilesState =
    useLoadingAndError(() =>
        dispatch.ProfileStore.fetchProfiles(),
      [user !== null],
      user
    );
  
  useEffect(() => {
    if (selectedRole) {
      setUser({
        id: selectedRole.id,
        roles: [selectedRole.roleName],
        permissions: selectedRole.accesses
      });
    }
  }, [selectedRole]);
  
  return {
    profilesLoading: fetchProfilesState.isLoading,
    profilesErrorNames: fetchProfilesState.errorName,
    profileIsCompleted: fetchProfilesState.isCompleted
  };
};

export default useProfileManager;
