import React from 'react';
import styles from "module/mandate/view/asset/add/AddMandateInfoFormComponent.module.scss";
import formStyles from "module/mandate/view/asset/add/inputs/MandateInputs.module.scss";
import {TextValidator} from "asset/validator";
import MuiInputSizes from "style/props/MuiInputSizes";
import {useTranslation} from "react-i18next";

const DESCRIPTION_MAX_LENGTH = 100;
const DESCRIPTION_ROWS = 7;

const MandateDescriptionInput = ({
  description,
  setDescription
}: {
  description: string,
  setDescription: (description: string) => void
}) => {
  const {t} = useTranslation('mandate');
  
  return (
    <div className={styles.inputContainer}>
      <div className={formStyles.labels}>
        <div className={formStyles.lengthCount}>{description.length}/{DESCRIPTION_MAX_LENGTH}</div>
      </div>
      <TextValidator
        id="description"
        className={styles.input}
        maxRows={DESCRIPTION_ROWS}
        minRows={DESCRIPTION_ROWS}
        multiline={true}
        size={MuiInputSizes.LARGE}
        fullWidth
        placeholder={t('form.description.placeholder')}
        value={description}
        onChange={(event) => setDescription(event.target.value)}
        inputProps={{maxLength: DESCRIPTION_MAX_LENGTH}} />
    </div>
  );
};

export default MandateDescriptionInput;
