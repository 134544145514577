import * as React from "react";
import styles from "asset/component/asset/subscription/SubscriptionCard.module.scss";
import {Typography} from "@mui/material";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import SubscriptionStatusTag from "asset/component/subscription/SubscriptionStatusTag";
import {useTranslation} from "react-i18next";
import {SubscriptionStatus} from "module/subscription/domain/model/SubscriptionStatus";
import SubscriptionAction from "asset/component/subscription/SubscriptionAction";
import SubscriptionUpdate from "asset/component/subscription/SubscriptionUpdate";

const SubscriptionCardActive = ({
  ownerEmail,
  updateAction,
  cancelAction
}: {
  ownerEmail: string,
  updateAction?: () => void,
  cancelAction?: () => void,
}) => {
  const {t} = useTranslation("common");
  
  return <div id={styles.subscriptionCard}>
    <div id={styles.header}>
      <div>
        <Typography variant={MuiTypographyVariants.H6}>
          {t("subscription.owner")}:
        </Typography>
        <Typography variant={MuiTypographyVariants.SM}>
        {ownerEmail}
        </Typography>
      </div>
      <div className={styles.subscriptionStatus}>
        <SubscriptionStatusTag status={SubscriptionStatus.ACTIVE} />
      </div>
    </div>
    <div id={styles.footer}>
      {/*TODO: UPDATE PADDLE*/}
      <div></div>
      {/*<SubscriptionUpdate action={updateAction} />*/}
      <SubscriptionAction cancelAction action={cancelAction} />
    </div>
  </div>;
};

export default SubscriptionCardActive;
