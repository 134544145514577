import React from 'react';
import AddCoacheeMotivator from "module/motivator/coachee/domain/model/AddCoacheeMotivator";
import CoacheeMotivator from "module/motivator/coachee/domain/model/CoacheeMotivator";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import TagDisplayer from "asset/component/tag/TagDisplayer";
import {useTranslation} from "react-i18next";
import {Tag} from "asset/component/tag/Tag";
import ConvertMotivatorsToTags from "module/motivator/ConvertMotivatorsToTags";
import InputEnter from "asset/component/form/inputs/InputEnter";
import ModuleSectionLayout from 'asset/component/module/ModuleSectionLayout';

const CoacheeMotivatorContainer = ({coacheeId}: { coacheeId: string }) => {
  const dispatch = useDispatch<Dispatch>();
  const {t} = useTranslation('motivator');
  
  const motivators = useSelector(
    (rootState: RootState) =>
      rootState.CoacheeMotivatorStore?.getters(rootState).getMotivatorsByCoacheeId(coacheeId)
  );
  
  const fetchMotivatorsByCoacheeIdState = useLoadingAndError(
    () =>
      dispatch.CoacheeMotivatorStore.fetchMotivatorsByCoacheeId(coacheeId), [], coacheeId
  );
  
  const [isAddMotivatorLoading, addMotivatorErrorName, dispatchAddMotivator] =
    usePromiseLoadingAndError((title) =>
      dispatch.CoacheeMotivatorStore.addMotivator({
        addMotivator: new AddCoacheeMotivator(title, coacheeId)
      })
    );
  
  const [isRemoveMotivatorLoading, removeMotivatorErrorName, dispatchRemoveMotivator] =
    usePromiseLoadingAndError((motivator: CoacheeMotivator) =>
      dispatch.CoacheeMotivatorStore.deleteMotivator({
        motivatorId: motivator.id,
        coacheeId
      })
    );
  
  const motivatorTags: Tag[] = ConvertMotivatorsToTags(motivators);
  
  return (
    <ModuleSectionLayout
      title={t('titles.motivators')}
      isLoading={isAddMotivatorLoading || isRemoveMotivatorLoading || fetchMotivatorsByCoacheeIdState.isLoading}
      errorName={fetchMotivatorsByCoacheeIdState.errorName || addMotivatorErrorName || removeMotivatorErrorName}
    >
      <TagDisplayer
        tags={motivatorTags}
        removeTag={(tag) => dispatchRemoveMotivator(tag)}
        input={<InputEnter placeholder={t('input.placeholderMotivator')}
                           errorMessage={t('input.errorMessageMotivator')}
                           setValue={(value) => dispatchAddMotivator(value)}
                           isOnNewLine={true}
                           data-testid="input-enter-tag" />}
      />
    </ModuleSectionLayout>
  );
};

export default CoacheeMotivatorContainer;
