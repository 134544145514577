import {Roles} from "module/authorization/domain/model/roles";
import {Accesses} from "module/authorization/domain/model/accesses";

export default class Role {
  private readonly _id: string;
  private readonly _roleName: Roles;
  private readonly _accesses: Accesses[];
  
  constructor(
    id: string,
    roleName: Roles,
    accesses: Accesses[],
  ) {
    this._id = id
    this._roleName = roleName
    this._accesses = accesses
  }
  
  get id(): string {
    return this._id;
  }
  
  get roleName(): Roles {
    return this._roleName;
  }
  
  get accesses(): Accesses[] {
    return this._accesses;
  }
}
