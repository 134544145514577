import React, {ReactElement} from 'react';
import {AppBar, Box, IconButton, Link, Menu, MenuItem, Toolbar, Container} from "asset/component";
import styles from "module/common/navbar/component/assets/Navbar.module.scss";
import "module/common/navbar/component/assets/Popover.scss";
import {Link as RouterLink} from "react-router-dom";
import {Routes} from "router/Routes";
import {useTranslation} from "react-i18next";
import LogoPanoramix from "asset/image/LogoPanoramix";
import usePaletteColors, {AddOpacity} from "style/usePaletteColors";
import {HomeSequence} from "module/onboarding/domain/model/SeedData/OnboardingSequencesSeed";
import SettingIcon from "asset/icon/SettingIcon";

function NavbarWrapper({changeLanguage, logout}: {
  changeLanguage: () => void,
  logout: () => void
}): ReactElement {
  const {t} = useTranslation('navbar');
  const [anchorProfileSubMenu, setAnchorProfileSubMenu] = React.useState<null | HTMLElement>(null);
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const palette = usePaletteColors();
  
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorProfileSubMenu(event.currentTarget);
    setIsFocused(true);
  };
  
  const handleClose = () => {
    setAnchorProfileSubMenu(null);
    setIsFocused(false);
  };
  
  const onLanguageChange = () => {
    changeLanguage();
    handleClose();
  };
  
  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar color="transparent" className={styles.customizeAppBar}>
        <Container className={styles.container}>
          <Toolbar>
            <Link className={styles.navigationLogo}
                  component={RouterLink}
                  to={Routes.home.path()}>
              <LogoPanoramix data-testid="logo" />
            </Link>
            <Box sx={{flexGrow: 1}} />
            
            <MenuItem onClick={() => {
            }}>
              <IconButton
                aria-label={t('profile')}
                id={HomeSequence.steps[2].anchorID}
                aria-controls="profile-menu"
                aria-haspopup="true"
                onClick={handleMenu}
                className={`${styles.buttonIcon} ${isFocused && styles.focus}`}
                backgroundColor="transparent"
                hoverBackgroundColor={AddOpacity(palette.primary.dark, 40)}
                buttonSize={"50px"}
                iconSize={"30px"}
                icon={<SettingIcon />}
              />
            </MenuItem>
            
            <Menu
              id="profile-menu"
              anchorEl={anchorProfileSubMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorProfileSubMenu)}
              onClose={handleClose}
            >
              <MenuItem>
                <Link className={`${styles.menuLink} ${styles.menuItemPopover}`}
                      component={RouterLink}
                      onClick={handleClose}
                      to={Routes.settings.path()}>
                  {t('settings')}
                </Link>
              </MenuItem>
              <MenuItem onClick={logout} data-test-id="logout">
                <span className={styles.menuItemPopover}>{t('logout')}</span>
              </MenuItem>
            </Menu>
            <MenuItem onClick={onLanguageChange}>
                <span
                  data-testid="change-language"
                  className={`${styles.changeLanguage}`}
                >
                  {t('common:oppositeLanguageShort')}
                </span>
            </MenuItem>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}

export default NavbarWrapper;
