import * as React from "react";
import {useState} from "react";
import {AccordionDetails, AccordionSummary, MUIAccordion, Typography} from "asset/component/index";
import styles from 'asset/component/asset/module/DropdownThumbnail.module.scss';
import EditableTitle from "asset/component/form/inputs/EditableTitle";
import {ExpandIcon} from "asset/component/Icons";

const DropdownThumbnail = ({
  title,
  setTitle,
  icons,
  deleteAction,
  deleteConfirmationText,
  editIsLoading,
  children,
  injectedStyle,
  isEditable = true
}: {
  title: string,
  setTitle?: (title: string) => void,
  icons?: JSX.Element[],
  deleteAction?: (...any) => void,
  deleteConfirmationText?: string,
  editIsLoading?: boolean
  children: JSX.Element | JSX.Element[],
  injectedStyle?: Record<string, string>,
  isEditable?: boolean,
}) => {
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  
  const getIcons = () => {
    if (icons && icons.length > 0) {
      return <span className={styles.icons}>
        {icons.map((icon, index) => <div key={index} className={styles.iconElement}>{icon}</div>)}
      </span>
    } else return <></>
  }
  
  return (
    <MUIAccordion className={`
      ${injectedStyle?.dropdownThumbnail}
      ${styles.dropdownThumbnail}
      ${isInEditMode ? styles.editMode : styles.displayMode}
    `}>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        {getIcons()}
        {isEditable ? <EditableTitle title={title}
                                     setTitle={setTitle}
                                     deleteAction={deleteAction}
                                     deleteConfirmationText={deleteConfirmationText}
                                     editIsLoading={editIsLoading}
                                     classes={{
                                   iconContainer: styles.iconContainer,
                                   updateIcon: styles.updateIcon,
                                   formContainer: styles.formContainer,
                                 }}
                                     onEditModeChange={setIsInEditMode}
          >
            <Typography className={styles.title} component="h5" variant="xs" title={title}>
              {title}
            </Typography>
          </EditableTitle> :
          <Typography className={styles.title} component="h5" variant="xs" title={title}>
            {title}
          </Typography>
        }
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </MUIAccordion>
  );
};

export default DropdownThumbnail;
