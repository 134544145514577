import React from 'react';
import {ModuleHeader} from "asset/component";
import ModuleCard from "asset/component/module/ModuleCard";
import {useTranslation} from "react-i18next";
import TeamMotivatorContainer from "module/motivator/team/view/container/TeamMotivatorContainer";
import TeamDemotivatorContainer from "module/demotivator/team/view/container/TeamDemotivatorContainer";

const TeamMotivatorModuleContainer = ({teamId}: { teamId: string }) => {
  const {t} = useTranslation('motivator');
  
  return (
    <ModuleCard data-testid="motivator-component">
      <ModuleHeader title={t('titles.main')}
                    subtitle={t('description.team')}
                    data-testid="motivator-module-container-header" />
      <TeamMotivatorContainer teamId={teamId} />
      <TeamDemotivatorContainer teamId={teamId} />
    </ModuleCard>
  );
};

export default TeamMotivatorModuleContainer;
