import React from 'react';
import styles from 'asset/component/asset/module/ModuleHeader.module.scss';
import {IconButton, Typography} from "asset/component/index";
import DialogMessage from "asset/component/global/dialogs/DialogMessage";
import {InfoIcon} from 'asset/component/Icons';
import usePaletteColors from "style/usePaletteColors";

const ModuleHeader = ({title, subtitle, modalInfo, textAlign = 'left', endIcon, id}: {
  title?: string,
  subtitle?: string | JSX.Element,
  modalInfo?: { title: string, text: string | JSX.Element }
  textAlign?: 'left' | 'center' | 'right',
  endIcon?: JSX.Element,
  id?: string,
}) => {
  const [openModal, setOpenModal] = React.useState(false);
  const palette = usePaletteColors();
  
  return (
    <div className={styles.moduleCardContainer} style={{textAlign: textAlign}}>
      <div className={styles.titleContainer}>
        {title &&
            <Typography className={styles.title} component="h3" variant="h3">{title}</Typography>}
        {
          modalInfo && <>
                <IconButton
                    data-testid="info-icon"
                    aria-label="info"
                    id={id}
                    onClick={() => setOpenModal(true)}
                    iconColor={palette.primary.veryLight}
                    backgroundColor={palette.secondary.dark}
                    hoverBackgroundColor={palette.secondary.main}
                    buttonSize={"20px"}
                    iconSize={"24px"}
                    icon={<InfoIcon />}
                />
                <DialogMessage open={openModal}
                               setOpen={setOpenModal}
                               title={modalInfo.title}
                               text={modalInfo.text} />
            </>
        }
        {endIcon}
      </div>
      {subtitle && <Typography className={styles.subTitle}
                               component="span"
                               variant="xs">{subtitle}
      </Typography>}
    </div>
  );
};

export default ModuleHeader;
