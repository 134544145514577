import {IMotivator} from "module/motivator/coachee/domain/model/CoacheeMotivator";

export default class TeamDemotivator implements IMotivator {
  private readonly _id: string;
  private readonly _title: string;
  private readonly _teamId: string;
  
  constructor(id: string, title: string, teamId: string) {
    this._id = id;
    this._title = title;
    this._teamId = teamId;
  }
  
  get id(): string {
    return this._id;
  }
  
  get title(): string {
    return this._title;
  }
  
  get teamId(): string {
    return this._teamId;
  }
}
