import {SelectValidator, TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import * as React from "react";
import {useEffect, useState} from "react";

const useFormValidation = (
  formRef: React.MutableRefObject<any>,
  ...dependencies: any[]
): { isFormValid: boolean, submitForm: () => void } => {
  const [isFormValid, setIsFormValid] = useState(false);
  
  useEffect(() => {
    formRef?.current?.isFormValid(true).then((isValid) => {
      setIsFormValid(isValid);
    });
  }, [dependencies]);
  
  const submitForm = () => {
    formRef?.current?.submit();
  };
  
  return {isFormValid, submitForm};
};

export {ValidatorForm, TextValidator, SelectValidator, useFormValidation};
