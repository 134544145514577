import React, {useState} from 'react';
import styles from 'asset/component/asset/global/ClosableSection.module.scss';
import {Container, IconButton} from "asset/component/index";
import {CloseIcon} from "asset/component/Icons";
import usePaletteColors from "style/usePaletteColors";

const ClosableSection = ({
  children,
  onClose
}: {
  children?: JSX.Element | JSX.Element[],
  onClose?: () => void
}) => {
  const [boxOpen, setBoxOpen] = useState<boolean>(true);
  const palette = usePaletteColors();
  
  return boxOpen ? (
      <Container data-testid="closable-section-container" className={styles.trialBoxContainer}>
        <div className={styles.trialBox}>
          <IconButton
            iconColor={palette.secondary.veryDark}
            backgroundColor="transparent"
            icon={<CloseIcon />}
            data-testid="close-button"
            className={styles.closeButton}
            onClick={() => {
              setBoxOpen(false);
              onClose && onClose();
            }
            }
          />
          {children}
        </div>
      </Container>)
    : <></>;
};

export default ClosableSection;
