import UpdateCompany from "module/company/domain/model/UpdateCompany";

export default interface UpdateCompanyRequest {
  name: string;
}

export function createUpdateCompanyRequest(updateCompany: UpdateCompany): UpdateCompanyRequest {
  return {
    name: updateCompany.name,
  } as UpdateCompanyRequest;
}
