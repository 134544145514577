import {useEffect, useState} from 'react';
import {ErrorNames} from "core/error/ErrorNames";

const useLoadingAndError = (
  promiseFunction: () => Promise<any>,
  conditions?: boolean[],
  ...dependencies: any[]
): {isLoading: boolean, errorName: ErrorNames | undefined, isCompleted: boolean} => {
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorName, setErrorName] = useState<ErrorNames | undefined>(undefined);
  
  useEffect(() => {
    if (!conditions || conditions?.every((condition) => condition)) {
      setIsLoading(true);
      setIsCompleted(false);
      setErrorName(undefined);
      
      promiseFunction().then(() => {
        setIsLoading(false);
        setIsCompleted(true);
      }).catch((errorName: ErrorNames) => {
        setErrorName(errorName);
        setIsLoading(false);
      });
    }
  }, [...dependencies]);
  
  return {isLoading, errorName, isCompleted};
};

export default useLoadingAndError;
