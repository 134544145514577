export default class UpdateSubObjective {
  private readonly _completed: boolean;
  
  constructor(completed: boolean) {
    this._completed = completed;
  }
  
  get completed(): boolean {
    return this._completed;
  }
}
