import ObjectiveCoachee from "module/objective/domain/model/ObjectiveCoachee";
import SubObjectiveResponse, {subObjectivesResponseToModel} from "module/objective/dal/dto/SubObjectiveResponse";
import ObjectiveTeam from "module/objective/domain/model/ObjectiveTeam";
import {NeedsCoachee} from "module/objective/domain/model/need/NeedsCoachee";
import {NeedsTeam} from "module/objective/domain/model/need/NeedsTeam";
import ObjectiveMandate from "module/objective/domain/model/ObjectiveMandate";
import {StateStatus} from "module/objective/domain/model/StateStatus";

export default interface ObjectiveResponse {
  id: string;
  name: string;
  text: string;
  createdAt: string;
  status: StateStatus;
  subObjectiveViews: SubObjectiveResponse[];
}

export interface ObjectiveCoacheeResponse extends ObjectiveResponse {
  needs: NeedsCoachee[];
}

export interface ObjectiveTeamResponse extends ObjectiveResponse {
  needs: NeedsTeam[];
}

export interface ObjectiveMandateResponse extends ObjectiveResponse {
}

export function objectiveCoacheeResponseToModel(
  objectiveCoacheeResponse: ObjectiveCoacheeResponse,
  coacheeId: string
): ObjectiveCoachee {
  return new ObjectiveCoachee(
    objectiveCoacheeResponse.id,
    coacheeId,
    objectiveCoacheeResponse.name,
    objectiveCoacheeResponse.text,
    objectiveCoacheeResponse.createdAt,
    objectiveCoacheeResponse.status,
    subObjectivesResponseToModel(objectiveCoacheeResponse.subObjectiveViews),
    objectiveCoacheeResponse.needs
  );
}

export function objectivesCoacheeResponseToModel(
  objectivesCoacheeResponse: ObjectiveCoacheeResponse[],
  coacheeId: string
): ObjectiveCoachee[] {
  return objectivesCoacheeResponse.map(objectiveCoacheeResponse => objectiveCoacheeResponseToModel(objectiveCoacheeResponse, coacheeId));
}

export function objectiveTeamResponseToModel(
  objectiveTeamResponse: ObjectiveTeamResponse,
  teamId: string
): ObjectiveTeam {
  return new ObjectiveTeam(
    objectiveTeamResponse.id,
    teamId,
    objectiveTeamResponse.name,
    objectiveTeamResponse.text,
    objectiveTeamResponse.createdAt,
    objectiveTeamResponse.status,
    subObjectivesResponseToModel(objectiveTeamResponse.subObjectiveViews),
    objectiveTeamResponse.needs
  );
}

export function objectivesTeamResponseToModel(
  objectivesTeamResponse: ObjectiveTeamResponse[],
  teamId: string
): ObjectiveTeam[] {
  return objectivesTeamResponse.map(objectiveTeamResponse => objectiveTeamResponseToModel(objectiveTeamResponse, teamId));
}

export function objectiveMandateResponseToModel(
  objectiveMandateResponse: ObjectiveMandateResponse,
  mandateId: string
): ObjectiveMandate {
  return new ObjectiveMandate(
    objectiveMandateResponse.id,
    mandateId,
    objectiveMandateResponse.name,
    objectiveMandateResponse.text,
    objectiveMandateResponse.createdAt,
    objectiveMandateResponse.status,
    subObjectivesResponseToModel(objectiveMandateResponse.subObjectiveViews),
  );
}

export function objectivesMandateResponseToModel(
  objectivesMandateResponse: ObjectiveMandateResponse[],
  mandateId: string
): ObjectiveMandate[] {
  return objectivesMandateResponse.map(objectiveMandateResponse => objectiveMandateResponseToModel(objectiveMandateResponse, mandateId));
}
