import * as React from "react";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import styles from "module/user/view/asset/ChangePasswordComponent.module.scss";
import {ErrorNames} from "core/error/ErrorNames";
import DialogMessage from "asset/component/global/dialogs/DialogMessage";
import {LoadingButton} from "@mui/lab";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import {Alert} from "asset/component";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";

interface ChangePasswordComponentProps {
  resetPassword: {
    loading?: boolean,
    errorName?: ErrorNames,
    dispatch: () => void,
    completed?: boolean
  };
}

const ChangePasswordComponent = ({resetPassword}: ChangePasswordComponentProps) => {
  const {t} = useTranslation('user');
  const [open, setOpen] = React.useState(false);
  const {getErrorMessage} = useErrorTranslation();
  
  useEffect(() => {
    if (resetPassword.completed) {
      setOpen(true);
    }
  }, [resetPassword.completed]);
  
  const changePasswordAlert =
    resetPassword.errorName && <Alert severity="error" data-testid="reset-password-error">
      {getErrorMessage(resetPassword.errorName)}
      </Alert>;
  
  return <div id={styles.changePasswordComponent}>
    <Typography className={styles.title} variant={MuiTypographyVariants.H3}>
      {t('changePassword.title')}
    </Typography>
    <Typography className={styles.title} variant={MuiTypographyVariants.SM}>
      {t('changePassword.description')}
    </Typography>
    {changePasswordAlert}
    <div>
      <LoadingButton data-testid="reset-password"
                     onClick={() => resetPassword.dispatch()}
                     variant={MuiButtonVariants.CONTAINED}
                     loading={resetPassword.loading}
                     disabled={resetPassword.loading}>
        {t('changePassword.button')}
      </LoadingButton>
    </div>
    <DialogMessage open={open}
                   setOpen={setOpen}
                   title={t('changePassword.dialog.title')}
                   text={t('changePassword.dialog.text')}
                   buttonText={t('changePassword.dialog.buttonText')} />
  </div>;
};

export default ChangePasswordComponent;
