import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import {RootState} from "store";
import AppError from "core/error/AppError";
import ReducerServices from "store/service/ReducerServices";
import AddTeamMotivator from "module/motivator/team/domain/model/AddTeamMotivator";
import TeamMotivator from "module/motivator/team/domain/model/TeamMotivator";

export const TeamMotivatorStore = createModel<RootModel>()({
  state: {
    motivators: [] as TeamMotivator[],
    getters: (state: RootState) => ({
      getMotivatorsByTeamId: (id: string) => {
        return state.TeamMotivatorStore.motivators.filter(
          motivator => motivator.teamId == id);
      }
    })
  },
  reducers: {
    setMotivator(
      state,
      motivator: TeamMotivator
    ) {
      return {
        ...state,
        motivators: ReducerServices.setItemToList<TeamMotivator>(
          state.motivators,
          motivator
        )
      };
    },
    setMotivators(
      state,
      motivators: TeamMotivator[]
    ) {
      return {
        ...state,
        motivators: ReducerServices.setItemsToList<TeamMotivator>(
          state.motivators,
          motivators
        )
      };
    },
    removeMotivatorById(
      state,
      motivatorId: string
    ) {
      return {
        ...state,
        motivators: ReducerServices.removeItemFromList<TeamMotivator>(
          state.motivators,
          motivatorId
        )
      };
    },
  },
  effects: (dispatch) => ({
    async addMotivator(payload: { addMotivator: AddTeamMotivator }) {
      return await clients.TeamMotivator
        .addMotivatorForTeam(payload.addMotivator, payload.addMotivator.teamId)
        .then((addedMotivator: TeamMotivator) => {
          dispatch.TeamMotivatorStore.setMotivator(addedMotivator);
          
          return addedMotivator;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteMotivator(payload: { motivatorId: string, teamId: string }) {
      return await clients.TeamMotivator
        .deleteMotivatorForTeam(payload.motivatorId, payload.teamId)
        .then(() => {
          dispatch.TeamMotivatorStore.removeMotivatorById(payload.motivatorId);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async fetchMotivatorsByTeamId(id: string) {
      await clients.TeamMotivator.fetchMotivatorsByTeamId(id).then(
        (motivators: TeamMotivator[]) => {
          dispatch.TeamMotivatorStore.setMotivators(motivators);
        }).catch((error: AppError) => {
        throw error.name;
      });
    }
  })
});
