import React from 'react';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import styles from "module/mandate/view/asset/add/AddMandateInfoFormComponent.module.scss";
import {useTranslation} from "react-i18next";
import 'dayjs/locale/fr';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment, {Moment} from "moment/moment";

export class MandateDateFormat {
  static readonly DATE_FORMAT = 'DD MMM YYYY';
}

const MandateDatePicker = ({
  startDate,
  deadline,
  setStartDate,
  setDeadline,
  placeHolderDeadline,
  placeHolderStartDate,
  hasLabel = true
}: {
  startDate: Moment | null,
  deadline: Moment | null,
  setStartDate: (date: Moment | null) => void,
  setDeadline: (date: Moment | null) => void,
  placeHolderDeadline?: string,
  placeHolderStartDate?: string,
  hasLabel?: boolean
}) => {
  const {t, i18n} = useTranslation('mandate');
  React.useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);
  
  return (
    <div className={styles.inputContainer}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
        <div className={styles.datePickerContainer}>
          <DatePicker label={placeHolderStartDate}
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      className={styles.datePicker}
                      format={MandateDateFormat.DATE_FORMAT}
                      data-testid={'date-picker-start'}
                      disablePast={true} />
          <DatePicker label={placeHolderDeadline}
                      value={deadline}
                      onChange={(date) => setDeadline(date)}
                      className={styles.datePicker}
                      data-testid={'date-picker-end'}
                      format={MandateDateFormat.DATE_FORMAT}
                      disablePast={true} />
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default MandateDatePicker;
