import * as React from "react";
import {DialogTitle, Typography} from "@mui/material";
import {Button, Dialog, DialogActions, DialogContent, IconButton} from "asset/component/index";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import styles from "asset/component/asset/global/dialogs/DialogMessage.module.scss";
import {CloseIcon} from "asset/component/Icons";
import usePaletteColors from "style/usePaletteColors";

const DialogMessage = ({
  open,
  setOpen,
  title,
  text,
  buttonText,
  icon,
  image,
  customAction,
  children
}: {
  open: boolean,
  setOpen: (opened: boolean) => void,
  title?: string | JSX.Element,
  text?: string | JSX.Element,
  buttonText?: string,
  icon?: JSX.Element,
  image?: string,
  customAction?: () => void,
  children?: JSX.Element
}) => {
  const palette = usePaletteColors();
  const handleClose = () => {
    customAction ? customAction() : setOpen(false);
  };
  
  return <Dialog open={open} onClose={() => setOpen(false)} className={styles.dialogMessage}>
    {image && <img src={image} className={styles.image} alt={'Image'} />}
    <div className={styles.dialogContent}>
      <DialogTitle className={styles.title}>
        {title && <Typography component="span" variant="h4">
          {title}
        </Typography>}
        <IconButton
          data-testid="dialog-icon"
          className={styles.closeButton}
          aria-label="close"
          onClick={() => setOpen(false)}
          iconColor={palette.secondary.veryDark}
          backgroundColor="transparent"
          buttonSize="40px"
          iconSize="22px"
          icon={<CloseIcon />}
        />
      </DialogTitle>
      <DialogContent className={styles.content}>
        {icon}
        {text && <Typography component="p" variant="sm">
          {text}
        </Typography>}
      </DialogContent>
      {children}
      {
        buttonText && <DialogActions className={styles.actions}>
              <Button data-testid="close-button"
                      variant={MuiButtonVariants.CONTAINED}
                      onClick={handleClose}>
                {buttonText}
              </Button>
          </DialogActions>
      }
    </div>
  </Dialog>;
};

export default DialogMessage;
