import {StateStatus} from "module/objective/domain/model/StateStatus";
import ObjectiveStateComment from "module/objective/domain/model/ObjectiveStateComment";

export default class ObjectiveState {
  private readonly _id: string;
  private readonly _comment: string;
  private readonly _comments: ObjectiveStateComment[];
  private readonly _status: StateStatus;
  private readonly _createdAt: Date;
  private readonly _endedAt: Date | null;
  
  constructor(
    id: string,
    comment: string,
    comments: ObjectiveStateComment[],
    status: StateStatus,
    createdAt: string,
    endedAt: string | null
  ) {
    this._id = id;
    this._comment = comment;
    this._comments = comments;
    this._status = status;
    this._createdAt = new Date(createdAt);
    this._endedAt = endedAt ? new Date(endedAt) : null;
  }
  
  get id(): string {
    return this._id;
  }
  
  get status(): StateStatus {
    return this._status;
  }
  
  get createdAt(): Date {
    return this._createdAt;
  }
  
  get comment(): string {
    return this._comment;
  }
  
  get comments(): ObjectiveStateComment[] {
    return this._comments;
  }
  
  get endedAt(): Date | null {
    return this._endedAt;
  }
}
