import AddCultureTag from "module/culture/domain/model/AddCultureTag";

export default interface AddCultureTagRequest {
  title: string;
}

export function createAddCultureTagRequest(addCultureTag: AddCultureTag): AddCultureTagRequest {
  return {
    title: addCultureTag.title,
  } as AddCultureTagRequest;
}
