import * as React from "react";
import {Box, Button, Typography} from "asset/component";
import {useTranslation} from "react-i18next";
import styles from 'module/home/view/asset/HomePageListingCoacheesHeaderComponent.module.scss';
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import {useNavigate} from "react-router-dom";
import {Routes} from "router/Routes";
import {AddIcon} from "asset/component/Icons";
import MuiButtonSizes from "style/props/MuiButtonSizes";
import CoacheesSequence from "module/onboarding/domain/model/SeedData/CoacheesSequence";

const HomePageListingCoacheesHeaderComponent = () => {
  const {t} = useTranslation('home');
  const navigate = useNavigate();
  
  const navigateAddSomething = () => {
    navigate(Routes.add.coachee.path());
  };
  
  return (
    <Box className={styles.container}>
      <Typography component="h2"
                  variant="h2"
                  className="font-color-light-regular">
        {t('allCoachees')}
      </Typography>
      <Button onClick={navigateAddSomething}
              variant={MuiButtonVariants.CONTAINED}
              size={MuiButtonSizes.LARGE}
              id={CoacheesSequence.steps[0].anchorID}
              startIcon={<AddIcon />}>
        {t('common:add')}
      </Button>
    </Box>
  );
};

export default HomePageListingCoacheesHeaderComponent;
