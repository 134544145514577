import React from 'react';
import CoacheeDemotivatorContainer from "module/demotivator/coachee/view/container/CoacheeDemotivatorContainer";
import {ModuleHeader} from "asset/component";
import ModuleCard from "asset/component/module/ModuleCard";
import {useTranslation} from "react-i18next";
import CoacheeMotivatorContainer from "module/motivator/coachee/view/container/CoacheeMotivatorContainer";

const CoacheeMotivatorModuleContainer = ({coacheeId}: { coacheeId: string }) => {
  const {t} = useTranslation('motivator');
  
  return (
    <ModuleCard data-testid="motivator-component">
      <ModuleHeader title={t('titles.main')}
                    subtitle={t('description.coachee')}
                    data-testid="motivator-module-container-header" />
      <CoacheeMotivatorContainer coacheeId={coacheeId} />
      <CoacheeDemotivatorContainer coacheeId={coacheeId} />
    </ModuleCard>
  );
};

export default CoacheeMotivatorModuleContainer;
