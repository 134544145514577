import React, {useEffect} from 'react';
import styles from "module/mandate/view/asset/listing/MandateCardsComponent.module.scss";
import ModuleCard from "asset/component/module/ModuleCard";
import Mandate from "module/mandate/domain/model/Mandate";
import MandateCardContent from "module/mandate/view/component/listing/MandateCardContent";
import moment from "moment/moment";

const MandateCard = ({
  mandate,
  displayMandate,
  id,
  dataParamId
}: {
  mandate: Mandate,
  displayMandate: () => void,
  id?: string,
  dataParamId?: string
}) => {
  const [isFinished, setIsFinished] = React.useState<boolean>(false);
  
  useEffect(() => {
    const now = moment();
    const deadline = moment(mandate.deadline);
    
    setIsFinished(now.isAfter(deadline));
  }, [mandate.deadline]);
  
  return (
    <div className={`${styles.cardParent} ${isFinished ? styles.finished : ""}`}
         onClick={displayMandate}
         data-testid="mandate-card"
         id={id}
         data-param-id={JSON.stringify({mandateId: dataParamId})}>
      <ModuleCard>
        <MandateCardContent mandate={mandate} isFinished={isFinished} />
      </ModuleCard>
    </div>
  );
};

export default MandateCard;
