import React from "react";
import {Typography} from "asset/component";
import Team from "module/team/domain/model/Team";
import styles from "module/team/view/asset/TeamProfileThumbnailComponent.module.scss";
import enterpriseImage from "asset/image/enterprise.svg";
import teamProfileImage from "asset/image/team-profile.svg";
import usePaletteColors from "style/usePaletteColors";
import UpdateTeamDialogContainer from "module/team/view/container/UpdateTeamDialogContainer";

const TeamProfileThumbnailComponent = ({
  team,
  companyName,
  isSelected = false
}: {
  team: Team,
  companyName?: string,
  isSelected?: boolean
}) => {
  const palette = usePaletteColors();
  
  return (
    <div className={`${isSelected ? styles.isSelected : styles.isNotSelected} ${styles.thumbnailProfileContainer}`}>
      <span className={styles.editIcon}>
        <UpdateTeamDialogContainer team={team} />
      </span>
      <img src={teamProfileImage}
           className={styles.avatar}
           alt="Panoramix" />
      <Typography className={styles.text} component="span" variant="h3" color={palette.secondary.dark}>
        {team.name}
      </Typography>
      <Typography className={styles.enterprise} component="span" variant="h5" color={palette.secondary.dark}>
        <img src={enterpriseImage} alt="enterprise" height="20" />
        <span className={styles.infos}>
          <Typography className={styles.companyName}
                      component="span"
                      variant="xs">
            {companyName}
          </Typography>
        </span>
      </Typography>
    </div>
  );
};

export default TeamProfileThumbnailComponent;
