import * as React from "react";
import styles from "asset/component/asset/subscription/SubscriptionCard.module.scss";
import {Typography} from "@mui/material";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import SubscriptionStatusTag from "asset/component/subscription/SubscriptionStatusTag";
import {useTranslation} from "react-i18next";
import {SubscriptionStatus} from "module/subscription/domain/model/SubscriptionStatus";

const SubscriptionCardVIP = () => {
  const {t} = useTranslation("common");
  
  return <div id={styles.subscriptionCard}>
    <div id={styles.header}>
      <div className={styles.subscriptionStatus}>
        <SubscriptionStatusTag status={SubscriptionStatus.VIP} />
      </div>
    </div>
    <div id={styles.footer} />
  </div>;
};

export default SubscriptionCardVIP;
