import * as React from "react";
import {Accordion, Alert, CircularProgress} from "asset/component";
import Team from "module/team/domain/model/Team";
import styles from "module/home/view/asset/HomePageTeamsListingComponent.module.scss";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import {ErrorNames} from "core/error/ErrorNames";
import {Routes} from "router/Routes";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface HomePageTeamsListingProps {
  teams: Team[];
  isLoading: boolean;
  errorName?: ErrorNames;
  children?: (team: Team) => React.ReactElement;
  expanded?: boolean;
}

const HomePageTeamsListingComponent: React.FC<HomePageTeamsListingProps> = ({
  teams,
  isLoading,
  errorName,
  children,
  expanded,
}) => {
  const {t} = useTranslation('home');
  const navigate = useNavigate();
  const {getErrorMessage} = useErrorTranslation();
  
  const buttonClick = (team: Team) => {
    navigate(Routes.team.profile.path({teamId: team.id, companyId: team.companyId}));
  };
  
  const teamsListing = <>
    {
      teams.map((team: Team, index) =>
        <div key={team.id}
             data-testid={`team_${team.id}`}
             className={styles.accordionContainer}>
          <Accordion expanded={expanded && index === 0}
                     teamId={team.id}
                     companyId={team.companyId}
                     title={team.name}
                     buttonClick={() => buttonClick(team)}
                     buttonText={t('seeTeam')}
          >
            <>{children && children(team)}</>
          </Accordion>
        </div>
      )
    }
  </>;
  
  const teamLoading = <CircularProgress data-testid="loading_teams" />;
  
  const teamError = <Alert severity="error"
                           data-testid="error_teams">{errorName && getErrorMessage(errorName)}</Alert>;
  
  const displayLoading = () => {
    return isLoading && !teams.length;
  };
  
  const renderTeamsListing = () => {
    if (errorName) {
      return teamError;
    } else if (displayLoading()) {
      return teamLoading;
    } else {
      return teamsListing;
    }
  };
  
  return renderTeamsListing();
};

export default HomePageTeamsListingComponent;
