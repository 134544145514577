import React from "react";
import {Grid, Typography} from "asset/component";
import {useTranslation} from "react-i18next";
import InputSelect, {InputOption} from "asset/component/form/inputs/InputSelect";
import usePaletteColors from "style/usePaletteColors";
import {MuiInputColors} from "style/props/MuiInputColors";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";

const UpdateCoacheeTeamFormComponent = ({
  teamId,
  teamOptions,
  setTeamId,
}:
  {
    teamId?: string,
    teamOptions: InputOption[],
    setTeamId: (teamId: string) => void,
  }) => {
  const {t} = useTranslation('coachee');
  const palette = usePaletteColors();
  
  return <div>
    <Typography component="p" variant={MuiTypographyVariants.XS} color={palette.secondary.dark}>
      {t('form.update.teamLabel')}
    </Typography>
    <Grid container>
      <InputSelect
        selectedId={teamId}
        setExistingValue={setTeamId}
        options={teamOptions}
        color={MuiInputColors.SECONDARY}
        selectLabel={t('form.teamPlaceholder')}
        addButtonLabel={t('form.addTeamButton')}
      />
    </Grid>
  </div>;
};

export default UpdateCoacheeTeamFormComponent;
