import React, {useState} from "react";
import User from "module/user/domain/model/User";
import PersonalInformationsComponent from "module/user/view/component/PersonalInformationsComponent";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import UpdateUser from "module/user/domain/model/UpdateUser";
import {useDispatch} from "react-redux";

const PersonalInformationsContainer = ({user}: { user: User }) => {
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const dispatch = useDispatch();
  
  const [updateUserLoading, UpdateUserErrorName, updateUserDispatch] =
    usePromiseLoadingAndError(() =>
      dispatch.UserStore.updateUser(
        new UpdateUser(
          firstName,
          lastName
        ))
    );
  
  const personalInformationsComponentProps = {
    updatePersonalInformations: updateUserDispatch,
    formState: {
      firstName,
      setFirstName,
      lastName,
      setLastName,
      email,
      setEmail
    },
    errorName: UpdateUserErrorName
  };
  
  return <PersonalInformationsComponent {...personalInformationsComponentProps} />;
};

export default PersonalInformationsContainer;
