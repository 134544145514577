import {StateStatus} from "module/objective/domain/model/StateStatus";
import styles from 'module/objective/view/asset/ObjectiveStateSelection.module.scss';
import React from "react";
import CardButton from "asset/component/global/buttons/CardButton";
import monitoring from "asset/image/status/monitoring.svg";
import idle from "asset/image/status/idle.svg";
import fine from "asset/image/status/fine.svg";
import optimal from "asset/image/status/optimal.svg";
import {useTranslation} from "react-i18next";
import {MuiSizes} from "style/props/MuiSizes";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";

const ObjectiveStateSelectionComponent = ({
  onStatusClick
} : {
  onStatusClick: (status: StateStatus) => void
}) => {
  const {t} = useTranslation("objective");
  
  const getStatusColor = (status: StateStatus) => {
    switch (status) {
      case StateStatus.MONITORING : return "lightest-green"
      case StateStatus.IDLE: return "very-light-green";
      case StateStatus.FINE: return "dark-creme";
      case StateStatus.OPTIMAL: return "flash-creme";
    }
  }
  
  const renderCardStatusButton = (image : string, status: StateStatus) => (
    
      <CardButton onClick={() => onStatusClick(status)}
                  text={t(`state.${status}`)}
                  image={image}
                  size={MuiSizes.FLEX}
                  textVariant={MuiTypographyVariants.XS}
                  backgroundColor={getStatusColor(status)}
                  overlayClass={styles.contentOverlay}/>
  );
  
  return (
    <div className={styles.statusCardContainer}>
      {renderCardStatusButton(monitoring, StateStatus.MONITORING)}
      {renderCardStatusButton(idle, StateStatus.IDLE)}
      {renderCardStatusButton(fine, StateStatus.FINE)}
      {renderCardStatusButton(optimal, StateStatus.OPTIMAL)}
    </div>
  )
}

export default ObjectiveStateSelectionComponent;
