import React from 'react';
import style from 'asset/component/asset/Circle.module.scss';

export enum CircleColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

const Circle = ({isFilled = false, color = CircleColor.PRIMARY, className}: {
  isFilled?: boolean,
  className?: string
  color?: CircleColor
}) => {
  let filledClassName = isFilled ? style.filled : '';
  
  return <div data-testid="circle"
              className={`${filledClassName} ${style.circle} ${color} ${className}`} />;
};

export default Circle;
