import React, {useEffect, useState} from "react";
import {Grid, Typography} from "asset/component";
import {ValidatorForm} from "asset/validator";
import {useTranslation} from "react-i18next";
import FormSection from "asset/component/form/FormSection";
import useNavigationFormSections from "asset/component/form/hook/useNavigationFormSections";
import InputSelect, {InputAction, InputOption, useInputAction} from "asset/component/form/inputs/InputSelect";
import FormNavigationButtons from "asset/component/form/FormNavigationButtons";

const AddCoacheeTeamFormComponent = ({
  teamFormSectionId,
  previousFormSectionId,
  nextFormSectionId,
  useNavigation,
  teamOptions,
  setTeamId,
  setTeamName,
}:
  {
    teamFormSectionId: string,
    previousFormSectionId: string,
    nextFormSectionId: string,
    useNavigation: typeof useNavigationFormSections.arguments,
    teamOptions: InputOption[],
    setTeamId: (teamId: string) => void,
    setTeamName: (teamName: string) => void,
    teamId?: string,
    teamName?: string,
  }) => {
  const {t} = useTranslation('coachee');
  const {inputAction, triggerInputAction} = useInputAction();
  
  const clearTeamValue = () => {
    setTeamId("");
    setTeamName("");
    triggerInputAction(InputAction.CLEAR);
  };
  
  return (
    <ValidatorForm onSubmit={() => useNavigation.activateSection(nextFormSectionId)}>
      <FormSection anchorId={teamFormSectionId}
                   isActivated={useNavigation.isSectionActivated(teamFormSectionId)}>
        <Typography component="h3" variant="h3">
          {t('form.add.teamTitle')}
        </Typography>
        <Typography component="p" variant="sm">
          {t('form.add.teamLabel')}
        </Typography>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={10} md={4} sm={6} lg={3}>
            <InputSelect
              setExistingValue={setTeamId}
              setNewValue={setTeamName}
              options={teamOptions}
              inputAction={inputAction}
              selectLabel={t('form.teamPlaceholder')}
              addButtonLabel={t('form.addTeamButton')}
            />
          </Grid>
        </Grid>
        <FormNavigationButtons previousAnchor={previousFormSectionId}
                               nextAnchor={nextFormSectionId}
                               onSkipButtonClick={clearTeamValue}
                               skipButtonText={t('form.skipTeamButton')}
                               activateSection={useNavigation.activateSection} />
      </FormSection>
    </ValidatorForm>
  );
};

export default AddCoacheeTeamFormComponent;
