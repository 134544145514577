import OnboardingSequence from "module/onboarding/domain/model/OnboardingSequence";
import OnboardingStep from "module/onboarding/domain/model/OnboardingStep";
import {ArrowPositions} from "asset/component/global/dialogs/Tooltip";
import {OnboardingSequenceNames} from "module/onboarding/domain/model/SeedData/OnboardingSequenceNames";

const ParametersSequence = new OnboardingSequence(
  [
    new OnboardingStep({
      name: "addUser",
      sequenceName: OnboardingSequenceNames.PARAMETERS,
      anchorID: "parameters-add-user-anchor",
      arrowPosition: ArrowPositions.TOP.MIDDLE,
      adjustments: {x: 0, y: -100}
    }),
    new OnboardingStep({
      name: "userOptions",
      sequenceName: OnboardingSequenceNames.PARAMETERS,
      anchorID: "parameters-user-options-anchor",
      arrowPosition: ArrowPositions.TOP.MIDDLE,
      adjustments: {x: 0, y: -100}
    }),
    new OnboardingStep({
      name: "editAffectations",
      sequenceName: OnboardingSequenceNames.PARAMETERS,
      anchorID: "parameters-edit-affectations-anchor",
      arrowPosition: ArrowPositions.TOP.MIDDLE,
      adjustments: {x: 0, y: -100}
    }),
    new OnboardingStep({
      name: "subscription",
      sequenceName: OnboardingSequenceNames.PARAMETERS,
      anchorID: "parameters-subscription-anchor",
      arrowPosition: ArrowPositions.TOP.MIDDLE,
      adjustments: {x: 0, y: -100}
    }),
    new OnboardingStep({
      name: "notifications",
      sequenceName: OnboardingSequenceNames.PARAMETERS,
      anchorID: "parameters-notifications-anchor",
      arrowPosition: ArrowPositions.TOP.MIDDLE,
      adjustments: {x: 0, y: -100}
    }),
  ]
);

export default ParametersSequence;
