import React, {useRef} from 'react';
import styles from "asset/component/asset/global/dialogs/Tooltip.module.scss";
import {Button, Typography} from "@mui/material";
import IconButton from "asset/component/global/buttons/IconButton";
import {Close} from "@mui/icons-material";
import usePaletteColors from "style/usePaletteColors";
import TooltipArrow from "asset/icon/TooltipArrow";
import {
  handleCloseTypes,
  getTooltipPosition,
  setAnchorToFront,
  setPreviousAnchor
} from "asset/component/global/dialogs/TooltipMethods";
import {useTranslation} from "react-i18next";

export const ArrowPositions = {
  TOP: {
    LEFT: 'top-left',
    MIDDLE: 'top-center',
    RIGHT: 'top-right',
  },
  BOTTOM: {
    LEFT: 'bottom-left',
    MIDDLE: 'bottom-center',
    RIGHT: 'bottom-right',
  },
  LEFT: {
    TOP: 'left-top',
    MIDDLE: 'left-center',
    BOTTOM: 'left-bottom',
  },
  RIGHT: {
    TOP: 'right-top',
    MIDDLE: 'right-center',
    BOTTOM: 'right-bottom',
  },
};

export enum CloseType {
  BUTTON = 'button',
  X = 'x',
}

export enum BackgroundTypes {
  DARK = 'dark',
  BLURRED = 'blurred',
  NONE = 'none',
}

const Tooltip = ({
  title,
  text,
  buttons,
  closeTypes,
  image,
  arrowPosition,
  toggleOpen,
  isOpen,
  anchorID,
  xAdjustment = 0,
  yAdjustment = 0,
  background
}: {
  title?: string,
  text?: string,
  buttons?: JSX.Element | JSX.Element[],
  closeTypes?: CloseType | CloseType[],
  image?: string,
  arrowPosition?: string | typeof ArrowPositions,
  toggleOpen: () => void,
  isOpen?: boolean,
  anchorID?: string,
  xAdjustment?: number,
  yAdjustment?: number,
  background?: BackgroundTypes
}) => {
  const palette = usePaletteColors();
  const {t} = useTranslation("common");
  const tooltip = useRef<HTMLDivElement>(null);
  
  const [isFading, setIsFading] = React.useState(false);
  const [previousAnchorID, setPreviousAnchorID] = React.useState<string>("");
  
  React.useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsFading(true);
      }, 300);
    }
  });
  
  const fadeOut = () => {
    setIsFading(false);
    setTimeout(() => {
      toggleOpen();
    }, 300);
  };
  
  const handleClickOutside = (event: React.MouseEvent) => {
    if (tooltip.current && !tooltip.current.contains(event.target as Node))
      fadeOut();
  };
  
  const makeTheAnchorZIndexHigher = () => {
    if (previousAnchorID === "") setPreviousAnchorID(anchorID!);
    
    setTimeout(() => {
      setPreviousAnchor(previousAnchorID);
      
      if (!anchorID) return;
      
      setAnchorToFront(anchorID);
      setPreviousAnchorID(anchorID);
    }, 10);
  };
  
  React.useEffect(() => {
    makeTheAnchorZIndexHigher();
  }, [isOpen, anchorID]);
  
  return (
    <>
      {isOpen && <div className={`${styles.background} ${isFading && styles.show} ${background}`}
                      onClick={(e: React.MouseEvent) => handleClickOutside(e)}
                      data-testid="tooltip-background">
          <div className={styles.container}
               ref={tooltip}
               style={isOpen && getTooltipPosition(tooltip, anchorID, arrowPosition, xAdjustment, yAdjustment)}>
              <div className={`${styles.arrow} ${arrowPosition} `} data-testid="tooltip-arrow">
                  <TooltipArrow />
              </div>
            {handleCloseTypes(CloseType.X, closeTypes) &&
                <IconButton backgroundColor={"none"}
                            iconColor={palette.secondary.veryDark}
                            hoverIconColor={palette.special.danger}
                            hoverBackgroundColor={palette.special.dangerLight}
                            className={styles.xIcon}
                            data-testid="x-close-button"
                            onClick={() => fadeOut()}
                            icon={<Close />} />}

              <div className={styles.innerContent}>
                  <div className={styles.text}>
                      <Typography color={palette.secondary.veryDark}
                                  data-testid="title"
                                  variant={"h6"}>{title}</Typography>
                      <Typography color={palette.secondary.veryDark}
                                  maxWidth={500}
                                  data-testid="text"
                                  variant={"body1"}>{text}</Typography>
                    {image && <img src={image} alt="tooltip" className={styles.image} data-testid="image" />}
                  </div>
                {(buttons || handleCloseTypes(CloseType.BUTTON, closeTypes)) && <div className={styles.buttons}>
                  {handleCloseTypes(CloseType.BUTTON, closeTypes) &&
                      <Button variant={"outlined"}
                              className={styles.closeButton}
                              data-testid="close-button"
                              onClick={() => fadeOut()}>{t('close')}</Button>}
                  {buttons}
                </div>}
              </div>
          </div>
      </div>}
    </>
  );
};

export default Tooltip;
