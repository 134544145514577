import React from 'react';
import {Accordion} from "asset/component";
import RichTextEditor from "asset/component/form/inputs/RichTextEditor";
import {AccordeonSizeVariants, AccordionColorVariants} from "asset/component/global/Accordion";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {useDispatch} from "react-redux";
import Team from "module/team/domain/model/Team";
import UpdateTeam from "module/team/domain/model/UpdateTeam";
import {useTranslation} from "react-i18next";

const TeamNoteContainer = ({team}: { team: Team }) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  
  const [updateTeamLoading, UpdateTeamErrorName, updateTeamDispatch, isUpdateCompleted] =
    usePromiseLoadingAndError((note) =>
      dispatch.TeamStore.updateTeam(
        new UpdateTeam(
          team.id,
          team.name,
          team.companyId,
          note || team.note
        ))
    );
  
  return (
    <Accordion key={team.id}
               title={t('summaryNote')}
               colorVariant={AccordionColorVariants.LIGHT}
               sizeVariant={AccordeonSizeVariants.LARGE}>
      <RichTextEditor initialValue={team.note}
                      saveAction={(note) => updateTeamDispatch(note)}
                      isLoading={updateTeamLoading}
                      errorName={UpdateTeamErrorName} />
    </Accordion>
  );
};

export default TeamNoteContainer;
