import * as React from 'react';
import {useTranslation} from "react-i18next";
import styles from "module/user/view/asset/SettingPage.module.scss";
import ProfileAndSettingSectionContainer from "module/user/view/container/ProfileAndSettingSectionContainer";
import BodySectionLayout from "asset/component/layout/BodySectionLayout";
import {Typography} from "@mui/material";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import SubscriptionContainer from "module/user/view/container/SubscriptionContainer";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import {useDispatch} from "react-redux";

export default function SettingPage() {
  const {t} = useTranslation('user');
  const dispatch = useDispatch();
  
  useLoadingAndError(() => dispatch.UserStore.fetchCurrentUser());
  useLoadingAndError(() => dispatch.SubscriptionStore.fetchSubscription());
  
  return (
    <div id={styles.settingPage}>
      <BodySectionLayout color="secondary" size="small">
        <div className={styles.sectionProfile}>
          <Typography className={styles.title} variant={MuiTypographyVariants.H2}>
            {t('profilAndSettings')}
          </Typography>
          <ProfileAndSettingSectionContainer />
        </div>
      </BodySectionLayout>
      <BodySectionLayout color="secondary" size="small">
        <div className={styles.sectionSubscription}>
          <Typography className={styles.title} variant={MuiTypographyVariants.H3}>
            {t('subscription')}
          </Typography>
          <SubscriptionContainer />
        </div>
      </BodySectionLayout>
    </div>
  );
}
