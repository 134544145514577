import React from 'react';
import {ModuleHeader} from "asset/component";
import ModuleCard from "asset/component/module/ModuleCard";
import {useTranslation} from "react-i18next";
import CultureTagsContainer from "module/culture/view/container/CultureTagsContainer";

const CultureTagsModuleContainer = ({teamId}: { teamId: string }) => {
  const {t} = useTranslation('culture');
  
  return (
    <ModuleCard data-testid="cultureTag-component">
      <ModuleHeader title={t('titles.main')}
                    subtitle={t('description')}
                    data-testid="cultureTag-module-container-header" />
      <CultureTagsContainer teamId={teamId} />
    </ModuleCard>
  );
};

export default CultureTagsModuleContainer;
