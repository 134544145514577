import React from 'react';
import LinkedMandateListComponent from "module/mandate/view/component/listing/LinkedMandateListComponent";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";

const CoacheeLinkedMandateListContainer = ({
  coacheeId
}: {
  coacheeId: string
}) => {
  const dispatch = useDispatch<Dispatch>();
  
  const mandates = useSelector(
    (rootState: RootState) =>
      rootState.MandateStore?.getters(rootState).getMandatesByCoacheeId(coacheeId)
  );
  
  const fetchMandatesForTenantState = useLoadingAndError(
    () => dispatch.MandateStore.fetchMandatesForTenant()
  );
  
  return (
    <>
      {mandates.length !== 0 &&
          <LinkedMandateListComponent mandates={mandates} isLoading={fetchMandatesForTenantState.isLoading} errorNames={fetchMandatesForTenantState.errorName} />}
    </>
  );
};

export default CoacheeLinkedMandateListContainer;
