import BadRequestError from "core/error/BadRequestError";
import UnauthorizedError from "core/error/UnauthorizedError";
import ForbiddenError from "core/error/ForbiddenError";
import NotFoundError from "core/error/NotFoundError";
import ConflictError from "core/error/ConflictError";
import UnprocessableEntityError from "core/error/UnprocessableEntityError";
import DefaultClientError from "core/error/DefaultClientError";
import AppError from "core/error/AppError";

const mapRESTResponseToError = (clientError): AppError => {
  switch (clientError.status.toString()) {
    case '400': {
      return new BadRequestError();
    }
    case '401': {
      return new UnauthorizedError();
    }
    case '403': {
      return new ForbiddenError();
    }
    case '404': {
      return new NotFoundError();
    }
    case '409': {
      return new ConflictError();
    }
    case '422': {
      return new UnprocessableEntityError();
    }
    default: {
      return new DefaultClientError(clientError.message);
    }
  }
};

export {mapRESTResponseToError};
