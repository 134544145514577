import * as React from 'react';
import {useTranslation} from "react-i18next";
import styles from 'module/authentication/view/asset/RegisterPage.module.scss';
import logo from "asset/image/logo_panoramix-register.svg";
import {Container, Typography, ToggleButton, ToggleButtonGroup} from "asset/component";
import RegisterContainer from "module/authentication/view/container/RegisterContainer";
import usePaletteColors from "style/usePaletteColors";
import {Link} from "@mui/material";
import {Routes} from "router/Routes";
import {useNavigate} from "react-router-dom";
import LanguageManager from "core/helper/LanguageManager";
import {useState} from "react";
import {Lan} from "@mui/icons-material";

export default function RegisterPage() {
  const {t} = useTranslation('authentication');
  const palette = usePaletteColors();
  const navigate = useNavigate();
  const [locale, setLocale] = React.useState(LanguageManager.getCurrentLanguage);
  
  const changeLanguage = (
    event,
  ) => {
    LanguageManager.changeLanguage(event.target.value);
    setLocale(LanguageManager.getCurrentLanguage())
  }
  
  return (
    <div id={styles.registerPage}>
      <div id={styles.waves}>
        <span id={styles.firstWave} className={styles.wave} />
        <span id={styles.secondWave} className={styles.wave} />
        <span id={styles.thirdWave} className={styles.wave} />
      </div>
      <Container id={styles.boxContainer}>
        <img id={styles.logo} src={logo} alt="Panoramix" height={100} />
        
        <ToggleButtonGroup
          exclusive
          onChange={changeLanguage}
          className={styles.changeLanguage}
        >
          <ToggleButton value="fr" className={styles.changeLanguageButton}>
              FR
          </ToggleButton>
          <ToggleButton value="en" className={styles.changeLanguageButton}>
              EN
          </ToggleButton>
        </ToggleButtonGroup>
        
        <Typography className={`${styles.formTitle} font-color-light-regular`}
                    component="h1"
                    variant="h2">
          {t('form.register')}
        </Typography>
        <Typography className={styles.loginLinkText}
                    component="span"
                    variant="sm"
                    color={palette.primary.veryLight}>
          {t('alreadyRegister')}
          <Link className={styles.loginLink}
                onClick={() => navigate(Routes.login.path())}
                color={palette.primary.veryLight}>
            {t('loginLink')}
          </Link>
        </Typography>
        <RegisterContainer />
      </Container>
    </div>
  );
}
