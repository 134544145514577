import * as React from "react";

const HistoryIcon = () => {
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.81818 6.72727H7.09091C7.49257 6.72727 7.81818 6.40166 7.81818 6C7.81818 5.59834 7.49257 5.27273 7.09091 5.27273H5.62696C6.88813 4.15489 8.11263 3.45455 10 3.45455C13.615 3.45455 16.5455 6.38505 16.5455 10C16.5455 13.615 13.615 16.5455 10 16.5455C6.38505 16.5455 3.45455 13.615 3.45455 10C3.45455 9.59834 3.12893 9.27273 2.72727 9.27273C2.32561 9.27273 2 9.59834 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C7.55389 2 5.97658 2.99551 4.54545 4.28775V2.72727C4.54545 2.32561 4.21984 2 3.81818 2C3.41652 2 3.09091 2.32561 3.09091 2.72727V6C3.09091 6.40166 3.41652 6.72727 3.81818 6.72727Z"
                      fill="#123029" />
                <path d="M10.267 6.95231C10.267 6.59464 9.97702 6.30469 9.61935 6.30469C9.26168 6.30469 8.97173 6.59464 8.97173 6.95231V10.7618C8.97173 10.954 9.0571 11.1363 9.20475 11.2593L11.4905 13.1641C11.7652 13.3931 12.1736 13.356 12.4026 13.0812C12.6315 12.8064 12.5944 12.3981 12.3197 12.1691L10.267 10.4585V6.95231Z"
                      fill="#123029" />
        </svg>
      );
};

export default HistoryIcon;
