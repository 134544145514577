import {createModel} from "@rematch/core";
import {RootState} from "store";
import {RootModel} from "store/RootModel";
import AppError from "core/error/AppError";
import clients from "module/common/client";
import LinkableEntity from "module/mandate/domain/model/LinkableEntity";
import UpdateLinkableEntity from "module/mandate/domain/model/UpdateLinkableEntity";
import LinkedEntity from "module/mandate/domain/model/LinkedEntity";

export const LinkableEntityStore = createModel<RootModel>()({
  state: {
    linkableEntities: [] as LinkableEntity[],
    linkedEntities: [] as LinkedEntity[],
    getters: (state: RootState) => ({
      getLinkableEntities: () => {
        const linkableEntities = state.LinkableEntityStore.linkableEntities;
        const linkedEntities = state.LinkableEntityStore.linkedEntities;
        return linkableEntities.filter((linkableEntity: LinkableEntity) => {
          return !linkedEntities.find((linkedEntity: LinkedEntity) => linkedEntity.id === linkableEntity.id);
        });
      },
      getLinkedEntities: () => {
        return state.LinkableEntityStore.linkedEntities;
      },
      getLinkedEntitiesByMandateId: (mandateId: string) => {
        return state.LinkableEntityStore.linkedEntities.filter((linkedEntity: LinkedEntity) => {
          return linkedEntity.mandateId === mandateId;
        });
      }
    }),
  },
  reducers: {
    setLinkableEntities(
      state,
      linkableEntities: LinkableEntity[]
    ) {
      return {
        ...state,
        linkableEntities: linkableEntities
      };
    },
    setLinkedEntities(
      state,
      linkedEntities: LinkedEntity[]
    ) {
      return {
        ...state,
        linkedEntities: linkedEntities
      };
    },
  },
  effects: (dispatch) => ({
    async fetchLinkableEntitiesForMandate(payload: {
      mandateId: string
    }) {
      await clients.LinkableEntity
        .fetchLinkableEntitiesForMandate(payload.mandateId)
        .then((linkableEntities: LinkableEntity[]) => {
          dispatch.LinkableEntityStore.setLinkableEntities(linkableEntities);
        })
        .catch((error: AppError) => {
          throw error;
        });
    },
    async fetchLinkedEntitiesForMandate(payload: {
      mandateId: string
    }) {
      await clients.LinkableEntity
        .fetchLinkedEntitiesForMandate(payload.mandateId)
        .then((linkableEntities: LinkedEntity[]) => {
          dispatch.LinkableEntityStore.setLinkedEntities(linkableEntities);
        })
        .catch((error: AppError) => {
          throw error;
        });
    },
    async addLinkableEntity(payload: {
      entity: UpdateLinkableEntity,
      mandateId: string
    }) {
      return await clients.LinkableEntity
        .addLinkableEntity(payload.entity, payload.mandateId)
        .then((linkableEntities: LinkedEntity[]) => {
          dispatch.MandateStore.fetchMandateById(payload.mandateId);
          dispatch.LinkableEntityStore.setLinkedEntities(linkableEntities);
          return linkableEntities;
        })
        .catch((error: AppError) => {
          throw error;
        });
    },
    async removeLinkableEntity(payload: {
      entity: UpdateLinkableEntity,
      mandateId: string
    }) {
      return await clients.LinkableEntity
        .removeLinkableEntity(payload.entity, payload.mandateId)
        .then((linkableEntities: LinkedEntity[]) => {
          dispatch.MandateStore.fetchMandateById(payload.mandateId);
          dispatch.LinkableEntityStore.setLinkedEntities(linkableEntities);
          return linkableEntities;
        })
        .catch((error: AppError) => {
          throw error;
        });
    }
  }),
});
