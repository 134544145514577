import React from 'react';
import MandatesDisplayerComponent from "module/mandate/view/component/listing/MandatesDisplayerComponent";
import Mandate from "module/mandate/domain/model/Mandate";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import {ErrorNames} from "core/error/ErrorNames";
import {Alert, CircularProgress} from "asset/component";
import {useParams} from "react-router";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import {useNavigate} from "react-router-dom";
import {Routes} from "router/Routes";

const MandatesDisplayerContainer = ({
  mandates,
  isLoading,
  error
}: {
  mandates: Mandate[],
  isLoading?: boolean,
  error?: ErrorNames
}) => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  
  const [isSidebarOpen, setIsSidebarOpen] = React.useState<boolean>(false);
  const {mandateId} = useParams() as { mandateId: string, };
  
  const fetchMandateByIdState = useLoadingAndError(
    () =>
      dispatch.MandateStore.fetchMandateById(mandateId),
    [mandateId !== undefined, mandateId !== ""],
    mandateId
  );
  
  const currentMandate = useSelector((rootState: RootState) =>
    rootState.MandateStore?.getters(rootState).getMandateById(mandateId));
  
  const navigateToMandate = (id: string) => {
    mandateId === id ? navigate(Routes.mandate.path()) : navigate(Routes.mandate.edit.path({mandateId: id}));
  };
  
  const deleteMandate = () => {
    if (!currentMandate) return;
    dispatch.MandateStore.deleteMandate(currentMandate.id);
    setIsSidebarOpen(false);
  };
  
  React.useEffect(() => {
    setIsSidebarOpen(currentMandate !== undefined);
  }, [currentMandate]);
  
  return (
    <>
      {error ?
        <Alert severity="error" data-testid="alert">{error}</Alert> : <></>}
      {isLoading ?
        <CircularProgress data-testid="mandates-loading"  /> :
        <MandatesDisplayerComponent mandates={mandates}
                                    currentMandate={currentMandate}
                                    isSidebarOpen={isSidebarOpen}
                                    displayMandate={(mandateId) => navigateToMandate(mandateId)}
                                    close={() => navigate(Routes.mandate.path())}
                                    deleteMandate={() => deleteMandate()} />
      }
    </>
  );
};

export default MandatesDisplayerContainer;
