import React from 'react';
import {Accordion} from "asset/component";
import RichTextEditor from "asset/component/form/inputs/RichTextEditor";
import {AccordeonSizeVariants, AccordionColorVariants} from "asset/component/global/Accordion";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {useDispatch} from "react-redux";
import Coachee from "module/coachee/domain/model/Coachee";
import UpdateCoachee from "module/coachee/domain/model/UpdateCoachee";
import {useTranslation} from "react-i18next";
import {Dispatch} from "store";

const CoacheeNoteContainer = ({coachee}: { coachee: Coachee }) => {
  const dispatch = useDispatch<Dispatch>();
  const {t} = useTranslation();
  
  const [updateCoacheeLoading, UpdateCoacheeErrorName, updateCoacheeDispatch] =
    usePromiseLoadingAndError((note) =>
      dispatch.CoacheeStore.updateCoachee(
        new UpdateCoachee(
          coachee.id,
          coachee.firstName,
          coachee.lastName,
          coachee.companyId,
          note || coachee.note,
          coachee.jobTitle,
          coachee.descriptionJobTitle,
          coachee.teamIds[0],
        ))
    );
  
  return (
    <Accordion key={coachee.id}
               title={t('summaryNote')}
               colorVariant={AccordionColorVariants.LIGHT}
               sizeVariant={AccordeonSizeVariants.LARGE}>
      <RichTextEditor initialValue={coachee.note}
                      saveAction={(note) => updateCoacheeDispatch(note)}
                      isLoading={updateCoacheeLoading}
                      feedbackModal={true}
                      errorName={UpdateCoacheeErrorName} />
    </Accordion>
  );
};

export default CoacheeNoteContainer;
