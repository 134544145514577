import React from "react";
import ModuleCard from "asset/component/module/ModuleCard";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import {HideListedItemsButton, ModuleHeader, ModuleListing} from "asset/component";
import {useTranslation} from "react-i18next";
import followUpsListingComponentStyles from "module/followUp/view/asset/FollowUpsListingComponent.module.scss";
import FollowUpCoachee from "module/followUp/domain/model/FollowUpCoachee";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import AddFollowUp from "module/followUp/domain/model/AddFollowUp";
import CoacheeFollowUpContainer from "module/followUp/view/container/coachee/CoacheeFollowUpContainer";
import useHideListedItem from "asset/component/hook/useHideListedItem";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import styles from "module/objective/view/asset/ObjectivesContainer.module.scss";
import InputEnter from "asset/component/form/inputs/InputEnter";

const CoacheeFollowUpsContainer = ({
  coacheeId
}: {
  coacheeId: string
}) => {
  const {t} = useTranslation('followUp');
  const dispatch = useDispatch<Dispatch>();
  const {showCompletedItem, setShowCompletedItem, filterList} = useHideListedItem<FollowUpCoachee>('completed');
  const {getErrorMessage} = useErrorTranslation();
  
  const coacheeFollowUps = useSelector(
    (rootState: RootState) =>
      rootState.FollowUpCoacheeStore?.getters(rootState).getFollowUpsByCoacheeId(coacheeId)
  );
  
  const fetchFollowUpsByCoacheeIdState = useLoadingAndError(
    () =>
      dispatch.FollowUpCoacheeStore.fetchFollowUpsByCoacheeId(coacheeId), [], coacheeId
  );
  
  const [isAddFollowUpLoading, addFollowUpErrorName, dispatchAddFollowUp] =
    usePromiseLoadingAndError((text: string) =>
      dispatch.FollowUpCoacheeStore.addFollowUpForCoachee({
        addFollowUp: new AddFollowUp(text),
        coacheeId,
      })
    );
  
  return (
    <ModuleCard>
      <ModuleHeader title={t('moduleTitle')} subtitle={t('moduleSubtitle')} />
      <HideListedItemsButton showItems={showCompletedItem}
                             setShowItems={setShowCompletedItem}
                             injectedStyle={followUpsListingComponentStyles}
      />
      <ModuleListing<FollowUpCoachee>
        data-testid="listing-followUp"
        elements={filterList(coacheeFollowUps)}
        injectedStyle={followUpsListingComponentStyles}
        isAddElementLoading={isAddFollowUpLoading}
        isLoading={fetchFollowUpsByCoacheeIdState.isLoading || isAddFollowUpLoading}
        errorName={fetchFollowUpsByCoacheeIdState.errorName || addFollowUpErrorName}
      >
        {
          (followUpCoachee: FollowUpCoachee) => <CoacheeFollowUpContainer key={followUpCoachee.id}
                                                                          followUpCoachee={followUpCoachee} />
        }
      </ModuleListing>
      <div className={styles.input}>
        <InputEnter data-testid="add-followUp"
                    placeholder={t('addElement')}
                    errorMessage={addFollowUpErrorName && getErrorMessage(addFollowUpErrorName)}
                    setValue={(value) => dispatchAddFollowUp(value)}
        />
      </div>
    </ModuleCard>
  );
};

export default CoacheeFollowUpsContainer;
