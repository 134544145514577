import AddMandate from "module/mandate/domain/model/AddMandate";

export default interface AddMandateRequest {
  title: string;
  description: string;
  startDate: string;
  deadline: string;
}

export function createAddMandateRequest(addMandate: AddMandate): AddMandateRequest {
  return {
    title: addMandate.title,
    description: addMandate.description,
    startDate: addMandate.startDate?.toISOString(),
    deadline: addMandate.deadline?.toISOString(),
  } as AddMandateRequest;
}
