import * as React from "react";
import FormSection from "asset/component/form/FormSection";
import CardButton from "asset/component/global/buttons/CardButton";
import {useTranslation} from "react-i18next";
import {Typography} from "asset/component/index";
import coacheeProfileImage from "asset/image/coachee-profile.svg";
import teamProfileImage from "asset/image/team-profile.svg";
import enterpriseProfileImage from "asset/image/enterprise.svg";
import usePaletteColors from "style/usePaletteColors";
import styles from "asset/component/asset/form/AddSomething.module.scss";
import {useLocation, useNavigate} from "react-router-dom";
import {Routes} from "router/Routes";

const AddSomething = () => {
  const {t} = useTranslation();
  const palette = usePaletteColors();
  const navigate = useNavigate();
  const {pathname} = useLocation();
  
  const renderCardButton = (path, text, image, dataTestId) => (
    <CardButton data-testid={dataTestId}
                onClick={() => navigate(path)}
                isSelected={pathname === path}
                text={text}
                isFilled
                image={image}
                textColor={palette.secondary.dark}
    />
  );
  
  return (
    <FormSection>
      <Typography component="h3" variant="h3">
        {t('addSomething')}
      </Typography>
      <div className={styles.addSomethingContainer}>
        {renderCardButton(Routes.add.coachee.path(), t('coachee'), coacheeProfileImage, "coachee-card")}
        {renderCardButton(Routes.add.team.path(), t('team'), teamProfileImage, "team-card")}
        {renderCardButton(Routes.add.company.path(), t('company'), enterpriseProfileImage, "company-card")}
      </div>
    </FormSection>
  );
};

export default AddSomething;
