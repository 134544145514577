import {ArrowPositions} from "asset/component/global/dialogs/Tooltip";

export default class OnboardingStep {
  private readonly _sequenceName: string;
  private readonly _name: string;
  private readonly _anchorID: string;
  private readonly _arrowPosition: string | typeof ArrowPositions;
  private readonly _adjustments: { x: number, y: number };
  private readonly _imageUrl?: string;
  private readonly _link?: (param?: any) => string;
  private _isDone: boolean;
  
  constructor(
    props: {
      name: string,
      sequenceName: string,
      anchorID: string,
      arrowPosition: string | typeof ArrowPositions,
      adjustments?: { x: number, y: number },
      link?: (param?: any) => string;
      imageUrl?: string
    }
  ) {
    this._name = props.name;
    this._sequenceName = props.sequenceName;
    this._anchorID = props.anchorID;
    this._arrowPosition = props.arrowPosition;
    this._adjustments = props.adjustments || {x: 0, y: 0};
    this._link = props.link;
    this._imageUrl = props.imageUrl;
    this._isDone = false;
  }
  
  get name(): string {
    return this._name;
  }
  
  get anchorID(): string {
    return this._anchorID;
  }
  
  get arrowPosition(): string | typeof ArrowPositions {
    return this._arrowPosition;
  }
  
  get adjustments(): { x: number, y: number } {
    return this._adjustments;
  }
  
  get sequenceName(): string {
    return this._sequenceName;
  }
  
  public getLink(param?: any): string | undefined {
    return this._link && this._link(param);
  }
  
  get imageUrl(): string | undefined {
    return this._imageUrl;
  }
  
  get isDone(): boolean {
    return this._isDone;
  }
  
  set isDone(value: boolean) {
    this._isDone = value;
  }
}
