import React from 'react';
import mandatIcon from 'asset/image/bullseye.png';
import {Typography} from "@mui/material";
import styles from "module/mandate/view/asset/listing/LinkedMandate.module.scss";

const LinkedMandate = ({
  label,
  showMandatePreview
}: {
  label: string,
  showMandatePreview: () => void
}) => {
  return (
    <div className={styles.container} onClick={showMandatePreview}>
      <img src={mandatIcon} alt={label} data-testid="image" />
      <Typography variant="h4" component="span" textTransform="uppercase" fontSize={"0.875rem"} data-testid="label">
        {label}
      </Typography>
    </div>
  );
};

export default LinkedMandate;
