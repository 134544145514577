import React from "react";
import {CheckboxButton} from "asset/component";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {useDispatch} from "react-redux";
import {Dispatch} from "store";
import UpdateFollowUp from "module/followUp/domain/model/UpdateFollowUp";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import FollowUpTeam from "module/followUp/domain/model/FollowUpTeam";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";

const TeamFollowUpContainer = ({followUpTeam}: {
  followUpTeam: FollowUpTeam,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const {getErrorMessage} = useErrorTranslation();
  
  const [isUpdateFollowUpLoading, updateFollowUpErrorName, dispatchUpdateFollowUp] =
    usePromiseLoadingAndError((isCompleted, text) =>
      dispatch.FollowUpTeamStore.updateFollowUpForTeam({
        followUpId: followUpTeam.id,
        teamId: followUpTeam.teamId,
        updateFollowUp: new UpdateFollowUp(text || followUpTeam.text, isCompleted)
      })
    );
  
  const [isDeleteFollowUpLoading, deleteFollowUpErrorName, dispatchDeleteFollowUp] =
    usePromiseLoadingAndError(() =>
      dispatch.FollowUpTeamStore.deleteFollowUpForTeam({
        followUpId: followUpTeam.id,
        teamId: followUpTeam.teamId,
      })
    );
  
  return (
    <CheckboxButton data-testid={`${followUpTeam.id}-checkbox-button`}
                    value={followUpTeam.completed}
                    setValue={dispatchUpdateFollowUp}
                    deleteElement={dispatchDeleteFollowUp}
                    label={followUpTeam.text}
                    date={followUpTeam.createdAt}
                    isLoading={isUpdateFollowUpLoading || isDeleteFollowUpLoading}
                    errorMessage={
                      updateFollowUpErrorName && getErrorMessage(updateFollowUpErrorName) ||
                      deleteFollowUpErrorName && getErrorMessage(deleteFollowUpErrorName)
                    }
                    variant={MuiButtonVariants.OUTLINED}
    />
  );
};

export default TeamFollowUpContainer;
