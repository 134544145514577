import React from "react";
import {Grid, Typography} from "asset/component";
import {TextValidator} from "asset/validator";
import {InputValidator} from "asset/validator/InputValidator";
import {useTranslation} from "react-i18next";
import usePaletteColors from "style/usePaletteColors";
import {MuiInputColors} from "style/props/MuiInputColors";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";

const UpdateTeamNameFormComponent = ({
  name,
  setName,
}:
  {
    name: string,
    setName: (name: string) => void,
  }) => {
  const {t} = useTranslation('team');
  const palette = usePaletteColors();
  
  return <div>
    <Typography component="p" variant={MuiTypographyVariants.XS} color={palette.secondary.dark}>
      {t('form.update.nameLabel')}
    </Typography>
    <Grid container>
      <Grid item>
        <TextValidator
          required
          type="text"
          data-testid="name-input"
          id="name"
          placeholder={t('form.name')}
          name="name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          validators={[InputValidator.REQUIRED]}
          errorMessages={[t('form.validation.nameRequired')]}
          color={MuiInputColors.SECONDARY}
        />
      </Grid>
    </Grid>
  </div>;
};

export default UpdateTeamNameFormComponent;
