import {
  AffectationsSequence,
  CoacheeSequence,
  CoacheesSequence,
  HomeSequence,
  MandatesSequence, ParametersSequence, TeamSequence
} from "module/onboarding/domain/model/SeedData/OnboardingSequencesSeed";
import OnboardingStep from "module/onboarding/domain/model/OnboardingStep";

export default class OnboardingSequences {
  private _steps: OnboardingStep[];
  
  constructor(completedSteps?: string[]) {
    this._steps = [];
    this.initializeSequences();
    if (completedSteps) this.setCompletedSteps(completedSteps);
  }
  
  get steps(): OnboardingStep[] {
    return this._steps;
  }
  
  initializeSequences() {
    this._steps = [
      HomeSequence,
      CoacheesSequence,
      MandatesSequence,
      CoacheeSequence,
      TeamSequence,
      ParametersSequence,
      AffectationsSequence
    ].flatMap(sequence => sequence.steps);
  }
  
  setCompletedSteps(completedSteps: string[]) {
    completedSteps.forEach(completedStep => {
      const [sequenceName, stepName] = completedStep.split('.');
      
      const step = this._steps.find(
        step => step.sequenceName === sequenceName && step.name === stepName
      );
      
      if (!step) return;
      
      step.isDone = true;
    });
  }
}
