import {IAddTeamMotivator} from "module/motivator/team/domain/model/AddTeamMotivator";

export default class AddTeamDemotivator implements IAddTeamMotivator {
  private readonly _title: string;
  private readonly _teamId: string;
  
  constructor(title: string, teamId: string) {
    this._title = title;
    this._teamId = teamId;
  }
  
  get title(): string {
    return this._title;
  }
  
  get teamId(): string {
    return this._teamId;
  }
}
