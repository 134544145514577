import UpdateUser from "module/user/domain/model/UpdateUser";

export default interface UpdateUserRequest {
  firstName: string;
  lastName: string;
}

export function createUpdateUserRequest(updateUser: UpdateUser): UpdateUserRequest {
  return {
    firstName: updateUser.firstName,
    lastName: updateUser.lastName,
  } as UpdateUserRequest;
}
