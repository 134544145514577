import React, {useEffect, useState} from 'react';
import styles from "asset/component/asset/tag/Tag.module.scss";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import {MuiButtonColors} from "style/props/MuiButtonColors";
import {LoadingButton} from "@mui/lab";
import {CloseIcon} from "asset/component/Icons";
import MuiButtonSizes from "style/props/MuiButtonSizes";

export interface Tag {
  id: string
  name: string,
  isSelected: boolean
}

const TagComponent = ({
    tag,
    updateTag,
    showCloseIconOnHover = false,
    selectable = true,
    isInactive = false,
    icon
  }: {
    tag: Tag,
    updateTag?: (tag: Tag) => void,
    showCloseIconOnHover?: boolean,
    selectable?: boolean,
    isInactive?: boolean
    icon?: JSX.Element
  }
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(tag.isSelected);
  
  useEffect(() => {
    setIsLoading(false);
    setIsSelected(tag.isSelected);
  }, [tag]);
  
  const updateOnClick = () => {
    if (updateTag) {
      setIsLoading(true);
      updateTag({...tag, isSelected: !isSelected});
      setIsSelected(!isSelected);
    }
  };
  
  const getTagStyle = () => {
    return selectable ? (isSelected ? styles.isSelected : styles.isNotSelected) : styles.isSelected;
  };
  
  return (
    <div data-testid="tag-component"
         className={`${styles.tag} ${showCloseIconOnHover && styles.showCloseIconOnHover} ${isInactive && styles.isInactive}`}
         onClick={() => updateOnClick()}>
      <LoadingButton className={`${styles.tagButton} ${getTagStyle()} darker`}
                     variant={isSelected ? MuiButtonVariants.CONTAINED : MuiButtonVariants.OUTLINED}
                     size={MuiButtonSizes.MEDIUM}
                     color={isSelected ? MuiButtonColors.PRIMARY : MuiButtonColors.SECONDARY_VERY_DARK}
                     loading={isLoading}
                     endIcon={<CloseIcon className={styles.closeIcon} />}>
        {icon}
        {tag.name}
      </LoadingButton>
    </div>
  );
};

export default TagComponent;
