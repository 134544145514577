import type RESTClient from "core/client/RESTClient";
import TeamDemotivatorRepository from "module/demotivator/team/domain/TeamDemotivatorRepository";
import TeamDemotivatorResponse, {
  teamDemotivatorResponseToModel,
  teamDemotivatorsResponseToModel
} from "module/demotivator/team/dal/dto/TeamDemotivatorResponse";
import TeamDemotivator from "module/demotivator/team/domain/model/TeamDemotivator";
import AddTeamDemotivator from "module/demotivator/team/domain/model/AddTeamDemotivator";
import {createAddTeamDemotivatorRequest} from "module/demotivator/team/dal/dto/AddTeamDemotivatorRequest";

export default class RESTTeamDemotivatorRepository implements TeamDemotivatorRepository {
  private static readonly BASE_URL: string = "api";
  private static readonly TEAM_URL: string = "teams";
  private static readonly MOTIVATOR_URL: string = "demotivators";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchDemotivatorsByTeamId(id: string): Promise<TeamDemotivator[]> {
    const demotivatorsResponse = await this.client.apiGet<TeamDemotivatorResponse[]>(
      `/${RESTTeamDemotivatorRepository.BASE_URL}` +
      `/${RESTTeamDemotivatorRepository.TEAM_URL}/${id}` +
      `/${RESTTeamDemotivatorRepository.MOTIVATOR_URL}`
    );
    
    return teamDemotivatorsResponseToModel(demotivatorsResponse, id);
  }
  
  public async addDemotivatorForTeam(
    addDemotivator: AddTeamDemotivator,
    teamId: string
  ): Promise<TeamDemotivator> {
    const demotivatorResponse = await this.client.apiPost<TeamDemotivatorResponse>(
      `/${RESTTeamDemotivatorRepository.BASE_URL}` +
      `/${RESTTeamDemotivatorRepository.TEAM_URL}/${teamId}` +
      `/${RESTTeamDemotivatorRepository.MOTIVATOR_URL}`,
      createAddTeamDemotivatorRequest(addDemotivator)
    );
    
    return teamDemotivatorResponseToModel(demotivatorResponse, teamId);
  }
  
  public async deleteDemotivatorForTeam(motivatorId: string, teamId: string): Promise<void> {
    await this.client.apiDelete<TeamDemotivatorResponse>(
      `/${RESTTeamDemotivatorRepository.BASE_URL}` +
      `/${RESTTeamDemotivatorRepository.TEAM_URL}/${teamId}` +
      `/${RESTTeamDemotivatorRepository.MOTIVATOR_URL}/${motivatorId}`
    );
  }
}
