import React from 'react';
import TagComponent, {Tag} from "asset/component/tag/Tag";
import styles from "asset/component/asset/tag/TagDisplayer.module.scss";
import DeleteConfirmation from "asset/component/global/dialogs/DeleteConfirmation";
import IconButton from "asset/component/global/buttons/IconButton";
import usePaletteColors, {AddOpacity} from "style/usePaletteColors";
import DoneButton from "asset/component/global/DoneButton";
import {EditIcon} from "asset/component/Icons";

const TagDisplayer = ({
  tags,
  predefinedTags,
  addTag,
  removeTag,
  icon,
  input,
  removeDialog = false,
  skipEditModeStep = true,
  removeConfirmationText,
}: {
  tags: Tag[],
  predefinedTags?: Tag[],
  addTag?: (tag: Tag) => void,
  removeTag?: (tag: Tag) => void,
  icon?: JSX.Element,
  editButton?: JSX.Element,
  input?: JSX.Element,
  removeDialog?: boolean,
  skipEditModeStep?: boolean,
  removeConfirmationText?: string
}) => {
  const [isEditMode, setIsEditMode] = React.useState(false);
  const palette = usePaletteColors();
  const displayInput = !skipEditModeStep && isEditMode || skipEditModeStep;
  
  const editButton = !skipEditModeStep &&
    (!isEditMode ? <IconButton data-testid="edit-mode-button"
                              className={styles.editButton}
                              onClick={() => setIsEditMode(true)}
                              iconColor={palette.secondary.veryDark}
                              hoverBackgroundColor={AddOpacity(palette.primary.middle, 40)}
                              backgroundColor="transparent"
                              icon={<EditIcon />} /> :
      <DoneButton onClick={() => setIsEditMode(false)} />);
  
  const predefinedListing = predefinedTags?.map((tag: Tag) =>
    <TagComponent key={tag.id}
                  tag={tag}
                  updateTag={() => addTag && addTag(tag)}
                  selectable={true}
                  data-testid={`${tag.id}-predefined-tag`} />);
  
  const listingConfirmation = <div className={styles.listingContainer} data-testid="listing-confirmation">
    {tags.map((tag: Tag) =>
      <DeleteConfirmation deleteAction={() => removeTag && removeTag(tag)}
                          confirmationText={removeConfirmationText || ''}
                          key={tag.id}
                          icon={
                            <TagComponent key={tag.id}
                                          tag={tag}
                                          showCloseIconOnHover={isEditMode}
                                          selectable={false}
                                          icon={icon}
                                          data-testid={`${tag.id}-tag`} />
                          } />)}
    {isEditMode && predefinedListing}
    {displayInput && input}
    {editButton}
  </div>;
  
  const listing = <div className={styles.listingContainer} data-testid="listing">
    {tags.map((tag: Tag) =>
      <TagComponent key={tag.id}
                    tag={tag}
                    updateTag={() => removeTag && removeTag(tag)}
                    showCloseIconOnHover={true}
                    selectable={false}
                    isInactive={(!skipEditModeStep && !isEditMode) || !removeTag}
                    icon={icon}
                    data-testid={`${tag.id}-tag`} />)}
    {isEditMode && predefinedListing}
    {displayInput && input}
    {editButton}
  </div>;
  
  return (removeDialog && removeTag) ? listingConfirmation : listing;
};

export default TagDisplayer;
