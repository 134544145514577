import type RESTClient from "core/client/RESTClient";
import CoacheeMotivator from "module/motivator/coachee/domain/model/CoacheeMotivator";
import AddCoacheeMotivator from "module/motivator/coachee/domain/model/AddCoacheeMotivator";
import CoacheeMotivatorResponse, {
  coacheeMotivatorsResponseToModel,
  coacheeMotivatorResponseToModel
} from "module/motivator/coachee/dal/dto/CoacheeMotivatorResponse";
import CoacheeMotivatorRepository from "module/motivator/coachee/domain/CoacheeMotivatorRepository";
import {createAddCoacheeMotivatorRequest} from "module/motivator/coachee/dal/dto/AddCoacheeMotivatorRequest";

export default class RESTCoacheeMotivatorRepository implements CoacheeMotivatorRepository {
  private static readonly BASE_URL: string = "api";
  private static readonly COACHEE_URL: string = "coachees";
  private static readonly MOTIVATOR_URL: string = "motivators";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchMotivatorsByCoacheeId(id: string): Promise<CoacheeMotivator[]> {
    const motivatorsResponse = await this.client.apiGet<CoacheeMotivatorResponse[]>(
      `/${RESTCoacheeMotivatorRepository.BASE_URL}` +
      `/${RESTCoacheeMotivatorRepository.COACHEE_URL}/${id}` +
      `/${RESTCoacheeMotivatorRepository.MOTIVATOR_URL}`
    );
    
    return coacheeMotivatorsResponseToModel(motivatorsResponse, id);
  }
  
  public async addMotivatorForCoachee(addMotivator: AddCoacheeMotivator, coacheeId: string): Promise<CoacheeMotivator> {
    const motivatorResponse = await this.client.apiPost<CoacheeMotivatorResponse>(
      `/${RESTCoacheeMotivatorRepository.BASE_URL}` +
      `/${RESTCoacheeMotivatorRepository.COACHEE_URL}/${coacheeId}` +
      `/${RESTCoacheeMotivatorRepository.MOTIVATOR_URL}`,
      createAddCoacheeMotivatorRequest(addMotivator)
    );
    
    return coacheeMotivatorResponseToModel(motivatorResponse, coacheeId);
  }
  
  public async deleteMotivatorForCoachee(motivatorId: string, coacheeId: string): Promise<void> {
    await this.client.apiDelete<CoacheeMotivatorResponse>(
      `/${RESTCoacheeMotivatorRepository.BASE_URL}` +
      `/${RESTCoacheeMotivatorRepository.COACHEE_URL}/${coacheeId}` +
      `/${RESTCoacheeMotivatorRepository.MOTIVATOR_URL}/${motivatorId}`
    );
  }
}
