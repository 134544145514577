import CultureTag from "module/culture/domain/model/CultureTag";

export default interface CultureTagResponse {
  id: string;
  title: string;
}

export function cultureTagResponseToModel(cultureTagResponse: CultureTagResponse, teamId: string): CultureTag {
  return new CultureTag(
    cultureTagResponse.id,
    teamId,
    cultureTagResponse.title,
  );
}

export function cultureTagsResponseToModel(cultureTagsResponse: CultureTagResponse[], teamId: string): CultureTag[] {
  return cultureTagsResponse.map(cultureTagResponse => cultureTagResponseToModel(cultureTagResponse, teamId));
}
