import React from 'react';
import ProgressionCircleEmpty from "asset/icon/progression/ProgressionCircleEmpty";
import ProgressionCircle33Percent from "asset/icon/progression/ProgressionCircle33Percent";
import ProgressionCircle66Percent from "asset/icon/progression/ProgressionCircle66Percent";
import ProgressionCircleCompleted from "asset/icon/progression/ProgressionCircleCompleted";
import styles from 'asset/component/asset/module/ProgressCircle.module.scss';
import {Typography} from "@mui/material";
import {MuiTypographyColors} from "style/props/MuiTypograpghyColors";

export enum ProgressCircleSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

const ProgressCircle = ({completed, total, progressCircleSize = ProgressCircleSize.MEDIUM, displayNumber = false}: {
  completed: number
  total: number,
  progressCircleSize?: ProgressCircleSize,
  displayNumber?: boolean
}) => {
  const RATIO_COMPLETED = 1;
  const RATIO_66_PERCENT = 2 / 3;
  const RATIO_33_PERCENT = 1 / 3;
  
  const EMPTY_ICON = <ProgressionCircleEmpty data-testid="empty-icon" />;
  const RATIO_33_ICON = <ProgressionCircle33Percent data-testid="ratio-33-icon" />;
  const RATIO_66_ICON = <ProgressionCircle66Percent data-testid="ratio-66-icon" />;
  const COMPLETED_ICON = <ProgressionCircleCompleted data-testid="completed-icon" />;
  
  const getProgressCircleIcon = () => {
    const ratio = completed / total;
    
    if (ratio === RATIO_COMPLETED) {
      return COMPLETED_ICON;
    } else if (ratio < RATIO_33_PERCENT) {
      return EMPTY_ICON;
    } else if (ratio < RATIO_66_PERCENT) {
      return RATIO_33_ICON;
    } else if (ratio >= RATIO_66_PERCENT) {
      return RATIO_66_ICON;
    }
    
    return EMPTY_ICON;
  };
  
  return (
    <div className={`${styles.progressCircleContainer} ${progressCircleSize}`}>
    <span className={styles.progressCircleIcon}>
      {getProgressCircleIcon()}
    </span>
      {
        displayNumber &&
          <Typography
              className={styles.numbers}
              component="span"
              variant="sm"
              color={MuiTypographyColors.SECONDARY_DARK}
          >
            {completed}/{total}
          </Typography>
      }
    </div>
  );
};

export default ProgressCircle;
