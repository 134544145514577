import React, {useEffect, useState} from 'react';
import {Button} from "asset/component/index";
import {Autocomplete, Paper} from "@mui/material";
import 'asset/component/asset/form/inputs/InputSelect.scss';
import {TextValidator} from "asset/validator";
import {useTranslation} from "react-i18next";
import {AddIcon, ExpandMoreIcon} from "asset/component/Icons";
import MuiButtonSizes from "style/props/MuiButtonSizes";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import {MuiInputColors} from "style/props/MuiInputColors";
import ExpandMore from "@mui/icons-material/ExpandMore";

export interface InputOption {
  value: string;
  label: string;
  icon?: string;
}

export enum InputSelectStyles {
  SQUARED = "squared",
  ROUNDED = "rounded",
  ICONS = "icons"
}

export enum InputAction {
  CLEAR = "clear",
}

interface InputSelectProps {
  selectedId?: string;
  options: InputOption[];
  setNewValue?: (newValue: string) => void;
  setExistingValue: (existingValue: string) => void;
  required?: boolean;
  disableClearable?: boolean;
  validationRequiredMessage?: string;
  selectLabel?: string;
  addButtonLabel?: string;
  color?: MuiInputColors;
  style?: InputSelectStyles;
  id?: string;
  inputAction?: InputAction;
}

const InputSelect: React.FC<InputSelectProps> = ({
  selectedId,
  options,
  setExistingValue,
  setNewValue,
  disableClearable = false,
  required = false,
  validationRequiredMessage,
  selectLabel,
  addButtonLabel,
  color = MuiInputColors.PRIMARY,
  style = InputSelectStyles.SQUARED,
  id,
  inputAction
}) => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = React.useState<InputOption | undefined | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [inputOptions, setInputOptions] = React.useState<InputOption[]>([...options]);
  const newValueKey = 'new';
  const unSelectedDefaultValue = '';
  
  useEffect(() => {
    setInputOptions([...options]);
    selectDefaultOption();
  }, [options]);
  
  useEffect(() => {
    if (inputAction === InputAction.CLEAR) {
      setSelectedOption(null);
    }
  }, [inputAction]);
  
  const selectDefaultOption = () => {
    setSelectedOption([...options].find(option => option.value === selectedId) || null);
  };
  
  const handleAddOption = () => {
    const newOption = {value: newValueKey, label: inputValue};
    setInputOptions([...inputOptions, newOption]);
    setSelectedOption(newOption);
    setInputValue(unSelectedDefaultValue);
    onChange(newOption);
    setIsOpen(false);
  };
  
  const AddButton = setNewValue && (
    <div className="add-button">
      <Button startIcon={<AddIcon />}
              disabled={!inputValue}
              variant={MuiButtonVariants.CONTAINED}
              size={MuiButtonSizes.MEDIUM}
              data-testid="add-input-select-button"
              onMouseDown={handleAddOption}>
        {addButtonLabel || t('common:addElement')}
      </Button>
    </div>
  );
  
  const onChange = (newSelectedOption) => {
    if (newSelectedOption?.value === newValueKey) {
      setExistingValue(unSelectedDefaultValue);
      setNewValue && setNewValue(newSelectedOption.label);
    } else if (newSelectedOption) {
      setExistingValue(newSelectedOption.value);
      setNewValue && setNewValue(unSelectedDefaultValue);
    } else {
      setExistingValue(unSelectedDefaultValue);
      setNewValue && setNewValue(unSelectedDefaultValue);
    }
  };
  
  const renderOption = (props, option) => {
    if (style === InputSelectStyles.ICONS) {
      return <li {...props} key={props.id}><img className="icon-option"
                                                src={option.icon}
                                                alt={option.label} />{option.label}</li>;
    } else {
      return <li {...props} key={props.id}>{option.label}</li>;
    }
  };
  
  return (
    <Autocomplete
      className={`${color} ${style} ${isOpen && 'isOpen'}`}
      componentsProps={{
        popper: {
          className: `${color} ${style}`
        },
        paper: {
          className: `${color} ${style}`
        }
      }}
      PaperComponent={({children}) => <Paper>{children}{AddButton}</Paper>}
      disableClearable={disableClearable}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      options={inputOptions}
      getOptionLabel={option => option.label}
      renderOption={renderOption}
      value={selectedOption}
      onChange={(event, newValue: InputOption | undefined | null) => {
        setSelectedOption(newValue);
        onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextValidator
          {...params}
          label={selectLabel}
          variant={MuiButtonVariants.OUTLINED}
          validators={required ? (selectedOption ? [] : ["required"]) : []}
          errorMessages={selectedOption ? [] : [validationRequiredMessage]}
          color={color}
          InputProps={style === InputSelectStyles.ICONS ? {
            ...params.InputProps,
            startAdornment: (<img className="icon-option" src={selectedOption?.icon} alt={selectedOption?.label} />),
            endAdornment: (<ExpandMore id={id} className="expand-icon" onClick={() => setIsOpen(!isOpen)} />)
          } : params.InputProps} />
      )}
      noOptionsText={
        <div className="no-options">
          <p className="no-option-text">{t('common:noItemFound')}</p>
        </div>
      }
      popupIcon={<ExpandMoreIcon />}
    />
  );
};

export const useInputAction = () => {
  const [inputAction, setInputAction] = useState<InputAction | undefined>(undefined);
  
  useEffect(() => {
    setInputAction(undefined);
  }, [inputAction]);
  
  return {inputAction, triggerInputAction: setInputAction};
};

export default InputSelect;
