import UpdateInformalRole from "module/skill/domain/model/UpdateInformalRole";

export default interface UpdateInformalRoleRequest {
  informalRole: string;
}

export function createUpdateInformalRoleRequest(updateInformalRole: UpdateInformalRole): UpdateInformalRoleRequest {
  return {
    informalRole: updateInformalRole.name,
  } as UpdateInformalRoleRequest;
}
