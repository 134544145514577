import React from 'react';
import {ErrorNames} from "core/error/ErrorNames";
import {Alert, CircularProgress} from "asset/component/index";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'asset/component/asset/CustomQuill.scss';
import styles from 'asset/component/asset/form/inputs/RichTextEditor.module.scss';
import useAutoSave from 'asset/component/hook/useAutoSave';
import SaveState from "asset/component/form/SaveState";
import FeedbackModal from "asset/component/global/dialogs/FeedbackModal";
import FeedbackServices from "asset/service/FeedbackServices";

const QUILL_MODULE_OPTION = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{'header': 1}, {'header': 2}],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],
    [{'indent': '-1'}, {'indent': '+1'}],
    [{'header': [1, 2, 3, 4, 5, 6, false]}],
    [{'color': []}, {'background': []}],
    [{'align': []}],
    ['clean']
  ],
  clipboard: {
    matchVisual: false,
  }
};

const RichTextEditor = ({initialValue = "", saveAction, isLoading, errorName, feedbackModal}: {
  initialValue?: string,
  saveAction: (text: string) => void,
  isLoading?: boolean,
  errorName?: ErrorNames,
  feedbackModal?: boolean
}) => {
  const {value, isSaving, isError, onChange} = useAutoSave({initialValue, saveAction});
  const [feedbackModalOpen, setFeedbackModalOpen] = React.useState(false);
  const {getErrorMessage} = useErrorTranslation();
  const loadingIndicator = <CircularProgress className={styles.loadingIndicator} data-testid="loading_indicator" />;
  
  React.useEffect(() => {
    if (!feedbackModal) return;
    if (value !== initialValue && FeedbackServices.showFeedbackMessage()) {
      const timeout = setTimeout(() => {
        setFeedbackModalOpen(true);
        FeedbackServices.showedFeedbackMessage();
      }, 10000);
      return () => clearTimeout(timeout);
    }
  }, [value]);
  
  const errorMessage =
    <Alert severity="error" data-testid="error_message">
      {errorName && getErrorMessage(errorName)}
    </Alert>;
  
  return <div className={`${styles.richTextEditorContainer} quill-container`}>
    {errorName && errorMessage}
    {isLoading && loadingIndicator}
    {feedbackModal && <FeedbackModal open={feedbackModalOpen}
                                     setOpen={(isOpen) => setFeedbackModalOpen(isOpen)}
                                     data-testid="feedback-modal" />}
    <ReactQuill
      data-testid="rich-text-editor"
      modules={QUILL_MODULE_OPTION}
      onBlur={() => saveAction(value)}
      theme="snow"
      value={value}
      onChange={onChange} />
    <SaveState isSaving={isSaving}
               isError={isError || errorName !== undefined}
               isSaved={!isError && !isSaving}
    />
  </div>;
};

export default RichTextEditor;
