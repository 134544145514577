import React from 'react';
import styles from "asset/component/asset/global/Plaque.module.scss";
import {Typography} from "@mui/material";

export enum PlaqueTheme {
  WHITE = 'white',
  LIGHT = 'light',
  DARK = 'dark',
}

const Plaque = ({
  id,
  label,
  icon,
  actionIcon,
  theme = PlaqueTheme.LIGHT,
  onClick,
  children,
  isEditMode = false,
}: {
  id: string;
  label?: string;
  icon?: JSX.Element;
  actionIcon?: JSX.Element;
  theme?: PlaqueTheme | string;
  onClick?: (name: string) => void;
  children?: JSX.Element[];
  isEditMode?: boolean;
}) => {
  const [displayChildren, setDisplayChildren] = React.useState(false);
  
  const action = () => {
    if (!isEditMode)
      setDisplayChildren(!displayChildren);
    else
      onClick && onClick(id);
  };
  
  return (
    <div className={`${styles.card} ${theme}`} data-testid="plaque">
      <div className={styles.cardContent}>
        <div className={styles.avatar}>
          {icon}
          <Typography variant="body1" component="div" className={styles.name}>
            {label}
          </Typography>
        </div>
        <div className={`${styles.actionIcon} ${displayChildren ? styles.active : styles.inactive}`}
             onClick={() => action()}
             data-testid="action-icon">
          {actionIcon}
        </div>
      </div>
      <div className={`${styles.childrenContainer} ${displayChildren ? "child-" + (children ? children.length : 0) : styles.childInactive}`}>
        {displayChildren &&
            <div className={styles.children}>
              {children}
            </div>}
      </div>
    </div>
  );
};

export default Plaque;
