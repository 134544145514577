import type RESTClient from "core/client/RESTClient";
import CoacheeRepository from "module/coachee/domain/CoacheeRepository";
import CoacheeResponse, {coacheeResponseToModel, coacheesResponseToModel} from "module/coachee/dal/dto/CoacheeResponse";
import Coachee from "module/coachee/domain/model/Coachee";
import {createAddCoacheeRequest} from "module/coachee/dal/dto/AddCoacheeRequest";
import AddCoachee from "module/coachee/domain/model/AddCoachee";
import UpdateCoachee from "module/coachee/domain/model/UpdateCoachee";
import {createUpdateCoacheeRequest} from "module/coachee/dal/dto/UpdateCoacheeRequest";

export default class RESTCoacheeRepository implements CoacheeRepository {
  private static readonly BASE_URL: string = "api";
  private static readonly COACHEES_URL: string = "coachees";
  private static readonly TEAMS_URL: string = "teams";
  private static readonly COMPANIES_URL: string = "companies";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchCoachees(): Promise<Coachee[]> {
    const coacheesResponse = await this.client.apiGet<CoacheeResponse[]>(
      `/${RESTCoacheeRepository.BASE_URL}` +
      `/${RESTCoacheeRepository.COACHEES_URL}`
    );
    
    return coacheesResponseToModel(coacheesResponse);
  }
  
  public async fetchCoacheesByTeamId(id: string): Promise<Coachee[]> {
    const coacheesResponse = await this.client.apiGet<CoacheeResponse[]>(
      `/${RESTCoacheeRepository.BASE_URL}` +
      `/${RESTCoacheeRepository.TEAMS_URL}` +
      `/${id}` +
      `/${RESTCoacheeRepository.COACHEES_URL}`
    );
    
    return coacheesResponseToModel(coacheesResponse);
  }
  
  public async fetchCoacheesByCompanyIdWithoutTeam(id: string): Promise<Coachee[]> {
    const coacheesResponse = await this.client.apiGet<CoacheeResponse[]>(
      `/${RESTCoacheeRepository.BASE_URL}` +
      `/${RESTCoacheeRepository.COMPANIES_URL}` +
      `/${id}` +
      `/${RESTCoacheeRepository.COACHEES_URL}` +
      `/noTeam`
    );
    
    return coacheesResponseToModel(coacheesResponse);
  }
  
  public async fetchCoacheeById(coacheeId: string): Promise<Coachee> {
    const coacheeResponse = await this.client.apiGet<CoacheeResponse>(
      `/${RESTCoacheeRepository.BASE_URL}` +
      `/${RESTCoacheeRepository.COACHEES_URL}` +
      `/${coacheeId}`
    );
    
    return coacheeResponseToModel(coacheeResponse);
  }
  
  public async addCoachee(addCoachee: AddCoachee): Promise<Coachee> {
    const coacheeResponse = await this.client.apiPost<CoacheeResponse>(
      `/${RESTCoacheeRepository.BASE_URL}` +
      `/${RESTCoacheeRepository.COACHEES_URL}`,
      createAddCoacheeRequest(addCoachee)
    );
    
    return coacheeResponseToModel(coacheeResponse);
  }
  
  public async updateCoachee(updateCoachee: UpdateCoachee): Promise<Coachee> {
    const coacheeResponse = await this.client.apiPut<CoacheeResponse>(
      `/${RESTCoacheeRepository.BASE_URL}` +
      `/${RESTCoacheeRepository.COACHEES_URL}` +
      `/${updateCoachee.id}`,
      createUpdateCoacheeRequest(updateCoachee)
    );
    
    return coacheeResponseToModel(coacheeResponse);
  }
  
  public async deleteCoachee(coacheeId: string): Promise<void> {
    await this.client.apiDelete<void>(
      `/${RESTCoacheeRepository.BASE_URL}` +
      `/${RESTCoacheeRepository.COACHEES_URL}` +
      `/${coacheeId}`
    );
  }
}
