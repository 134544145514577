import React from 'react';

const Checkmark = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.6726 5.59961L8.03743 13.1996L3.61706 8.79961L2.71289 9.69961L7.13326 14.0996L8.03743 14.9996L16.5768 6.49961L15.6726 5.59961Z" />
    </svg>
  );
};

export default Checkmark;
