import React, {useEffect} from "react";
import {Alert} from "asset/component";
import {useFormValidation, ValidatorForm} from "asset/validator";
import {useTranslation} from "react-i18next";
import useNavigationFormSections from "asset/component/form/hook/useNavigationFormSections";
import AddTeamOrganisationFormComponent from "module/team/view/component/addTeam/AddTeamOrganisationFormComponent";
import {InputOption} from "asset/component/form/inputs/InputSelect";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import {ErrorNames} from "core/error/ErrorNames";
import AddTeamNameFormComponent from "module/team/view/component/addTeam/AddTeamNameFormComponent";
import SubmitFormSection from "asset/component/form/SubmitFormSection";

export interface AddTeamStateProps {
  name: string;
  setName: ((name: string) => void);
  companyName: string;
  setCompanyName: ((companyName: string) => void);
  companyId: string;
  setCompanyId: ((companyId: string) => void);
}

export interface AddTeamFormComponentProps {
  addTeamState: AddTeamStateProps;
  addTeam: Function;
  isLoading: boolean;
  errorName?: ErrorNames;
  companyOptions: InputOption[];
}

const AddTeamFormComponent = ({
  addTeam,
  isLoading,
  errorName,
  companyOptions,
  addTeamState
}: AddTeamFormComponentProps) => {
  const {t} = useTranslation('team');
  const {
    name,
    setName,
    setCompanyName,
    setCompanyId
  } = addTeamState;
  const useNavigation = useNavigationFormSections();
  const {getErrorMessage} = useErrorTranslation();
  const nameFormSectionId = 'name-section';
  const organisationFormSectionId = 'organisation-section';
  const submitFormSectionId = 'submit-section';
  
  const formNameRef = React.useRef(null);
  const {isFormValid: isNameValid, submitForm: submitName} = useFormValidation(formNameRef, name);
  const formCompanyRef = React.useRef(null);
  const {isFormValid: isCompanyValid, submitForm: submitCompany} = useFormValidation(formCompanyRef, setCompanyName, setCompanyId);
  
  const addTeamSubmit = (event) => {
    event.preventDefault();
    
    useNavigation.resetSections();
    
    if (!isNameValid) {
      submitName();
      useNavigation.activateSection(nameFormSectionId);
    } else if (!isCompanyValid) {
      submitCompany();
      useNavigation.activateSection(organisationFormSectionId);
    } else {
      addTeam();
    }
  };
  
  useEffect(() => {
    useNavigation.activateSection(nameFormSectionId);
  }, []);
  
  return <div>
    {errorName && <Alert severity="error"
                         data-testid="add-team-error-message">{errorName && getErrorMessage(errorName)}</Alert>}
    <ValidatorForm data-testid="add-team-form" onSubmit={addTeamSubmit}>
      <AddTeamNameFormComponent nameFormSectionId={nameFormSectionId}
                                nextFormSectionId={organisationFormSectionId}
                                useNavigation={useNavigation}
                                name={name}
                                setName={setName}
                                formRef={formNameRef}
                                submitForm={submitName}
                                isValid={isNameValid}
      />
      <AddTeamOrganisationFormComponent data-testid="company-form"
                                        organisationFormSectionId={organisationFormSectionId}
                                        previousFormSectionId={nameFormSectionId}
                                        nextFormSectionId={submitFormSectionId}
                                        useNavigation={useNavigation}
                                        companyOptions={companyOptions}
                                        setCompanyName={setCompanyName}
                                        setCompanyId={setCompanyId}
                                        submitForm={submitCompany}
                                        formRef={formCompanyRef}
                                        isValid={isCompanyValid}
      />
      <SubmitFormSection submitFormSectionId={submitFormSectionId}
                         previousFormSectionId={organisationFormSectionId}
                         useNavigation={useNavigation}
                         text={`${t('form.add.submitTitle', {teamName: name})}`}
                         isLoading={isLoading}
                         submitText={t('form.add.submit')}
      />
    </ValidatorForm>
  </div>;
};

export default AddTeamFormComponent;
