import styles from "asset/component/asset/module/HorizontalSeparatorLine.module.scss"

export enum SeparatorColor {
  BLACK = 'black',
  WHITE = 'white',
  MIDDLE_CREAM = 'middle-cream'
}

const HorizontalSeparatorLine = ({
  color,
  classes
} : {
  color?: SeparatorColor,
  classes?,
}) => {
  
  return <div className={`${styles.separatorLine} ${color} ${classes?.separator}`}/>
}

export default HorizontalSeparatorLine;
