import {NeedsCoachee} from "module/objective/domain/model/need/NeedsCoachee";
import {NeedsTeam} from "module/objective/domain/model/need/NeedsTeam";

export default class AddObjective {
  private readonly _name: string;
  private readonly _needs: NeedsCoachee[] | NeedsTeam[];
  
  constructor(name: string, needs?: NeedsCoachee[] | NeedsTeam[]) {
    this._name = name;
    this._needs = needs || [];
  }
  
  get name(): string {
    return this._name;
  }
  
  get needs(): NeedsCoachee[] | NeedsTeam[] {
    return this._needs;
  }
}
