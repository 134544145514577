import React, {ReactElement} from 'react';
import {Box} from "asset/component/";
import {LayoutTheme} from "module/common/layout/container/Layout";
import styles from "module/common/layout/component/assets/LayoutWrapper.module.scss";

function LayoutWrapper({
  children,
  layoutTheme = LayoutTheme.DARK
}: {
  children: JSX.Element | JSX.Element[],
  layoutTheme?: LayoutTheme
}): ReactElement {
  return <Box className={layoutTheme}>
    <span id={styles.backgroundTheme} />
    {children}
  </Box>;
}

export default LayoutWrapper;
