import * as React from 'react';
import {useTranslation} from "react-i18next";
import styles from 'module/authentication/view/asset/LoginPage.module.scss';
import logo from "asset/image/logo_panoramix-login.svg";
import {Button, Typography} from "asset/component";
import LoginContainer from "module/authentication/view/container/LoginContainer";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import {useNavigate} from "react-router-dom";
import {Routes} from "router/Routes";
import MuiButtonSizes from "style/props/MuiButtonSizes";

export default function LoginPage() {
  const {t} = useTranslation('authentication');
  const navigate = useNavigate();
  
  const registerClick = () => {
    navigate(Routes.register.path());
  };
  
  return (
    <div id={styles.loginPage}>
      <Button className={styles.registerButton}
              onClick={registerClick}
              variant={MuiButtonVariants.OUTLINED}
              size={MuiButtonSizes.LARGE}>
        {t('registerLink')}
      </Button>
      <div id={styles.boxContainer}>
        <img id={styles.logo} src={logo} alt="Panoramix" height={262} />
        <Typography className={`${styles.formTitle} font-color-light-regular`}
                    component="h1"
                    variant="h1">
          {t('form.login')}
        </Typography>
        <LoginContainer />
      </div>
    </div>
  );
}
