import React, {useEffect} from "react";
import {Alert} from "asset/component";
import {useFormValidation, ValidatorForm} from "asset/validator";
import {useTranslation} from "react-i18next";
import useNavigationFormSections from "asset/component/form/hook/useNavigationFormSections";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import {ErrorNames} from "core/error/ErrorNames";
import AddCompanyNameFormComponent from "module/company/view/component/addCompany/AddCompanyNameFormComponent";
import SubmitFormSection from "asset/component/form/SubmitFormSection";

export interface AddCompanyStateProps {
  name: string;
  setName: ((name: string) => void);
}

export interface AddCompanyFormComponentProps {
  addCompanyState: AddCompanyStateProps;
  addCompany: Function;
  isLoading: boolean;
  errorName?: ErrorNames;
}

const AddCompanyFormComponent = ({
  addCompany,
  isLoading,
  errorName,
  addCompanyState
}: AddCompanyFormComponentProps) => {
  const {t} = useTranslation('company');
  const {name, setName} = addCompanyState;
  const useNavigation = useNavigationFormSections();
  const {getErrorMessage} = useErrorTranslation();
  const nameFormSectionId = 'name-section';
  const submitFormSectionId = 'submit-section';
  
  const formRef = React.useRef(null);
  const {isFormValid, submitForm} = useFormValidation(formRef, name);
  
  const addCompanySubmit = (event) => {
    event.preventDefault();
    
    useNavigation.resetSections();
    
    if (!isFormValid) {
      submitForm();
      useNavigation.activateSection(nameFormSectionId);
    } else {
      addCompany();
    }
  };
  
  useEffect(() => {
    useNavigation.activateSection(nameFormSectionId);
  }, []);
  
  return <div>
    {errorName && <Alert severity="error"
                         data-testid="add-company-error-message">{errorName && getErrorMessage(errorName)}</Alert>}
    <ValidatorForm data-testid="add-company-form" onSubmit={addCompanySubmit}>
      <AddCompanyNameFormComponent nameFormSectionId={nameFormSectionId}
                                   nextFormSectionId={submitFormSectionId}
                                   useNavigation={useNavigation}
                                   name={name}
                                   setName={setName}
                                   formRef={formRef}
                                   submitForm={submitForm}
                                   isValid={isFormValid}
      />
      <SubmitFormSection submitFormSectionId={submitFormSectionId}
                         previousFormSectionId={nameFormSectionId}
                         useNavigation={useNavigation}
                         text={`${t('form.add.submitTitle', {name: name})}`}
                         isLoading={isLoading}
                         submitText={t('form.add.submit')}
      />
    </ValidatorForm>
  </div>;
};

export default AddCompanyFormComponent;
