export default class FollowUp {
  private readonly _id: string;
  private readonly _text: string;
  private readonly _createdAt: Date;
  private readonly _completed: boolean;
  
  constructor(id: string, text: string, createdAt: string, completed: boolean) {
    this._id = id;
    this._text = text;
    this._createdAt = new Date(createdAt);
    this._completed = completed;
  }
  
  get id(): string {
    return this._id;
  }
  
  get text(): string {
    return this._text;
  }
  
  get createdAt(): Date {
    return this._createdAt;
  }
  
  get completed(): boolean {
    return this._completed;
  }
}
