import * as React from "react";

const ProgressionCircleCompleted = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" transform="rotate(-90 20 20)" fill="#9AB59F" />
      <path d="M16.7951 23.8749L12.6251 19.7049L11.2051 21.1149L16.7951 26.7049L28.7951 14.7049L27.3851 13.2949L16.7951 23.8749Z"
            fill="currentColor" />
    </svg>
  );
};
export default ProgressionCircleCompleted;
