import * as React from "react";

const ProgressionCircle33Percent = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" transform="rotate(-90 20 20)" fill="currentColor" />
      <path d="M20 0.999999C20 0.447715 20.448 -0.0026094 20.9996 0.0249912C24.1278 0.181517 27.1805 1.07095 29.9092 2.62737C32.9279 4.3492 35.4458 6.82789 37.2148 9.81917C38.9839 12.8104 39.9429 16.2111 39.9975 19.6859C40.0469 22.8268 39.3556 25.9304 37.9858 28.7471C37.7443 29.2437 37.1338 29.4194 36.6498 29.1533L20.5182 20.2849C20.1986 20.1092 20 19.7734 20 19.4086L20 0.999999Z"
            fill="#FF8A00" />
    </svg>
  );
};
export default ProgressionCircle33Percent;
