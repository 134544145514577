import AxiosInterceptor from "core/client/AxiosInterceptor";
import TenantServices from "module/profile/dal/service/TenantServices";

export default class TenantRequestInterceptor implements AxiosInterceptor {
  public onFulfilled(): any {
    return async (config) => {
      const tenantId = TenantServices.getCurrentTenantId();
      
      if (tenantId) {
        // @ts-ignore
        config.headers["X-TenantID"] = tenantId;
      }
      
      return config;
    };
  }
  
  public onRejected(): any {
  }
  
  public options(): any {
  }
}
