import Role from "module/profile/domain/model/Role";
import {Roles} from "module/authorization/domain/model/roles";
import {Accesses} from "module/authorization/domain/model/accesses";

export default interface RoleResponse {
  roleName: string;
  entityId: string;
  accesses: string[];
}

export function roleResponseToModel(roleResponse: RoleResponse): Role {
  return new Role(
    roleResponse.entityId,
    roleResponse.roleName as Roles,
    roleResponse.accesses as Accesses[]
  );
}

export function rolesResponseToModel(rolesResponse: RoleResponse[]): Role[] {
  return rolesResponse.map(roleResponse => roleResponseToModel(roleResponse));
}
