import {Cookies} from 'react-cookie';

export default class TrialServices {
  private static readonly COOKIES = new Cookies();
  private static readonly TRYOUT_COOKIE_NAME = 'trial';
  
  public static showTrialComponent(): boolean {
    return TrialServices.COOKIES.get(TrialServices.TRYOUT_COOKIE_NAME) === undefined;
  }
  
  public static showedTrialComponent(): void {
    TrialServices.COOKIES.set(TrialServices.TRYOUT_COOKIE_NAME, true);
  }
}
