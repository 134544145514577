import AddTeam from "module/team/domain/model/AddTeam";

export default interface AddTeamRequest {
  name: string;
  companyName: string | undefined;
  companyId: string | undefined;
}

export function createAddTeamRequest(addTeam: AddTeam): AddTeamRequest {
  const addTeamRequest = {
    name: addTeam.name,
  } as AddTeamRequest;
  
  if (addTeam.companyId) {
    addTeamRequest.companyId = addTeam.companyId;
  } else if (addTeam.companyName) {
    addTeamRequest.companyName = addTeam.companyName;
  }
  
  if (!addTeam.companyName && !addTeam.companyId) {
    throw new TypeError('Company name or company id is required');
  }
  
  return addTeamRequest;
}
