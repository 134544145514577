import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import Skill from "module/skill/domain/model/Skill";
import {RootState} from "store";
import AppError from "core/error/AppError";
import ReducerServices from "store/service/ReducerServices";
import AddSkill from "module/skill/domain/model/AddSkill";

export const SkillStore = createModel<RootModel>()({
  state: {
    skills: [] as Skill[],
    getters: (state: RootState) => ({
      getSkillsByCoacheeId: (coacheeId: string) => {
        return state.SkillStore.skills.filter(skill => skill.coacheeId == coacheeId);
      }
    })
  },
  reducers: {
    setSkills(state, skills: Skill[]) {
      return {
        ...state,
        skills: ReducerServices.setItemsToList<Skill>(state.skills, skills)
      };
    },
    setSkill(state, skill: Skill) {
      return {
        ...state,
        skills: ReducerServices.setItemToList<Skill>(state.skills, skill)
      };
    },
    removeSkillById(
      state,
      skillId: string
    ) {
      return {
        ...state,
        skills: ReducerServices.removeItemFromList<Skill>(state.skills, skillId)
      };
    },
  },
  effects: (dispatch) => ({
    async fetchSkills(payload: { coacheeId: string }) {
      await clients.Skill.fetchSkills(payload.coacheeId).then((skills: Skill[]) => {
        dispatch.SkillStore.setSkills(skills);
      }).catch((error: AppError) => {
        throw error.name;
      });
    },
    async addSkill(payload: { addSkill: AddSkill, coacheeId: string }) {
      return await clients.Skill
        .addSkill(payload.addSkill, payload.coacheeId)
        .then((addedSkill) => {
          dispatch.SkillStore.setSkill(addedSkill);
          
          return addedSkill;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteSkill(payload: { skillId: string, coacheeId: string }) {
      return await clients.Skill
        .deleteSkill(payload.skillId, payload.coacheeId)
        .then(() => {
          dispatch.SkillStore.removeSkillById(payload.skillId);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
  })
});
