import React from "react";
import {Grid, Typography} from "asset/component";
import {TextValidator, ValidatorForm} from "asset/validator";
import {InputValidator} from "asset/validator/InputValidator";
import {useTranslation} from "react-i18next";
import FormSection from "asset/component/form/FormSection";
import useNavigationFormSections from "asset/component/form/hook/useNavigationFormSections";
import FormNavigationButtons from "asset/component/form/FormNavigationButtons";
import {onEnterKeyPress} from "asset/service/Form";

const AddCompanyNameFormComponent = ({
  nameFormSectionId,
  nextFormSectionId,
  useNavigation,
  name,
  setName,
  formRef,
  submitForm,
  isValid
}:
  {
    nameFormSectionId: string,
    nextFormSectionId: string,
    useNavigation: typeof useNavigationFormSections.arguments,
    name: string,
    setName: (name: string) => void,
    formRef?: any,
    submitForm: () => void,
    isValid?: boolean
  }) => {
  const {t} = useTranslation('company');
  
  const submit = () => {
    submitForm();
    useNavigation.activateSection(nextFormSectionId);
  };
  
  const onKeyPress = (event) => {
    onEnterKeyPress(event, () => {
      event.preventDefault();
      useNavigation.nextSectionOnEnterPress(event, nextFormSectionId);
    });
  };
  
  return (
    <ValidatorForm ref={formRef} onSubmit={() => useNavigation.activateSection(nextFormSectionId)}>
      <FormSection anchorId={nameFormSectionId}
                   isActivated={useNavigation.isSectionActivated(nameFormSectionId)}>
        <Typography component="h3" variant="h3">
          {t('form.add.nameTitle')}
        </Typography>
        <Typography component="p" variant="sm">
          {t('form.add.nameLabel')} *
        </Typography>
        <Grid container spacing={1} justifyContent="center">
          <Grid item>
            <TextValidator
              type="text"
              data-testid="name-input"
              id="name"
              placeholder={t('form.name')}
              name="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              validators={[InputValidator.REQUIRED]}
              errorMessages={[t('form.validation.nameRequired')]}
              autoFocus
              onKeyPress={onKeyPress}
            />
          </Grid>
        </Grid>
        <FormNavigationButtons nextAnchor={nextFormSectionId}
                               activateSection={() => submit()}
                               isDisabled={!isValid} />
      </FormSection>
    </ValidatorForm>
  );
};

export default AddCompanyNameFormComponent;
