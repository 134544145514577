import React from "react";
import {Grid, LoadingButton, Typography} from "asset/component/index";
import FormSection from "asset/component/form/FormSection";
import useNavigationFormSections from "asset/component/form/hook/useNavigationFormSections";
import {NavigationPreviousButton} from "asset/component/form/NavigationFormButtons";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";

const SubmitFormComponent = ({
  submitFormSectionId,
  previousFormSectionId,
  useNavigation,
  text,
  isLoading,
  submitText
}:
  {
    submitFormSectionId: string,
    previousFormSectionId: string,
    useNavigation: typeof useNavigationFormSections.arguments,
    text: string,
    isLoading: boolean,
    submitText?: string
  }) => {
  return <FormSection anchorId={submitFormSectionId}
                      isActivated={useNavigation.isSectionActivated(submitFormSectionId)}>
    <Typography component="h3" variant="h3">
      {text}
    </Typography>
    
    <Grid container spacing={1} justifyContent="center">
      <Grid item>
        <NavigationPreviousButton previousSectionId={previousFormSectionId} />
      </Grid>
      <Grid item>
        <LoadingButton
          data-testid="add-submit-button"
          loading={isLoading}
          disabled={isLoading}
          type="submit"
          variant={MuiButtonVariants.CONTAINED}
        >
          {submitText}
        </LoadingButton>
      </Grid>
    </Grid>
  </FormSection>;
};

export default SubmitFormComponent;
