import * as React from "react";
import styles from "asset/component/asset/subscription/SubscriptionCard.module.scss";
import {Typography} from "@mui/material";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import SubscriptionStatusTag from "asset/component/subscription/SubscriptionStatusTag";
import {useTranslation} from "react-i18next";
import {SubscriptionStatus} from "module/subscription/domain/model/SubscriptionStatus";
import useDateTranslation from "asset/component/hook/useDateTranslation";
import CheckoutAction from "asset/component/subscription/CheckoutAction";
import moment from "moment/moment";

const SubscriptionCardTrial = ({
  endOfTrialPeriod,
  checkoutMonthly,
  checkoutYearly
}: {
  endOfTrialPeriod?: Date
  checkoutMonthly?: () => void
  checkoutYearly?: () => void
}) => {
  const {t} = useTranslation("common");
  const {getRemainingTimeLeft} = useDateTranslation();
  
  return <div id={styles.subscriptionCard}>
    <div id={styles.header}>
      <div className={styles.subscriptionStatus}>
        <SubscriptionStatusTag status={SubscriptionStatus.TRIAL} />
        {
          endOfTrialPeriod && endOfTrialPeriod >= moment().toDate() &&
            <Typography variant={MuiTypographyVariants.XS} textTransform="initial">
              {t('subscription.timeRemaining')} {getRemainingTimeLeft(endOfTrialPeriod)}
            </Typography>
        }
      </div>
    </div>
    <div id={styles.footer} className={styles.singleButton}>
      <div className={styles.card}>
        <span className={styles.text}>
        <Typography variant={MuiTypographyVariants.XS} textTransform="initial">
          {t('subscription.monthly')}
        </Typography>
        <Typography variant={MuiTypographyVariants.XS} textTransform="initial">
          {t('subscription.monthlySecondary')}
        </Typography>
          </span>
        <CheckoutAction action={checkoutMonthly} />
      </div>
      <div className={styles.card}>
        <span className={styles.text}>
          <Typography variant={MuiTypographyVariants.XS} textTransform="initial">
            {t('subscription.yearly')}
          </Typography>
          <Typography variant={MuiTypographyVariants.XS} textTransform="initial">
            {t('subscription.yearlySecondary')}
          </Typography>
        </span>
        <CheckoutAction action={checkoutYearly} />
      </div>
     <span className={styles.paddle}>
      <Typography variant={MuiTypographyVariants.XXS} textTransform="initial">
        {t('subscription.paddleNote')} <a href="https://www.paddle.com/">Paddle</a>
      </Typography>
     </span>
    </div>
  </div>;
};

export default SubscriptionCardTrial;
