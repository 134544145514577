import React from "react";
import {Alert, Box, CircularProgress} from "asset/component/index";
import {ErrorNames} from "core/error/ErrorNames";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";

const ModuleListing = <TYPE extends any>({
  elements,
  isLoading,
  isAddElementLoading,
  errorName,
  children,
  injectedStyle
}: {
  elements: TYPE[],
  isAddElementLoading?: boolean,
  isLoading?: boolean,
  errorName?: ErrorNames,
  children?: (elements: TYPE, index?: number) => React.ReactElement;
  injectedStyle?: Record<string, string>
}) => {
  const {getErrorMessage} = useErrorTranslation();
  const elementsListing =
    elements.map((elements, index) => children && children(elements, index));
  
  const elementLoading = <CircularProgress data-testid="loading_element" />;
  
  const elementsLoading = <CircularProgress data-testid="loading_elements" />;
  
  const elementsError =
    <Alert severity="error" data-testid="error_elements">
      {errorName && getErrorMessage(errorName)}
    </Alert>;
  
  const displayLoading = () => {
    return isLoading && !elements.length;
  };
  
  const renderElementsListing = () => {
    if (errorName) {
      return elementsError;
    } else if (displayLoading()) {
      return elementsLoading;
    } else {
      return elementsListing;
    }
  };
  
  return (
    <Box className={injectedStyle?.listingContainer} data-testid="elements_listing_component">
      {renderElementsListing()}
      {isAddElementLoading && elementLoading}
    </Box>
  );
};

export default ModuleListing;
