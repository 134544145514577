import button from "style/button.scss";

export default {
  MuiButton: {
    styleOverrides: {
      root: {
        lineHeight: button.buttonLineHeight,
        borderRadius: button.buttonBorderRadius,
        boxShadow: button.buttonBoxShadow,
        "&:focus": {
          boxShadow: button.buttonBoxShadow,
        },
        "&:hover": {
          boxShadow: button.buttonHoverBoxShadow,
        },
        '&.MuiButton-text ': {
          textTransform: 'initial'
        },
        '&.lowerCase': {
          textTransform: 'initial'
        },
        '&.disabled-effect': {
          cursor: 'initial',
          pointerEvents: 'none',
          "&:focus": {
            backgroundColor: 'initial',
            color: 'initial',
            borderColor: 'initial',
          },
          "&:hover": {
            backgroundColor: 'initial',
            color: 'initial',
            borderColor: 'initial'
          },
        },
        "&.MuiButton-sizeLarge .MuiButton-startIcon": {
          height: '100%',
          width: '100%'
        },
        "&.opacity": {
          opacity: "0.65"
        }
      },
      sizeLarge: {
        fontSize: button.largeButtonTextSize,
        fontWeight: button.largeButtonTextFont,
        height: button.largeButtonHeight,
        padding: button.largeButtonPadding,
      },
      sizeMedium: {
        fontSize: button.mediumButtonTextSize,
        fontWeight: button.mediumButtonTextFont,
        height: button.mediumButtonHeight,
        padding: button.mediumButtonPadding,
      },
      sizeSmall: {
        fontSize: button.smallButtonTextSize,
        fontWeight: button.smallButtonTextFont,
        height: button.smallButtonHeight,
        padding: button.smallButtonPadding,
      },
      outlinedWhite: {
        color: button.outlinedWhiteButtonColor,
        borderColor: button.outlinedWhiteButtonColor,
      },
      containedPrimary: {
        color: button.containedPrimaryButtonTextColor,
        backgroundColor: button.containedPrimaryButtonBackgroundColor,
        "&:hover": {
          backgroundColor: button.containedPrimaryButtonHoverBackgroundColor,
        },
        "&:focus": {
          color: button.containedPrimaryButtonFocusColor,
        },
        "&:disabled": {
          color: button.containedPrimaryButtonDisabledColor,
          backgroundColor: button.containedPrimaryButtonDisabledBackgroundColor,
          opacity: button.containedPrimaryButtonDisabledOpacity
        }
      },
      outlinedPrimary: {
        border: button.outlinedPrimaryBorder,
        color: button.outlinedPrimaryButtonTextColor,
        "&:hover": {
          border: button.outlinedPrimaryHoverBorder,
          color: button.outlinedPrimaryButtonHoverTextColor,
          backgroundColor: button.outlinedPrimaryButtonHoverBackgroundColor,
        },
        "&:focus": {
          color: button.outlinedPrimaryButtonFocusTextColor,
        },
        "&:disabled": {
          color: button.outlinedPrimaryButtonDisabledTextColor,
          borderColor: button.outlinedPrimaryButtonDisabledBorderColor,
          opacity: button.outlinedPrimaryButtonDisabledOpacity
        },
        "&.darker": {
          border: button.outlinedPrimaryBorderDarker,
          color: button.outlinedPrimaryButtonTextColorDarker,
        }
      },
      containedPrimaryLight: {
        color: button.containedPrimaryLightButtonTextColor,
        "&:hover": {
          backgroundColor: button.containedPrimaryButtonHoverBackgroundColor,
        },
        "&:focus": {
          color: button.containedPrimaryButtonFocusColor,
        },
        "&:disabled": {
          color: button.containedPrimaryButtonDisabledColor,
          backgroundColor: button.containedPrimaryButtonDisabledBackgroundColor,
          opacity: button.containedPrimaryButtonDisabledOpacity
        }
      },
      outlinedPrimaryDark: {
        color: button.outlinedPrimaryDarkButtonTextColor,
        border: button.outlinedPrimaryDarkButtonBorder,
        "&:hover": {
          color: button.outlinedPrimaryDarkButtonHoverTextColor,
          backgroundColor: button.outlinedPrimaryDarkButtonHoverBackgroundColor,
          borderColor: button.outlinedPrimaryDarkButtonHoverBorderColor
        },
        "&:focus": {
          color: button.outlinedPrimaryDarkButtonFocusTextColor,
        },
        "&:disabled": {
          color: button.outlinedPrimaryDarkButtonDisabledTextColor,
          borderColor: button.outlinedPrimaryDarkButtonDisabledBorderColor,
          opacity: button.outlinedPrimaryDarkButtonDisabledOpacity
        }
      },
      endIcon: {
        marginLeft: button.iconButtonSpacing,
        height: button.iconButtonHeight,
        width: button.iconButtonWidth,
        
        "svg": {
          height: "100%"
        }
      },
      startIcon: {
        height: button.iconButtonHeight,
        width: button.iconButtonWidth,
        
        "svg": {
          height: "100%"
        }
      }
    }
  }
};
