import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import type {Dispatch, RootState} from "store";
import HomePageTeamsListingContainer from "module/home/view/container/HomePageTeamsListingContainer";
import Company from "module/company/domain/model/Company";
import CoacheesListingByCompanyWithoutTeamContainer
  from "module/coachee/view/container/CoacheesListingByCompanyWithoutTeamContainer";
import {useNavigate} from "react-router-dom";
import {Routes} from "router/Routes";
import {ModuleListing} from "asset/component";
import EditableTitleSeparator from "asset/component/module/EditableTitleSeparator";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import UpdateCompany from "module/company/domain/model/UpdateCompany";
import {useTranslation} from "react-i18next";

const HomePageCompaniesListingContainer = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const dispatch = useDispatch<Dispatch>();
  const {companies} = useSelector((rootState: RootState) => rootState.CompanyStore);
  
  let fetchCompaniesState = useLoadingAndError(() => dispatch.CompanyStore.fetchCompanies());
  
  useEffect(() => {
    if (fetchCompaniesState.isCompleted && companies.length === 0) {
      navigate(Routes.add.coachee.path());
    }
  }, [fetchCompaniesState.isCompleted, companies]);
  
  const [updateCompanyLoading, UpdateCompanyErrorName, updateCompanyDispatch] =
    usePromiseLoadingAndError((company, name: string) =>
      dispatch.CompanyStore.updateCompany(
        new UpdateCompany(company.id, name))
    );
  
  const [deleteCompanyLoading, deleteCompanyErrorName, deleteCompanyDispatch] =
    usePromiseLoadingAndError((company) =>
      dispatch.CompanyStore.deleteCompany({
        companyId: company.id,
      }).then(() => {
        navigate(Routes.home.path());
      })
    );
  
  return <ModuleListing<Company>
    data-testid="listing-companies"
    elements={companies}
    isLoading={fetchCompaniesState.isLoading}
    errorName={fetchCompaniesState.errorName}
  >
    {
      (company: Company, index) => <div key={company.id}>
        <EditableTitleSeparator title={company.name}
                                setTitle={(title) => updateCompanyDispatch(company, title)}
                                deleteAction={() => deleteCompanyDispatch(company)}
                                deleteConfirmationText={t("company:delete.deleteConfirmationText")}
                                deleteConfirmButtonText={t("company:delete.deleteConfirmButtonText")}
                                editIsLoading={updateCompanyLoading || deleteCompanyLoading}
                                errorName={UpdateCompanyErrorName || deleteCompanyErrorName}
        />
        <CoacheesListingByCompanyWithoutTeamContainer companyId={company.id} />
        <HomePageTeamsListingContainer expanded={index === 0}
                                       companyId={company.id}
                                       teamsId={company.teamIds} />
      </div>
    }
  </ModuleListing>;
};

export default HomePageCompaniesListingContainer;
