import React, {useEffect, useState} from 'react';
import InputSelect, {InputOption, InputSelectStyles} from "asset/component/form/inputs/InputSelect";
import {MuiInputColors} from "style/props/MuiInputColors";
import {getPredefinedInformalRoles} from "module/skill/view/PredefinedInformalRoles";
import {ValidatorForm} from 'asset/validator';
import {ErrorNames} from "core/error/ErrorNames";
import InformalRole from "module/skill/domain/model/InformalRole";
import i18n from 'translation/config';
import ModuleSectionLayout from 'asset/component/module/ModuleSectionLayout';
import {useTranslation} from "react-i18next";

const InformalRoleComponent = ({
    informalRole,
    updateInformalRole,
    errorName,
    isLoading,
    id
  }: {
    informalRole?: InformalRole,
    updateInformalRole: (title: string) => void,
    errorName?: ErrorNames
    isLoading?: boolean,
    id?: string
  }
) => {
  const [informalRoleOptions, setInformalRoleOptions] = useState<InputOption[]>([]);
  const {t} = useTranslation('skill');
  
  useEffect(() => {
    setInformalRoleOptions(getPredefinedInformalRoles());
  }, [i18n.language]);
  
  const control = <InputSelect
    
    selectedId={informalRole?.name}
    setExistingValue={(value) => value && updateInformalRole(value)}
    options={informalRoleOptions}
    color={MuiInputColors.PRIMARY}
    style={InputSelectStyles.ICONS}
    disableClearable
  />;
  
  return (
    <ModuleSectionLayout
      title={t('titles.informalRole')}
      isLoading={isLoading}
      errorName={errorName}
      theme="cream"
      id={id}
    >
      <ValidatorForm>
        {informalRole && control}
      </ValidatorForm>
    </ModuleSectionLayout>
  );
};

export default InformalRoleComponent;
