import * as React from "react";

const NavigationArrow = () => {
  return (
    <svg width="100%"
         height="100%"
         viewBox="0 0 98 31"
         fill="currentColor"
         xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M79.1455 30.207L77.8843 28.6548L94.7508 14.9508L77.9149 2.32389L79.1149 0.723892L98 14.8877L79.1455 30.207Z"
            fill="currentColor" />
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M0.374855 13.9199L96.375 13.9199L96.375 15.9199L0.374855 15.9199L0.374855 13.9199Z"
            fill="currentColor" />
    </svg>
  );
};

export default NavigationArrow;
