import React, {useEffect} from 'react';
import Mandate from "module/mandate/domain/model/Mandate";
import {ErrorNames} from "core/error/ErrorNames";
import {Alert, IconButton} from "asset/component";
import {Button, CircularProgress, Dialog, DialogTitle, Typography} from "@mui/material";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import styles from "module/mandate/view/asset/listing/MandatePreviewComponent.module.scss";
import {ArrowIcon, CloseIcon} from "asset/component/Icons";
import usePaletteColors from "style/usePaletteColors";
import Hourglass from "asset/icon/Hourglass";
import {useTranslation} from "react-i18next";
import useDateTranslation from "asset/component/hook/useDateTranslation";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import {Routes} from "router/Routes";
import {useNavigate} from "react-router-dom";

const MandatePreviewComponent = ({
  mandate,
  isDialogOpen = false,
  close,
  objectives,
  isLoading,
  errorNames
}: {
  mandate: Mandate,
  isDialogOpen?: boolean,
  close: () => void,
  objectives: JSX.Element,
  isLoading?: boolean,
  errorNames?: ErrorNames
}) => {
  const {getErrorMessage} = useErrorTranslation();
  const navigate = useNavigate();
  const palette = usePaletteColors();
  const {t, i18n} = useTranslation('mandate');
  const {formatDateWithMonthName} = useDateTranslation();
  
  const [deadline, setDeadline] = React.useState<string | null>(null);
  
  useEffect(() => {
    setDeadline(formatDateWithMonthName(mandate.deadline));
  }, [i18n.language, mandate.deadline]);
  
  return (
    <>
      {errorNames ?
        <Alert severity="error" data-testid="alert">{getErrorMessage(errorNames)}</Alert> : <></>}
      {isLoading ? <CircularProgress data-testid="circular-progress" /> :
        <Dialog open={isDialogOpen} className={styles.dialog} data-testid="dialog">
          <DialogTitle className={styles.title} data-testid="dialog-title">
            <div className={styles.deadline}>
              <Hourglass />
              <Typography variant="subtitle1" component="span" textTransform="capitalize">
                {deadline}
              </Typography>
            </div>
            <IconButton
              data-testid="dialog-icon"
              className={styles.closeButton}
              aria-label="close"
              onClick={close}
              iconColor={palette.secondary.veryDark}
              backgroundColor="transparent"
              buttonSize="40px"
              iconSize="22px"
              icon={<CloseIcon />}
            />
          </DialogTitle>
          <div className={styles.info}>
            <Typography variant="h3" component="h2" textTransform="uppercase" data-testid="title">
              {mandate.title}
            </Typography>
            <Typography variant="body1" component="p" data-testid="description">
              {mandate.description}
            </Typography>
          </div>
          {objectives}
          <Button onClick={() => navigate(Routes.mandate.edit.path({mandateId: mandate.id}))}
                  variant={MuiButtonVariants.CONTAINED}
                  endIcon={<ArrowIcon />}
                  data-testid="button"
                  className={styles.button}>
            {t('seeDetails')}
          </Button>
        </Dialog>}
    </>
  );
};

export default MandatePreviewComponent;
