import Objective from "module/objective/domain/model/Objective";
import SubObjective from "module/objective/domain/model/SubObjective";
import {StateStatus} from "module/objective/domain/model/StateStatus";

export default class ObjectiveMandate extends Objective {
  private readonly _mandateId: string;
  
  constructor(
    id: string,
    mandateId: string,
    name: string,
    text: string,
    createdAt: string,
    state: StateStatus,
    subObjectives: SubObjective[]
  ) {
    super(id, name, text, createdAt, state, subObjectives);
    this._mandateId = mandateId;
  }
  
  get mandateId(): string {
    return this._mandateId;
  }
}
