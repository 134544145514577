import styles from "module/objective/view/asset/ObjectiveStateHistoryModalErrorMessage.module.scss"
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import {ErrorNames} from "core/error/ErrorNames";
import ErrorIcon from '@mui/icons-material/Error';

const ObjectiveStateHistoryModalErrorMessage = (
  { error
  }: {
    error?: ErrorNames
  }) => {
  const {getErrorMessage} = useErrorTranslation();
  
  
  return (
    <div className={styles.container}>
      <div className={styles.errorContainer}>
        <ErrorIcon className={styles.errorIcon}/>
        {error && getErrorMessage(error)}
      </div>
    
    </div>
  )
}

export default ObjectiveStateHistoryModalErrorMessage;
