export default class UpdateObjectiveStateComment {
  private readonly _comment: string;
  
  constructor(comment: string) {
    this._comment = comment;
  }
  
  get comment(): string {
    return this._comment;
  }
}
