import moment from "moment/moment";
import i18n from "translation/config";

const useDateTranslation = () => {
  const formatDateWithMonthName = (date: Date) => {
    if (i18n.language === 'fr') return moment(date).utc().locale(i18n.language).format('Do MMMM YYYY');
    else return moment(date).utc().locale(i18n.language).format('MMMM Do YYYY');
  };
  
  const getRemainingTimeLeft = (date: Date) => {
    return moment(date).locale(i18n.language).fromNow(true);
  };
  
  const getMonthAndYearFromDate = (date: Date) => {
    return moment(date).utc().locale(i18n.language).format('MMMM YYYY');
  }
  
  return {formatDateWithMonthName, getRemainingTimeLeft, getMonthAndYearFromDate};
};

export default useDateTranslation;
