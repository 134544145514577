import OnboardingSequence from "module/onboarding/domain/model/OnboardingSequence";
import OnboardingStep from "module/onboarding/domain/model/OnboardingStep";
import {ArrowPositions} from "asset/component/global/dialogs/Tooltip";
import {OnboardingSequenceNames} from "module/onboarding/domain/model/SeedData/OnboardingSequenceNames";

const TeamSequence = new OnboardingSequence(
  [
    new OnboardingStep({
      name: "addObjective",
      sequenceName: OnboardingSequenceNames.TEAM,
      anchorID: "team-add-objective-anchor",
      arrowPosition: ArrowPositions.TOP.MIDDLE,
      adjustments: {x: -168, y: 10}
    }),
    new OnboardingStep({
      name: "infoNeeds",
      sequenceName: OnboardingSequenceNames.TEAM,
      anchorID: "team-info-needs-anchor",
      arrowPosition: ArrowPositions.LEFT.MIDDLE,
      adjustments: {x: 30, y: -87.5}
    }),
  ]
);

export default TeamSequence;
