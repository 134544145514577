import React from "react";
import TeamNeed from "module/need/view/team/component/TeamNeedTag";
import styles from "module/need/view/asset/team/TeamNeedComponent.module.scss";
import {NeedsTeam} from "module/objective/domain/model/need/NeedsTeam";

const TeamNeedsComponent = ({
  onNeedClick,
  teamId
}: {
  onNeedClick: (needsTeam: NeedsTeam) => void,
  teamId: string
}) => {
  return (
    <div className={styles.teamNeedsComponent}>
      <TeamNeed onClick={onNeedClick}
                needsTeam={NeedsTeam.RESULT}
                teamId={teamId} />
      <TeamNeed onClick={onNeedClick}
                needsTeam={NeedsTeam.RESPONSIBILITY}
                teamId={teamId} />
      <TeamNeed onClick={onNeedClick}
                needsTeam={NeedsTeam.ENGAGEMENT}
                teamId={teamId} />
      <TeamNeed onClick={onNeedClick}
                needsTeam={NeedsTeam.CONFRONTATION}
                teamId={teamId} />
      <TeamNeed onClick={onNeedClick}
                needsTeam={NeedsTeam.TRUST}
                teamId={teamId} />
    </div>
  );
};

export default TeamNeedsComponent;
