import React, {ReactElement} from 'react';
import LayoutWrapper from "module/common/layout/component/LayoutWrapper";
import PageContentWrapper from "module/common/layout/component/PageContentWrapper";
import {CircularProgress} from "asset/component";

function LoadingLayout(): ReactElement {
  return (
    <LayoutWrapper>
      <PageContentWrapper data-test-id="page-content-wrapper">
        <CircularProgress />
      </PageContentWrapper>
    </LayoutWrapper>
  );
}

export default LoadingLayout;
