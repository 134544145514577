import React from 'react';
import {CheckIcon} from "asset/component/Icons";
import styles from "asset/component/asset/global/Done.module.scss";
import {useTranslation} from "react-i18next";

const DoneButton = ({
  icon = <CheckIcon />,
  text,
  onClick
}: {
  icon?: JSX.Element,
  text?: string,
  onClick?: () => void
}) => {
  const {t} = useTranslation("common");
  
  return (
    <div className={`${styles.done} ${onClick !== undefined && styles.clickable}`}
         onClick={onClick}
         data-testid="done-button">
      {icon}
      {text || t('finished')}
    </div>
  );
};

export default DoneButton;
