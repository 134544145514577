import React from "react";
import {Grid, Typography} from "asset/component";
import {TextValidator} from "asset/validator";
import {InputValidator} from "asset/validator/InputValidator";
import {useTranslation} from "react-i18next";
import usePaletteColors from "style/usePaletteColors";
import {MuiInputColors} from "style/props/MuiInputColors";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";

const UpdateCoacheeNamesFormComponent = ({
  firstName,
  setFirstName,
  lastName,
  setLastName
}:
  {
    firstName: string,
    setFirstName: (firstName: string) => void,
    lastName: string,
    setLastName: (lastName: string) => void
  }) => {
  const {t} = useTranslation('coachee');
  const palette = usePaletteColors();
  
  return <div>
    <Typography component="p" variant={MuiTypographyVariants.XS} color={palette.secondary.dark}>
      {t('form.update.firstNameLastNameLabel')}
    </Typography>
    <Grid container spacing={3} justifyContent="space-between">
      <Grid item md={6}>
        <TextValidator
          required
          fullWidth
          type="text"
          data-testid="first-name-input"
          id="firstName"
          placeholder={t('form.firstName')}
          name="firstName"
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
          validators={[InputValidator.REQUIRED]}
          errorMessages={[t('form.validation.firstNameRequired')]}
          color={MuiInputColors.SECONDARY}
        />
      </Grid>
      <Grid item md={6}>
        <TextValidator
          required
          fullWidth
          type="text"
          data-testid="last-name-input"
          id="lastName"
          placeholder={t('form.lastName')}
          name="lastName"
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
          validators={[InputValidator.REQUIRED]}
          color={MuiInputColors.SECONDARY}
          errorMessages={[t('form.validation.lastNameRequired')]}
        />
      </Grid>
    </Grid>
  </div>;
};

export default UpdateCoacheeNamesFormComponent;
