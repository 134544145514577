export default class AddCoachee {
  private readonly _firstName: string;
  private readonly _lastName: string;
  private readonly _teamName: string;
  private readonly _teamId: string;
  private readonly _companyName: string;
  private readonly _companyId: string;
  
  constructor(
    firstName: string,
    lastName: string,
    teamName: string,
    teamId: string,
    companyName: string,
    companyId: string
  ) {
    this._firstName = firstName;
    this._lastName = lastName;
    this._teamName = teamName;
    this._teamId = teamId;
    this._companyName = companyName;
    this._companyId = companyId;
  }
  
  get firstName(): string {
    return this._firstName;
  }
  
  get lastName(): string {
    return this._lastName;
  }
  
  get teamName(): string {
    return this._teamName;
  }
  
  get teamId(): string {
    return this._teamId;
  }
  
  get companyName(): string {
    return this._companyName;
  }
  
  get companyId(): string {
    return this._companyId;
  }
}
