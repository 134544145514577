import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import {useParams} from "react-router";
import CoacheeProfileHeaderComponent from "module/coachee/view/component/coacheeProfile/CoacheeProfileHeaderComponent";
import CoacheeProfileBodyComponent from "module/coachee/view/component/coacheeProfile/CoacheeProfileBodyComponent";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import CoacheeProfileBreadcrumbsContainer from "module/coachee/view/container/CoacheeProfileBreadcrumbsContainer";
import {Accesses} from "module/authorization/domain/model/accesses";
import HadAccess from "module/authorization/HadAccess";

const CoacheeProfileContainer = () => {
  const {coacheeId, teamId} = useParams() as {
    coacheeId: string,
    teamId: string
  };
  const dispatch = useDispatch<Dispatch>();
  
  const coachee = useSelector((rootState: RootState) =>
    rootState.CoacheeStore?.getters(rootState).getCoacheeById(coacheeId));
  
  const coachees = useSelector((rootState: RootState) =>
    rootState.CoacheeStore?.getters(rootState).getCoacheesByTeamId(teamId));
  
  const company = useSelector((rootState: RootState) =>
    coachee && rootState.CompanyStore?.getters(rootState).getCompanyById(coachee.companyId));
  
  const teams = useSelector((rootState: RootState) =>
    coachee && rootState.TeamStore?.getters(rootState).getTeamsByIds(coachee.teamIds));
  
  let fetchCoacheeState = useLoadingAndError(
    () => dispatch.CoacheeStore.fetchCoacheeById(coacheeId),
    [coacheeId !== undefined, coachee?.id !== coacheeId],
    coacheeId
  );
  
  let fetchCoacheesByTeamState = useLoadingAndError(
    () => dispatch.CoacheeStore.fetchCoacheesByTeamId(teamId),
    [teamId !== undefined],
    teamId
  );
  
  let fetchCompanyByIdState = useLoadingAndError(
    () => dispatch.CompanyStore.fetchCompanyById(coachee?.companyId || ''),
    [coachee !== undefined],
    coachee
  );
  
  let fetchTeamsByCoacheeIdState = useLoadingAndError(
    () => dispatch.TeamStore.fetchTeamsByCoacheeId(coachee?.id || ''),
    [coachee !== undefined],
    coachee
  );
  
  return <>
    <HadAccess accesses={[Accesses.COACHEES_MANAGEMENT]}>
      <CoacheeProfileBreadcrumbsContainer coacheeId={coacheeId} companyId={company?.id} teamId={teamId} />
      <CoacheeProfileHeaderComponent data-testid="coachee_profile_header"
                                     coachee={coachee}
                                     coachees={coachees}
                                     teamId={teamId}
                                     isLoading={fetchCoacheeState.isLoading || fetchCoacheesByTeamState.isLoading || fetchCompanyByIdState.isLoading || fetchTeamsByCoacheeIdState.isLoading}
                                     errorName={fetchCoacheeState.errorName || fetchCoacheesByTeamState.errorName || fetchCompanyByIdState.errorName || fetchTeamsByCoacheeIdState.errorName}
                                     company={company}
                                     teams={teams}
      />
      <CoacheeProfileBodyComponent data-testid="coachee_profile_body"
                                   coachee={coachee} />
    </HadAccess>
  </>;
};

export default CoacheeProfileContainer;
