import OnboardingStep from "module/onboarding/domain/model/OnboardingStep";

export default class OnboardingSequence {
  private readonly _steps: OnboardingStep[];
  
  constructor(steps: OnboardingStep[]) {
    this._steps = steps;
  }
  
  get steps(): OnboardingStep[] {
    return this._steps;
  }
  
  get isDone(): boolean {
    return this._steps.every(step => step.isDone);
  }
}
