import {LinkableEntityType} from "module/mandate/domain/model/LinkableEntity";

export interface IUpdateLinkableEntity {
  objectId: string;
  type: LinkableEntityType;
}

export default class UpdateLinkableEntity implements IUpdateLinkableEntity {
  private readonly _objectId: string;
  private readonly _type: LinkableEntityType;
  
  constructor(objectId: string, type: LinkableEntityType) {
    this._objectId = objectId;
    this._type = type;
  }
  
  get objectId(): string {
    return this._objectId;
  }
  
  get type(): LinkableEntityType {
    return this._type;
  }
}
