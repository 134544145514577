import {Cookies} from 'react-cookie';

export default class TenantServices {
  public static readonly COOKIES = new Cookies();
  private static readonly CURRENT_TENANT_COOKIE_NAME = 'CurrentTenantId';
  
  public static getCurrentTenantId(): boolean {
    return TenantServices.COOKIES.get(TenantServices.CURRENT_TENANT_COOKIE_NAME);
  }
  
  public static setCurrentTenantId(tenantId: string): void {
    TenantServices.COOKIES.set(TenantServices.CURRENT_TENANT_COOKIE_NAME, tenantId);
  }
  
  public static removeCurrentTenant(): void {
    TenantServices.COOKIES.remove(TenantServices.CURRENT_TENANT_COOKIE_NAME);
  }
}
