import React from 'react';
import {ModuleHeader} from "asset/component";
import ModuleCard from "asset/component/module/ModuleCard";
import {useTranslation} from "react-i18next";
import SkillsContainer from "module/skill/view/container/SkillsContainer";
import InformalRoleContainer from 'module/skill/view/container/InformalRoleContainer';
import {CoacheeSequence} from "module/onboarding/domain/model/SeedData/OnboardingSequencesSeed";

const SkillsModuleContainer = ({coacheeId}: { coacheeId: string }) => {
  const {t} = useTranslation('skill');
  
  return (
    <ModuleCard data-testid="skill-component">
      <ModuleHeader title={t('titles.main')}
                    subtitle={t('description')}
                    data-testid="skill-module-container-header" />
      <InformalRoleContainer coacheeId={coacheeId} id={CoacheeSequence.steps[1].anchorID} />
      <SkillsContainer coacheeId={coacheeId} />
    </ModuleCard>
  );
};

export default SkillsModuleContainer;
