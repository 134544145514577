import React from "react";
import {Typography} from "asset/component";
import Coachee from "module/coachee/domain/model/Coachee";
import {Routes} from "router/Routes";
import {useNavigate} from 'react-router-dom';
import styles from "module/coachee/view/asset/CoacheeThumbnailComponent.module.scss";
import coacheeProfileImage from "asset/image/coachee-profile.svg";
import usePaletteColors from "style/usePaletteColors";

const CoacheeThumbnailComponent = ({coachee, teamId, id, dataParamId}: {
  coachee: Coachee,
  teamId?: string,
  id?: string,
  dataParamId?: any
}) => {
  const navigate = useNavigate();
  const palette = usePaletteColors();
  
  const redirectToCoacheeProfile = () => {
    navigate(teamId ? Routes.coachee.profileTeam.path({coacheeId: coachee.id, teamId: teamId}) : Routes.coachee.profile.path({coacheeId: coachee.id}));
  };
  
  return (
    <div onClick={redirectToCoacheeProfile}
         data-param-id={dataParamId}
         id={id}
         className={styles.thumbnailContainer}>
      <img src={coacheeProfileImage} alt="Panoramix" height="60" />
      <Typography className={styles.text} component="span" variant="xs" color={palette.secondary.veryDark}>
        {coachee.firstName} {coachee.lastName}
      </Typography>
    </div>
  );
};

export default CoacheeThumbnailComponent;
