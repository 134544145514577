import {Cookies} from 'react-cookie';

export default class FeedbackServices {
  public static readonly COOKIES = new Cookies();
  private static readonly FEEDBACK_COOKIE_NAME = 'feedback';
  
  public static showFeedbackMessage(): boolean {
    return FeedbackServices.COOKIES.get(FeedbackServices.FEEDBACK_COOKIE_NAME) === undefined;
  }
  
  public static showedFeedbackMessage(): void {
    FeedbackServices.COOKIES.set(FeedbackServices.FEEDBACK_COOKIE_NAME, true);
  }
}
