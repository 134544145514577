import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import type {Dispatch, RootState} from "store";
import CoacheesListingComponent from "module/coachee/view/component/CoacheesListingComponent";
import {ErrorNames} from "core/error/ErrorNames";

const CoacheesListingByCompanyWithoutTeamContainer = ({companyId}: {
  companyId: string
}) => {
  const dispatch = useDispatch<Dispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorName, setErrorName] = useState<ErrorNames | undefined>(undefined);
  const coachees = useSelector((rootState: RootState) => rootState.CoacheeStore?.getters(rootState).getCoacheesByCompanyIdWithoutTeam(
    companyId));
  
  useEffect(() => {
    setIsLoading(true);
    setErrorName(undefined);
    
    dispatch.CoacheeStore.fetchCoacheesByCompanyIdWithoutTeam(companyId).then(() => {
      setIsLoading(false);
    }).catch((errorName) => {
      setErrorName(errorName);
    });
  }, []);
  
  return (
    <CoacheesListingComponent data-testid="coachees_listing"
                              coachees={coachees}
                              isLoading={isLoading}
                              errorName={errorName}
                              slidesNumber={4}
                              hasAnchor={true}
    />);
};

export default CoacheesListingByCompanyWithoutTeamContainer;
