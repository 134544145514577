import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import {RootState} from "store";
import AppError from "core/error/AppError";
import ReducerServices from "store/service/ReducerServices";
import CoacheeDemotivator from "module/demotivator/coachee/domain/model/CoacheeDemotivator";
import AddCoacheeDemotivator from "module/demotivator/coachee/domain/model/AddCoacheeDemotivator";

export const CoacheeDemotivatorStore = createModel<RootModel>()({
  state: {
    demotivators: [] as CoacheeDemotivator[],
    getters: (state: RootState) => ({
      getDemotivatorsByCoacheeId: (id: string) => {
        return state.CoacheeDemotivatorStore.demotivators.filter(
          demotivator => demotivator.coacheeId == id);
      }
    })
  },
  reducers: {
    setDemotivator(
      state,
      demotivator: CoacheeDemotivator
    ) {
      return {
        ...state,
        demotivators: ReducerServices.setItemToList<CoacheeDemotivator>(
          state.demotivators,
          demotivator
        )
      };
    },
    setDemotivators(
      state,
      demotivators: CoacheeDemotivator[]
    ) {
      return {
        ...state,
        demotivators: ReducerServices.setItemsToList<CoacheeDemotivator>(
          state.demotivators,
          demotivators
        )
      };
    },
    removeDemotivatorById(
      state,
      demotivatorId: string
    ) {
      return {
        ...state,
        demotivators: ReducerServices.removeItemFromList<CoacheeDemotivator>(
          state.demotivators,
          demotivatorId
        )
      };
    },
  },
  effects: (dispatch) => ({
    async addDemotivator(payload: { addDemotivator: AddCoacheeDemotivator }) {
      return await clients.CoacheeDemotivator
        .addDemotivatorForCoachee(payload.addDemotivator, payload.addDemotivator.coacheeId)
        .then((addedDemotivator: CoacheeDemotivator) => {
          dispatch.CoacheeDemotivatorStore.setDemotivator(addedDemotivator);
          
          return addedDemotivator;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteDemotivator(payload: { demotivatorId: string, coacheeId: string }) {
      return await clients.CoacheeDemotivator
        .deleteDemotivatorForCoachee(payload.demotivatorId, payload.coacheeId)
        .then(() => {
          dispatch.CoacheeDemotivatorStore.removeDemotivatorById(payload.demotivatorId);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async fetchDemotivatorsByCoacheeId(id: string) {
      await clients.CoacheeDemotivator.fetchDemotivatorsByCoacheeId(id).then(
        (demotivators: CoacheeDemotivator[]) => {
          dispatch.CoacheeDemotivatorStore.setDemotivators(demotivators);
        }).catch((error: AppError) => {
        throw error.name;
      });
    }
  })
});
