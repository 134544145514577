export default class AddTeam {
  private readonly _name: string;
  private readonly _companyName?: string;
  private readonly _companyId?: string;
  
  constructor(
    name: string,
    companyName?: string,
    companyId?: string
  ) {
    this._name = name;
    this._companyName = companyName;
    this._companyId = companyId;
  }
  
  get name(): string {
    return this._name;
  }
  
  get companyName(): string | undefined {
    return this._companyName;
  }
  
  get companyId(): string | undefined {
    return this._companyId;
  }
}
