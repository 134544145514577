import * as React from 'react';
import {Button, Dialog, DialogActions, DialogContent, IconButton, Typography} from 'asset/component/index';
import {useTranslation} from "react-i18next";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import {MuiButtonColors} from "style/props/MuiButtonColors";
import {IconButtonProps} from "asset/component/global/buttons/IconButton";
import {DeleteOutlineIcon} from "asset/component/Icons";
import usePaletteColors from "style/usePaletteColors";

export default function DeleteConfirmation(
  {
    deleteAction,
    confirmationText,
    icon,
    confirmButtonText,
    iconButton
  }: {
    deleteAction: () => void,
    confirmationText: string,
    icon?: JSX.Element
    confirmButtonText?: string,
    iconButton?: IconButtonProps
  }) {
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(false);
  const palette = usePaletteColors();
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  
  const declined = () => {
    handleClose();
  };
  
  const confirmed = () => {
    deleteAction();
    handleClose();
  };
  
  return (
    <div>
      {icon ?
        <div onClick={handleClickOpen}>
          {icon}
        </div>
        :
        <IconButton data-testid="dialog-icon"
                    onClick={handleClickOpen}
                    iconColor={palette.special.danger}
                    hoverBackgroundColor={palette.primary.main}
                    backgroundColor="transparent"
                    buttonSize="40px"
                    iconSize="20px"
                    icon={<DeleteOutlineIcon />}
                    {...iconButton}
        />}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography className="font-color-dark" component="p" variant="sm">
            {confirmationText}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button data-testid="dialog-cancel"
                  variant={MuiButtonVariants.OUTLINED}
                  color={MuiButtonColors.PRIMARY_DARK}
                  onClick={declined}>
            {t('common:cancel')}
          </Button>
          <Button data-testid="dialog-delete"
                  variant={MuiButtonVariants.CONTAINED}
                  onClick={confirmed}>
            {confirmButtonText || t('common:delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
