export default {
  MuiDialog: {
    styleOverrides: {
      root: {
        borderRadius: "10px",
        '.MuiPaper-root': {
          borderRadius: "10px",
          padding: "25px"
        },
        '@media (max-width: 600px)': {
          '.MuiPaper-root': {
            margin: "10px",
            padding: "10px"
          },
        }
      },
    }
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: "0",
        color: "#20463D",
        marginBottom: "35px"
      },
    }
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: "0",
        justifyContent: "center"
      },
    }
  }
};
