import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import {RootState} from "store";
import AppError from "core/error/AppError";
import ReducerServices from "store/service/ReducerServices";
import TeamDemotivator from "./domain/model/TeamDemotivator";
import AddTeamDemotivator from "module/demotivator/team/domain/model/AddTeamDemotivator";

export const TeamDemotivatorStore = createModel<RootModel>()({
  state: {
    demotivators: [] as TeamDemotivator[],
    getters: (state: RootState) => ({
      getDemotivatorsByTeamId: (id: string) => {
        return state.TeamDemotivatorStore.demotivators.filter(
          demotivator => demotivator.teamId == id);
      }
    })
  },
  reducers: {
    setDemotivator(
      state,
      demotivator: TeamDemotivator
    ) {
      return {
        ...state,
        demotivators: ReducerServices.setItemToList<TeamDemotivator>(
          state.demotivators,
          demotivator
        )
      };
    },
    setDemotivators(
      state,
      demotivators: TeamDemotivator[]
    ) {
      return {
        ...state,
        demotivators: ReducerServices.setItemsToList<TeamDemotivator>(
          state.demotivators,
          demotivators
        )
      };
    },
    removeDemotivatorById(
      state,
      demotivatorId: string
    ) {
      return {
        ...state,
        demotivators: ReducerServices.removeItemFromList<TeamDemotivator>(
          state.demotivators,
          demotivatorId
        )
      };
    },
  },
  effects: (dispatch) => ({
    async addDemotivator(payload: { addDemotivator: AddTeamDemotivator }) {
      return await clients.TeamDemotivator
        .addDemotivatorForTeam(payload.addDemotivator, payload.addDemotivator.teamId)
        .then((addedDemotivator: TeamDemotivator) => {
          dispatch.TeamDemotivatorStore.setDemotivator(addedDemotivator);
          
          return addedDemotivator;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteDemotivator(payload: { demotivatorId: string, teamId: string }) {
      return await clients.TeamDemotivator
        .deleteDemotivatorForTeam(payload.demotivatorId, payload.teamId)
        .then(() => {
          dispatch.TeamDemotivatorStore.removeDemotivatorById(payload.demotivatorId);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async fetchDemotivatorsByTeamId(id: string) {
      await clients.TeamDemotivator.fetchDemotivatorsByTeamId(id).then(
        (demotivators: TeamDemotivator[]) => {
          dispatch.TeamDemotivatorStore.setDemotivators(demotivators);
        }).catch((error: AppError) => {
        throw error.name;
      });
    }
  })
});
