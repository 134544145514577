import crypto, {Cipher} from "crypto-browserify";
import {Buffer} from 'buffer';
import moment from "moment-timezone";
import {API_IV} from "core/client/ClientConfig";

window.Buffer = Buffer;

export default class ApiKeyService {
  protected readonly ALGORITHM: string = "aes-256-cbc";
  protected readonly KEY: string = process.env.REACT_APP_API_KEY as string;
  protected readonly IV: string = API_IV;
  protected readonly ENCODING: BufferEncoding = "hex";
  protected readonly MESSAGE_ENCODING: BufferEncoding = "utf-8";
  protected readonly CIPHER: Cipher;
  
  constructor() {
    const keyBuffer = Buffer.from(this.KEY, this.ENCODING);
    const ivBuffer = Buffer.from(this.IV, this.ENCODING);
    
    this.CIPHER = crypto.createCipheriv(this.ALGORITHM, keyBuffer, ivBuffer);
  }
  
  private getMessage(): string {
    return moment().tz("UTC").format("DD-MM-YYYY HH:mm:ss");
  }
  
  private getEncryptedMessage(): string {
    let encrypted = this.CIPHER.update(this.getMessage(), this.MESSAGE_ENCODING, this.ENCODING);
    encrypted += this.CIPHER.final(this.ENCODING);
    
    return encrypted;
  }
  
  public getApiKey(): string {
    return this.getEncryptedMessage();
  }
}
