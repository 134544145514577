import {Navigate} from 'react-router-dom';
import React from 'react';
import {Routes} from 'router/Routes';
import {useAuthenticationState} from "module/authentication/hook/useAuthenticationState";

type AuthenticationRouteProps = {
  element: JSX.Element;
  path?: string;
};

export const AuthenticationRoute = ({element, path = Routes.home.path()}: AuthenticationRouteProps) => {
  const [user] = useAuthenticationState();
  
  if (user) {
    return <Navigate to={path} />;
  }
  
  return element;
};

export default AuthenticationRoute;
