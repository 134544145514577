import React, {ReactElement} from 'react';
import styles from "module/common/layout/component/assets/Footer.module.scss";
import {Button, Container, Grid, Typography} from "asset/component";
import {useTranslation} from "react-i18next";
import LogoPanoramix from "asset/image/LogoPanoramix";
import Link from "@mui/material/Link";
import {FEEDBACK_URL_EN, FEEDBACK_URL_FR, HELP_URL_EN, HELP_URL_FR} from "config";
import i18n from "i18next";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import MuiButtonSizes from "style/props/MuiButtonSizes";
import {MuiButtonColors} from "style/props/MuiButtonColors";

function Footer(): ReactElement {
  const currentYear = new Date().getFullYear();
  const {t} = useTranslation();
  const isFrenchLanguage = i18n.language === "fr";
  
  const getHelpUrl = () => {
    return isFrenchLanguage ? HELP_URL_FR : HELP_URL_EN;
  };
  
  const getFeedbackUrl = () => {
    return isFrenchLanguage ? FEEDBACK_URL_FR : FEEDBACK_URL_EN;
  };
  
  const feedBackButton = (className, color) => (
    <Button component={Link}
            target="_blank"
            className={`${styles.registerButton} ${className}`}
            href={getFeedbackUrl()}
            variant={MuiButtonVariants.OUTLINED}
            color={color}
            size={MuiButtonSizes.LARGE}>
      {t('feedback')}
    </Button>
  );
  
  return (
    <Container>
      <Grid container id={styles.footerContainer}>
        <Grid container className={styles.linkContainer} data-testid="link-container">
          <Grid md={4} xs={12} item className={styles.leftLinksContainer} data-testid="feedback-container">
            {feedBackButton(styles.darkTheme, MuiButtonColors.WHITE)}
            {feedBackButton(styles.flashTheme, MuiButtonColors.SECONDARY)}
          </Grid>
          <Grid md={4} xs={12} item>
            <div id={styles.footerLogoContainer} data-testid="pano-logo">
              <LogoPanoramix />
            </div>
          </Grid>
          <Grid md={4} xs={12} item className={styles.rightLinksContainer}>
            <Link className={styles.link} href={getHelpUrl()} target="_blank" data-testid="help-link">
              {t('help')}
            </Link>
          </Grid>
        </Grid>
        
        <Grid xs={12} item>
          <Typography id={styles.footerText} component="p" variant="xxs" data-testid="footer-text">
            @PANORAMIX{currentYear}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Footer;
