import optimal from "asset/image/status/optimal.svg";
import fine from "asset/image/status/fine.svg";
import idle from "asset/image/status/idle.svg";
import monitoring from "asset/image/status/monitoring.svg";
import {StateStatus} from "module/objective/domain/model/StateStatus";
import styles from "module/objective/view/asset/StatusCircle.module.scss"
import {MuiSizes} from "style/props/MuiSizes";

const StatusIcon = ({
  status,
  size = MuiSizes.MEDIUM
} : {
  status: StateStatus
  size?: MuiSizes
}) => {
  
  const OPTIMAL_ICON = <img src={optimal} alt={""} className={styles.statusCircleIcon}/>;
  const FINE_ICON = <img src={fine} alt={""} className={styles.statusCircleIcon}/>;
  const IDLE_ICON = <img src={idle} alt={""} className={styles.statusCircleIcon}/>;
  const MONITORING_ICON = <img src={monitoring} alt={""} className={styles.statusCircleIcon}/>;
  
  const getStatusIcon = () => {
    switch (status) {
      case StateStatus.OPTIMAL:
        return OPTIMAL_ICON;
      case StateStatus.FINE:
        return FINE_ICON;
      case StateStatus.IDLE:
        return IDLE_ICON;
      case StateStatus.MONITORING:
        return MONITORING_ICON;
      case StateStatus.NONE:
        return <></>;
    }
  };
  
  return (
    <div className={`${styles.statusCircleContainer}  ${size}`}>
      {getStatusIcon()}
    </div>
  );
}

export default StatusIcon;
