import React from "react";
import {Grid, Typography} from "asset/component";
import {ValidatorForm} from "asset/validator";
import {useTranslation} from "react-i18next";
import FormSection from "asset/component/form/FormSection";
import useNavigationFormSections from "asset/component/form/hook/useNavigationFormSections";
import InputSelect, {InputOption} from "asset/component/form/inputs/InputSelect";
import FormNavigationButtons from "asset/component/form/FormNavigationButtons";

const AddTeamOrganisationFormComponent = ({
  organisationFormSectionId,
  previousFormSectionId,
  nextFormSectionId,
  useNavigation,
  companyOptions,
  setCompanyName,
  setCompanyId,
  submitForm,
  formRef,
  isValid
}:
  {
    organisationFormSectionId: string,
    previousFormSectionId: string,
    nextFormSectionId: string,
    useNavigation: typeof useNavigationFormSections.arguments,
    companyOptions: InputOption[],
    setCompanyName: (firstName: string) => void,
    setCompanyId: (companyId: string) => void,
    submitForm: () => void,
    formRef?: any,
    isValid?: boolean
  }) => {
  const {t} = useTranslation('team');
  
  const submit = () => {
    submitForm();
    useNavigation.activateSection(nextFormSectionId);
  };
  
  return (
    <ValidatorForm ref={formRef} onSubmit={() => useNavigation.activateSection(nextFormSectionId)}>
      <FormSection anchorId={organisationFormSectionId}
                   isActivated={useNavigation.isSectionActivated(organisationFormSectionId)}>
        <Typography component="h3" variant="h3">
          {t('form.add.organisationTitle')}
        </Typography>
        <Typography component="p" variant="sm">
          {t('form.add.organisationLabel')}
        </Typography>
        <Grid container justifyContent="center">
          <Grid item xs={10} md={4} sm={6} lg={3}>
            <InputSelect
              setExistingValue={setCompanyId}
              setNewValue={setCompanyName}
              options={companyOptions}
              selectLabel={t('form.organisationPlaceholder')}
              required={true}
              validationRequiredMessage={t('form.validation.companyRequired')}
              addButtonLabel={t('form.addOrganisationButton')}
            />
          </Grid>
        </Grid>
        <FormNavigationButtons previousAnchor={previousFormSectionId}
                               nextAnchor={nextFormSectionId}
                               activateSection={() => submit()}
                               isDisabled={!isValid} />
      </FormSection>
    </ValidatorForm>
  );
};

export default AddTeamOrganisationFormComponent;
