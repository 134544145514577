import React from "react";
import {Alert, CircularProgress, Container} from "asset/component";
import Coachee from "module/coachee/domain/model/Coachee";
import styles from "module/coachee/view/asset/coacheeProfile/CoacheeProfileHeaderComponent.module.scss";
import CoacheeProfileThumbnailComponent from "module/coachee/view/component/CoacheeProfileThumbnailComponent";
import Company from "module/company/domain/model/Company";
import Team from "module/team/domain/model/Team";
import NavigationSlider from "asset/component/global/slider/NavigationSlider";
import {Routes} from "router/Routes";
import {useNavigate} from "react-router-dom";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import {ErrorNames} from "core/error/ErrorNames";
import {CoacheeSequence} from "module/onboarding/domain/model/SeedData/OnboardingSequencesSeed";

const CoacheeProfileHeaderComponent = ({
  coachee,
  coachees,
  company,
  teams,
  teamId,
  isLoading,
  errorName
}:
  {
    coachee?: Coachee,
    coachees?: Coachee[],
    teamId?: string,
    isLoading: boolean,
    errorName?: ErrorNames,
    company?: Company,
    teams?: Team[]
  }) => {
  const navigate = useNavigate();
  const {getErrorMessage} = useErrorTranslation();
  
  const navigateOnClick = (id, teamId) => {
    navigate(teamId ? Routes.coachee.profileTeam.path({coacheeId: id, teamId: teamId}) : Routes.coachee.profile.path({coacheeId: id}));
  };
  
  const navigateOnTeamClick = (team) => {
    navigate(Routes.team.profile.path({teamId: team.id, companyId: team.companyId}));
  };
  
  const sliderCoachees = () => {
    let coacheesToRender: Coachee[] = [];
    
    if (coachees && coachees.length > 0) {
      coacheesToRender = coachees;
    } else if (coachee) {
      coacheesToRender.push(coachee);
    }
    
    return coacheesToRender.map((childProps) => ({
      'data-testid': `coachee_${childProps.id}`,
      id: childProps.id,
      coachee: childProps,
      teamId: teamId,
      companyName: company?.name,
      teams: teams,
      navigateOnClick: () => navigateOnClick(childProps.id, teamId),
      navigateOnTeamClick: (team) => navigateOnTeamClick(team),
      isSelected: coachee?.id === childProps.id,
      isClone: false
    }));
  };
  
  const coacheesSlider = coachee && <NavigationSlider
      Component={CoacheeProfileThumbnailComponent}
      componentsProps={sliderCoachees()}
      id={CoacheeSequence.steps[3].anchorID}
      selectedId={coachee.id}
  />;
  
  const coacheeLoading = <CircularProgress data-testid="loading_coachee" />;
  
  const coacheeError =
    <Alert severity="error" data-testid="error_coachee">
      {errorName && getErrorMessage(errorName)}
    </Alert>;
  
  const displayLoading = () => {
    return isLoading && !coachee;
  };
  
  const renderCoacheeProfile = () => {
    if (errorName) {
      return coacheeError;
    } else if (displayLoading()) {
      return coacheeLoading;
    } else {
      return <>{coacheesSlider}</>;
    }
  };
  
  return (
    <Container id={styles.CoacheeProfileHeaderComponentContainer}
               data-testid="coachee_profile_header_component">
      {renderCoacheeProfile()}
    </Container>
  );
};

export default CoacheeProfileHeaderComponent;
