export default class Coachee {
  private readonly _id: string;
  private readonly _firstName: string;
  private readonly _lastName: string;
  private readonly _teamIds: string[];
  private readonly _companyId: string;
  private readonly _note: string;
  private readonly _jobTitle: string;
  private readonly _descriptionJobTitle: string;
  
  constructor(id: string, firstName: string, lastName: string, teamIds: string[], companyId: string, note: string, jobTitle: string, descriptionJobTitle: string) {
    this._id = id;
    this._firstName = firstName;
    this._lastName = lastName;
    this._teamIds = teamIds;
    this._companyId = companyId;
    this._note = note;
    this._jobTitle = jobTitle;
    this._descriptionJobTitle = descriptionJobTitle;
  }
  
  get id(): string {
    return this._id;
  }
  
  get firstName(): string {
    return this._firstName;
  }
  
  get lastName(): string {
    return this._lastName;
  }
  
  get teamIds(): string[] {
    return this._teamIds;
  }
  
  get companyId(): string {
    return this._companyId;
  }
  
  get note(): string {
    return this._note;
  }
  
  get jobTitle(): string {
    return this._jobTitle;
  }
  
  get descriptionJobTitle(): string {
    return this._descriptionJobTitle;
  }
}
