import React from 'react';

const Coachee = () => {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <mask id="mask0_5047_5018"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="30"
              height="31">
          <circle cx="15" cy="15.5" r="15" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_5047_5018)">
          <rect y="0.49939" width="30" height="30" fill="#FFDEB9" />
          <path d="M6.16406 26.9804C6.16406 25.4325 7.16216 24.1115 8.52394 23.857L14.5354 22.7336C14.8428 22.6762 15.1566 22.6762 15.464 22.7336L21.4754 23.857C22.8372 24.1115 23.8353 25.4325 23.8353 26.9804V29.387C23.8353 31.1359 22.5709 32.5536 21.0111 32.5536H8.98823C7.42848 32.5536 6.16406 31.1359 6.16406 29.387V26.9804Z"
                fill="#FAF4EB" />
          <path d="M16.9914 22.1839C19.6066 21.3427 21.499 18.8898 21.499 15.995C21.499 12.4051 18.5889 9.495 14.999 9.495C11.4092 9.495 8.49902 12.4051 8.49902 15.995C8.49902 18.6836 10.1722 21.0512 12.4999 22.0403C12.513 22.2621 12.4999 22.5151 12.4999 22.7441C12.4999 23.9867 13.5073 24.9941 14.7499 24.9941C15.9925 24.9941 16.9999 23.9867 16.9999 22.7441C16.9999 22.5507 16.9999 22.363 16.9914 22.1839Z"
                fill="#FDC78B" />
          <path d="M15.0002 13.9957C15.0002 12.7457 14.7501 9.99568 13.7501 9.99568C13.7501 11.5971 11.7501 13.4129 10.7501 13.9957H9.25006C9.08339 15.4951 9.45274 17.5701 9.45274 18.1701C9.45274 18.7701 9.77466 19.8471 10.2747 20.4304L7.60286 18.7866C7.10286 18.0366 6.80129 17.1443 7.00057 13.7457C7.19985 10.347 10.7501 6.9973 13.2497 8.49883C19.9997 5.99931 24.7502 10.7477 23.2502 18.4987L18.4935 21.4578C20.6935 19.4578 20.5002 17.1427 20.5002 13.9957H15.0002Z"
                fill="#FF8A00" />
          <path d="M11 15.7452C11 16.9879 9.99264 17.9952 8.75 17.9952C7.50736 17.9952 6.5 16.9879 6.5 15.7452C6.5 14.5026 7.50736 13.4952 8.75 13.4952C9.99264 13.4952 11 14.5026 11 15.7452Z"
                fill="#FDC78B" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5047_5018">
          <rect width="30" height="30" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Coachee;
