import * as React from "react";

export enum ArrowDirection {
  LEFT,
  RIGHT
}

const ArrowIcon = ({
  direction
} : {
  direction?: ArrowDirection
}) => {
  const arrowStyle = () => {
    if (direction === ArrowDirection.LEFT) {
      return {transform: `rotate(180deg)`}
    } else return {}
  }
  
  return (
    <svg style={arrowStyle()}
          width="100%"
         height="100%"
         viewBox="0 0 16 16"
         fill="currentColor"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z"
            fill="currentColor" />
    </svg>
  );
};

export default ArrowIcon;
