import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "store";
import {Routes} from "router/Routes";
import Breadcrumbs, {BreadcrumbLink} from "asset/component/global/Breadcrumbs";
import {Container} from "asset/component";
import styles from 'module/coachee/view/asset/coacheeProfile/CoacheeProfileBreadcrumbsComponent.module.scss';
import {useTranslation} from "react-i18next";

const CoacheeProfileContainer = ({coacheeId, companyId, teamId}: {
  coacheeId?: string,
  companyId?: string,
  teamId?: string
}) => {
  const {t} = useTranslation('coachee');
  
  const coachee = useSelector((rootState: RootState) =>
    coacheeId && rootState.CoacheeStore?.getters(rootState).getCoacheeById(coacheeId));
  
  const company = useSelector((rootState: RootState) =>
    companyId && rootState.CompanyStore?.getters(rootState).getCompanyById(companyId));
  
  const team = useSelector((rootState: RootState) =>
    teamId && rootState.TeamStore?.getters(rootState).getTeamById(teamId));
  
  const breadcrumbLinks = () => {
    const links: BreadcrumbLink[] = [{name: t('home'), path: Routes.home.path()}];
    
    company && links.push({name: company.name});
    team && links.push({name: team?.name, path: Routes.team.profile.path({teamId: team.id, companyId: team.companyId})});
    coachee && links.push({name: `${coachee.firstName} ${coachee.lastName}`, selected: true});
    
    return links;
  };
  
  return (
    <Container id={styles.coacheeProfileBreadcrumbContainer}>
      <Breadcrumbs data-testid="breadcrumbs" links={breadcrumbLinks()} />
    </Container>
  );
};

export default CoacheeProfileContainer;
