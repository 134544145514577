import * as React from 'react';
import {useEffect} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Alert} from 'asset/component/index';
import {useTranslation} from "react-i18next";
import {CloseIcon, EditIcon} from "asset/component/Icons";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import {MuiButtonColors} from "style/props/MuiButtonColors";
import styles from "asset/component/asset/global/dialogs/UpdateDialog.module.scss";
import DeleteConfirmation from "asset/component/global/dialogs/DeleteConfirmation";
import {LoadingButton} from "@mui/lab";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import {ValidatorForm} from "asset/validator";
import {ErrorNames} from "core/error/ErrorNames";
import usePaletteColors from "style/usePaletteColors";

export default function UpdateDialog(
  {
    updateAction,
    deleteAction,
    errorName,
    isLoading,
    isCompleted,
    children
  }: {
    updateAction: () => void,
    deleteAction: () => void,
    errorName?: ErrorNames,
    isLoading?: boolean,
    isCompleted?: boolean,
    children: JSX.Element | JSX.Element[]
  }) {
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(false);
  const {getErrorMessage} = useErrorTranslation();
  const palette = usePaletteColors();
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  
  const declined = () => {
    handleClose();
  };
  
  const confirmed = () => {
    updateAction();
  };
  
  useEffect(() => {
    if (isCompleted) {
      handleClose();
    }
  }, [isCompleted]);
  
  const editButton =
    <IconButton onClick={handleClickOpen}
                className={styles.editButton}
                data-testid="dialog-edit-icon"
                iconColor={palette.primary.dark}
                hoverBackgroundColor={palette.primary.main}
                backgroundColor="transparent"
                buttonSize="40px"
                iconSize="20px"
                icon={<EditIcon />}
    />;
  
  const deleteButton =
    <DeleteConfirmation deleteAction={deleteAction}
                        confirmationText={t('deleteElementConfirmation')}
    />;
  
  const dialogTitle =
    <DialogTitle className={styles.title}>
      {deleteButton}
      <IconButton
        className={styles.closeButton}
        aria-label="close"
        onClick={handleClose}
        iconColor={palette.secondary.veryDark}
        backgroundColor="transparent"
        buttonSize="40px"
        iconSize="22px"
        icon={<CloseIcon />}
      />
    </DialogTitle>;
  
  const dialogContent =
    <DialogContent className={styles.content}>
      {
        errorName &&
          <Alert severity="error"
                 data-testid="update-coachee-error-message">
            {errorName && getErrorMessage(errorName)}
          </Alert>
      }
      {children}
    </DialogContent>;
  
  const dialogAction =
    <DialogActions className={styles.actions}>
      <Button data-testid="dialog-cancel"
              variant={MuiButtonVariants.OUTLINED}
              color={MuiButtonColors.PRIMARY_DARK}
              onClick={declined}>
        {t('common:cancel')}
      </Button>
      <LoadingButton data-testid="dialog-save"
                     variant={MuiButtonVariants.CONTAINED}
                     loading={isLoading}
                     disabled={isLoading}
                     type="submit">
        {t('common:save')}
      </LoadingButton>
    </DialogActions>;
  
  return (
    <div>
      {editButton}
      <Dialog open={open} onClose={handleClose} className={styles.updateDialog}>
        <ValidatorForm data-testid="update-form" onSubmit={confirmed}>
          {dialogTitle}
          {dialogContent}
          {dialogAction}
        </ValidatorForm>
      </Dialog>
    </div>
  );
}
