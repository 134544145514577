import type RESTClient from "core/client/RESTClient";
import FollowUpTeam from "module/followUp/domain/model/FollowUpTeam";
import FollowUpResponse, {
  followUpsTeamResponseToModel,
  followUpTeamResponseToModel
} from "module/followUp/dal/dto/FollowUpResponse";
import FollowUpTeamRepository from "module/followUp/domain/FollowUpTeamRepository";
import AddFollowUp from "module/followUp/domain/model/AddFollowUp";
import {createAddFollowUpRequest} from "module/followUp/dal/dto/AddFollowUpRequest";
import UpdateFollowUp from "module/followUp/domain/model/UpdateFollowUp";
import {createUpdateFollowUpRequest} from "module/followUp/dal/dto/UpdateFollowUpRequest";

export default class RESTFollowUpTeamRepository implements FollowUpTeamRepository {
  private static readonly BASE_URL: string = "api";
  private static readonly TEAM_URL: string = "teams";
  private static readonly FOLLOW_UP_URL: string = "followUps";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchFollowUpsByTeamId(id: string): Promise<FollowUpTeam[]> {
    const followUpsResponse = await this.client.apiGet<FollowUpResponse[]>(
      `/${RESTFollowUpTeamRepository.BASE_URL}` +
      `/${RESTFollowUpTeamRepository.TEAM_URL}/${id}` +
      `/${RESTFollowUpTeamRepository.FOLLOW_UP_URL}`
    );
    
    return followUpsTeamResponseToModel(followUpsResponse, id);
  }
  
  public async addFollowUpForTeam(addFollowUp: AddFollowUp, teamId: string): Promise<FollowUpTeam> {
    const followUpResponse = await this.client.apiPost<FollowUpResponse>(
      `/${RESTFollowUpTeamRepository.BASE_URL}` +
      `/${RESTFollowUpTeamRepository.TEAM_URL}/${teamId}` +
      `/${RESTFollowUpTeamRepository.FOLLOW_UP_URL}`,
      createAddFollowUpRequest(addFollowUp)
    );
    
    return followUpTeamResponseToModel(followUpResponse, teamId);
  }
  
  public async updateFollowUpForTeam(
    updateFollowUp: UpdateFollowUp,
    followUpId: string,
    teamId: string
  ): Promise<FollowUpTeam> {
    const followUpResponse = await this.client.apiPut<FollowUpResponse>(
      `/${RESTFollowUpTeamRepository.BASE_URL}` +
      `/${RESTFollowUpTeamRepository.TEAM_URL}/${teamId}` +
      `/${RESTFollowUpTeamRepository.FOLLOW_UP_URL}/${followUpId}`,
      createUpdateFollowUpRequest(updateFollowUp)
    );
    
    return followUpTeamResponseToModel(followUpResponse, teamId);
  }
  
  public async deleteFollowUpForTeam(followUpId: string, teamId: string): Promise<void> {
    await this.client.apiDelete<FollowUpResponse>(
      `/${RESTFollowUpTeamRepository.BASE_URL}` +
      `/${RESTFollowUpTeamRepository.TEAM_URL}/${teamId}` +
      `/${RESTFollowUpTeamRepository.FOLLOW_UP_URL}/${followUpId}`
    );
  }
}
