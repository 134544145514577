import {useTheme} from "@mui/system";

export type Palette = {
  primary: {
    dark: string,
    middle: string,
    main: string,
    light: string,
    veryLight: string,
  },
  secondary: {
    veryDark: string,
    dark: string,
    middle: string,
    main: string,
    light: string,
    veryLight: string,
  },
  special: {
    danger: string,
    dangerLight: string,
  },
  white: {
    main: string
  }
}

const usePaletteColors = () => {
  const theme = useTheme();
  
  return {
    primary: {
      dark: theme.palette.primary.dark,
      middle: theme.palette.primary.middle,
      main: theme.palette.primary.main,
      light: theme.palette.primary.light,
      veryLight: theme.palette.primary.veryLight,
    },
    secondary: {
      veryDark: theme.palette.secondary.veryDark,
      dark: theme.palette.secondary.dark,
      middle: theme.palette.secondary.middle,
      main: theme.palette.secondary.main,
      light: theme.palette.secondary.light,
      veryLight: theme.palette.secondary.veryLight,
    },
    special: {
      danger: theme.palette.special.danger,
      dangerLight: theme.palette.special.dangerLight,
    },
    white: {
      main: theme.palette.white.main,
    }
  } as Palette;
};

export const AddOpacity = (color: string, opacity: number) => {
  return color.concat(opacity.toString());
};

export default usePaletteColors;
