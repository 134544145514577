import React, {useEffect, useState} from 'react';
import styles from "asset/component/asset/tag/AddTag.module.scss";
import InputSelect, {InputSelectStyles} from "asset/component/form/inputs/InputSelect";
import {ValidatorForm} from 'asset/validator';
import {CircularProgress} from "asset/component/index";
import ActionButton from "asset/component/global/buttons/ActionButton";
import TagSelector from "asset/component/tag/TagSelector";
import {Tag} from "asset/component/tag/Tag";
import useClickOutside from "asset/component/hook/useClickOutside";

export enum AddTagTools {
  INPUT_SELECT = 'inputSelect',
  TAG_SELECTOR = 'tag_selector',
}

const AddTag = ({
  tags,
  updateTags,
  tools
}: {
  tags: Tag[],
  updateTags: (tags: Tag[]) => void,
  tools: AddTagTools
}) => {
  const [tagsUpdated, setTagsUpdated] = useState<Tag[]>(tags);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const addTagRef = useClickOutside(() => tools !== AddTagTools.INPUT_SELECT && setIsFormVisible(false));
  
  useEffect(() => {
    setIsLoading(false);
    setTagsUpdated(tags);
  }, [tags]);
  
  const updateSubmit = (tagsToUpdate = tagsUpdated) => {
    setIsLoading(true);
    updateTags(tagsToUpdate);
    setIsFormVisible(false);
  };
  
  const remainingTags = () => {
    return tagsUpdated.filter(tag => !tag.isSelected);
  };
  
  const updateTag = (updatedTag) => {
    const updatedTags = tagsUpdated.map(tag =>
      tag.id === updatedTag.id ? updatedTag : tag
    );
    
    setTagsUpdated(updatedTags);
    
    updateSubmit(updatedTags);
  };
  
  const inputSelect = (
    <ValidatorForm className={styles.form} onSubmit={() => setIsLoading(true)}>
      <InputSelect options={remainingTags().map(tag => ({value: tag.id, label: tag.name}))}
                   setExistingValue={(tagId) => {
                     if (tagId) {
                       const tag = tagsUpdated.find(tag => tag.id === tagId);
                       if (tag) {
                         tag.isSelected = true;
                         
                         updateTag(tag);
                         
                         updateSubmit();
                       }
                     }
                   }}
                   style={InputSelectStyles.ROUNDED}
      />
    </ValidatorForm>
  );
  
  const tagSelector = (
    <TagSelector tags={tagsUpdated} updateTag={updateTag} />
  );
  
  const addTools = () => {
    switch (tools) {
      case AddTagTools.INPUT_SELECT: {
        return inputSelect;
      }
      case AddTagTools.TAG_SELECTOR: {
        return tagSelector;
      }
      default: {
        return inputSelect;
      }
    }
  };
  
  return <div ref={addTagRef} className={`${styles.addTag} ${isFormVisible ? styles.opened : styles.close} ${tools}`}>
    {
      tags.filter(tag => !tag.isSelected).length > 0 &&
        <>
          {
            !isFormVisible && <ActionButton onClick={() => setIsFormVisible(true)} />
          }
          {
            isFormVisible && (!isLoading ? addTools() : <CircularProgress data-testid="add-tag-loading" />)
          }
        </>
    }
  </div>;
};

export default AddTag;
