import React from 'react';
import styles from "module/mandate/view/asset/listing/MandateCardContent.module.scss";
import {Typography} from "@mui/material";
import Mandate from "module/mandate/domain/model/Mandate";
import MandateDeadlineDisplay from "module/mandate/view/component/listing/MandateDeadlineDisplay";
import MandateLinkedCompaniesContainer from "module/mandate/view/container/listing/MandateLinkedCompaniesContainer";

const MandateCardContent = ({
  mandate,
  isFinished
}: {
  mandate: Mandate,
  isFinished: boolean
}) => {
  return (
    <div className={styles.cardContent}>
      <MandateDeadlineDisplay mandate={mandate} isFinished={isFinished} />
      <MandateLinkedCompaniesContainer companyIds={mandate.companies} />
      <Typography variant="h5"
                  component="h3"
                  fontSize={15}
                  textAlign={"start"}
                  className={styles.title}
                  data-testid="mandate-card-content-title">
        {mandate.title}
      </Typography>
      <Typography variant="body1"
                  component="p"
                  fontSize={14}
                  maxHeight={68}
                  textAlign={"start"}
                  className={styles.description}
                  data-testid="mandate-card-content-description">
        {mandate.description}
      </Typography>
    </div>
  );
};

export default MandateCardContent;
