import * as React from "react";

const ProgressionCircle66Percent = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" transform="rotate(-90 20 20)" fill="currentColor" />
      <path d="M20 0.999999C20 0.447715 20.448 -0.00261015 20.9996 0.024993C24.1278 0.181518 27.1805 1.07095 29.9092 2.62737C32.9279 4.3492 35.4458 6.82789 37.2148 9.81917C38.9839 12.8104 39.9429 16.2111 39.9975 19.6859C40.0521 23.1606 39.2003 26.5897 37.5261 29.6351C35.8519 32.6804 33.4131 35.237 30.45 37.0528C27.4869 38.8686 24.1017 39.881 20.6282 39.9901C17.1547 40.0993 13.7127 39.3015 10.6414 37.6753C7.86517 36.2054 5.47847 34.1045 3.67016 31.5471C3.3513 31.0962 3.49146 30.4766 3.95777 30.1807L19.5358 20.2946C19.8249 20.1111 20 19.7926 20 19.4502L20 0.999999Z"
            fill="#FF8A00" />
    </svg>
  );
};
export default ProgressionCircle66Percent;
