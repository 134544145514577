import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import InformalRole from "module/skill/domain/model/InformalRole";
import {RootState} from "store";
import AppError from "core/error/AppError";
import ReducerServices from "store/service/ReducerServices";
import UpdateInformalRole from "module/skill/domain/model/UpdateInformalRole";

export const InformalRoleStore = createModel<RootModel>()({
  state: {
    informalRoles: [] as InformalRole[],
    getters: (state: RootState) => ({
      getInformalRoleByCoacheeId: (coacheeId: string) => {
        return state.InformalRoleStore.informalRoles.find(informalRole => informalRole.coacheeId == coacheeId);
      }
    })
  },
  reducers: {
    setInformalRole(state, informalRole: InformalRole) {
      return {
        ...state,
        informalRoles: ReducerServices.setItemToList<InformalRole>(state.informalRoles, informalRole)
      };
    }
  },
  effects: (dispatch) => ({
    async fetchInformalRole(payload: { coacheeId: string }) {
      await clients.InformalRole.fetchInformalRole(payload.coacheeId).then((informalRole: InformalRole) => {
        dispatch.InformalRoleStore.setInformalRole(informalRole);
      }).catch((error: AppError) => {
        throw error.name;
      });
    },
    async updateInformalRole(payload: { updateInformalRole: UpdateInformalRole, coacheeId: string }) {
      return await clients.InformalRole
        .updateInformalRole(payload.updateInformalRole, payload.coacheeId)
        .then((updatedInformalRole) => {
          dispatch.InformalRoleStore.setInformalRole(updatedInformalRole);
          
          return updatedInformalRole;
        }).catch((error: AppError) => {
          throw error.name;
        });
    }
  })
});
