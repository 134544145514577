import User from "module/user/domain/model/User";

export default interface UserResponse {
  email: string;
  firstName: string;
  lastName: string;
}

export function userResponseToModel(userResponse: UserResponse): User {
  return new User(
    userResponse.email,
    userResponse.firstName,
    userResponse.lastName
  );
}
