import FollowUpCoachee from "module/followUp/domain/model/FollowUpCoachee";
import FollowUpTeam from "module/followUp/domain/model/FollowUpTeam";

export default interface FollowUpResponse {
  id: string;
  text: string;
  createdAt: string;
  completed: boolean;
}

export function followUpCoacheeResponseToModel(
  followUpResponse: FollowUpResponse,
  coacheeId: string
): FollowUpCoachee {
  return new FollowUpCoachee(
    followUpResponse.id,
    followUpResponse.text,
    followUpResponse.createdAt,
    followUpResponse.completed,
    coacheeId,
  );
}

export function followUpsCoacheeResponseToModel(
  followUpResponse: FollowUpResponse[],
  coacheeId: string
): FollowUpCoachee[] {
  return followUpResponse.map(followUpResponse => followUpCoacheeResponseToModel(followUpResponse, coacheeId));
}

export function followUpTeamResponseToModel(
  followUpResponse: FollowUpResponse,
  teamId: string
): FollowUpTeam {
  return new FollowUpTeam(
    followUpResponse.id,
    followUpResponse.text,
    followUpResponse.createdAt,
    followUpResponse.completed,
    teamId,
  );
}

export function followUpsTeamResponseToModel(
  followUpResponse: FollowUpResponse[],
  teamId: string
): FollowUpTeam[] {
  return followUpResponse.map(followUpResponse => followUpTeamResponseToModel(followUpResponse, teamId));
}
