import * as React from "react";
import {Box} from "asset/component";
import HomePageCompaniesListingContainer from "module/home/view/container/HomePageCompaniesListingContainer";
import BodySectionLayout from "asset/component/layout/BodySectionLayout";
import HomePageListingCoacheesHeaderComponent from "module/home/view/component/HomePageListingCoacheesHeaderComponent";
import {CONTENT_ID} from "router/Router";
import {Accesses} from "module/authorization/domain/model/accesses";
import HadAccess from "module/authorization/HadAccess";

const HomePage = () => {
  return (
    <Box id={CONTENT_ID}>
      <HadAccess accesses={[Accesses.COACHEES_MANAGEMENT]}>
        <BodySectionLayout padding={"small"}>
          <HomePageListingCoacheesHeaderComponent />
          <HomePageCompaniesListingContainer />
        </BodySectionLayout>
      </HadAccess>
    </Box>
  );
};

export default HomePage;
