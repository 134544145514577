import React from 'react';
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import styles from 'asset/component/asset/CheckboxButton.module.scss';
import MuiCheckboxColors from "style/props/MuiCheckboxColors";
import DeleteConfirmation from "asset/component/global/dialogs/DeleteConfirmation";
import {useTranslation} from "react-i18next";
import {Alert, Checkbox, CircularProgress, FormControlLabel, Typography} from "asset/component/index";
import usePaletteColors from "style/usePaletteColors";
import useDateTranslation from "asset/component/hook/useDateTranslation";

interface CheckboxButtonProps {
  id?: string;
  value: boolean;
  label: string;
  date?: Date;
  setValue: (value: boolean) => void;
  deleteElement?: () => void;
  deleteElementConfirmationText?: string;
  errorMessage?: string;
  isLoading?: boolean;
  variant?: MuiButtonVariants;
}

const CheckboxButton: React.FC<CheckboxButtonProps> = ({
  id,
  value,
  label,
  date,
  setValue,
  deleteElement,
  deleteElementConfirmationText,
  isLoading,
  errorMessage,
  variant = MuiButtonVariants.CONTAINED
}) => {
  const {t} = useTranslation('common');
  const {formatDateWithMonthName} = useDateTranslation();
  const palette = usePaletteColors();
  
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked);
  };
  
  const deleteButton = deleteElement &&
      <div className={styles.deleteButton}>
          <DeleteConfirmation data-testid={`${id}-delete-button`}
                              deleteAction={deleteElement}
                              confirmationText={deleteElementConfirmationText || t('deleteElementConfirmation')}
          />
      </div>;
  
  return (
    <>
      {errorMessage && <Alert severity="error" data-testid={`${id}-error-message`}>{errorMessage}</Alert>}
      <div className={styles.checkboxButtonContainer}>
        <FormControlLabel
          className={`${styles.formControl} ${variant}`}
          data-testid={`${id}-checkbox`}
          control={!isLoading ?
            <Checkbox color={MuiCheckboxColors.SECONDARY} /> :
            <CircularProgress data-testid={`${id}-loading-checkbox`} size={20} />
          }
          checked={value}
          onChange={event => onChange(event as React.ChangeEvent<HTMLInputElement>)}
          label={<>
            <div className={styles.labelContainer}>
              <Typography title={label}
                          className={`${styles.label} ${value && styles.checked}`}
                          component="span"
                          color={palette.secondary.dark}
                          variant="xs">
                {label}
              </Typography>
              {
                date &&
                  <Typography title={date.toDateString()}
                              className={`${styles.date} ${value && styles.checked}`}
                              component="span"
                              color={palette.secondary.dark}
                              variant="xxs">
                    {formatDateWithMonthName(date)}
                  </Typography>
              }
            </div>
            {deleteButton}
          </>}
        />
      </div>
    </>
  );
};

export default CheckboxButton;
