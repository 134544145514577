import {Button} from "asset/component/index";
import Navigation from "asset/service/Navigation";
import React from "react";
import {useTranslation} from "react-i18next";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";

const NavigationPreviousButton = ({previousSectionId}: {
  previousSectionId: string
}) => {
  const {t} = useTranslation();
  
  return (
    <Button data-testid={`${previousSectionId}-previous`}
            onClick={() => Navigation.AnchorRedirection(previousSectionId)}
            variant={MuiButtonVariants.OUTLINED}>
      {t('common:previous')}
    </Button>
  );
};

const NavigationNextButton = ({
  nextSectionId,
  text,
  className,
  onClick,
  isSubmit,
  isDisabled
}: {
  nextSectionId: string,
  text?: string,
  className?: string,
  onClick: (nextSectionId: string) => void,
  isSubmit?: boolean,
  isDisabled?: boolean
}) => {
  const {t} = useTranslation();
  
  return (
    <Button data-testid={`${nextSectionId}-next`}
            className={className}
            onClick={() => onClick(nextSectionId)}
            variant={MuiButtonVariants.CONTAINED}
            disabled={isDisabled}
            type={isSubmit ? 'submit' : 'button'}
    >
      {text ? text : t('common:next')}
    </Button>
  );
};

const NavigationSkipButton = ({
  nextSectionId,
  text,
  className,
  onClick
}: {
  nextSectionId: string,
  text: string,
  className?: string,
  onClick: (nextSectionId: string) => void
}) => {
  return (
    <Button data-testid={`${nextSectionId}-skip`}
            className={className}
            onClick={() => onClick(nextSectionId)}>
      {text}
    </Button>
  );
};
export {NavigationPreviousButton, NavigationNextButton, NavigationSkipButton};
