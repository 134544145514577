import UpdateObjective from "module/objective/domain/model/UpdateObjective";
import UpdateObjectiveRequest, {createUpdateObjectiveRequest} from "module/objective/dal/dto/UpdateObjectiveRequest";

export default interface UpdateObjectiveCoacheeRequest extends UpdateObjectiveRequest {
  needs: string[];
}

export function createUpdateObjectiveCoacheeRequest(updateObjective: UpdateObjective): UpdateObjectiveCoacheeRequest {
  return {
    ...createUpdateObjectiveRequest(updateObjective),
    needs: updateObjective.needs
  } as UpdateObjectiveCoacheeRequest;
}
