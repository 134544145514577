import {Tag} from "asset/component/tag/Tag";
import CultureTag from "module/culture/domain/model/CultureTag";
import i18n from "i18next";

const PREDEFINED_SKILL_TRANSLATION_KEY = "culture:predefinedCultureTags";

export const PredefinedCultureTags = [
  "autonomous",
  "cohesion",
  "collaborative",
  "flexible",
  "innovative",
  "commonVision",
  "proactive"
];

const getTranslationCultureTag = (predefinedCultureTag) =>{
  return i18n.t(`${PREDEFINED_SKILL_TRANSLATION_KEY}.${predefinedCultureTag}`)
}

export const getFilteredPredefinedCultureTags = (actualCultureTags: CultureTag[]) => {
  return PredefinedCultureTags.filter((predefinedCultureTag) => {
    return !actualCultureTags.some((cultureTag) => cultureTag.title === getTranslationCultureTag(predefinedCultureTag));
  }).map(predefinedCultureTag => ({
    id: predefinedCultureTag,
    name: getTranslationCultureTag(predefinedCultureTag),
    isSelected: false
  } as Tag));
};
