import React from 'react';
import {Autocomplete, TextField} from "@mui/material";
import styles from "asset/component/asset/search/SearchAutoCompleteCategories.module.scss";
import {StyledPopper} from "asset/component/form/inputs/InputAutoComplete";

export interface InputCategoryOptions {
  value: string;
  label: string;
  category: string;
}

const SearchAutoCompleteCategories = ({
  options,
  placeholder = "",
  noOptionsText = "",
  onChange,
  value
}: {
  options: InputCategoryOptions[],
  placeholder?: string,
  noOptionsText?: string,
  onChange: (id: string) => void,
  value?: InputCategoryOptions
}) => {
  const [inputValue, setInputValue] = React.useState('');
  
  return (
    <Autocomplete
      id="search-auto-complete-categories"
      data-testid="search-autocomplete"
      PopperComponent={StyledPopper}
      className={`${styles.container} ${styles.searcher}`}
      noOptionsText={noOptionsText}
      options={options}
      groupBy={(options) => options.category}
      onChange={(event, newValue) => {
        onChange(newValue ? newValue.value : '');
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        );
      }}
      inputValue={inputValue}
      value={value}
      renderInput={
        (params) =>
          <TextField {...params} label={placeholder}
                     onChange={(event) => setInputValue(event.target.value)}
                     className={styles.input}
                     data-testid="search-autocomplete-text-field" />
      }
    />
  );
};

export default SearchAutoCompleteCategories;
