export enum MuiTypographyVariants {
  XXS = "xxs",
  XS = "xs",
  SM = "sm",
  H6 = "h6",
  H5 = "h5",
  H4 = "h4",
  H3 = "h3",
  H2 = "h2",
  H1 = "h1",
}
