import React, {ReactElement, useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes as Switch} from 'react-router-dom';
import Layout, {LayoutTheme} from "module/common/layout/container/Layout";
import {Routes} from 'router/Routes';
import HomePage from "module/home/view/container/HomePage";
import AuthenticationRoute from "router/AuthenticationRoute";
import PrivateRoute from "router/PrivateRoute";
import {useAuthenticationState} from "module/authentication/hook/useAuthenticationState";
import LoadingLayout from "module/common/layout/container/LoadingLayout";
import TeamProfileContainer from 'module/team/view/container/TeamProfileContainer';
import AddCoacheeFormContainer from "module/coachee/view/container/AddCoacheeFormContainer";
import CoacheeProfileContainer from "module/coachee/view/container/CoacheeProfileContainer";
import AddTeamFormContainer from "module/team/view/container/AddTeamFormContainer";
import AddCompanyFormContainer from 'module/company/view/container/AddCompanyFormContainer';
import HeaderContainer from "module/common/layout/container/HeaderContainer";
import SettingPage from "module/user/view/SettingPage";
import RegisterPage from "module/authentication/view/RegisterPage";
import LoginPage from "module/authentication/view/LoginPage";
import {isNewUser} from 'asset/service/UserServices';
import TrialComponent from "asset/component/global/TrialComponent";
import AddMandateFormContainer from "module/mandate/view/container/add/AddMandateFormContainer";
import MandatesContainer from "module/mandate/view/container/listing/MandatesContainer";
import useProfileManager from "module/profile/view/hook/useProfileManager";
import InactiveWorkSpaceMessage from "asset/component/global/InactiveWorkspaceMessage";

export const CONTENT_ID = "content";

function Router(): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  const [user, userLoading] = useAuthenticationState();
  const {profileIsCompleted} = useProfileManager();
  
  const showTrialComponent = user ? isNewUser(user.metadata) : false;
  
  useEffect(() => {
    setIsLoading(userLoading && !profileIsCompleted);
  }, [userLoading, profileIsCompleted]);
  
  return <BrowserRouter>
    <Switch data-test-id="router-switch">
      <Route path={Routes.home.path()}
             element={
               <Layout key={Routes.home.path()}
                       isLoading={isLoading}
                       user={user}
                       layoutTheme={LayoutTheme.TEXTURED}
                       scrollToContent={true}>
                 <PrivateRoute element={<HeaderContainer />} />
                 {showTrialComponent ? <TrialComponent /> : <></>}
                 <InactiveWorkSpaceMessage />
               </Layout>
             }
      />
      
      <Route path={Routes.coachees.path()}
             element={
               <Layout key={Routes.coachees.path()}
                       isLoading={isLoading}
                       user={user}
                       layoutTheme={LayoutTheme.TEXTURED}
                       scrollToContent={true}>
                 <HeaderContainer />
                 {showTrialComponent ? <TrialComponent /> : <></>}
                 <PrivateRoute element={<HomePage />} />
               </Layout>
             }
      />
      
      {[Routes.mandate.path(), Routes.mandate.edit.path()].map((path) => (
        <Route path={path}
               key={path}
               element={
                 <Layout key={"mandates"}
                         isLoading={isLoading}
                         user={user}
                         layoutTheme={LayoutTheme.TEXTURED}
                         scrollToContent={true}>
                   <HeaderContainer />
                   {showTrialComponent ? <TrialComponent /> : <></>}
                   <PrivateRoute element={<MandatesContainer />} />
                 </Layout>
               }
        />
      ))}
      
      <Route path={Routes.mandate.path()}
             element={
               <Layout key={Routes.mandate.path()}
                       isLoading={isLoading}
                       user={user}
                       layoutTheme={LayoutTheme.TEXTURED}
                       scrollToContent={true}>
                 <HeaderContainer />
                 {showTrialComponent ? <TrialComponent /> : <></>}
                 <PrivateRoute element={<MandatesContainer />} />
               </Layout>
             }
      />
      
      <Route path={Routes.add.coachee.path()}
             element={
               <Layout key={Routes.add.coachee.path()}
                       isLoading={isLoading}
                       layoutTheme={LayoutTheme.TEXTURED}
                       user={user}>
                 {showTrialComponent ? <TrialComponent /> : <></>}
                 <PrivateRoute element={<AddCoacheeFormContainer />} />
               </Layout>
             }
      />
      
      <Route path={Routes.add.team.path()}
             element={
               <Layout key={Routes.add.team.path()}
                       isLoading={isLoading}
                       layoutTheme={LayoutTheme.TEXTURED}
                       user={user}>
                 <PrivateRoute element={<AddTeamFormContainer />} />
               </Layout>
             }
      />
      
      <Route path={Routes.add.company.path()}
             element={
               <Layout key={Routes.add.company.path()}
                       isLoading={isLoading}
                       layoutTheme={LayoutTheme.TEXTURED}
                       user={user}>
                 <PrivateRoute element={<AddCompanyFormContainer />} />
               </Layout>
             }
      />
      
      <Route path={Routes.add.mandate.path()}
             element={
               <Layout key={Routes.add.mandate.path()}
                       isLoading={isLoading}
                       layoutTheme={LayoutTheme.TEXTURED}
                       user={user}>
                 <PrivateRoute element={<AddMandateFormContainer />} />
               </Layout>
             }
      />
      
      {[Routes.coachee.profile.path(), Routes.coachee.profileTeam.path()].map((path) => (
        <Route key="path" path={path}
               element={
                 <Layout key={path} isLoading={isLoading} layoutTheme={LayoutTheme.LIGHT} user={user}>
                   <PrivateRoute element={<CoacheeProfileContainer />} />
                 </Layout>
               }
        />
      ))}
      
      <Route key="path" path={Routes.team.profile.path()}
             element={
               <Layout key={Routes.team.profile.path()}
                       isLoading={isLoading}
                       layoutTheme={LayoutTheme.DARK}
                       user={user}>
                 <PrivateRoute element={<TeamProfileContainer />} />
               </Layout>
             }
      />
      
      <Route path={Routes.settings.path()}
             element={
               <Layout key={Routes.settings.path()} isLoading={isLoading} layoutTheme={LayoutTheme.FLASH} user={user}>
                 <PrivateRoute element={<SettingPage />} />
               </Layout>
             }
      />
      
      <Route path={Routes.login.path()}
             element={
               <AuthenticationRoute element={isLoading ? <LoadingLayout /> : <LoginPage />} />
             }
      />
      
      <Route path={Routes.register.path()}
             element={
               <AuthenticationRoute element={isLoading ? <LoadingLayout /> : <RegisterPage />} />
             }
      />
    </Switch>
  </BrowserRouter>;
}

export default Router;
