import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import {RootState} from "store";
import AppError from "core/error/AppError";
import ObjectiveCoachee from "./domain/model/ObjectiveCoachee";
import ReducerServices from "store/service/ReducerServices";
import AddObjective from "module/objective/domain/model/AddObjective";
import UpdateObjective from "module/objective/domain/model/UpdateObjective";
import AddSubObjective from "module/objective/domain/model/AddSubObjective";
import UpdateSubObjective from "module/objective/domain/model/UpdateSubObjective";
import {NeedsCoachee} from "module/objective/domain/model/need/NeedsCoachee";
import NeedStats from "module/objective/domain/model/need/NeedStats";
import CoacheeNeedsStats from "module/objective/domain/model/need/CoacheeNeedsStats";
import ObjectiveState from "module/objective/domain/model/ObjectiveState";
import AddObjectiveState from "module/objective/domain/model/AddObjectiveState";
import UpdateObjectiveStateComment from "module/objective/domain/model/UpdateObjectiveStateComment";
import AddObjectiveStateComment from "module/objective/domain/model/AddObjectiveStateComment";
import ObjectiveStateComment from "module/objective/domain/model/ObjectiveStateComment";
import ObjectiveReducers from "module/objective/view/reducers/ObjectiveReducers";

export const ObjectiveCoacheeStore = createModel<RootModel>()({
  state: {
    objectivesCoachee: [] as ObjectiveCoachee[],
    needsStats: {} as CoacheeNeedsStats,
    objectiveStateHistory: [] as ObjectiveState[],
    getters: (state: RootState) => ({
      getObjectivesByCoacheeId: (id: string) => {
        return state.ObjectiveCoacheeStore
          .objectivesCoachee
          .filter(objective => objective.coacheeId == id);
      },
      getObjectivesCoacheeByNeed: (id: string, need: NeedsCoachee) => {
        return state.ObjectiveCoacheeStore
          .objectivesCoachee
          .filter(objective => objective.coacheeId == id)
          .filter(objective => objective.needs.includes(need));
      },
      getCompletedSubObjectivesCoacheesCount: (objectiveId: string) => {
        return state.ObjectiveCoacheeStore
          .objectivesCoachee
          .find(objective => objective.id == objectiveId)
          ?.subObjectives
          .filter(subObjective => subObjective.completed).length || 0;
      },
      getStatsForNeed: (need: NeedsCoachee) => {
        return state.ObjectiveCoacheeStore.needsStats[need.toLowerCase()];
      },
      getObjectiveStateHistory: () => {
        return state.ObjectiveCoacheeStore.objectiveStateHistory
      }
    })
  },
  reducers: {
    setObjectiveCoachee(
      state,
      objectiveCoachee: ObjectiveCoachee
    ) {
      return {
        ...state,
        objectivesCoachee: ReducerServices.setItemToList<ObjectiveCoachee>(
          state.objectivesCoachee,
          objectiveCoachee)
      };
    },
    setObjectivesCoachee(
      state,
      objectivesCoachee: ObjectiveCoachee[]
    ) {
      return {
        ...state,
        objectivesCoachee: ReducerServices.setItemsToList<ObjectiveCoachee>(
          state.objectivesCoachee,
          objectivesCoachee)
      };
    },
    removeObjectiveById(
      state,
      objectiveId: string
    ) {
      return {
        ...state,
        objectivesCoachee: ReducerServices.removeItemFromList<ObjectiveCoachee>(
          state.objectivesCoachee,
          objectiveId)
      };
    },
    setNeedStats(
      state,
      need: NeedsCoachee,
      needStats: NeedStats
    ) {
      return {
        ...state,
        needsStats: CoacheeNeedsStats.setCoacheeNeedsStatsFromNeed(state.needsStats, need, needStats)
      };
    },
    setObjectiveStateHistory(
      state,
      objectiveStateHistory: ObjectiveState[]
    ) {
      return {
        ...state,
        objectiveStateHistory: ReducerServices.setItemsToList<ObjectiveState>(
          state.objectiveStateHistory,
          objectiveStateHistory)
      }
    },
    setObjectiveStateHistoryToEmpty(state) {
      return {
        ...state,
        objectiveStateHistory: []
      };
    },
    addCommentToObjectiveStateFoundById(
      state,
      objectiveStateId: string,
      objectiveStateComment: ObjectiveStateComment
    ) {
      return {
        ...state,
        ObjectiveStateHistory : ObjectiveReducers.addCommentToObjectiveStateFoundById(state, objectiveStateId, objectiveStateComment)
      }
    }
  },
  effects: (dispatch) => ({
    async fetchObjectivesByCoacheeId(payload: {
      id: string,
      need?: NeedsCoachee
    }) {
      await clients.ObjectiveCoachee
        .fetchObjectivesByCoacheeId(payload.id, payload.need)
        .then((objectivesCoachee: ObjectiveCoachee[]) => {
          dispatch.ObjectiveCoacheeStore.setObjectivesCoachee(
            objectivesCoachee);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async addObjectiveForCoachee(payload: {
      addObjective: AddObjective,
      coacheeId: string
    }) {
      return await clients.ObjectiveCoachee
        .addObjectiveForCoachee(payload.addObjective, payload.coacheeId)
        .then((addedObjective) => {
          dispatch.ObjectiveCoacheeStore.setObjectiveCoachee(addedObjective);
          dispatch.ObjectiveCoacheeStore.setObjectiveStateHistory([]);
          return addedObjective;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async updateObjectiveForCoachee(payload: {
      updateObjective: UpdateObjective,
      objectiveId: string,
      coacheeId: string
    }) {
      return await clients.ObjectiveCoachee
        .updateObjectiveForCoachee(payload.updateObjective, payload.objectiveId, payload.coacheeId)
        .then((updatedObjective) => {
          dispatch.ObjectiveCoacheeStore.setObjectiveCoachee(updatedObjective);
          dispatch.ObjectiveCoacheeStore.fetchNeedsStats({coacheeId: payload.coacheeId});
          
          return updatedObjective;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteObjectiveForCoachee(payload: {
      objectiveId: string,
      coacheeId: string
    }) {
      return await clients.ObjectiveCoachee
        .deleteObjectiveForCoachee(payload.objectiveId, payload.coacheeId)
        .then(() => {
          dispatch.ObjectiveCoacheeStore.removeObjectiveById(payload.objectiveId);
          dispatch.ObjectiveCoacheeStore.fetchNeedsStats({coacheeId: payload.coacheeId});
          dispatch.ObjectiveCoacheeStore.setObjectiveStateHistoryToEmpty();
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async addSubObjectiveForCoachee(payload: {
      addSubObjective: AddSubObjective,
      objectiveId: string,
      coacheeId: string
    }) {
      return await clients.ObjectiveCoachee
        .addSubObjectiveForCoachee(payload.addSubObjective, payload.objectiveId, payload.coacheeId)
        .then((addedSubObjective) => {
          dispatch.ObjectiveCoacheeStore.fetchObjectivesByCoacheeId({id: payload.coacheeId});
          dispatch.ObjectiveCoacheeStore.fetchNeedsStats({coacheeId: payload.coacheeId});
          
          return addedSubObjective;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async updateSubObjectiveForCoachee(payload: {
      updateSubObjective: UpdateSubObjective,
      subObjectiveId: string,
      objectiveId: string,
      coacheeId: string
    }) {
      return await clients.ObjectiveCoachee
        .updateSubObjectiveForCoachee(payload.updateSubObjective, payload.subObjectiveId, payload.objectiveId, payload.coacheeId)
        .then((updatedSubObjective) => {
          dispatch.ObjectiveCoacheeStore.fetchObjectivesByCoacheeId({id: payload.coacheeId});
          dispatch.ObjectiveCoacheeStore.fetchNeedsStats({coacheeId: payload.coacheeId});
          
          return updatedSubObjective;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteSubObjectiveForCoachee(payload: {
      subObjectiveId: string,
      objectiveId: string,
      coacheeId: string
    }) {
      return await clients.ObjectiveCoachee
        .deleteSubObjectiveForCoachee(payload.subObjectiveId, payload.objectiveId, payload.coacheeId)
        .then(() => {
          dispatch.ObjectiveCoacheeStore.fetchObjectivesByCoacheeId({id: payload.coacheeId});
          dispatch.ObjectiveCoacheeStore.fetchNeedsStats({coacheeId: payload.coacheeId});
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async fetchNeedStats(payload: {
      coacheeId: string,
      need: NeedsCoachee
    }) {
      return await clients.ObjectiveCoachee
        .fetchObjectiveNeedsStatsByNeed(payload.coacheeId, payload.need)
        .then((needStats) => {
          dispatch.ObjectiveCoacheeStore.setNeedStats(payload.need, needStats);
          
          return needStats;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async fetchNeedsStats(payload: {
      coacheeId: string
    }) {
      Object.values(NeedsCoachee).forEach(
        need => dispatch.ObjectiveCoacheeStore.fetchNeedStats({
          coacheeId: payload.coacheeId,
          need
        }));
    },
    async addObjectiveState(payload: {
      addObjectiveState: AddObjectiveState,
      coacheeId: string,
      objectiveId: string,
    }) {
      return await clients.ObjectiveCoachee.addObjectiveStateForCoachee(payload.addObjectiveState, payload.coacheeId, payload.objectiveId)
        .then((createdObjectiveState) => {
          dispatch.ObjectiveCoacheeStore.fetchObjectivesByCoacheeId({id: payload.coacheeId});
          dispatch.ObjectiveCoacheeStore.fetchObjectiveStateHistory({coacheeId: payload.coacheeId, objectiveId: payload.objectiveId});
          return createdObjectiveState;
      }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async updateObjectiveStateComment(payload: {
      updateObjectiveStateComment: UpdateObjectiveStateComment,
      coacheeId: string,
      objectiveId: string,
    }) {
      return await clients.ObjectiveCoachee.updateObjectiveStateCommentForCoachee(payload.updateObjectiveStateComment, payload.coacheeId, payload.objectiveId)
        .then((updatedObjectiveStateComment) => {
          dispatch.ObjectiveCoacheeStore.fetchObjectivesByCoacheeId({id: payload.coacheeId});
          return updatedObjectiveStateComment;
      }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async fetchObjectiveStateHistory(payload: {
      coacheeId: string,
      objectiveId: string
    }) {
      return await clients.ObjectiveCoachee.fetchObjectiveStateHistory(payload.coacheeId, payload.objectiveId)
        .then((objectiveStateHistory: ObjectiveState[] ) => {
        dispatch.ObjectiveCoacheeStore.setObjectiveStateHistory(objectiveStateHistory);
        return objectiveStateHistory;
      }).catch((error: AppError) => {
        throw error.name;
      });
    },
    async addObjectiveStateComment(payload: {
      addObjectiveStateComment: AddObjectiveStateComment,
      coacheeId: string,
      objectiveId: string,
      objectiveStateId: string,
    }) {
      return await clients.ObjectiveCoachee.addObjectiveStateCommentForCoachee(payload.addObjectiveStateComment, payload.coacheeId, payload.objectiveId, payload.objectiveStateId)
        .then((createdObjectiveStateComment) => {
          dispatch.ObjectiveCoacheeStore.addCommentToObjectiveStateFoundById(payload.objectiveStateId, createdObjectiveStateComment)
          return createdObjectiveStateComment;
        }).catch((error: AppError) => {
          throw error.name;
        });
    }
  })
});
