import {MuiColors} from "style/props/MuiColors";

enum CustomMuiTypographyColors {
  PRIMARY_DARK = "text.primary.dark",
  SECONDARY_DARK = "text.secondary.dark",
  SECONDARY_VERY_DARK = "text.secondary.veryDark"
}

export const MuiTypographyColors = {...MuiColors, ...CustomMuiTypographyColors};

export type MuiTypographyColors = MuiColors | CustomMuiTypographyColors;
