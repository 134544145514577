import React from 'react';
import styles from "asset/component/asset/global/FeedbackCatcher.module.scss";
import FeedbackBubble from "asset/icon/FeedbackBubble";
import {useTranslation} from "react-i18next";
import {FEEDBACK_URL_EN, FEEDBACK_URL_FR} from "config";

const FeedbackCatcher = () => {
  const {t, i18n} = useTranslation("common");
  
  const giveFeedback = () => {
    const url = i18n.language === "fr" ? FEEDBACK_URL_FR : FEEDBACK_URL_EN;
    window.open(url, '_blank');
  };
  
  return (
    <div className={styles.container} onClick={() => giveFeedback()} data-testid="feedback-catcher-container">
      <FeedbackBubble />
      {t('giveFeedback')}
    </div>
  );
};

export default FeedbackCatcher;
