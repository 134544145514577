import AddObjectiveMandate from "module/objective/domain/model/AddObjectiveMandate";

export default interface AddObjectiveMandateRequest {
  name: string;
}

export function createAddObjectiveMandateRequest(addObjectiveMandate: AddObjectiveMandate): AddObjectiveMandateRequest {
  return {
    name: addObjectiveMandate.name,
  } as AddObjectiveMandateRequest;
}
