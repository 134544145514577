import React from 'react';
import styles from "module/mandate/view/asset/listing/MandateComponent.module.scss";
import MandatesTopBar from "module/mandate/view/component/listing/MandatesTopBar";

const MandatesComponent = ({
  toggleFinished,
  displayFinished,
  onSearch,
  children
}: {
  toggleFinished: () => void,
  displayFinished: boolean,
  onSearch: (value: string) => void,
  children?: JSX.Element
}) => {
  
  return (
    <div className={styles.componentContainer}>
      <MandatesTopBar toggleFinished={toggleFinished} displayFinished={displayFinished} onSearch={onSearch} />
      {children}
    </div>
  );
};

export default MandatesComponent;
