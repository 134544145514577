import * as React from 'react';
import {useRef} from 'react';
import {Alert, Container, LoadingButton} from "asset/component";
import {useTranslation} from "react-i18next";
import {TextValidator, useFormValidation, ValidatorForm} from "asset/validator";
import styles from 'module/authentication/view/asset/LoginFormComponent.module.scss';
import {InputValidator} from "asset/validator/InputValidator";
import {ArrowIcon} from "asset/component/Icons";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import MuiButtonSizes from "style/props/MuiButtonSizes";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import {ErrorNames} from "core/error/ErrorNames";
import ResetPasswordDialog from "asset/component/auth/ResetPasswordDialog";

export interface LoginFormProps {
  formState: {
    email: string,
    setEmail: (email: string) => void,
    password: string,
    setPassword: (email: string) => void,
  },
  login: {
    dispatch: () => void,
    errorName?: ErrorNames,
    isLoading?: boolean
  },
  resetPassword: {
    dispatch: () => void,
    errorName?: ErrorNames,
    isLoading?: boolean
    isCompleted?: boolean
  }
}

export default function LoginFormComponent({
  formState,
  login,
  resetPassword
}: LoginFormProps) {
  const {t} = useTranslation('authentication');
  const {getErrorMessage} = useErrorTranslation('authentication');
  const formRef = useRef();
  const {isFormValid} = useFormValidation(formRef, [formState.email, formState.password]);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    
    login.dispatch();
  };
  
  return (
    <Container id={styles.loginFormContainer}>
      <ValidatorForm id={styles.loginForm}
                     ref={formRef}
                     data-testid="login-form"
                     onSubmit={handleSubmit}>
        {login.errorName &&
            <Alert severity="error" data-testid="login-error-message">
              {getErrorMessage(login.errorName)}
            </Alert>}
        <TextValidator
          required
          fullWidth
          data-testid="email-input"
          id="email"
          placeholder={t('form.email')}
          name="email"
          autoComplete="email"
          value={formState.email}
          onChange={(event) => formState.setEmail(event.target.value)}
          validators={[InputValidator.REQUIRED, InputValidator.IS_EMAIL]}
          errorMessages={[t('form.validation.emailRequired'), t('form.validation.emailIsValid')]}
          autoFocus
        />
        <TextValidator
          required
          fullWidth
          name="password"
          placeholder={t('form.password')}
          type="password"
          id="password"
          data-testid="password-input"
          value={formState.password}
          onChange={(event) => formState.setPassword(event.target.value)}
          validators={[InputValidator.REQUIRED]}
          errorMessages={[t('form.validation.passwordRequired')]}
          autoComplete="current-password"
        />
        <div className={styles.resetPassword}>
          <ResetPasswordDialog resetAction={resetPassword.dispatch}
                               errorName={resetPassword.errorName}
                               isLoading={resetPassword.isLoading}
                               isCompleted={resetPassword.isCompleted}
          />
        </div>
        <LoadingButton
          data-testid="login-button"
          variant={MuiButtonVariants.CONTAINED}
          endIcon={<ArrowIcon />}
          size={MuiButtonSizes.LARGE}
          loading={login.isLoading}
          disabled={login.isLoading || !isFormValid}
          type="submit"
        >
          {t('form.button')}
        </LoadingButton>
      </ValidatorForm>
    </Container>
  );
}
