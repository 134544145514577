import {useState, useEffect} from 'react';

interface AutoSaveOptions {
  initialValue?: string;
  saveAction: (content: string) => void;
  delay?: number;
}

const useAutoSave = ({initialValue, saveAction, delay = 3000}: AutoSaveOptions) => {
  const [value, setValue] = useState<string>(initialValue || "");
  const [timeoutId, setTimeoutId] = useState<number | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  
  useEffect(() => {
    initialValue !== undefined && setValue(initialValue);
  }, [initialValue]);
  
  const handleDelayedSave = (content: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    
    setIsSaving(true);
    setIsError(false);
    
    const newTimeoutId = window.setTimeout(() => {
      try {
        saveAction(content);
      } catch (e) {
        setIsError(true);
      }
      setIsSaving(false);
    }, delay);
    
    setTimeoutId(newTimeoutId);
  };
  
  const handleChange = (content: string) => {
    setValue(content);
    handleDelayedSave(content);
  };
  
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);
  
  return {
    value,
    isSaving,
    isError,
    onChange: handleChange,
  };
};

export default useAutoSave;
