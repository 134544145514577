import FollowUp from "module/followUp/domain/model/FollowUp";

export default class FollowUpTeam extends FollowUp {
  private readonly _teamId: string;
  
  constructor(
    id: string,
    text: string,
    createdAt: string,
    completed: boolean,
    teamId: string,
  ) {
    super(id, text, createdAt, completed);
    this._teamId = teamId;
  }
  
  get teamId(): string {
    return this._teamId;
  }
}
