import {useEffect, useState} from 'react';

export interface ComponentProps {
  id: string;
  navigateOnClick: () => void;
}

interface SlideProps extends ComponentProps {
  isClone: boolean;
}

export enum DIRECTION {
  left = 'left',
  right = 'right',
  middle = 'middle'
}

const useNavigationSlider = (
  componentsProps: ComponentProps[],
  selectedId: string,
  renderComponent: (slideProps: SlideProps, horizontalLevel: number) => JSX.Element
)
  : [JSX.Element[], () => void, () => void, (
  slideProps: SlideProps,
  direction: DIRECTION,
  horizontalLevel: number
) => void, boolean] => {
  const [sliderState, setSliderState] = useState({active: 0, direction: ''});
  
  const [slidesProps, setSlidesProps] = useState<SlideProps[]>([]);
  const [slidesComponent, setSlidesComponent] = useState<JSX.Element[]>([]);
  
  const [displayClone, setDisplayClone] = useState<boolean>(false);
  const [hasFasterTransition, setHasFasterTransition] = useState<boolean>(false);
  
  useEffect(() => {
    generateSlidesProps()
      .then(generatedSlidesProps =>
        selectActiveIndexFromId(generatedSlidesProps)
          .then(activeIndex =>
            generateSlidesComponent(generatedSlidesProps, activeIndex)
          )
      );
  }, [selectedId, componentsProps]);
  
  const generateSlidesProps: () => Promise<SlideProps[]> = async () => {
    return new Promise(resolve => {
      const MIN_LENGTH_TO_SET_CLONES = 1;
      const MAX_LENGTH_TO_SET_CLONES = 7;
      const generateSlidesProps: SlideProps[] = [];
      
      componentsProps.forEach(childProps => {
        generateSlidesProps.push({...childProps, isClone: false});
      });
      
      if (componentsProps.length < MAX_LENGTH_TO_SET_CLONES && componentsProps.length > MIN_LENGTH_TO_SET_CLONES) {
        setClones(generateSlidesProps);
      }
      
      setSlidesProps(generateSlidesProps);
      
      resolve(generateSlidesProps);
    });
  };
  
  const selectActiveIndexFromId: (generatedSlidesProps) => Promise<number> = (generatedSlidesProps) => {
    return new Promise(resolve => {
      let indexSelected = sliderState.active;
      
      generatedSlidesProps.forEach((slideProps, index) => {
        if (slideProps.id === selectedId && slideProps.isClone === displayClone) {
          indexSelected = index;
          
          setSliderState({
            ...sliderState,
            active: index,
          });
        }
      });
      
      resolve(indexSelected);
    });
  };
  
  const generateSlidesComponent = (slides, activeIndex) => {
    const slideCount = slides.length;
    
    let generatedSlides: JSX.Element[] = [];
    
    const MIN_INDEX = -2;
    const MAX_INDEX = 3;
    const MAX_SLIDES = 5;
    const HAS_MINIMAL_SLIDES = slideCount >= MAX_SLIDES;
    
    const start = HAS_MINIMAL_SLIDES ? activeIndex + MIN_INDEX : activeIndex - Math.round((slideCount / 2));
    const end = HAS_MINIMAL_SLIDES ? activeIndex + MAX_INDEX : activeIndex + Math.round((slideCount / 2));
    
    for (let i = start; i < end; i++) {
      const index = getIndex(i, slideCount);
      const horizontalLevel = activeIndex - i;
      
      generatedSlides.push(renderComponent(slides[index], horizontalLevel));
    }
    
    setSlidesComponent(generatedSlides);
  };
  
  const getIndex = (counter, slideCount) => {
    let index = counter;
    if (counter < 0) {
      index = slideCount + counter;
    } else if (counter >= slideCount) {
      index = counter % slideCount;
    }
    
    return index;
  };
  
  const setClones = (generateSlidesProps) => {
    componentsProps.forEach(childProps => {
      generateSlidesProps.push({...childProps, isClone: true});
    });
  };
  
  const onSliderClick = (slideProps, direction, horizontalLevel) => {
    if (!slideProps.isSelected) {
      const steps = Math.abs(horizontalLevel);
      
      setDisplayClone(slideProps.isClone);
      
      if (direction === DIRECTION.left) {
        moveLeft(steps);
      } else {
        moveRight(steps);
      }
    }
  };
  
  const isOutOfIndex = (moveCount) => {
    return sliderState.active - moveCount < 0;
  };
  
  const isMultiStep = (moveCount) => {
    return moveCount >= 2;
  };
  
  const isSecondIndex = () => {
    return sliderState.active === 1;
  };
  
  const getLastIndex = () => {
    return slidesProps.length - 1;
  };
  
  function getLastIndexAfterMoves(moveCount) {
    return slidesProps.length - moveCount;
  }
  
  const normalizeIndex = (moveCount) => {
    return isMultiStep(moveCount) && isSecondIndex() ?
      getLastIndex() :
      getLastIndexAfterMoves(moveCount);
  };
  
  const getPreviousIndexFromMoveCount = (moveCount) => {
    return sliderState.active - moveCount;
  };
  
  const getPreviousIndex = (moveCount) => {
    return isOutOfIndex(moveCount) ?
      normalizeIndex(moveCount) :
      getPreviousIndexFromMoveCount(moveCount);
  };
  
  const getNextIndex = (moveCount) => {
    return (sliderState.active + moveCount) % slidesProps.length;
  };
  
  const moveLeft = (moveCount = 1) => {
    moveSlider(getPreviousIndex(moveCount), moveCount, DIRECTION.left);
  };
  
  const moveRight = (moveCount = 1) => {
    moveSlider(getNextIndex(moveCount), moveCount, DIRECTION.right);
  };
  
  const moveSlider = (index, moveCount, direction) => {
    setSliderState({
      ...sliderState,
      active: index,
      direction: direction,
    });
    
    setHasFasterTransition(moveCount > 1);
    setDisplayClone(slidesProps[index].isClone);
    slidesProps[index].navigateOnClick();
  };
  
  return [slidesComponent, moveLeft, moveRight, onSliderClick, hasFasterTransition];
};

export default useNavigationSlider;
