import * as React from "react";
import {useEffect, useState} from "react";
import SubscriptionComponent from "module/user/view/component/SubscriptionComponent";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store";
import usePaddle from "module/paddle/view/usePaddle";

const SubscriptionContainer = () => {
  const dispatch = useDispatch();
  const {initialize, checkoutMonthly, checkoutYearly} = usePaddle();
  const currentUser = useSelector(
    (rootState: RootState) => rootState.UserStore?.getters(rootState)
      .getCurrentUser());
  
  const subscription = useSelector(
    (rootState: RootState) => rootState.SubscriptionStore?.getters(rootState)
      .getSubscription());
  
  useEffect(() => {
    currentUser && initialize(currentUser.email, dispatch.SubscriptionStore.fetchSubscription);
  }, [currentUser]);
  
  return currentUser && subscription ?
    <SubscriptionComponent
      checkoutMonthly={() => checkoutMonthly(subscription.workspaceId)}
      checkoutYearly={() => checkoutYearly(subscription.workspaceId)}
      status={subscription.status}
      ownerEmail={subscription.ownerEmail}
      endOfTrialPeriod={subscription.endOfTrialPeriod}
      redirectToCancelSubscription={dispatch.SubscriptionStore.redirectToCancelUrlSubscription}
      cancellationEffectiveDate={subscription.cancellationEffectiveDate}
    /> : <></>;
};

export default SubscriptionContainer;
