import {StateStatusColor} from "module/objective/domain/model/StateStatusColor";
import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";

const ObjectiveStateHistoryMiddleTrace = ({
  statusColor,
  index,
  selectObjectiveState,
  objectiveStateId,
  className
}: {
  statusColor: StateStatusColor
  index: number,
  selectObjectiveState?: (id: string) => void,
  objectiveStateId: string,
  className?: string
}) => {
  
  const cellStyles = {
    backgroundColor: statusColor
  }
  
  const classes = selectObjectiveState ? `${styles.tableCell} ${styles.tableCellClickable} ${className}` : `${styles.tableCell} ${className}`;
  
  const onClick = selectObjectiveState ? () => selectObjectiveState(objectiveStateId) : undefined;
  
  return <div className={classes}
              key={index}
              style={cellStyles}
              onClick={onClick}
  />
};

export default ObjectiveStateHistoryMiddleTrace;

