import React from 'react';
import styles from 'asset/component/asset/module/ModuleSectionTitle.module.scss';
import {Typography} from "asset/component/index";

const ModuleSectionTitle = ({title, noMargin}: { title: string, noMargin?: boolean }) => {
  return <Typography className={`${styles.title} ${noMargin && 'noMargin'}`}
                     component="h5"
                     variant="xs">{title}</Typography>;
};

export default ModuleSectionTitle;
