import SubObjective from "./SubObjective";
import {StateStatus} from "module/objective/domain/model/StateStatus";

export default class Objective {
  private readonly _id: string;
  private readonly _name: string;
  private readonly _text: string;
  private readonly _createdAt: Date;
  private readonly _state: StateStatus;
  private readonly _subObjectives: SubObjective[];
  
  constructor(id: string, name: string, text: string, createdAt: string, state: StateStatus, subObjectives: SubObjective[]) {
    this._id = id;
    this._name = name;
    this._text = text;
    this._createdAt = new Date(createdAt);
    this._state = state
    this._subObjectives = subObjectives;
  }
  
  get id(): string {
    return this._id;
  }
  
  get name(): string {
    return this._name;
  }
  
  get text(): string {
    return this._text;
  }
  
  get createdAt(): Date {
    return this._createdAt;
  }
  
  get state(): StateStatus {
    return this._state;
  }
  
  get subObjectives(): SubObjective[] {
    return this._subObjectives;
  }
}
