import React from 'react';
import style from "asset/component/asset/global/buttons/CardButton.module.scss";
import {Typography} from "asset/component/index";
import {MuiSizes} from "style/props/MuiSizes";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";

export enum CardButtonSelectedColors {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

const CardButton = ({
  text,
  image,
  isFilled = false,
  size = MuiSizes.SMALL,
  textColor,
  backgroundColor,
  textVariant = MuiTypographyVariants.SM,
  onClick,
  isSelected,
  selectedColor = CardButtonSelectedColors.PRIMARY,
  overlayClass,
  id
}: {
  text: string,
  image: string,
  size?: MuiSizes.LARGE | MuiSizes.SMALL | MuiSizes.FLEX,
  isFilled?: boolean,
  textColor?: string,
  backgroundColor?: string,
  textVariant?: MuiTypographyVariants,
  onClick?: () => void,
  isSelected?: boolean,
  selectedColor?: CardButtonSelectedColors,
  overlayClass?: string,
  id?: string,
}) => {
  const filledClassName = isFilled ? style.filled : '';
  const overlay = overlayClass && <div className={overlayClass}/>
  
  return (
    <div onClick={onClick}
         id={id}
         data-testid="card-button"
         className={`${filledClassName}
            ${style.cardButtonContainer}
            ${size}
            ${isSelected && style.isSelected}
            ${selectedColor}
            ${backgroundColor}`}>
      <div className={style.imageContainer}>
        <img className={style.image} src={image} alt={text} />
      </div>
      <Typography className={style.text} component="span" variant={textVariant} color={textColor} align={"center"}>
        {text}
      </Typography>
      {overlay}
    </div>
  );
};

export default CardButton;
