import React, {useState} from "react";
import AddCompanyFormComponent, {AddCompanyStateProps} from "module/company/view/component/AddCompanyFormComponent";
import {useDispatch} from "react-redux";
import {Dispatch} from "store";
import {useNavigate} from "react-router-dom";
import {Routes} from "router/Routes";
import AddCompany from "module/company/domain/model/AddCompany";
import BodySectionLayout from "asset/component/layout/BodySectionLayout";
import AddSomething from "asset/component/form/AddSomething";
import FormHeader from "asset/component/form/FormHeader";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {Accesses} from "module/authorization/domain/model/accesses";
import HadAccess from "module/authorization/HadAccess";

const AddCompanyFormContainer = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  
  const [name, setName] = useState("");
  
  const addCompanyState = {
    name,
    setName,
  } as AddCompanyStateProps;
  
  const [addCompanyLoading, addCompanyErrorName, addCompanyDispatch] =
    usePromiseLoadingAndError((isCompleted, text) =>
      dispatch.CompanyStore.addCompany(
        new AddCompany(
          name,
        )).then((addedCompany) => {
        addedCompany && navigate(Routes.home.path());
      })
    );
  
  return (<>
    <HadAccess accesses={[Accesses.COACHEES_MANAGEMENT]}>
      <BodySectionLayout>
        <FormHeader />
        <AddSomething />
        <AddCompanyFormComponent addCompany={addCompanyDispatch}
                                 addCompanyState={addCompanyState}
                                 isLoading={addCompanyLoading}
                                 errorName={addCompanyErrorName} />
      </BodySectionLayout>
    </HadAccess>
  </>);
};

export default AddCompanyFormContainer;
