import React from 'react';
import FormSection from "asset/component/form/FormSection";
import {Typography} from "asset/component";
import {useTranslation} from "react-i18next";
import usePaletteColors from "style/usePaletteColors";
import useNavigationFormSections from "asset/component/form/hook/useNavigationFormSections";
import styles from "module/mandate/view/asset/add/AddMandateConfirmationComponent.module.scss";
import AddMandateConfirmationButtons from "module/mandate/view/component/add/AddMandateConfirmationButtons";

const AddMandateConfirmationComponent = ({
  anchorId,
  useNavigation,
  previousSectionFormId
}: {
  anchorId: string,
  useNavigation: typeof useNavigationFormSections.arguments,
  previousSectionFormId: string
}) => {
  const {t} = useTranslation('mandate');
  const palette = usePaletteColors();
  
  return (
    <div>
      <FormSection anchorId={anchorId}
                   isActivated={useNavigation.isSectionActivated(anchorId)}>
        <div className={styles.text}>
          <Typography component="h4" variant="h4" color={palette.primary.veryLight} className={styles.title}>
            {t('form.add.confirmation.title')}
          </Typography>
          <Typography component="h4" variant="h4" color={palette.primary.veryLight}>
            {t('form.add.confirmation.subtitle')}
          </Typography>
        </div>
        <AddMandateConfirmationButtons previousSectionFormId={previousSectionFormId} />
      </FormSection>
    </div>
  );
};

export default AddMandateConfirmationComponent;
