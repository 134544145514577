import {StateStatusColor} from "module/objective/domain/model/StateStatusColor";
import {StateStatus} from "module/objective/domain/model/StateStatus";
import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";
import StatusIcon from "module/objective/view/component/StatusIcon";
import {MuiSizes} from "style/props/MuiSizes";
import ChatBubble from "asset/icon/ChatBubble";

const ObjectiveStateHistoryMiddleTraceChatBubble = ({
  statusColor,
  index,
  selectObjectiveState,
  objectiveStateId,
  className
}: {
  statusColor: StateStatusColor
  index: number,
  selectObjectiveState?: (id: string) => void,
  objectiveStateId: string,
  className?: string
}) => {
  
  const cellStyles = {
    backgroundColor: statusColor
  }
  
  const classes = selectObjectiveState ? `${styles.tableCell} ${styles.tableCellClickable} ${className}` : `${styles.tableCell} ${className}`;
  
  const onClick = selectObjectiveState ? () => selectObjectiveState(objectiveStateId) : undefined;
  
  return <div key={index} style={cellStyles}
              className={classes}
              onClick={onClick}
  >
      <div className={styles.tableCellChatBubble}>
        <ChatBubble width={12} height={12} />
      </div>
    </div>
};

export default ObjectiveStateHistoryMiddleTraceChatBubble;

