import type RESTClient from "core/client/RESTClient";
import TeamMotivatorRepository from "module/motivator/team/domain/TeamMotivatorRepository";
import AddTeamMotivator from "module/motivator/team/domain/model/AddTeamMotivator";
import TeamMotivatorResponse, {
  teamMotivatorResponseToModel,
  teamMotivatorsResponseToModel
} from "module/motivator/team/dal/dto/TeamMotivatorResponse";
import TeamMotivator from "module/motivator/team/domain/model/TeamMotivator";
import {createAddTeamMotivatorRequest} from "module/motivator/team/dal/dto/AddTeamMotivatorRequest";

export default class RESTTeamMotivatorRepository implements TeamMotivatorRepository {
  private static readonly BASE_URL: string = "api";
  private static readonly TEAM_URL: string = "teams";
  private static readonly MOTIVATOR_URL: string = "motivators";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchMotivatorsByTeamId(id: string): Promise<TeamMotivator[]> {
    const motivatorsResponse = await this.client.apiGet<TeamMotivatorResponse[]>(
      `/${RESTTeamMotivatorRepository.BASE_URL}` +
      `/${RESTTeamMotivatorRepository.TEAM_URL}/${id}` +
      `/${RESTTeamMotivatorRepository.MOTIVATOR_URL}`
    );
    
    return teamMotivatorsResponseToModel(motivatorsResponse, id);
  }
  
  public async addMotivatorForTeam(addMotivator: AddTeamMotivator, teamId: string): Promise<TeamMotivator> {
    const motivatorResponse = await this.client.apiPost<TeamMotivatorResponse>(
      `/${RESTTeamMotivatorRepository.BASE_URL}` +
      `/${RESTTeamMotivatorRepository.TEAM_URL}/${teamId}` +
      `/${RESTTeamMotivatorRepository.MOTIVATOR_URL}`,
      createAddTeamMotivatorRequest(addMotivator)
    );
    
    return teamMotivatorResponseToModel(motivatorResponse, teamId);
  }
  
  public async deleteMotivatorForTeam(motivatorId: string, teamId: string): Promise<void> {
    await this.client.apiDelete<TeamMotivatorResponse>(
      `/${RESTTeamMotivatorRepository.BASE_URL}` +
      `/${RESTTeamMotivatorRepository.TEAM_URL}/${teamId}` +
      `/${RESTTeamMotivatorRepository.MOTIVATOR_URL}/${motivatorId}`
    );
  }
}
