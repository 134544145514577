import Mandate from "module/mandate/domain/model/Mandate";

export default interface MandateResponse {
  id: string;
  title: string;
  description: string;
  startDate: string;
  deadline: string;
  companies: string[];
  teams: string[];
  coachees: string[];
}

export function mandateResponseToModel(mandateResponse: MandateResponse): Mandate {
  return new Mandate(
    mandateResponse.id,
    mandateResponse.title,
    mandateResponse.description,
    new Date(mandateResponse.startDate),
    new Date(mandateResponse.deadline),
    mandateResponse.companies,
    mandateResponse.teams,
    mandateResponse.coachees
  );
}

export function mandatesResponseToModel(mandateResponse: MandateResponse[]): Mandate[] {
  return mandateResponse.map(mandateResponse => mandateResponseToModel(mandateResponse));
}
