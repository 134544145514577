import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import "moment/locale/fr";

import commonFrench from 'translation/common/fr.json';
import commonEnglish from 'translation/common/en.json';
import userFrench from 'module/user/view/translation/fr.json';
import userEnglish from 'module/user/view/translation/en.json';
import homeFrench from 'module/home/view/translation/fr.json';
import homeEnglish from 'module/home/view/translation/en.json';
import navbarFrench from 'module/common/navbar/translation/fr.json';
import navbarEnglish from 'module/common/navbar/translation/en.json';
import authenticationFrench from 'module/authentication/view/translation/fr.json';
import authenticationEnglish from 'module/authentication/view/translation/en.json';
import coacheeEnglish from 'module/coachee/view/translation/en.json';
import coacheeFrench from 'module/coachee/view/translation/fr.json';
import teamEnglish from 'module/team/view/translation/en.json';
import teamFrench from 'module/team/view/translation/fr.json';
import companyEnglish from 'module/company/view/translation/en.json';
import companyFrench from 'module/company/view/translation/fr.json';
import objectiveEnglish from 'module/objective/view/translation/en.json';
import objectiveFrench from 'module/objective/view/translation/fr.json';
import followUpEnglish from 'module/followUp/view/translation/en.json';
import followUpFrench from 'module/followUp/view/translation/fr.json';
import needEnglish from 'module/need/view/translation/en.json';
import needFrench from 'module/need/view/translation/fr.json';
import motivatorEnglish from 'module/motivator/coachee/view/translation/en.json';
import motivatorFrench from 'module/motivator/coachee/view/translation/fr.json';
import mandateEnglish from 'module/mandate/view/translation/en.json';
import mandateFrench from 'module/mandate/view/translation/fr.json';
import skillEnglish from 'module/skill/view/translation/en.json';
import skillFrench from 'module/skill/view/translation/fr.json';
import cultureEnglish from 'module/culture/view/translation/en.json';
import cultureFrench from 'module/culture/view/translation/fr.json';
import dashboardNavigationEnglish from 'module/dashboardNavigation/view/translation/en.json';
import dashboardNavigationFrench from 'module/dashboardNavigation/view/translation/fr.json';
import OnboardingEnglish from 'module/onboarding/view/translation/en.json';
import OnboardingFrench from 'module/onboarding/view/translation/fr.json';

import LanguageManager from 'core/helper/LanguageManager';

const resources = {
  fr: {
    common: commonFrench,
    user: userFrench,
    home: homeFrench,
    navbar: navbarFrench,
    authentication: authenticationFrench,
    coachee: coacheeFrench,
    team: teamFrench,
    company: companyFrench,
    objective: objectiveFrench,
    followUp: followUpFrench,
    need: needFrench,
    motivator: motivatorFrench,
    mandate: mandateFrench,
    skill: skillFrench,
    culture: cultureFrench,
    dashboardNavigation: dashboardNavigationFrench,
    onboarding: OnboardingFrench
  },
  en: {
    common: commonEnglish,
    user: userEnglish,
    home: homeEnglish,
    navbar: navbarEnglish,
    authentication: authenticationEnglish,
    coachee: coacheeEnglish,
    team: teamEnglish,
    company: companyEnglish,
    objective: objectiveEnglish,
    followUp: followUpEnglish,
    need: needEnglish,
    motivator: motivatorEnglish,
    mandate: mandateEnglish,
    skill: skillEnglish,
    culture: cultureEnglish,
    dashboardNavigation: dashboardNavigationEnglish,
    onboarding: OnboardingEnglish
  },
};

i18n.use(initReactI18next).init({
  ns: ['common'],
  defaultNS: 'shared',
  resources,
  lng: 'fr',
  fallbackLng: ['en', 'fr'],
  fallbackNS: 'common'
}).then(() => LanguageManager.initLanguage());

export default i18n;
