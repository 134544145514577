import * as React from "react";
import {SubscriptionStatus} from "module/subscription/domain/model/SubscriptionStatus";
import SubscriptionCardTrial from "asset/component/subscription/SubscriptionCardTrial";
import SubscriptionCardVIP from "asset/component/subscription/SubscriptionCardVIP";
import SubscriptionCardActive from "asset/component/subscription/SubscriptionCardActive";
import SubscriptionCardInactive from "asset/component/subscription/SubscriptionCardInactive";

const SubscriptionCard = ({
  status,
  ownerEmail,
  endOfTrialPeriod,
  cancellationEffectiveDate,
  redirectToCancelSubscription,
  updateSubscriptionUrl,
  checkoutMonthly,
  checkoutYearly
}: {
  status: SubscriptionStatus,
  ownerEmail: string,
  endOfTrialPeriod?: Date,
  cancellationEffectiveDate?: Date
  redirectToCancelSubscription?: () => void,
  updateSubscriptionUrl?: string,
  checkoutMonthly?: () => void,
  checkoutYearly?: () => void
}) => {
  
  const cancelAction = () => {
    redirectToCancelSubscription?.();
  };
  
  const updateAction = () => {
    if (updateSubscriptionUrl) {
      window.location.href = updateSubscriptionUrl;
    }
  };
  
  const getSubscriptionCard = () => {
    switch (status) {
      case SubscriptionStatus.TRIAL: {
        return <SubscriptionCardTrial
          endOfTrialPeriod={endOfTrialPeriod}
          checkoutMonthly={checkoutMonthly}
          checkoutYearly={checkoutYearly}
        />;
      }
      case SubscriptionStatus.VIP: {
        return <SubscriptionCardVIP />;
      }
      case SubscriptionStatus.ACTIVE: {
        return <SubscriptionCardActive
          ownerEmail={ownerEmail}
          updateAction={updateAction}
          cancelAction={cancelAction}
        />;
      }
      case SubscriptionStatus.INACTIVE: {
        return <SubscriptionCardInactive
          cancellationEffectiveDate={cancellationEffectiveDate}
          checkoutMonthly={checkoutMonthly}
          checkoutYearly={checkoutYearly}
        />;
      }
      default: {
        return <SubscriptionCardVIP />;
      }
    }
  };
  
  return getSubscriptionCard();
};

export default SubscriptionCard;
