import React from "react";
import CoacheeNeed from "module/need/view/coachee/component/CoacheeNeedTag";
import styles from "module/need/view/asset/coachee/CoacheeNeedComponent.module.scss";
import {NeedsCoachee} from "module/objective/domain/model/need/NeedsCoachee";

const CoacheeNeedsComponent = ({
  onNeedClick,
  coacheeId,
}: {
  onNeedClick: (needsCoachee: NeedsCoachee) => void,
  coacheeId: string,
}) => {
  return (
    <div className={styles.coacheeNeedsComponent}>
      <div className={styles.firstRow}>
        <CoacheeNeed onClick={onNeedClick}
                     needsCoachee={NeedsCoachee.COMPETENCE}
                     coacheeId={coacheeId} />
        <CoacheeNeed onClick={onNeedClick}
                     needsCoachee={NeedsCoachee.AUTONOMY}
                     coacheeId={coacheeId} />
      </div>
      <div className={styles.secondRow}>
        <CoacheeNeed onClick={onNeedClick}
                     needsCoachee={NeedsCoachee.SOCIAL}
                     coacheeId={coacheeId} />
      </div>
    </div>
  );
};

export default CoacheeNeedsComponent;
