import * as React from "react";
import SubscriptionCard from "asset/component/subscription/SubscriptionCard";
import {SubscriptionStatus} from "module/subscription/domain/model/SubscriptionStatus";

const SubscriptionComponent = ({
  status,
  ownerEmail,
  endOfTrialPeriod,
  cancellationEffectiveDate,
  redirectToCancelSubscription,
  updateSubscriptionUrl,
  checkoutMonthly,
  checkoutYearly
}: {
  status: SubscriptionStatus,
  ownerEmail: string,
  endOfTrialPeriod?: Date,
  cancellationEffectiveDate?: Date
  redirectToCancelSubscription?: () => string,
  updateSubscriptionUrl?: string,
  checkoutMonthly?: () => void
  checkoutYearly?: () => void
}) => {
  return <SubscriptionCard status={status}
                           ownerEmail={ownerEmail}
                           endOfTrialPeriod={endOfTrialPeriod}
                           redirectToCancelSubscription={redirectToCancelSubscription}
                           updateSubscriptionUrl={updateSubscriptionUrl}
                           cancellationEffectiveDate={cancellationEffectiveDate}
                           checkoutMonthly={checkoutMonthly}
                           checkoutYearly={checkoutYearly}
  />;
};

export default SubscriptionComponent;
