import RESTClient from "core/client/RESTClient";
import LinkableEntityRepository from "module/mandate/domain/LinkableEntityRepository";
import ILinkableEntityResponse, {
  linkableEntitiesResponseToModel
} from "module/mandate/dal/dto/LinkableEntityResponse";
import LinkableEntity from "module/mandate/domain/model/LinkableEntity";
import UpdateLinkableEntity from "module/mandate/domain/model/UpdateLinkableEntity";
import {createUpdateLinkableEntityRequest} from "module/mandate/dal/dto/UpdateLinkableEntityRequest";
import {linkedEntitiesResponseToModel} from "module/mandate/dal/dto/LinkedEntityResponse";
import LinkedEntity from "module/mandate/domain/model/LinkedEntity";

export default class RESTLinkableEntityRepository implements LinkableEntityRepository {
  private static readonly BASE_URL: string = "api";
  private static readonly MANDATES_URL: string = "mandates";
  private static readonly LINKABLE_ENTITIES_URL: string = "linkableEntities";
  private static readonly LINKED_ENTITIES_URL: string = "linkedEntities";
  private static readonly LINK: string = "link";
  private static readonly UNLINK: string = "unlink";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchLinkableEntitiesForMandate(mandateId: string): Promise<LinkableEntity[]> {
    const linkableEntitiesResponse = await this.client.apiGet<ILinkableEntityResponse[]>(
      `/${RESTLinkableEntityRepository.BASE_URL}` +
      `/${RESTLinkableEntityRepository.MANDATES_URL}` +
      `/${mandateId}` +
      `/${RESTLinkableEntityRepository.LINKABLE_ENTITIES_URL}`
    );
    
    return linkableEntitiesResponseToModel(linkableEntitiesResponse);
  }
  
  public async fetchLinkedEntitiesForMandate(mandateId: string): Promise<LinkedEntity[]> {
    const linkableEntitiesResponse = await this.client.apiGet<ILinkableEntityResponse[]>(
      `/${RESTLinkableEntityRepository.BASE_URL}` +
      `/${RESTLinkableEntityRepository.MANDATES_URL}` +
      `/${mandateId}` +
      `/${RESTLinkableEntityRepository.LINKED_ENTITIES_URL}`
    );
    
    return linkedEntitiesResponseToModel(linkableEntitiesResponse, mandateId);
  }
  
  public async addLinkableEntity(entity: UpdateLinkableEntity, mandateId: string): Promise<LinkedEntity[]> {
    const linkableEntityResponse = await this.client.apiPut<ILinkableEntityResponse[]>(
      `/${RESTLinkableEntityRepository.BASE_URL}` +
      `/${RESTLinkableEntityRepository.MANDATES_URL}` +
      `/${mandateId}` +
      `/${RESTLinkableEntityRepository.LINK}`,
      createUpdateLinkableEntityRequest(entity)
    );
    
    return linkedEntitiesResponseToModel(linkableEntityResponse, mandateId);
  }
  
  public async removeLinkableEntity(entity: UpdateLinkableEntity, mandateId: string): Promise<LinkedEntity[]> {
    const linkableEntityResponse = await this.client.apiPut<ILinkableEntityResponse[]>(
      `/${RESTLinkableEntityRepository.BASE_URL}` +
      `/${RESTLinkableEntityRepository.MANDATES_URL}` +
      `/${mandateId}` +
      `/${RESTLinkableEntityRepository.UNLINK}`,
      createUpdateLinkableEntityRequest(entity)
    );
    
    return linkedEntitiesResponseToModel(linkableEntityResponse, mandateId);
  }
}
