import React from 'react';
import {IconButton} from "asset/component/index";
import {AddIcon} from "asset/component/Icons";
import usePaletteColors from "style/usePaletteColors";
import {IconButtonProps} from "asset/component/global/buttons/IconButton";

const ActionButton = ({
  onClick,
  iconButtonProps,
  id
}: {
  onClick?: () => void,
  iconButtonProps?: IconButtonProps,
  id?: string
}) => {
  const palette = usePaletteColors();
  
  return <IconButton
    id={id}
    aria-label="close"
    className={`action-button`}
    iconColor={palette.primary.veryLight}
    backgroundColor={palette.secondary.dark}
    hoverBackgroundColor={palette.secondary.main}
    buttonSize="40px"
    iconSize="24px"
    onClick={onClick}
    icon={<AddIcon />}
    {...iconButtonProps}
  />;
};

export default ActionButton;
