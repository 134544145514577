import * as React from "react";
import HeaderComponent from "module/common/layout/component/HeaderComponent";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store";
import {useAuthenticationState} from "module/authentication/hook/useAuthenticationState";

const HeaderContainer = () => {
  const dispatch = useDispatch();
  const [user, userLoading] = useAuthenticationState();
  
  const currentUser = useSelector(
    (rootState: RootState) => rootState.UserStore?.getters(rootState)
      .getCurrentUser());
  
  useLoadingAndError(() => dispatch.UserStore.fetchCurrentUser(), [user !== null]);
  
  return <HeaderComponent data-testid="header-component" userName={currentUser?.firstName} />;
};

export default HeaderContainer;
