import React, {useEffect} from 'react';
import useNavigationFormSections from "asset/component/form/hook/useNavigationFormSections";
import FormSection from "asset/component/form/FormSection";
import {Typography} from "asset/component";
import {useTranslation} from "react-i18next";
import usePaletteColors from "style/usePaletteColors";
import SearchAutoCompleteCategories, {InputCategoryOptions} from "asset/component/search/SearchAutoCompleteCategories";
import styles from "module/mandate/view/asset/add/AddLinkedEntitiesFormComponent.module.scss";
import LinkedEntity from "module/mandate/domain/model/LinkedEntity";
import ModuleListing from "asset/component/module/ModuleListing";
import LinkableEntity, {LinkableEntityType} from "module/mandate/domain/model/LinkableEntity";
import Plaque, {PlaqueTheme} from "asset/component/global/Plaque";
import {CloseIcon, CoacheeIcon} from "asset/component/Icons";
import TeamLinkedEntityContainer from "module/mandate/view/container/edit/TeamLinkedEntityContainer";
import {ErrorNames} from "core/error/ErrorNames";
import {
  convertLinkableEntitiesToOptionsAndTags
} from "module/mandate/view/container/LinkableEntitiesMethods";
import FormNavigationButtons from "asset/component/form/FormNavigationButtons";

const AddLinkedEntitiesFormComponent = ({
  linkableEntities,
  linkedEntities,
  anchorId,
  useNavigation,
  addLinkedEntity,
  removeLinkedEntity,
  isLoading,
  errorName,
  nextSectionFormId,
  previousSectionFormId
}: {
  linkableEntities: LinkableEntity[],
  linkedEntities: LinkedEntity[],
  anchorId: string,
  useNavigation: typeof useNavigationFormSections.arguments,
  addLinkedEntity: (id: string) => void,
  removeLinkedEntity: (name: string) => void,
  isLoading?: boolean,
  errorName?: ErrorNames,
  nextSectionFormId: string,
  previousSectionFormId: string
}) => {
  const {t} = useTranslation('mandate');
  const palette = usePaletteColors();
  
  const [linkableEntitiesOptions, setLinkableEntitiesOptions] = React.useState<InputCategoryOptions[]>([]);
  const [searchValue, setSearchValue] = React.useState<InputCategoryOptions>();
  
  useEffect(() => {
    const {options} = convertLinkableEntitiesToOptionsAndTags(linkableEntities, t);
    
    setLinkableEntitiesOptions(options);
  }, [linkableEntities, linkedEntities, t]);
  
  const resetSearch = (id: string) => {
    addLinkedEntity(id);
    setSearchValue(undefined);
  };
  
  const getPlaque = (entity: LinkedEntity) => {
    switch (entity.type) {
      case LinkableEntityType.COACHEE:
        return <Plaque id={entity.id}
                       data-testid={`${'plaque-' + entity.id}`}
                       key={entity.id}
                       label={entity.name}
                       icon={<CoacheeIcon />}
                       actionIcon={<CloseIcon />}
                       onClick={removeLinkedEntity}
                       isEditMode={true}
                       theme={PlaqueTheme.LIGHT} />;
      case LinkableEntityType.TEAM:
        return <TeamLinkedEntityContainer isEditMode={true}
                                          entity={entity}
                                          action={removeLinkedEntity} />;
      default:
        return <></>;
    }
  };
  
  return (
    <FormSection anchorId={anchorId}
                 isActivated={useNavigation.isSectionActivated(anchorId)}>
      <div data-testid="texts">
        <Typography component="h3" variant="h3" color={palette.primary.veryLight} marginBottom={"10px"}>
          {t('form.add.linkedEntities.title1')}
        </Typography>
        <Typography component="h3" variant="h3" color={palette.primary.veryLight}>
          {t('form.add.linkedEntities.title2')}
        </Typography>
      </div>
      <Typography component="p" variant="sm" color={palette.primary.veryLight}>
        {t('form.add.linkedEntities.subtitle')}
      </Typography>
      <div className={styles.formContainer}>
        <SearchAutoCompleteCategories
          options={linkableEntitiesOptions}
          placeholder={t('form.edit.search.placeholder')}
          noOptionsText={t('form.edit.search.noResults')}
          value={searchValue}
          onChange={(id: string) => resetSearch(id)} />
        <ModuleListing elements={linkedEntities}
                       isLoading={isLoading}
                       errorName={errorName}
                       injectedStyle={styles}>
          {
            (element: LinkedEntity) => getPlaque(element)
          }
        </ModuleListing>
        <FormNavigationButtons nextAnchor={nextSectionFormId}
                               previousAnchor={previousSectionFormId}
                               activateSection={useNavigation.activateSection}
                               nextIsSubmit={true}
                               skipButtonText={t("form.buttons.skip")} />
      </div>
    </FormSection>
  );
};

export default AddLinkedEntitiesFormComponent;
