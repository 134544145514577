import * as React from "react";
import {Button} from "@mui/material";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import MuiButtonSizes from "style/props/MuiButtonSizes";
import {CheckIcon, QueryBuilderIcon, ErrorIcon} from "asset/component/Icons";
import {MuiButtonColors} from "style/props/MuiButtonColors";
import styles from "asset/component/asset/subscription/SubscriptionStatus.module.scss";
import {useTranslation} from "react-i18next";
import {SubscriptionStatus} from "module/subscription/domain/model/SubscriptionStatus";

const SubscriptionStatusTag = ({status}: { status: SubscriptionStatus }) => {
  const {t} = useTranslation("common");
  
  const subscriptionButton = (color, startIcon, text, variant = MuiButtonVariants.OUTLINED) => {
    return <Button className={`${styles.subscriptionStatus} disabled-effect lowerCase`}
                   variant={variant}
                   size={MuiButtonSizes.LARGE}
                   color={color}
                   startIcon={startIcon}>
      {text}
    </Button>;
  };
  
  const getStatusButton = () => {
    switch (status) {
      case SubscriptionStatus.TRIAL: {
        return subscriptionButton(MuiButtonColors.PRIMARY_DARK, <QueryBuilderIcon />, t('subscription.status.trial'));
      }
      case SubscriptionStatus.ACTIVE: {
        return subscriptionButton(MuiButtonColors.SECONDARY, <CheckIcon />, t('subscription.status.active'));
      }
      case SubscriptionStatus.VIP: {
        return subscriptionButton(MuiButtonColors.SECONDARY, <CheckIcon />, t('subscription.status.VIP'));
      }
      case SubscriptionStatus.INACTIVE: {
        return subscriptionButton('error',
          <ErrorIcon />, t('subscription.status.inactive'), MuiButtonVariants.CONTAINED);
      }
      default: {
        return subscriptionButton(MuiButtonColors.PRIMARY_DARK, <QueryBuilderIcon />, t('subscription.status.trial'));
      }
    }
  };
  
  return getStatusButton();
};

export default SubscriptionStatusTag;
