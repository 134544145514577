import React from 'react';
import styles from "asset/component/asset/search/SearchBar.module.scss";
import {InputBase} from "@mui/material";
import SearchIcon from "asset/icon/SearchIcon";

export enum SearchBarTheme {
  WHITE = 'white',
  OUTLINE = 'outline',
}

export enum SearchBarSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

const SearchBar = ({
  onSearch,
  theme = SearchBarTheme.WHITE,
  size = SearchBarSize.MEDIUM,
  placeholder = ""
}: {
  onSearch: (value: string) => void,
  theme?: SearchBarTheme,
  size?: SearchBarSize,
  placeholder?: string
}) => {
  return (
    <div className={`${theme} ${size} ${styles.container}`}>
      <SearchIcon />
      <InputBase
        className={styles.input}
        placeholder={placeholder}
        inputProps={{'aria-label': 'search'}}
        onChange={(event) => onSearch(event.target.value)}
      />
    </div>
  );
};

export default SearchBar;
