import React from 'react';
import styles from 'asset/component/asset/module/ModuleSubHeader.module.scss';
import {IconButton, Typography} from "asset/component/index";
import {KeyboardBackspaceIcon} from "asset/component/Icons";
import usePaletteColors from "style/usePaletteColors";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";

const ModuleSubHeader = ({title, subtitle, onClose}: {
  title?: string,
  subtitle?: string | JSX.Element,
  onClose?: () => void
}) => {
  const palette = usePaletteColors();
  
  return <div className={styles.moduleCardContainer}>
    <IconButton
      className={styles.backIcon}
      data-testid="close-icon"
      onClick={onClose}
      iconColor={palette.secondary.veryDark}
      backgroundColor="transparent"
      buttonSize="50px"
      iconSize="30px"
      icon={<KeyboardBackspaceIcon />}
    />
    
    <div className={styles.titleContainer}>
      {title && <Typography textTransform="initial"
                            color={palette.secondary.dark}
                            className={styles.title}
                            variant={MuiTypographyVariants.H4}>{title}</Typography>}
      {subtitle && <Typography color={palette.secondary.dark}
                               className={styles.subTitle}
                               component="span"
                               variant={MuiTypographyVariants.XS}>{subtitle}</Typography>}
    </div>
  </div>;
};

export default ModuleSubHeader;
