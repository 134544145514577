import InformalRole from "module/skill/domain/model/InformalRole";

export default interface InformalRoleResponse {
  informalRole: string;
}

export function informalRoleResponseToModel(informalRoleResponse: InformalRoleResponse, coacheeId: string): InformalRole {
  return new InformalRole(
    informalRoleResponse.informalRole,
    coacheeId,
  );
}
