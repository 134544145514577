import React from 'react';
import styles from "module/mandate/view/asset/listing/FinishedTag.module.scss";
import {useTranslation} from "react-i18next";
import Checkmark from "asset/icon/Checkmark";

const FinishedTag = () => {
  const {t} = useTranslation('mandate');
  
  return (
    <div className={styles.container}>
      <Checkmark />
      {t('finished')}
    </div>
  );
};

export default FinishedTag;
