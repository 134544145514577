import React, {useEffect} from "react";
import {Alert} from "asset/component";
import {useFormValidation, ValidatorForm} from "asset/validator";
import {useTranslation} from "react-i18next";
import useNavigationFormSections from "asset/component/form/hook/useNavigationFormSections";
import AddCoacheeNamesFormComponent from "module/coachee/view/component/addCoachee/AddCoacheeNamesFormComponent";
import AddCoacheeOrganisationFormComponent
  from "module/coachee/view/component/addCoachee/AddCoacheeOrganisationFormComponent";
import AddCoacheeTeamFormComponent from "module/coachee/view/component/addCoachee/AddCoacheeTeamFormComponent";
import {InputOption} from "asset/component/form/inputs/InputSelect";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import {ErrorNames} from "core/error/ErrorNames";
import SubmitFormSection from "asset/component/form/SubmitFormSection";

export interface AddCoacheeStateProps {
  firstName: string;
  setFirstName: ((firstName: string) => void);
  lastName: string;
  setLastName: ((lastName: string) => void);
  teamName: string;
  setTeamName: ((teamName: string) => void);
  teamId: string;
  setTeamId: ((teamId: string) => void);
  companyName: string;
  setCompanyName: ((companyName: string) => void);
  companyId: string;
  setCompanyId: ((companyId: string) => void);
}

export interface AddCoacheeFormComponentProps {
  addCoacheeState: AddCoacheeStateProps;
  addCoachee: Function;
  isLoading: boolean;
  errorName?: ErrorNames;
  companyOptions: InputOption[];
  teamOptions: InputOption[];
}

const AddCoacheeFormComponent = ({
  addCoachee,
  isLoading,
  errorName,
  companyOptions,
  teamOptions,
  addCoacheeState
}: AddCoacheeFormComponentProps) => {
  const {t} = useTranslation('coachee');
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    setTeamName,
    setTeamId,
    setCompanyName,
    setCompanyId
  } = addCoacheeState;
  const useNavigation = useNavigationFormSections();
  const {getErrorMessage} = useErrorTranslation();
  const nameFormSectionId = 'name-section';
  const organisationFormSectionId = 'organisation-section';
  const teamFormSectionId = 'team-section';
  const submitFormSectionId = 'submit-section';
  
  const formNameRef = React.useRef(null);
  const {isFormValid: isNameValid, submitForm: submitName} = useFormValidation(formNameRef, firstName, lastName);
  const formCompanyRef = React.useRef(null);
  const {isFormValid: isCompanyValid, submitForm: submitCompany} = useFormValidation(formCompanyRef, setCompanyName, setCompanyId);
  
  const addCoacheeSubmit = (event) => {
    event.preventDefault();
    
    useNavigation.resetSections();
    
    if (!isNameValid) {
      submitName();
      useNavigation.activateSection(nameFormSectionId);
    } else if (!isCompanyValid) {
      submitCompany();
      useNavigation.activateSection(organisationFormSectionId);
    } else {
      addCoachee();
    }
  };
  
  useEffect(() => {
    useNavigation.activateSection(nameFormSectionId);
  }, []);
  
  return <div>
    {errorName && <Alert severity="error"
                         data-testid="add-coachee-error-message">{errorName && getErrorMessage(errorName)}</Alert>}
    
    <ValidatorForm data-testid="add-coachee-form" onSubmit={addCoacheeSubmit}>
      <AddCoacheeNamesFormComponent nameFormSectionId={nameFormSectionId}
                                    nextFormSectionId={organisationFormSectionId}
                                    useNavigation={useNavigation}
                                    firstName={firstName}
                                    setFirstName={setFirstName}
                                    lastName={lastName}
                                    setLastName={setLastName}
                                    formRef={formNameRef}
                                    submitForm={submitName}
                                    isValid={isNameValid}
      />
      <AddCoacheeOrganisationFormComponent data-testid="company-form"
                                           organisationFormSectionId={organisationFormSectionId}
                                           previousFormSectionId={nameFormSectionId}
                                           nextFormSectionId={teamFormSectionId}
                                           useNavigation={useNavigation}
                                           companyOptions={companyOptions}
                                           setCompanyName={setCompanyName}
                                           setCompanyId={setCompanyId}
                                           submitForm={submitCompany}
                                           formRef={formCompanyRef}
                                           isValid={isCompanyValid}
      />
      <AddCoacheeTeamFormComponent data-testid="team-form"
                                   teamFormSectionId={teamFormSectionId}
                                   previousFormSectionId={organisationFormSectionId}
                                   nextFormSectionId={submitFormSectionId}
                                   useNavigation={useNavigation}
                                   teamOptions={teamOptions}
                                   setTeamName={setTeamName}
                                   setTeamId={setTeamId}
      />
      
      <SubmitFormSection submitFormSectionId={submitFormSectionId}
                         previousFormSectionId={teamFormSectionId}
                         useNavigation={useNavigation}
                         text={`${t('form.add.submitTitle')} ${firstName} ${lastName}`}
                         isLoading={isLoading}
                         submitText={t('form.add.submit')}
      />
    </ValidatorForm>
  </div>;
};

export default AddCoacheeFormComponent;
