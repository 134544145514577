import i18n from "i18next";
import defaultIcon from "asset/image/role/default.svg";
import changeAgentIcon from "asset/image/role/changeAgent.svg";
import enthusiastIcon from "asset/image/role/enthusiast.svg";
import allyIcon from "asset/image/role/ally.svg";
import disengagedIcon from "asset/image/role/disengaged.svg";
import neutralZoneIcon from "asset/image/role/neutralZone.svg";
import negotiatorIcon from "asset/image/role/negotiator.svg";
import challengerIcon from "asset/image/role/challenger.svg";
import negativeLeaderIcon from "asset/image/role/negativeLeader.svg";
import refractoryIcon from "asset/image/role/refractory.svg";
import resignedIcon from "asset/image/role/resigned.svg";
import saboteurIcon from "asset/image/role/saboteur.svg";
import septicIcon from "asset/image/role/septic.svg";

const PREDEFINED_SKILL_TRANSLATION_KEY = "skill:predefinedInformalRoles";

export const PredefinedInformalRoles = [
  {id: "DEFAULT", icon: defaultIcon},
  {id: "CHANGE_AGENT", icon: changeAgentIcon},
  {id: "ENTHUSIAST", icon: enthusiastIcon},
  {id: "ALLY", icon: allyIcon},
  {id: "DISENGAGED", icon: disengagedIcon},
  {id: "NEUTRAL_ZONE", icon: neutralZoneIcon},
  {id: "NEGOTIATOR", icon: negotiatorIcon},
  {id: "CHALLENGER", icon: challengerIcon},
  {id: "NEGATIVE_LEADER", icon: negativeLeaderIcon},
  {id: "REFRACTORY", icon: refractoryIcon},
  {id: "RESIGNED", icon: resignedIcon},
  {id: "SABOTEUR", icon: saboteurIcon},
  {id: "SEPTIC", icon: septicIcon},
];

const getTranslationInformalRole = (predefinedInformalRole) => {
  return i18n.t(`${PREDEFINED_SKILL_TRANSLATION_KEY}.${predefinedInformalRole}`);
};

export const getPredefinedInformalRoles = () => {
  return PredefinedInformalRoles.map(predefinedInformalRole => ({
    value: predefinedInformalRole.id,
    label: getTranslationInformalRole(predefinedInformalRole.id),
    icon: predefinedInformalRole.icon
  }));
};
