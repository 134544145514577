import UpdateTeam from "module/team/domain/model/UpdateTeam";

export default interface UpdateTeamRequest {
  name: string;
  companyId: string;
  note: string;
}

export function createUpdateTeamRequest(updateTeam: UpdateTeam): UpdateTeamRequest {
  return {
    name: updateTeam.name,
    companyId: updateTeam.companyId,
    note: updateTeam.note,
  } as UpdateTeamRequest;
}
