import OnboardingSequence from "module/onboarding/domain/model/OnboardingSequence";
import OnboardingStep from "module/onboarding/domain/model/OnboardingStep";
import {ArrowPositions} from "asset/component/global/dialogs/Tooltip";
import {OnboardingSequenceNames} from "module/onboarding/domain/model/SeedData/OnboardingSequenceNames";

const CoacheeSequence = new OnboardingSequence(
  [
    new OnboardingStep({
      name: "addObjective",
      sequenceName: OnboardingSequenceNames.COACHEE,
      anchorID: "coachee-add-objective-anchor",
      arrowPosition: ArrowPositions.TOP.MIDDLE,
      adjustments: {x: -168, y: 10}
    }),
    new OnboardingStep({
      name: "roleDropdown",
      sequenceName: OnboardingSequenceNames.COACHEE,
      anchorID: "coachee-role-dropdown-anchor",
      arrowPosition: ArrowPositions.LEFT.MIDDLE,
      adjustments: {x: 30, y: 100}
    }),
    new OnboardingStep({
      name: "infoNeeds",
      sequenceName: OnboardingSequenceNames.COACHEE,
      anchorID: "coachee-info-needs-anchor",
      arrowPosition: ArrowPositions.TOP.RIGHT,
      adjustments: {x: 36.5, y: 17}
    }),
    new OnboardingStep({
      name: "carousel",
      sequenceName: OnboardingSequenceNames.COACHEE,
      anchorID: "coachee-carousel-anchor",
      arrowPosition: ArrowPositions.TOP.MIDDLE,
      adjustments: {x: 0, y: -175}
    }),
  ]
);

export default CoacheeSequence;
