import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import User from "module/user/domain/model/User";
import {RootState} from "store";
import AppError from "core/error/AppError";
import UpdateUser from "module/user/domain/model/UpdateUser";

export const UserStore = createModel<RootModel>()({
  state: {
    currentUser: undefined as User | undefined,
    getters: (state: RootState) => ({
      getCurrentUser: () => {
        return state.UserStore.currentUser;
      }
    })
  },
  reducers: {
    setCurrentUser(state, user: User) {
      return {
        ...state,
        currentUser: user
      };
    }
  },
  effects: (dispatch) => ({
    async fetchCurrentUser() {
      await clients.User.fetchCurrentUser().then((user: User) => {
        dispatch.UserStore.setCurrentUser(user);
      }).catch((error: AppError) => {
        throw error.name;
      });
    },
    async updateUser(updateUser: UpdateUser) {
      return await clients.User
        .updateCurrentUser(updateUser)
        .then((updatedUser) => {
          dispatch.UserStore.setCurrentUser(updatedUser);
          
          return updatedUser;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
  })
});
