import React from "react";
import {HasAccess as HasAccessPermify} from "@permify/react-role";
import {Accesses} from "module/authorization/domain/model/accesses";
import {Roles} from "module/authorization/domain/model/roles";
import {CircularProgress} from "@mui/material";

const HadAccess = ({
  children,
  roles,
  accesses
}: {
  children: JSX.Element | JSX.Element[]
  roles?: Roles[],
  accesses: Accesses[]
}) => {
  return <HasAccessPermify
    roles={roles}
    permissions={accesses}
    isLoading={<CircularProgress />}
  >
    <>{children}</>
  </HasAccessPermify>;
};

export default HadAccess;