import type RESTClient from "core/client/RESTClient";
import CompanyRepository from "module/company/domain/CompanyRepository";
import CompanyResponse, {
  companiesResponseToModel,
  companyResponseToModel
} from "module/company/dal/dto/CompanyResponse";
import Company from "module/company/domain/model/Company";
import {createAddCompanyRequest} from "module/company/dal/dto/AddCompanyRequest";
import AddCompany from "module/company/domain/model/AddCompany";
import UpdateCompany from "module/company/domain/model/UpdateCompany";
import {createUpdateCompanyRequest} from "module/company/dal/dto/UpdateCompanyRequest";

export default class RESTCompanyRepository implements CompanyRepository {
  private static readonly BASE_URL: string = "/api/companies";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchCompanies(): Promise<Company[]> {
    const companiesResponse = await this.client.apiGet<CompanyResponse[]>(
      `${RESTCompanyRepository.BASE_URL}`
    );
    
    return companiesResponseToModel(companiesResponse);
  }
  
  public async fetchCompanyById(id: string): Promise<Company> {
    const companyResponse = await this.client.apiGet<CompanyResponse>(
      `${RESTCompanyRepository.BASE_URL}/${id}`
    );
    
    return companyResponseToModel(companyResponse);
  }
  
  public async addCompany(addCompany: AddCompany): Promise<Company> {
    const companyResponse = await this.client.apiPost<CompanyResponse>(
      `${RESTCompanyRepository.BASE_URL}`,
      createAddCompanyRequest(addCompany)
    );
    
    return companyResponseToModel(companyResponse);
  }
  
  public async updateCompany(updateCompany: UpdateCompany): Promise<Company> {
    const companyResponse = await this.client.apiPut<CompanyResponse>(
      `${RESTCompanyRepository.BASE_URL}` +
      `/${updateCompany.id}`,
      createUpdateCompanyRequest(updateCompany)
    );
    
    return companyResponseToModel(companyResponse);
  }
  
  public async deleteCompany(companyId: string): Promise<void> {
    await this.client.apiDelete<void>(
      `${RESTCompanyRepository.BASE_URL}` +
      `/${companyId}`
    );
  }
}
