import NeedStats from "module/objective/domain/model/need/NeedStats";
import {NeedsTeam} from "module/objective/domain/model/need/NeedsTeam";

export default class TeamNeedsStats {
  private _result: NeedStats;
  private _responsibility: NeedStats;
  private _engagement: NeedStats;
  private _confrontation: NeedStats;
  private _trust: NeedStats;
  
  constructor() {
    this._result = new NeedStats(0, 0);
    this._responsibility = new NeedStats(0, 0);
    this._engagement = new NeedStats(0, 0);
    this._confrontation = new NeedStats(0, 0);
    this._trust = new NeedStats(0, 0);
  }
  
  get result(): NeedStats {
    return this._result;
  }
  
  get responsibility(): NeedStats {
    return this._responsibility;
  }
  
  get engagement(): NeedStats {
    return this._engagement;
  }
  
  get confrontation(): NeedStats {
    return this._confrontation;
  }
  
  get trust(): NeedStats {
    return this._trust;
  }
  
  set result(value: NeedStats) {
    this._result = value;
  }
  
  set responsibility(value: NeedStats) {
    this._responsibility = value;
  }
  
  set engagement(value: NeedStats) {
    this._engagement = value;
  }
  
  set confrontation(value: NeedStats) {
    this._confrontation = value;
  }
  
  set trust(value: NeedStats) {
    this._trust = value;
  }
  
  public static setTeamNeedsStatsFromNeed(
    teamNeedsStats: TeamNeedsStats,
    need: NeedsTeam,
    needStats: NeedStats
  ): TeamNeedsStats {
    switch (need) {
      case NeedsTeam.RESULT:
        teamNeedsStats.result = needStats;
        break;
      case NeedsTeam.RESPONSIBILITY:
        teamNeedsStats.responsibility = needStats;
        break;
      case NeedsTeam.ENGAGEMENT:
        teamNeedsStats.engagement = needStats;
        break;
      case NeedsTeam.CONFRONTATION:
        teamNeedsStats.confrontation = needStats;
        break;
      case NeedsTeam.TRUST:
        teamNeedsStats.trust = needStats;
        break;
    }
    
    return teamNeedsStats;
  }
}
