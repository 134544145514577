export default class NeedStats {
  private readonly _objectiveCount: number;
  private readonly _completedObjectiveCount: number;
  
  constructor(objectiveCount: number, completedObjectiveCount: number) {
    this._objectiveCount = objectiveCount;
    this._completedObjectiveCount = completedObjectiveCount;
  }
  
  get objectiveCount(): number {
    return this._objectiveCount;
  }
  
  get completedObjectiveCount(): number {
    return this._completedObjectiveCount;
  }
}
