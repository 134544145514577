import LinkableEntity, {LinkableEntityType} from "module/mandate/domain/model/LinkableEntity";

export default interface ILinkableEntityResponse {
  objectId: string;
  name: string;
  type: LinkableEntityType;
}

export function linkableEntityResponseToModel(
  linkableEntityResponse: ILinkableEntityResponse,
): LinkableEntity {
  return new LinkableEntity(
    linkableEntityResponse.objectId,
    linkableEntityResponse.objectId,
    linkableEntityResponse.name,
    linkableEntityResponse.type,
  );
}

export function linkableEntitiesResponseToModel(
  linkableEntitiesResponse: ILinkableEntityResponse[]
): LinkableEntity[] {
  return linkableEntitiesResponse.map(linkableEntity => linkableEntityResponseToModel(linkableEntity));
}
