import React from 'react';
import styles from 'asset/component/asset/global/typography/TitleRecord.module.scss';
import {Typography} from "asset/component/index";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import usePaletteColors from "style/usePaletteColors";

const TitleRecord = ({title}) => {
  const palette = usePaletteColors();
  
  return (
    <div className={styles.titleContainer}>
      <Typography className={styles.title} variant={MuiTypographyVariants.H2} color={palette.primary.main}>
        {title}
      </Typography>
    </div>
  );
};

export default TitleRecord;
