import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";
import ChatBubble from "asset/icon/ChatBubble";
import {Typography} from "@mui/material";
import React from "react";
import ObjectiveStateComment from "module/objective/domain/model/ObjectiveStateComment";
import {useTranslation} from "react-i18next";

const ObjectiveStateHistoryComment = ({
  comment
} : {
  comment: ObjectiveStateComment
}) => {
  
  const {t} = useTranslation("objective");
  
  const formatDate = (date: Date): string => {
    const datePart = date.toLocaleDateString(t("stateHistoryModal.commentDateFormat"), {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
    const timePart = date.toLocaleTimeString(t("stateHistoryModal.commentDateFormat"), {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${datePart} ${t("stateHistoryModal.commentDateHour")} ${timePart}`;
  };
  
  return <div className={styles.commentContainer}>
    <div className={styles.chatBubble}>
      <ChatBubble/>
    </div>
    <div className={styles.individualComment}>
      <Typography className={styles.comment}>{comment.comment}</Typography>
      <Typography>{formatDate(new Date(comment.createdAt))}</Typography>
    </div>
  </div>
}

export default ObjectiveStateHistoryComment
