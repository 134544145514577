import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import AddObjective from "module/objective/domain/model/AddObjective";
import {ModuleListing} from "asset/component";
import {useTranslation} from "react-i18next";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import TeamObjectiveContainer from "module/objective/view/container/team/TeamObjectiveContainer";
import ObjectiveTeam from "module/objective/domain/model/ObjectiveTeam";
import ObjectivesListingComponentStyles from "module/objective/view/asset/ObjectivesListingComponent.module.scss";
import {NeedsTeam} from "module/objective/domain/model/need/NeedsTeam";
import ModuleSubHeader from "asset/component/module/ModuleSubHeader";
import styles from "module/objective/view/asset/ObjectivesContainer.module.scss";
import InputEnter from "asset/component/form/inputs/InputEnter";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";

const TeamObjectivesNeedContainer = ({teamId, onClose, need}: {
  teamId: string,
  need: NeedsTeam
  onClose?: () => void
}) => {
  const {t} = useTranslation('need');
  const dispatch = useDispatch<Dispatch>();
  const {getErrorMessage} = useErrorTranslation();
  
  const teamObjectives = useSelector((rootState: RootState) =>
    rootState.ObjectiveTeamStore?.getters(rootState).getObjectivesTeamByNeed(teamId, need));
  
  const fetchObjectivesByTeamIdState =
    useLoadingAndError(() =>
      dispatch.ObjectiveTeamStore.fetchObjectivesByTeamId({id: teamId, need: need}), [], teamId);
  
  const [isAddObjectiveLoading, addObjectiveErrorName, dispatchAddObjective] =
    usePromiseLoadingAndError((name: string) =>
      dispatch.ObjectiveTeamStore.addObjectiveForTeam({
        addObjective: new AddObjective(name, [need]),
        teamId,
      })
    );
  
  return (
    <>
      <ModuleSubHeader title={t(`teamNeeds.${need}`)} subtitle={t('moduleSubHeader')} onClose={onClose} />
      <ModuleListing<ObjectiveTeam>
        data-testid="listing-objective"
        elements={teamObjectives}
        injectedStyle={ObjectivesListingComponentStyles}
        isAddElementLoading={isAddObjectiveLoading}
        isLoading={fetchObjectivesByTeamIdState.isLoading}
        errorName={fetchObjectivesByTeamIdState.errorName || addObjectiveErrorName}
      >
        {
          (objective: ObjectiveTeam) => <TeamObjectiveContainer key={objective.id} objectiveTeam={objective} />
        }
      </ModuleListing>
      <div className={styles.input}>
        <InputEnter data-testid="add-objective"
                    placeholder={t('addObjective')}
                    errorMessage={addObjectiveErrorName && getErrorMessage(addObjectiveErrorName)}
                    setValue={(value) => dispatchAddObjective(value)}
        />
      </div>
    </>
  );
};

export default TeamObjectivesNeedContainer;
