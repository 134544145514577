export default class CultureTag {
  private readonly _id: string;
  private readonly _teamId: string;
  private readonly _title: string;
  
  constructor(id: string, teamId: string, title: string) {
    this._id = id;
    this._title = title;
    this._teamId = teamId;
  }
  
  get id(): string {
    return this._id;
  }
  
  get teamId(): string {
    return this._teamId;
  }
  
  get title(): string {
    return this._title;
  }
}
