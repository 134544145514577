import * as React from 'react';
import {useState} from 'react';
import type {Dispatch} from "store";
import {useDispatch} from "react-redux";
import LoginFormComponent, {LoginFormProps} from "module/authentication/view/component/LoginFormComponent";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";

export default function LoginContainer() {
  const dispatch = useDispatch<Dispatch>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  
  const [loginLoading, loginErrorName, loginDispatch, loginCompleted] =
    usePromiseLoadingAndError(() => dispatch.AuthenticationStore.login({email, password}));
  
  const [resetPasswordLoading, resetPasswordErrorName, resetPasswordDispatch, resetPasswordCompleted] =
    usePromiseLoadingAndError((email: string) => dispatch.AuthenticationStore.resetPassword(email));
  
  const LoginFormProps = {
    formState: {
      email,
      setEmail,
      password,
      setPassword
    },
    login: {
      dispatch: loginDispatch,
      errorName: loginErrorName,
      isLoading: loginLoading
    },
    resetPassword: {
      dispatch: resetPasswordDispatch,
      errorName: resetPasswordErrorName,
      isLoading: resetPasswordLoading,
      isCompleted: resetPasswordCompleted
    }
  } as LoginFormProps;
  
  return <LoginFormComponent {...LoginFormProps} />;
}
