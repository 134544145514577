import React, {ReactElement} from 'react';
import {Box} from "asset/component/";

function PageContentWrapper({children}: {
  children: JSX.Element | JSX.Element[]
}): ReactElement {
  return <Box>{children}</Box>;
}

export default PageContentWrapper;
