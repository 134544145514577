import {StateStatus} from "module/objective/domain/model/StateStatus";
import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";
import StatusIcon from "module/objective/view/component/StatusIcon";
import {MuiSizes} from "style/props/MuiSizes";

const ObjectiveStateHistoryStartNoTrace = ({
  statusType,
  index,
  selectObjectiveState,
  objectiveStateId,
  className
}: {
  statusType: StateStatus,
  index: number,
  selectObjectiveState?: (id: string) => void,
  objectiveStateId: string,
  className?: string
}) => {
  
  const classes = selectObjectiveState ? `${styles.tableCell} ${styles.tableCellClickable} ${className}` : `${styles.tableCell} ${className}`;
  
  const onClick = selectObjectiveState ? () => selectObjectiveState(objectiveStateId) : undefined;
  
  return <div className={classes}
              key={index}
              onClick={onClick}>
  <StatusIcon status={statusType} size={MuiSizes.SMALL} />
  </div>;
};

export default ObjectiveStateHistoryStartNoTrace;
