import ObjectiveState from "module/objective/domain/model/ObjectiveState";
import {ErrorNames} from "core/error/ErrorNames";
import {TextValidator, ValidatorForm} from "asset/validator";
import {useState} from "react";
import styles from "module/objective/view/asset/ObjectiveStateHistoryCommentInput.module.scss";
import {IconButton} from "asset/component/index";
import SendArrow from "asset/icon/SendArrow";
import {CircularProgress, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import IconError from "asset/image/icon/ErrorIcon.svg"
import useErrorTranslation from "asset/component/hook/useErrorTranslation";

const ObjectiveStateHistoryCommentInput = ({
  objectiveStateDisplayed,
  addObjectiveStateComment,
  isAddCommentLoading,
  addCommentError
} : {
  objectiveStateDisplayed: ObjectiveState,
  addObjectiveStateComment?: (id: string, comment: string) => void,
  isAddCommentLoading?: boolean,
  addCommentError?: ErrorNames
}) => {
  
  const { t } = useTranslation("objective");
  const {getErrorMessage} = useErrorTranslation()
  const [comment, setcomment] = useState<string>("")
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false)
  const arrow = <SendArrow/>
  const loader = <CircularProgress size={20} sx={{color:"black"}}></CircularProgress>
  
  const handleSubmit = () => {
    if (addObjectiveStateComment) {
      addObjectiveStateComment(objectiveStateDisplayed.id, comment)
    }
    setIsInputFocused(false)
    setcomment("")
  }
  
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  }
  
  return <div>
    <ValidatorForm className={styles.container} onSubmit={handleSubmit} role={"form"} onError={undefined}>
      <div className={`${addCommentError ? styles.borderInputError : isInputFocused ? styles.borderInputFocused : ''}`}>
        <TextValidator
          fullWidth
          type="text"
          id="comment"
          name="comment"
          label={t("stateHistoryModal.commentInput.label")}
          value={comment}
          onChange={(event) => setcomment(event.target.value)}
          onKeyPress={handleKeyPress}
          disabled={isAddCommentLoading}
          rows={2}
          multiline
          inputProps={{
            className: `${ isInputFocused ? styles.inputCommentFocused : styles.inputComment}`,
            role: "textbox"
          }}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(comment.trim().length > 0)}
          validators={["required"]}
        />
        {addCommentError ? <img src={IconError} alt="errorIcon" className={`${isInputFocused ? styles.iconErrorHidden : styles.iconError}`}/> : <></>}
        {isInputFocused || isAddCommentLoading ? (
          isAddCommentLoading ? (
            <IconButton icon={loader} className={styles.iconLoading} />
          ) : (
            <IconButton disabled={comment.trim().length <= 0}
                        type="submit"
                        icon={arrow}
                        className={styles.iconArrow}
                        role={"button"} />
          )
        ) : null}
      
      </div>
    </ValidatorForm>
    {addCommentError ? <Typography className={styles.errorMessage} role={"alert"}>{getErrorMessage(addCommentError)}</Typography> : <></>}
  </div>
  
  
}

export default ObjectiveStateHistoryCommentInput;
