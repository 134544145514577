import React, {ReactElement, useEffect} from 'react';
import LayoutWrapper from "module/common/layout/component/LayoutWrapper";
import PageContentWrapper from "module/common/layout/component/PageContentWrapper";
import Navbar from "module/common/navbar/container/Navbar";
import PageContent from "module/common/layout/component/PageContent";
import LoadingLayout from "module/common/layout/container/LoadingLayout";
import Footer from "module/common/layout/component/Footer";
import {useLocation} from "react-router-dom";
import MobileAlert from "module/common/MobileAlert";
import FeedbackCatcher from "asset/component/global/FeedbackCatcher";
import {CONTENT_ID} from "router/Router";

export enum LayoutTheme {
  DARK = 'dark',
  LIGHT = 'light',
  TEXTURED = 'textured',
  FLASH = 'flash',
}

function Layout({
  children,
  layoutTheme = LayoutTheme.DARK,
  isLoading = false,
  scrollToContent = false,
  user
}: {
  children: JSX.Element | JSX.Element[],
  layoutTheme?: LayoutTheme,
  isLoading?: boolean,
  scrollToContent?: boolean,
  user?: any
}): ReactElement {
  const {pathname} = useLocation();
  
  useEffect(() => {
    const content = document.getElementById(CONTENT_ID);
    
    if (scrollToContent && content) {
      setTimeout(() => {
        content.scrollIntoView({behavior: "smooth"});
      }, 150);
    } else {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [pathname]);
  
  const layout =
    <LayoutWrapper layoutTheme={layoutTheme}>
      <PageContentWrapper data-testid="page-content-wrapper">
        {/*Enlevé temporairement en attendant le backend*/}
        {/*{user && <OnboardingContainer />}*/}
        <MobileAlert data-testid="mobile-alert" />
        <Navbar data-testid="navbar" />
        <PageContent data-testid="page-content">
          {children}
        </PageContent>
        <FeedbackCatcher data-testid="feedback-catcher" />
        <Footer data-testid="footer" />
      </PageContentWrapper>
    </LayoutWrapper>;
  
  const loadingLayout = <LoadingLayout data-testid="loading-layout" />;
  
  return isLoading ? loadingLayout : layout;
}

export default Layout;
