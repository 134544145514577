import React from 'react';
import MandateSidebarContainer from "module/mandate/view/container/edit/MandateSidebarContainer";
import MandatesCardsComponent from "module/mandate/view/component/listing/MandatesCardsComponent";
import Mandate from "module/mandate/domain/model/Mandate";
import styles from "module/mandate/view/asset/listing/MandateDisplayerComponent.module.scss";

const MandatesDisplayerComponent = ({
  mandates,
  currentMandate,
  isSidebarOpen,
  displayMandate,
  deleteMandate,
  close
}: {
  mandates: Mandate[],
  currentMandate?: Mandate | null,
  isSidebarOpen?: boolean,
  displayMandate: (id: string) => void,
  deleteMandate: () => void,
  close: () => void
}) => {
  return (
    <div className={styles.displayerContainer}>
      <MandatesCardsComponent mandates={mandates}
                              displayMandate={(mandateId) => displayMandate(mandateId)} />
      <MandateSidebarContainer mandate={currentMandate}
                               isSidebarOpen={isSidebarOpen}
                               close={close}
                               deleteMandate={deleteMandate} />
    </div>
  );
};

export default MandatesDisplayerComponent;
