import * as React from "react";
import {IconButton as IconButtonMui, IconButtonProps as MuiIconButtonProps} from "@mui/material";
import {MuiButtonColors} from "style/props/MuiButtonColors";
import usePaletteColors, {AddOpacity} from "style/usePaletteColors";
import styles from "asset/component/asset/global/buttons/IconButton.module.scss";

export interface IconButtonProps {
  iconColor?: string | MuiButtonColors;
  hoverIconColor?: string | MuiButtonColors;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  icon?: JSX.Element;
  buttonSize?: string,
  iconSize?: string,
  className?: string
}

const DEFAULT_OPACITY = 40;

const IconButton: React.FC<IconButtonProps & MuiIconButtonProps> = ({
  iconColor,
  hoverIconColor,
  backgroundColor,
  hoverBackgroundColor,
  icon,
  buttonSize = "50px",
  iconSize = "25px",
  className,
  ...props
}) => {
  const palette = usePaletteColors();
  const defaultColor = iconColor || palette.primary.main;
  
  return (
    <IconButtonMui
      className={`${styles.iconButton} ${className}`}
      sx={{
        width: buttonSize,
        height: buttonSize,
        color: defaultColor,
        backgroundColor: backgroundColor || AddOpacity(defaultColor, DEFAULT_OPACITY),
        "&:hover": {
          color: hoverIconColor || defaultColor,
          backgroundColor: hoverBackgroundColor || AddOpacity(backgroundColor || defaultColor, DEFAULT_OPACITY)
        }
      }}
      {...props}
    >
        <span className={styles.icon} style={{width: iconSize, height: iconSize}}>
          {icon}
        </span>
    </IconButtonMui>
  );
};

export default IconButton;
