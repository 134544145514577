import type RESTClient from "core/client/RESTClient";
import UserRepository from "module/user/domain/UserRepository";
import UserResponse, {userResponseToModel} from "module/user/dal/dto/UserResponse";
import User from "module/user/domain/model/User";
import UpdateUser from "module/user/domain/model/UpdateUser";
import {createUpdateUserRequest} from "module/user/dal/dto/UpdateUserRequest";
import Profile from "module/profile/domain/model/Profile";
import ProfileResponse, {profilesResponseToModel} from "module/profile/dal/dto/ProfileResponse";
import ProfileRepository from "module/profile/domain/ProfileRepository";

export default class RESTProfileRepository implements ProfileRepository {
  private static readonly BASE_URL: string = "/api/profiles";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchProfiles(): Promise<Profile[]> {
    const profilesResponse = await this.client.apiGet<ProfileResponse[]>(
      `${RESTProfileRepository.BASE_URL}`
    );
    
    return profilesResponseToModel(profilesResponse);
  }
}
