import React from "react";
import styles from "module/coachee/view/asset/coacheeProfile/CoacheeProfileBodyComponent.module.scss";
import CoacheeObjectivesContainer from "module/objective/view/container/coachee/CoacheeObjectivesContainer";
import CoacheeFollowUpsContainer from "module/followUp/view/container/coachee/CoacheeFollowUpsContainer";
import Coachee from "module/coachee/domain/model/Coachee";
import {Container, Masonry} from "asset/component";
import CoacheeNeedsContainer from "module/need/view/coachee/container/CoacheeNeedsContainer";
import CoacheeNoteContainer from "module/coachee/view/container/CoacheeNoteContainer";
import CoacheeMotivatorModuleContainer from "module/motivator/coachee/view/container/CoacheeMotivatorModuleContainer";
import SkillsModuleContainer from "module/skill/view/container/SkillsModuleContainer";
import CoacheeLinkedMandateListContainer from "module/mandate/view/container/listing/CoacheeLinkedMandateListContainer";

const CoacheeProfileBodyComponent = ({coachee}: { coachee?: Coachee }) => {
  
  return (
    <Container id={styles.coacheeProfileBodyComponentContainer}
               data-testid="coachee_profile_body_component">
      {coachee && <div className={styles.linkedMandateList}>
          <CoacheeLinkedMandateListContainer coacheeId={coachee?.id} data-testid="linked-mandate-list" />
      </div>}
      {
        coachee &&
          <div className={styles.noteContainer}>
              <CoacheeNoteContainer coachee={coachee} />
          </div>
      }
      <div className={styles.moduleContainer}>
        {coachee &&
            <Masonry columns={{xs: 1, md: 2, lg: 3}} spacing={3}>
                <SkillsModuleContainer coacheeId={coachee.id} />
                <CoacheeObjectivesContainer data-testid="CoacheeObjectivesContainer"
                                            coacheeId={coachee.id} />
                <CoacheeFollowUpsContainer data-testid="CoacheeFollowUpsContainer"
                                           coacheeId={coachee.id} />
                <CoacheeMotivatorModuleContainer coacheeId={coachee.id} />
                <CoacheeNeedsContainer coacheeId={coachee.id} />
            </Masonry>
        }
      </div>
    </Container>
  );
};

export default CoacheeProfileBodyComponent;
