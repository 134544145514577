import ObjectiveStateComment from "module/objective/domain/model/ObjectiveStateComment";
import ReducerServices from "store/service/ReducerServices";
import ObjectiveState from "module/objective/domain/model/ObjectiveState";

export default class ObjectiveReducers {
  public static addCommentToObjectiveStateFoundById(
    state: any,
    objectiveStateId: string,
    objectiveStateComment: ObjectiveStateComment
  ) {
    const foundObjectiveState = state.objectiveStateHistory.find((objectiveState: ObjectiveState) => objectiveState.id === objectiveStateId);
    
    if (!foundObjectiveState) return state;
    
    foundObjectiveState.comments.push(objectiveStateComment);
    
    return ReducerServices.setItemToList<ObjectiveState>(
        state.objectiveStateHistory,
        foundObjectiveState
      );
  }
}
