import UpdateObjectiveMandate from "module/objective/domain/model/UpdateObjectiveMandate";

export default interface UpdateObjectiveMandateRequest {
  name: string;
  text: string;
}

export function createUpdateObjectiveMandateRequest(updateObjectiveMandate: UpdateObjectiveMandate): UpdateObjectiveMandateRequest {
  return {
    name: updateObjectiveMandate.name,
    text: updateObjectiveMandate.text,
  } as UpdateObjectiveMandateRequest;
}
