import React, {useState} from "react";
import {ModuleCard, ModuleHeader} from "asset/component";
import {useTranslation} from "react-i18next";
import CoacheeNeedsComponent from "module/need/view/coachee/component/CoacheeNeedsComponent";
import {NeedsCoachee} from "module/objective/domain/model/need/NeedsCoachee";
import CoacheeObjectivesNeedContainer from "module/objective/view/container/coachee/CoacheeObjectivesNeedContainer";
import styles from "module/need/view/asset/team/TeamNeedContainer.module.scss";
import {ModuleTheme} from "asset/component/module/ModuleCard";
import {CoacheeSequence} from "module/onboarding/domain/model/SeedData/OnboardingSequencesSeed";

const CoacheeNeedsContainer = ({coacheeId}: {
  coacheeId: string
}) => {
  const {t} = useTranslation('need');
  const [selectedNeed, setSelectedNeed] = useState<NeedsCoachee>();
  
  const onNeedClick = (need: NeedsCoachee) => {
    setSelectedNeed(need);
  };
  
  const unSelectNeed = () => {
    setSelectedNeed(undefined);
  };
  
  return <ModuleCard theme={selectedNeed && ModuleTheme.WHITE}>
    <div className={styles.teamNeedContainer}>
      <ModuleHeader title={t('moduleTitle')}
                    id={CoacheeSequence.steps[2].anchorID}
                    subtitle={
                      <>
                        <div>{t('moduleSubtitle')}</div>
                        <div>{t('moduleSubtitle2')}</div>
                      </>
                    }
                    modalInfo={{title: t('infoCoachee.title'), text: t('infoCoachee.text')}} />
      {
        !selectedNeed ?
          <CoacheeNeedsComponent onNeedClick={onNeedClick}
                                 coacheeId={coacheeId} /> :
          <CoacheeObjectivesNeedContainer need={selectedNeed}
                                          coacheeId={coacheeId}
                                          onClose={unSelectNeed} />
      }
    </div>
  </ModuleCard>;
};

export default CoacheeNeedsContainer;
