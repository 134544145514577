export default class Team {
  private readonly _id: string;
  private readonly _name: string;
  private readonly _companyId: string;
  private readonly _note: string;
  
  constructor(id: string, name: string, companyId: string, note: string) {
    this._id = id;
    this._name = name;
    this._companyId = companyId;
    this._note = note;
  }
  
  get id(): string {
    return this._id;
  }
  
  get name(): string {
    return this._name;
  }
  
  get companyId(): string {
    return this._companyId;
  }
  
  get note(): string {
    return this._note;
  }
}
