import * as React from "react";

const EditIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M0 17.5388H20.461V19.0003H0V17.5388ZM17.1058 5.11524C17.6904 4.53064 17.6904 3.65374 17.1058 3.06914L14.4751 0.438449C13.8905 -0.14615 13.0136 -0.14615 12.429 0.438449L1.46775 11.3997V16.0765H6.14454L17.1058 5.11524ZM13.4477 1.46775L16.0784 4.09844L13.8862 6.29069L11.2555 3.66L13.4477 1.46775ZM2.91489 14.6135V11.9828L10.2224 4.67536L12.8531 7.30606L5.54558 14.6135H2.91489Z"
            fill="currentColor" />
    </svg>
  );
};
export default EditIcon;
