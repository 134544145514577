export default class Skill {
  private readonly _id: string;
  private readonly _coacheeId: string;
  private readonly _name: string;
  
  constructor(id: string, coacheeId: string, name: string) {
    this._id = id;
    this._name = name;
    this._coacheeId = coacheeId;
  }
  
  get id(): string {
    return this._id;
  }
  
  get coacheeId(): string {
    return this._coacheeId;
  }
  
  get name(): string {
    return this._name;
  }
}
