import * as React from "react";
import {Button, Typography} from "asset/component/index";
import ClosableSection from "asset/component/global/ClosableSection";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import {ArrowIcon} from "asset/component/Icons";
import expired from "asset/image/expired.png";
import MuiButtonSizes from "style/props/MuiButtonSizes";
import usePaletteColors from "style/usePaletteColors";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import styles from "asset/component/asset/global/TrialComponent.module.scss";
import {useNavigate} from "react-router-dom";
import {Routes} from "router/Routes";
import {useTranslation} from "react-i18next";
import HadAccess from "module/authorization/HadAccess";
import {Accesses} from "module/authorization/domain/model/accesses";

const InactiveWorkSpaceMessage = () => {
  const {t} = useTranslation('common');
  const palette = usePaletteColors();
  const navigate = useNavigate();
  
  return <HadAccess accesses={[Accesses.INACTIVE_WORKSPACE]}>
    <ClosableSection>
      <div className={styles.trialContainer}>
        <img className={styles.image} src={expired} alt={t('workspaceInactive.title')} />
        
        <Typography className={styles.title}
                    color={palette.secondary.dark}
                    variant={MuiTypographyVariants.H2}>
          <div>{t('workspaceInactive.title')}</div>
          <div>{t('workspaceInactive.title2')}</div>
        </Typography>
        <Typography className={styles.text}
                    color={palette.secondary.dark}
                    variant={MuiTypographyVariants.H4}>
          {t('workspaceInactive.text')}
        </Typography>
        <Button
          data-testid="setting-page-redirection"
          variant={MuiButtonVariants.CONTAINED}
          endIcon={<ArrowIcon />}
          size={MuiButtonSizes.LARGE}
          onClick={() => {
            navigate(Routes.settings.path());
          }}
        >
          {t('workspaceInactive.button')}
        </Button>
      </div>
    </ClosableSection>
  </HadAccess>;
};

export default InactiveWorkSpaceMessage;
