import type RESTClient from "core/client/RESTClient";
import FollowUpCoachee from "module/followUp/domain/model/FollowUpCoachee";
import FollowUpResponse, {
  followUpsCoacheeResponseToModel,
  followUpCoacheeResponseToModel
} from "module/followUp/dal/dto/FollowUpResponse";
import FollowUpCoacheeRepository from "module/followUp/domain/FollowUpCoacheeRepository";
import AddFollowUp from "module/followUp/domain/model/AddFollowUp";
import {createAddFollowUpRequest} from "module/followUp/dal/dto/AddFollowUpRequest";
import UpdateFollowUp from "module/followUp/domain/model/UpdateFollowUp";
import {createUpdateFollowUpRequest} from "module/followUp/dal/dto/UpdateFollowUpRequest";

export default class RESTFollowUpCoacheeRepository implements FollowUpCoacheeRepository {
  private static readonly BASE_URL: string = "api";
  private static readonly COACHEE_URL: string = "coachees";
  private static readonly FOLLOW_UP_URL: string = "followUps";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchFollowUpsByCoacheeId(id: string): Promise<FollowUpCoachee[]> {
    const followUpsResponse = await this.client.apiGet<FollowUpResponse[]>(
      `/${RESTFollowUpCoacheeRepository.BASE_URL}` +
      `/${RESTFollowUpCoacheeRepository.COACHEE_URL}/${id}` +
      `/${RESTFollowUpCoacheeRepository.FOLLOW_UP_URL}`
    );
    
    return followUpsCoacheeResponseToModel(followUpsResponse, id);
  }
  
  public async addFollowUpForCoachee(addFollowUp: AddFollowUp, coacheeId: string): Promise<FollowUpCoachee> {
    const followUpResponse = await this.client.apiPost<FollowUpResponse>(
      `/${RESTFollowUpCoacheeRepository.BASE_URL}` +
      `/${RESTFollowUpCoacheeRepository.COACHEE_URL}/${coacheeId}` +
      `/${RESTFollowUpCoacheeRepository.FOLLOW_UP_URL}`,
      createAddFollowUpRequest(addFollowUp)
    );
    
    return followUpCoacheeResponseToModel(followUpResponse, coacheeId);
  }
  
  public async updateFollowUpForCoachee(
    updateFollowUp: UpdateFollowUp,
    followUpId: string,
    coacheeId: string
  ): Promise<FollowUpCoachee> {
    const followUpResponse = await this.client.apiPut<FollowUpResponse>(
      `/${RESTFollowUpCoacheeRepository.BASE_URL}` +
      `/${RESTFollowUpCoacheeRepository.COACHEE_URL}/${coacheeId}` +
      `/${RESTFollowUpCoacheeRepository.FOLLOW_UP_URL}/${followUpId}`,
      createUpdateFollowUpRequest(updateFollowUp)
    );
    
    return followUpCoacheeResponseToModel(followUpResponse, coacheeId);
  }
  
  public async deleteFollowUpForCoachee(followUpId: string, coacheeId: string): Promise<void> {
    await this.client.apiDelete<FollowUpResponse>(
      `/${RESTFollowUpCoacheeRepository.BASE_URL}` +
      `/${RESTFollowUpCoacheeRepository.COACHEE_URL}/${coacheeId}` +
      `/${RESTFollowUpCoacheeRepository.FOLLOW_UP_URL}/${followUpId}`
    );
  }
}
