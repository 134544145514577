import React from "react";
import {Grid, Typography} from "asset/component";
import {useTranslation} from "react-i18next";
import InputSelect, {InputOption} from "asset/component/form/inputs/InputSelect";
import usePaletteColors from "style/usePaletteColors";
import {MuiInputColors} from "style/props/MuiInputColors";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";

const UpdateCoacheeOrganisationFormComponent = ({
  companyOptions,
  companyId,
  setCompanyId,
}:
  {
    companyOptions: InputOption[],
    companyId?: string,
    setCompanyId: (companyId: string) => void,
  }) => {
  const {t} = useTranslation('coachee');
  const palette = usePaletteColors();
  
  return <div>
    <Typography component="p" variant={MuiTypographyVariants.XS} color={palette.secondary.dark}>
      {t('form.update.organisationLabel')}
    </Typography>
    <Grid container>
      <InputSelect
        data-testid="organisation-input"
        selectedId={companyId}
        setExistingValue={setCompanyId}
        options={companyOptions}
        selectLabel={t('form.organisationPlaceholder')}
        required={true}
        color={MuiInputColors.SECONDARY}
        validationRequiredMessage={t('form.validation.companyRequired')}
        addButtonLabel={t('form.addOrganisationButton')}
      />
    </Grid>
  </div>;
};

export default UpdateCoacheeOrganisationFormComponent;
