import * as React from "react";
import {useState} from "react";
import {Alert, CircularProgress, IconButton} from "asset/component/index";
import styles from 'asset/component/asset/form/inputs/InputEdit.module.scss';
import DeleteConfirmation from "asset/component/global/dialogs/DeleteConfirmation";
import {EditIcon} from "asset/component/Icons";
import {TextValidator, ValidatorForm} from "asset/validator";
import CheckIcon from "@mui/icons-material/Check";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import {ErrorNames} from "core/error/ErrorNames";
import {MuiInputColors} from "style/props/MuiInputColors";
import {IconButtonProps} from "asset/component/global/buttons/IconButton";
import usePaletteColors from "style/usePaletteColors";

export interface IconsProps {
  editIcon?: IconButtonProps,
  deleteIcon?: IconButtonProps,
  updateIcon?: IconButtonProps,
}

export interface InputEditClasses {
  updateIcon?: string;
  editIcon?: string;
  deleteIcon?: string;
  input?: string;
  formContainer?: string;
  iconContainer?: string;
  titleContainer?: string;
}

const EditableTitle = ({
  title,
  setTitle,
  deleteAction,
  deleteConfirmationText,
  deleteConfirmButtonText,
  defaultEditMode = false,
  editIsLoading,
  errorName,
  children,
  inputColor = MuiInputColors.PRIMARY,
  onEditModeChange,
  classes,
  iconsProps
}: {
  title: string,
  setTitle?: (title: string) => void,
  deleteAction?: (...any) => void,
  deleteConfirmationText?: string,
  deleteConfirmButtonText?: string,
  defaultEditMode?: boolean,
  editIsLoading?: boolean,
  errorName?: ErrorNames,
  children?: JSX.Element,
  inputColor?: MuiInputColors
  onEditModeChange?: (state: boolean) => void,
  classes?: InputEditClasses
  iconsProps?: IconsProps
}) => {
  const [titleValue, setTitleValue] = useState(title);
  const [editMode, setEditMode] = useState<boolean>(defaultEditMode);
  const {getErrorMessage} = useErrorTranslation();
  const palette = usePaletteColors();
  
  const preventClickEvent = event => event.stopPropagation();
  
  const changeEditMode = (mode: boolean) => {
    setEditMode(mode);
    onEditModeChange && onEditModeChange(mode);
  };
  
  const errorAlert = errorName &&
      <Alert severity="error"
             data-testid="error-message">
        {getErrorMessage(errorName)}
      </Alert>;
  
  const deleteButton = deleteAction && deleteConfirmationText && editMode &&
      <DeleteConfirmation
          deleteAction={() => {
            changeEditMode(false);
            deleteAction();
          }}
          confirmButtonText={deleteConfirmButtonText}
          confirmationText={deleteConfirmationText}
          iconButton={iconsProps?.deleteIcon}
      />;
  
  const editButton =
    <IconButton onClick={() => changeEditMode(!editMode)}
                className={`${styles.editButton} ${classes?.editIcon}`}
                data-testid="dialog-icon"
                iconColor={palette.primary.dark}
                hoverBackgroundColor={palette.primary.main}
                backgroundColor="transparent"
                buttonSize="40px"
                iconSize="20px"
                {...iconsProps?.editIcon}
                icon={!editIsLoading ? <EditIcon /> : <CircularProgress size={20} />}
    />;
  
  const iconContainer = (
    <div className={`${styles.iconContainer} ${classes?.iconContainer}`}
         onClick={preventClickEvent}>
      {!editMode && setTitle && editButton}
      <span className={classes?.deleteIcon}>{deleteButton}</span>
    </div>);
  
  const onTitleInputSubmit = () => {
    setTitle && setTitle(titleValue);
    changeEditMode(false);
  };
  
  const titleInputForm =
    <ValidatorForm data-testid="title-form"
                   className={`${styles.formContainer} ${classes?.formContainer}`}
                   onClick={preventClickEvent}
                   onSubmit={onTitleInputSubmit}>
      <TextValidator
        autoFocus
        data-testid="title-input"
        required
        className={`${styles.input} ${classes?.input}`}
        value={titleValue}
        color={inputColor}
        onChange={(event) => setTitleValue(event.target.value)}
      />
      <IconButton type="submit"
                  className={classes?.updateIcon}
                  iconColor={palette.secondary.dark}
                  hoverBackgroundColor={palette.primary.main}
                  backgroundColor="transparent"
                  buttonSize="40px"
                  iconSize="20px"
                  icon={<CheckIcon />}
                  {...iconsProps?.updateIcon}
      />
      {iconContainer}
    </ValidatorForm>;
  
  const displayTitle =
    <div className={`${styles.titleContainer} ${classes?.titleContainer}`}>
      {errorAlert}
      {children || title}
      {iconContainer}
    </div>;
  
  return setTitle && editMode ? titleInputForm : displayTitle;
};

export default EditableTitle;
