import type RESTClient from "core/client/RESTClient";
import CoacheeDemotivatorRepository from "module/demotivator/coachee/domain/CoacheeDemotivatorRepository";
import CoacheeDemotivator from "module/demotivator/coachee/domain/model/CoacheeDemotivator";
import CoacheeDemotivatorResponse, {
  coacheeDemotivatorResponseToModel,
  coacheeDemotivatorsResponseToModel
} from "module/demotivator/coachee/dal/dto/CoacheeDemotivatorResponse";
import AddCoacheeDemotivator from "module/demotivator/coachee/domain/model/AddCoacheeDemotivator";
import {createAddCoacheeDemotivatorRequest} from "module/demotivator/coachee/dal/dto/AddCoacheeDemotivatorRequest";

export default class RESTCoacheeDemotivatorRepository implements CoacheeDemotivatorRepository {
  private static readonly BASE_URL: string = "api";
  private static readonly COACHEE_URL: string = "coachees";
  private static readonly MOTIVATOR_URL: string = "demotivators";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchDemotivatorsByCoacheeId(id: string): Promise<CoacheeDemotivator[]> {
    const demotivatorsResponse = await this.client.apiGet<CoacheeDemotivatorResponse[]>(
      `/${RESTCoacheeDemotivatorRepository.BASE_URL}` +
      `/${RESTCoacheeDemotivatorRepository.COACHEE_URL}/${id}` +
      `/${RESTCoacheeDemotivatorRepository.MOTIVATOR_URL}`
    );
    
    return coacheeDemotivatorsResponseToModel(demotivatorsResponse, id);
  }
  
  public async addDemotivatorForCoachee(
    addDemotivator: AddCoacheeDemotivator,
    coacheeId: string
  ): Promise<CoacheeDemotivator> {
    const demotivatorResponse = await this.client.apiPost<CoacheeDemotivatorResponse>(
      `/${RESTCoacheeDemotivatorRepository.BASE_URL}` +
      `/${RESTCoacheeDemotivatorRepository.COACHEE_URL}/${coacheeId}` +
      `/${RESTCoacheeDemotivatorRepository.MOTIVATOR_URL}`,
      createAddCoacheeDemotivatorRequest(addDemotivator)
    );
    
    return coacheeDemotivatorResponseToModel(demotivatorResponse, coacheeId);
  }
  
  public async deleteDemotivatorForCoachee(motivatorId: string, coacheeId: string): Promise<void> {
    await this.client.apiDelete<CoacheeDemotivatorResponse>(
      `/${RESTCoacheeDemotivatorRepository.BASE_URL}` +
      `/${RESTCoacheeDemotivatorRepository.COACHEE_URL}/${coacheeId}` +
      `/${RESTCoacheeDemotivatorRepository.MOTIVATOR_URL}/${motivatorId}`
    );
  }
}
