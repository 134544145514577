import React from 'react';
import LinkedEntity from "module/mandate/domain/model/LinkedEntity";
import Coachee from "module/coachee/domain/model/Coachee";
import {Alert} from "asset/component";
import {CircularProgress} from "@mui/material";
import Plaque, {PlaqueTheme} from "asset/component/global/Plaque";
import {CloseIcon, CoacheeIcon, ExpandIcon} from "asset/component/Icons";
import {ErrorNames} from "core/error/ErrorNames";

const TeamLinkedEntityComponent = ({
  entity,
  isEditMode,
  action,
  coachees,
  isCoacheesLoading,
  errorCoachees
}: {
  entity: LinkedEntity;
  isEditMode?: boolean;
  action: (name: string) => void;
  coachees?: Coachee[];
  isCoacheesLoading?: boolean;
  errorCoachees?: ErrorNames;
}) => {
  return (
    <>
      {errorCoachees &&
          <Alert severity="error" data-testid="alert">{errorCoachees}</Alert>}
      {isCoacheesLoading ? <CircularProgress /> :
        <Plaque id={entity.id}
                key={entity.id}
                label={entity.name}
                icon={<CoacheeIcon />}
                actionIcon={isEditMode ? <CloseIcon /> : <ExpandIcon />}
                onClick={(value) => action(value)}
                isEditMode={isEditMode}
                theme={PlaqueTheme.DARK}>
          {coachees?.map(coachee =>
            <Plaque id={coachee.id}
                    key={entity.id}
                    label={coachee.firstName + ' ' + coachee.lastName}
                    icon={<CoacheeIcon />} />
          )}
        </Plaque>}
    </>
  );
};

export default TeamLinkedEntityComponent;
