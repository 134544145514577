import * as React from 'react';
import {useEffect, useRef} from 'react';
import {Button, Dialog, DialogActions, DialogContent, Alert, Typography} from 'asset/component/index';
import {useTranslation} from "react-i18next";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import {MuiButtonColors} from "style/props/MuiButtonColors";
import styles from "asset/component/asset/auth/ResetPasswordDialog.module.scss";
import {LoadingButton} from "@mui/lab";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import {TextValidator, useFormValidation, ValidatorForm} from "asset/validator";
import {ErrorNames} from "core/error/ErrorNames";
import {InputValidator} from "asset/validator/InputValidator";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import {MuiInputColors} from "style/props/MuiInputColors";
import usePaletteColors from "style/usePaletteColors";

export default function ResetPasswordDialog(
  {
    resetAction,
    errorName,
    isLoading,
    isCompleted,
  }: {
    resetAction: (email: string) => void,
    errorName?: ErrorNames,
    isLoading?: boolean,
    isCompleted?: boolean,
  }) {
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const formRef = useRef();
  const {submitForm} = useFormValidation(formRef, [email]);
  const {getErrorMessage} = useErrorTranslation();
  const palette = usePaletteColors();
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  
  const declined = () => {
    handleClose();
  };
  
  const resetPassword = () => {
    submitForm();
  };
  
  const confirmed = () => {
    resetAction(email);
  };
  
  useEffect(() => {
    if (isCompleted) {
      handleClose();
    }
  }, [isCompleted]);
  
  const dialogContent =
    <DialogContent className={styles.content}>
      <Typography className={styles.title}
                  component="p"
                  variant={MuiTypographyVariants.XS}
                  color={palette.secondary.dark}
      >
        {t('common:resetPasswordTitle')}
      </Typography>
      {
        errorName &&
          <Alert severity="error"
                 data-testid="reset-password-error-message">
            {errorName && getErrorMessage(errorName)}
          </Alert>
      }
      <TextValidator
        required
        fullWidth
        data-testid="email-input"
        id="email"
        placeholder={t('common:email')}
        name="email"
        autoComplete="email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        validators={[InputValidator.REQUIRED, InputValidator.IS_EMAIL]}
        errorMessages={[t('form.validation.emailRequired'), t('form.validation.emailIsValid')]}
        autoFocus
        color={MuiInputColors.SECONDARY}
      />
    </DialogContent>;
  
  const dialogAction =
    <DialogActions className={styles.actions}>
      <Button data-testid="dialog-cancel"
              variant={MuiButtonVariants.OUTLINED}
              color={MuiButtonColors.PRIMARY_DARK}
              onClick={declined}>
        {t('common:cancel')}
      </Button>
      <LoadingButton data-testid="dialog-reset"
                     variant={MuiButtonVariants.CONTAINED}
                     loading={isLoading}
                     disabled={isLoading}
                     onClick={resetPassword}>
        {t('common:resetPassword')}
      </LoadingButton>
    </DialogActions>;
  
  return (
    <div>
      <Typography className={styles.forgetPasswordButton}
                  data-testid="dialog-button"
                  onClick={handleClickOpen}
                  component="span"
                  variant={MuiTypographyVariants.SM}
                  color={palette.primary.middle}>
        {t('common:forgetPassword')}
      </Typography>
      <Dialog open={open} onClose={handleClose} className={styles.resetPasswordDialog}>
        <ValidatorForm ref={formRef} data-testid="reset-form" onSubmit={confirmed}>
          {dialogContent}
          {dialogAction}
        </ValidatorForm>
      </Dialog>
    </div>
  );
}
