import React from "react";
import ModuleCard from "asset/component/module/ModuleCard";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import {HideListedItemsButton, ModuleHeader, ModuleListing} from "asset/component";
import {useTranslation} from "react-i18next";
import followUpsListingComponentStyles from "module/followUp/view/asset/FollowUpsListingComponent.module.scss";
import FollowUpTeam from "module/followUp/domain/model/FollowUpTeam";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import AddFollowUp from "module/followUp/domain/model/AddFollowUp";
import TeamFollowUpContainer from "module/followUp/view/container/team/TeamFollowUpContainer";
import useHideListedItem from "asset/component/hook/useHideListedItem";
import styles from "module/objective/view/asset/ObjectivesContainer.module.scss";
import InputEnter from "asset/component/form/inputs/InputEnter";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";

const TeamFollowUpsContainer = ({
  teamId
}: {
  teamId: string
}) => {
  const {t} = useTranslation('followUp');
  const dispatch = useDispatch<Dispatch>();
  const {showCompletedItem, setShowCompletedItem, filterList} = useHideListedItem<FollowUpTeam>('completed');
  const {getErrorMessage} = useErrorTranslation();
  
  const teamFollowUps = useSelector(
    (rootState: RootState) =>
      rootState.FollowUpTeamStore?.getters(rootState).getFollowUpsByTeamId(teamId)
  );
  
  const fetchFollowUpsByTeamIdState = useLoadingAndError(
    () =>
      dispatch.FollowUpTeamStore.fetchFollowUpsByTeamId(teamId), [], teamId
  );
  
  const [isAddFollowUpLoading, addFollowUpErrorName, dispatchAddFollowUp] =
    usePromiseLoadingAndError((text: string) =>
      dispatch.FollowUpTeamStore.addFollowUpForTeam({
        addFollowUp: new AddFollowUp(text),
        teamId,
      })
    );
  
  return (
    <ModuleCard>
      <ModuleHeader title={t('moduleTitle')} subtitle={t('moduleSubtitle')} />
      <HideListedItemsButton showItems={showCompletedItem}
                             setShowItems={setShowCompletedItem}
                             injectedStyle={followUpsListingComponentStyles}
      />
      <ModuleListing<FollowUpTeam>
        data-testid="listing-followUp"
        elements={filterList(teamFollowUps)}
        injectedStyle={followUpsListingComponentStyles}
        isAddElementLoading={isAddFollowUpLoading}
        isLoading={fetchFollowUpsByTeamIdState.isLoading || isAddFollowUpLoading}
        errorName={fetchFollowUpsByTeamIdState.errorName || addFollowUpErrorName}
      >
        {
          (followUpTeam: FollowUpTeam) => <TeamFollowUpContainer key={followUpTeam.id}
                                                                 followUpTeam={followUpTeam} />
        }
      </ModuleListing>
      <div className={styles.input}>
        <InputEnter data-testid="add-followUp"
                    placeholder={t('addElement')}
                    errorMessage={addFollowUpErrorName && getErrorMessage(addFollowUpErrorName)}
                    setValue={(value) => dispatchAddFollowUp(value)}
        />
      </div>
    </ModuleCard>
  );
};

export default TeamFollowUpsContainer;
