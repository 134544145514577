import FollowUp from "module/followUp/domain/model/FollowUp";

export default class FollowUpCoachee extends FollowUp {
  private readonly _coacheeId: string;
  
  constructor(
    id: string,
    text: string,
    createdAt: string,
    completed: boolean,
    coacheeId: string,
  ) {
    super(id, text, createdAt, completed);
    this._coacheeId = coacheeId;
  }
  
  get coacheeId(): string {
    return this._coacheeId;
  }
}
