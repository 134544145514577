import React from "react";
import {CheckboxButton} from "asset/component";
import SubObjective from "module/objective/domain/model/SubObjective";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {useDispatch} from "react-redux";
import {Dispatch} from "store";
import UpdateSubObjective from "module/objective/domain/model/UpdateSubObjective";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import styles from "module/mandate/view/asset/edit/MandateSubObjectiveContainer.module.scss";

const MandateSubObjectiveContainer = ({
  subObjective,
  objectiveId,
  mandateId,
  isEditable = true
}: {
  subObjective: SubObjective,
  objectiveId: string,
  mandateId: string,
  isEditable?: boolean
}) => {
  const dispatch = useDispatch<Dispatch>();
  const {getErrorMessage} = useErrorTranslation();
  const [isUpdateSubObjectiveLoading, updateSubObjectiveErrorName, dispatchUpdateSubObjective] =
    usePromiseLoadingAndError((isCompleted) =>
      dispatch.ObjectiveMandateStore.updateSubObjectiveForMandate({
        updateSubObjective: new UpdateSubObjective(isCompleted),
        subObjectiveId: subObjective.id,
        objectiveId: objectiveId,
        mandateId: mandateId
      })
    );
  
  const [isDeleteSubObjectiveLoading, deleteSubObjectiveErrorName, dispatchDeleteSubObjective] =
    usePromiseLoadingAndError(() =>
      dispatch.ObjectiveMandateStore.deleteSubObjectiveForMandate({
        subObjectiveId: subObjective.id,
        objectiveId: objectiveId,
        mandateId: mandateId,
      })
    );
  
  const editableCheckboxButton =
    <CheckboxButton data-testid={`${subObjective.id}-checkbox-button`}
                    value={subObjective.completed}
                    setValue={dispatchUpdateSubObjective}
                    deleteElement={dispatchDeleteSubObjective}
                    label={subObjective.text}
                    isLoading={isUpdateSubObjectiveLoading || isDeleteSubObjectiveLoading}
                    errorMessage={
                      updateSubObjectiveErrorName && getErrorMessage(updateSubObjectiveErrorName) ||
                      deleteSubObjectiveErrorName && getErrorMessage(deleteSubObjectiveErrorName)
                    }
    />;
  
  const nonEditableCheckboxButton =
    <div className={`${styles.fakeCheckboxButton} ${subObjective.completed && styles.checked}`}
         data-testid="fake-checkbox-button">
      {subObjective.text}
    </div>;
  
  return (
    <>
      {isEditable ? editableCheckboxButton : nonEditableCheckboxButton}
    </>
  );
};

export default MandateSubObjectiveContainer;
