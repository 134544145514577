import UpdateObjective from "module/objective/domain/model/UpdateObjective";

export default interface UpdateObjectiveRequest {
  name: string;
  text: string;
}

export function createUpdateObjectiveRequest(updateObjective: UpdateObjective): UpdateObjectiveRequest {
  return {
    name: updateObjective.name,
    text: updateObjective.text,
  } as UpdateObjectiveRequest;
}
