import {SubscriptionStatus} from "module/subscription/domain/model/SubscriptionStatus";
import {status} from "@grpc/grpc-js";

export default class Subscription {
  private readonly _status: SubscriptionStatus;
  private readonly _ownerEmail; string;
  private readonly _endOfTrialPeriod: Date | undefined;
  private readonly _workspaceId: string;
  private readonly _cancellationEffectiveDate: Date | undefined;
  
  constructor(
    workspaceId: string,
    status: SubscriptionStatus,
    ownerEmail: string,
    endOfTrialPeriod?: string,
    cancellationEffectiveDate?: string,
  ) {
    this._status = status;
    this._ownerEmail = ownerEmail;
    this._workspaceId = workspaceId;
    
    if (endOfTrialPeriod) {
      try {
        this._endOfTrialPeriod = new Date(endOfTrialPeriod);
      } catch {
        this._endOfTrialPeriod = undefined;
      }
    }
    
    if (cancellationEffectiveDate) {
      try {
        this._cancellationEffectiveDate = new Date(cancellationEffectiveDate);
      } catch {
        this._cancellationEffectiveDate = undefined;
      }
    }
  }
  
  get workspaceId(): string {
    return this._workspaceId;
  }

  get status(): SubscriptionStatus {
    return this._status;
  }
  
  get ownerEmail(): string {
    return this._ownerEmail;
  }
  
  get endOfTrialPeriod(): Date | undefined {
    return this._endOfTrialPeriod;
  }
  
  get cancellationEffectiveDate(): Date | undefined {
    return this._cancellationEffectiveDate;
  }
}
