import React from 'react';
import Slick from "react-slick";
import "asset/component/asset/global/slider/Slider.scss";
import {NextArrow, PreviousArrow} from "asset/component/global/slider/SliderArrows";

const Slider = ({slidesToShow = 4, children}: {
  slidesToShow?: number,
  children: JSX.Element[]
}) => {
  const activateSlider = children.length >= slidesToShow;
  
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    useTransform: false
  };
  
  return <div className={`${!activateSlider ? 'not-active-slider' : 'active-slider'} slider`}>
    <Slick className={!activateSlider ? 'align-left' : ''} {...settings} >
      {children}
    </Slick>
  </div>;
};

export default Slider;
