import AuthenticationRepository from 'module/authentication/domain/AuthenticationRepository';
import AuthRESTClient from "module/authentication/dal/AuthRESTClient";
import RESTClient from "core/client/RESTClient";
import ApiKeyService from "core/client/ApiKeyService";

export default class RESTAuthenticationRepository implements AuthenticationRepository {
  private readonly authClient: AuthRESTClient;
  private readonly restClient: RESTClient;
  private static readonly BASE_URL: string = "api";
  private static readonly AUTH_URL: string = "onboarding";
  
  constructor(authClient: AuthRESTClient, restClient: RESTClient) {
    this.authClient = authClient;
    this.restClient = restClient;
  }
  
  async login(email: string, password: string): Promise<void> {
    await this.authClient.login(email, password);
  }
  
  async register(email: string, firstName: string, lastName: string, password: string, locale: string): Promise<void> {
    const apiKeyService = new ApiKeyService();
    
    await this.restClient.apiPost<void>(
      `/${RESTAuthenticationRepository.BASE_URL}` +
      `/${RESTAuthenticationRepository.AUTH_URL}/`,
      {
        email,
        firstName,
        lastName,
        password,
        locale,
        apiKey: apiKeyService.getApiKey()
      }
    ).then(() => {
      this.authClient.login(email, password);
    });
  }
  
  async resetPassword(email?: string): Promise<void> {
    await this.authClient.resetPassword(email);
  }
  
  async logout(): Promise<void> {
    await this.authClient.logout();
  }
}
