import React from "react";
import {InputOption} from "asset/component/form/inputs/InputSelect";
import UpdateTeamNameFormComponent from "module/team/view/component/updateTeam/UpdateTeamNameFormComponent";
import UpdateTeamOrganisationFormComponent
  from "module/team/view/component/updateTeam/UpdateTeamOrganisationFormComponent";
import {Typography} from "asset/component";
import {useTranslation} from "react-i18next";
import usePaletteColors from "style/usePaletteColors";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import styles from "module/team/view/asset/UpdateTeamFormComponent.module.scss";

export interface UpdateTeamStateProps {
  name: string;
  setName: ((name: string) => void);
  companyId: string;
  setCompanyId: ((companyId: string) => void);
  currentCompanyId?: string;
}

export interface UpdateTeamFormComponentProps {
  updateTeamState: UpdateTeamStateProps;
  companyOptions: InputOption[];
}

const UpdateTeamFormComponent = ({
  companyOptions,
  updateTeamState
}: UpdateTeamFormComponentProps) => {
  const {t} = useTranslation('team');
  const palette = usePaletteColors();
  const {
    name,
    setName,
    setCompanyId,
    currentCompanyId,
  } = updateTeamState;
  
  return <div className={styles.updateTeamFormComponent}>
    <Typography component="p" variant={MuiTypographyVariants.H4} color={palette.secondary.dark}>
      {t('form.update.title')}
    </Typography>
    <UpdateTeamNameFormComponent name={name}
                                 setName={setName}
    />
    <UpdateTeamOrganisationFormComponent data-testid="company-form"
                                         companyOptions={companyOptions}
                                         companyId={currentCompanyId}
                                         setCompanyId={setCompanyId}
    />
  </div>;
};

export default UpdateTeamFormComponent;
