import React from 'react';
import 'asset/component/asset/global/slider/NavigationSlider.scss';
import {NextArrow, PreviousArrow} from "asset/component/global/slider/SliderArrows";
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import useNavigationSlider, {ComponentProps, DIRECTION} from 'asset/component/hook/useNavigationSlider';

const NavigationSlider = ({
    Component,
    componentsProps,
    selectedId,
    id
  }:
    {
      Component: (any) => JSX.Element,
      componentsProps: ComponentProps[],
      selectedId: string;
      id?: string;
    }) => {
    const getDirectionByLevel = (level) => {
      if (level < 0) {
        return DIRECTION.right;
      } else if (level > 0) {
        return DIRECTION.left;
      }
      
      return DIRECTION.middle;
    };
    
    const renderComponent = (slideProps, horizontalLevel) => {
      const direction = getDirectionByLevel(horizontalLevel);
      
      const onClick = () => onSliderClick(slideProps, direction, horizontalLevel);
      
      return (
        <CSSTransition key={`${slideProps.id} ${slideProps.isClone && 'clone'}`}
                       classNames={direction}
                       timeout={100}>
          <div onClick={onClick}
               id={slideProps.id}
               className={`slide ${direction}-horizontal-level-${Math.abs(horizontalLevel)}`}>
            <Component{...slideProps} />
          </div>
        </CSSTransition>
      );
    };
    
    const [
      slides,
      moveLeft,
      moveRight,
      onSliderClick,
      hasFasterTransition,
    ] =
      useNavigationSlider(
        componentsProps,
        selectedId,
        renderComponent
      );
    
    return (
      <div id="carousel"
           className={`slide-count-${componentsProps.length} ${hasFasterTransition && 'faster-transition'}`}>
        <PreviousArrow onClick={() => moveLeft()} />
        <TransitionGroup className="slide-container"
                         id={slides.length > 1 ? id : undefined}
                         data-testid="slide-container">
          {slides}
        </TransitionGroup>
        <NextArrow onClick={() => moveRight()} />
      </div>
    );
  }
;

export default NavigationSlider;
