import AuthRESTClient from 'module/authentication/dal/AuthRESTClient';
import {Auth, getIdToken, signInWithEmailAndPassword, signOut, sendPasswordResetEmail} from 'firebase/auth';
import ServerIsDownError from "core/error/ServerIsDownError";
import {mapRESTResponseToError} from "core/helper/ErrorMapper";
import AppError from "core/error/AppError";

export enum FireAuthErrorName {
  USER_NOT_FOUND = 'user-not-found',
  WRONG_PASSWORD = 'wrong-password',
}

export default class FirebaseAuthRESTClient implements AuthRESTClient {
  private static readonly FIREBASE_API_KEY: string = process.env.REACT_APP_FIREBASE_API_KEY as string;
  private static readonly FIREBASE_AUTH_DOMAIN: string = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string;
  public static readonly FIREBASE_CONFIG = {
    apiKey: FirebaseAuthRESTClient.FIREBASE_API_KEY,
    authDomain: FirebaseAuthRESTClient.FIREBASE_AUTH_DOMAIN
  };
  protected readonly firebaseAuthentication: Auth;
  
  constructor(firebaseAuthentication: Auth) {
    this.firebaseAuthentication = firebaseAuthentication;
  }
  
  protected static mapToError(response): AppError {
    if (!response) {
      throw new ServerIsDownError();
    }
    
    const clientError: { status?: string } = {status: undefined};
    
    if (this.isResponseHasErrorMessage(response, FireAuthErrorName.USER_NOT_FOUND)) {
      clientError.status = '404';
    } else if (this.isResponseHasErrorMessage(response, FireAuthErrorName.WRONG_PASSWORD)) {
      clientError.status = '401';
    } else {
      clientError.status = '400';
    }
    
    throw mapRESTResponseToError(clientError);
  }
  
  private static isResponseHasErrorMessage(response, errorMessage) {
    return response.toString().includes(errorMessage);
  }
  
  public async login(email: string, password: string): Promise<void> {
    try {
      await signInWithEmailAndPassword(this.firebaseAuthentication, email, password);
    } catch (error) {
      FirebaseAuthRESTClient.mapToError(error);
    }
  }
  
  public async logout(): Promise<void> {
    try {
      await signOut(this.firebaseAuthentication);
    } catch (error) {
      FirebaseAuthRESTClient.mapToError(error);
    }
  }
  
  public async resetPassword(email?): Promise<void> {
    try {
      await sendPasswordResetEmail(this.firebaseAuthentication, email || this.firebaseAuthentication.currentUser?.email);
    } catch (error) {
      FirebaseAuthRESTClient.mapToError(error);
    }
  }
  
  public async getToken(): Promise<string> {
    try {
      const currentUser = this.firebaseAuthentication.currentUser;
      
      if (currentUser) {
        return await getIdToken(currentUser);
      }
    } catch (error) {
      FirebaseAuthRESTClient.mapToError(error);
    }
    
    return '';
  }
}
