import SubObjective from "./SubObjective";
import Objective from "./Objective";
import {NeedsCoachee} from "module/objective/domain/model/need/NeedsCoachee";
import {StateStatus} from "module/objective/domain/model/StateStatus";

export default class ObjectiveCoachee extends Objective {
  private readonly _needs: NeedsCoachee[];
  private readonly _coacheeId: string;
  
  constructor(
    id: string,
    coacheeId: string,
    name: string,
    text: string,
    createdAt: string,
    state: StateStatus,
    subObjectives: SubObjective[],
    needs?: NeedsCoachee[]
  ) {
    super(id, name, text, createdAt, state, subObjectives);
    this._coacheeId = coacheeId;
    this._needs = needs || [];
  }
  
  get coacheeId(): string {
    return this._coacheeId;
  }
  
  get needs(): NeedsCoachee[] {
    return this._needs;
  }
}
