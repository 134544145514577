import React, {useEffect} from 'react';
import DialogMessage from "asset/component/global/dialogs/DialogMessage";
import {ErrorIcon} from "asset/component/Icons";
import {useTranslation} from "react-i18next";
import MobileServices from "asset/service/MobileServices";
import useMediaBreakpoints from "asset/service/useMediaBreakpoints";

const MobileAlert = () => {
  const {t} = useTranslation('common');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  
  const {isMobile} = useMediaBreakpoints();
  
  useEffect(() => {
    MobileServices.showMobileMessage() && isMobile ?
      setDialogOpen(true) :
      setDialogOpen(false);
  }, [isMobile]);
  
  const handleClose = () => {
    setDialogOpen(false);
    MobileServices.showedMobileMessage();
  };
  
  return (
    <DialogMessage open={dialogOpen}
                   setOpen={() => handleClose()}
                   text={t("mobile.warning")}
                   icon={<ErrorIcon />}
    />
  );
};

export default MobileAlert;
