import React from 'react';
import styles from "module/mandate/view/asset/listing/MandateComponent.module.scss";
import {FormControlLabel, Switch, Typography} from "@mui/material";
import {Button} from "asset/component";
import {Routes} from "router/Routes";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import MuiButtonSizes from "style/props/MuiButtonSizes";
import {AddIcon} from "asset/component/Icons";
import SearchBar, {SearchBarSize} from "asset/component/search/SearchBar";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {MandatesSequence} from "module/onboarding/domain/model/SeedData/OnboardingSequencesSeed";
import useMediaBreakpoints from "asset/service/useMediaBreakpoints";

const MandatesTopBar = ({
  toggleFinished,
  displayFinished,
  onSearch
}: {
  toggleFinished: () => void,
  displayFinished: boolean,
  onSearch: (value: string) => void
}) => {
  const {t} = useTranslation('mandate');
  const navigate = useNavigate();
  const {isMobile} = useMediaBreakpoints();
  
  return (
    <div className={styles.header}>
      <div className={styles.topRow}>
        <Typography variant="h2" component="h1" className={styles.title}>
          {t('titles.main')}
        </Typography>
        <Button onClick={() => navigate(Routes.add.mandate.path())}
                className={styles.addMandateButton}
                variant={MuiButtonVariants.CONTAINED}
                size={MuiButtonSizes.LARGE}
                id={MandatesSequence.steps[0].anchorID}
                startIcon={<AddIcon />}>
          {t('actions.add')}
        </Button>
      </div>
      <div className={styles.bottomRow}>
        {/*TODO : Lors du refactor, remplacer la div className={styles.toggler} par le component Toggle dans le dossier component.*/}
        <div className={styles.toggler}>
          <FormControlLabel
            className={styles.switchLabel}
            label={t("actions.displayFinished")}
            labelPlacement="start"
            id={MandatesSequence.steps[1].anchorID}
            control={
              <Switch
                checked={displayFinished}
                className={styles.switch}
                onChange={toggleFinished}
              />}
          />
          {displayFinished && <span>{t('common:yes')}</span>}
        </div>
        <div className={styles.searchContainer}>
          <SearchBar onSearch={onSearch}
                     placeholder={t('actions.search.placeholder')}
                     size={isMobile ? SearchBarSize.LARGE : SearchBarSize.MEDIUM} />
        </div>
      </div>
    </div>
  );
};

export default MandatesTopBar;
