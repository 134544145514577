import Company from "module/company/domain/model/Company";

export default interface CompanyResponse {
  id: string;
  name: string;
  teamIds: string[];
}

export function companyResponseToModel(companyResponse: CompanyResponse): Company {
  return new Company(
    companyResponse.id,
    companyResponse.name,
    companyResponse.teamIds,
  );
}

export function companiesResponseToModel(companiesResponse: CompanyResponse[]): Company[] {
  return companiesResponse.map(companyResponse => companyResponseToModel(companyResponse));
}
