import {useState} from "react";
import Navigation from "asset/service/Navigation";
import {onEnterKeyPress} from "asset/service/Form";

const useNavigationFormSections = () => {
  const [activatedSections, setActivatedSections] = useState<string[]>([]);
  
  const activateSection = (sectionId: string) => {
    setActivatedSections([...activatedSections, sectionId]);
    
    Navigation.AnchorRedirection(sectionId);
  };
  
  const resetSections = () => {
    Navigation.AnchorRedirection("");
  };
  
  const isSectionActivated = (section: string) => {
    return activatedSections.includes(section);
  };
  
  const nextSectionOnEnterPress = ((event, sectionId) => {
    onEnterKeyPress(event, () => activateSection(sectionId));
  });
  
  return {activateSection, isSectionActivated, nextSectionOnEnterPress, resetSections};
};

export default useNavigationFormSections;
