import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import CultureTag from "module/culture/domain/model/CultureTag";
import {RootState} from "store";
import AppError from "core/error/AppError";
import ReducerServices from "store/service/ReducerServices";
import AddCultureTag from "module/culture/domain/model/AddCultureTag";

export const CultureTagStore = createModel<RootModel>()({
  state: {
    cultureTags: [] as CultureTag[],
    getters: (state: RootState) => ({
      getCultureTagsByTeamId: (teamId: string) => {
        return state.CultureTagStore.cultureTags.filter(cultureTag => cultureTag.teamId == teamId);
      }
    })
  },
  reducers: {
    setCultureTags(state, cultureTags: CultureTag[]) {
      return {
        ...state,
        cultureTags: ReducerServices.setItemsToList<CultureTag>(state.cultureTags, cultureTags)
      };
    },
    setCultureTag(state, cultureTag: CultureTag) {
      return {
        ...state,
        cultureTags: ReducerServices.setItemToList<CultureTag>(state.cultureTags, cultureTag)
      };
    },
    removeCultureTagById(
      state,
      cultureTagId: string
    ) {
      return {
        ...state,
        cultureTags: ReducerServices.removeItemFromList<CultureTag>(state.cultureTags, cultureTagId)
      };
    },
  },
  effects: (dispatch) => ({
    async fetchCultureTags(payload: { teamId: string }) {
      await clients.CultureTag.fetchCultureTags(payload.teamId).then((cultureTags: CultureTag[]) => {
        dispatch.CultureTagStore.setCultureTags(cultureTags);
      }).catch((error: AppError) => {
        throw error.name;
      });
    },
    async addCultureTag(payload: { addCultureTag: AddCultureTag, teamId: string }) {
      return await clients.CultureTag
        .addCultureTag(payload.addCultureTag, payload.teamId)
        .then((addedCultureTag) => {
          dispatch.CultureTagStore.setCultureTag(addedCultureTag);
          
          return addedCultureTag;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteCultureTag(payload: { cultureTagId: string, teamId: string }) {
      return await clients.CultureTag
        .deleteCultureTag(payload.cultureTagId, payload.teamId)
        .then(() => {
          dispatch.CultureTagStore.removeCultureTagById(payload.cultureTagId);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
  })
});
