import fonts from "style/variable/_fonts.scss";

export default {
  typography: {
    fontFamily: fonts.fontFamily,
    fontWeightRegular: fonts.fontRegular,
    h1: {
      letterSpacing: fonts.letterSpacing,
      fontSize: fonts.h1FontSize,
      fontWeight: fonts.h1FontWeight
    },
    h2: {
      letterSpacing: fonts.letterSpacing,
      fontSize: fonts.h2FontSize,
      fontWeight: fonts.h2FontWeight
    },
    h3: {
      letterSpacing: fonts.letterSpacing,
      fontSize: fonts.h3FontSize,
      fontWeight: fonts.h3FontWeight
    },
    h4: {
      letterSpacing: fonts.letterSpacing,
      fontSize: fonts.h4FontSize,
      fontWeight: fonts.h4FontWeight
    },
    h5: {
      letterSpacing: fonts.letterSpacing,
      fontSize: fonts.h5FontSize,
      fontWeight: fonts.h5FontWeight
    },
    sm: {
      letterSpacing: fonts.letterSpacing,
      fontSize: fonts.smFontSize,
      fontWeight: fonts.smFontWeight
    },
    xs: {
      letterSpacing: fonts.letterSpacing,
      fontFamily: fonts.fontFamily,
      fontSize: fonts.xsFontSize,
      fontWeight: fonts.xsFontWeight
    },
    xxs: {
      letterSpacing: fonts.letterSpacing,
      fontFamily: fonts.fontFamily,
      fontSize: fonts.xxsFontSize,
      fontWeight: fonts.xxsFontWeight
    }
  },
};
