import type RESTClient from "core/client/RESTClient";
import SkillRepository from "module/skill/domain/SkillRepository";
import SkillResponse, {skillResponseToModel, skillsResponseToModel} from "module/skill/dal/dto/SkillResponse";
import Skill from "module/skill/domain/model/Skill";
import {createAddSkillRequest} from "module/skill/dal/dto/AddSkillRequest";
import AddSkill from "module/skill/domain/model/AddSkill";

export default class RESTSkillRepository implements SkillRepository {
  private static readonly BASE_URL: string = "/api/coachees";
  private static readonly SKILL_URL: string = "/skills";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchSkills(coacheeId: string): Promise<Skill[]> {
    const skillsResponse = await this.client.apiGet<SkillResponse[]>(
      `${RESTSkillRepository.BASE_URL}` +
      `/${coacheeId}` +
      `${RESTSkillRepository.SKILL_URL}`
    );
    
    return skillsResponseToModel(skillsResponse, coacheeId);
  }
  
  public async addSkill(addSkill: AddSkill, coacheeId: string): Promise<Skill> {
    const skillResponse = await this.client.apiPost<SkillResponse>(
      `${RESTSkillRepository.BASE_URL}` +
      `/${coacheeId}` +
      `${RESTSkillRepository.SKILL_URL}`,
      createAddSkillRequest(addSkill)
    );
    
    return skillResponseToModel(skillResponse, coacheeId);
  }
  
  public async deleteSkill(skillId: string, coacheeId: string): Promise<void> {
    await this.client.apiDelete<void>(
      `${RESTSkillRepository.BASE_URL}` +
      `/${coacheeId}` +
      `${RESTSkillRepository.SKILL_URL}` +
      `/${skillId}`
    );
  }
}
