import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import Coachee from "module/coachee/domain/model/Coachee";
import AddCoachee from "module/coachee/domain/model/AddCoachee";
import {RootState} from "store";
import AppError from "core/error/AppError";
import ReducerServices from "store/service/ReducerServices";
import UpdateCoachee from "module/coachee/domain/model/UpdateCoachee";

export const CoacheeStore = createModel<RootModel>()({
  state: {
    coachees: [] as Coachee[],
    getters: (state: RootState) => ({
      getCoacheeById: (id: string) => {
        return state.CoacheeStore.coachees.find(coachee => coachee.id == id);
      },
      getCoacheesByTeamId: (teamId: string) => {
        return state.CoacheeStore.coachees.filter(coachee => coachee.teamIds?.includes(
          teamId));
      },
      getCoacheesByCompanyIdWithoutTeam: (companyId: string) => {
        return state.CoacheeStore.coachees.filter(coachee => {
          return coachee.companyId == companyId && coachee.teamIds.length === 0;
        });
      }
    })
  },
  reducers: {
    setCoachees(state, coachees: Coachee[]) {
      return {
        ...state,
        coachees: ReducerServices.setItemsToList<Coachee>(
          state.coachees,
          coachees
        )
      };
    },
    setCoachee(state, coachee: Coachee) {
      return {
        ...state,
        coachees: ReducerServices.setItemToList<Coachee>(
          state.coachees,
          coachee
        )
      };
    },
    removeCoacheeById(
      state,
      coacheeId: string
    ) {
      return {
        ...state,
        coachees: ReducerServices.removeItemFromList<Coachee>(
          state.coachees,
          coacheeId
        )
      };
    },
  },
  effects: (dispatch) => ({
    async fetchCoachees() {
      await clients.Coachee
        .fetchCoachees()
        .then((coachees: Coachee[]) => {
          dispatch.CoacheeStore.setCoachees(coachees);
        })
        .catch((error: AppError) => {
          throw error.name;
        });
    },
    async fetchCoacheesByTeamId(id: string) {
      await clients.Coachee
        .fetchCoacheesByTeamId(id)
        .then((coachees: Coachee[]) => {
          dispatch.CoacheeStore.setCoachees(coachees);
        })
        .catch((error: AppError) => {
          throw error.name;
        });
    },
    async fetchCoacheesByCompanyIdWithoutTeam(companyId: string) {
      await clients.Coachee
        .fetchCoacheesByCompanyIdWithoutTeam(companyId)
        .then((coachees: Coachee[]) => {
          dispatch.CoacheeStore.setCoachees(coachees);
        })
        .catch((error: AppError) => {
          throw error.name;
        });
    },
    async fetchCoacheeById(coacheeId: string) {
      await clients.Coachee
        .fetchCoacheeById(coacheeId)
        .then(
          (coachee: Coachee) => {
            dispatch.CoacheeStore.setCoachee(coachee);
          })
        .catch((error: AppError) => {
          throw error.name;
        });
    },
    async addCoachee(addCoachee: AddCoachee) {
      return await clients.Coachee
        .addCoachee(addCoachee)
        .then((addedCoachee) => {
          dispatch.CoacheeStore.setCoachee(addedCoachee);
          
          return addedCoachee;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async updateCoachee(updateCoachee: UpdateCoachee) {
      return await clients.Coachee
        .updateCoachee(updateCoachee)
        .then((updatedCoachee) => {
          dispatch.CoacheeStore.setCoachee(updatedCoachee);
          
          return updatedCoachee;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteCoachee(payload: { coacheeId: string }) {
      return await clients.Coachee
        .deleteCoachee(payload.coacheeId)
        .then(() => {
          dispatch.CoacheeStore.removeCoacheeById(payload.coacheeId);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
  })
});
