import Team from "module/team/domain/model/Team";

export default interface TeamResponse {
  id: string;
  name: string;
  companyId: string;
  note: string;
}

export function teamResponseToModel(teamResponse: TeamResponse): Team {
  return new Team(
    teamResponse.id,
    teamResponse.name,
    teamResponse.companyId,
    teamResponse.note
  );
}

export function teamsResponseToModel(teamsResponse: TeamResponse[]): Team[] {
  return teamsResponse.map(teamResponse => teamResponseToModel(teamResponse));
}
