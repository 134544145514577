import React from 'react';
import styles from "module/mandate/view/asset/add/AddMandateInfoFormComponent.module.scss";
import formStyles from "module/mandate/view/asset/add/inputs/MandateInputs.module.scss";
import {TextValidator} from "asset/validator";
import {InputValidator} from "asset/validator/InputValidator";
import {useTranslation} from "react-i18next";

const MandateTitleInput = ({
  title,
  setTitle
}: {
  title: string,
  setTitle: (title: string) => void
}) => {
  const {t} = useTranslation('mandate');
  
  return (
    <div className={styles.inputContainer}>
      <TextValidator
        type="text"
        id="title"
        name="title"
        placeholder={t('form.title.placeholder')}
        value={title}
        onChange={(event) => setTitle(event.target.value)}
        validators={[InputValidator.REQUIRED]}
        errorMessages={[t('form.validation.title')]}
        autoFocus
        fullWidth
      />
    </div>
  );
};

export default MandateTitleInput;
