export interface ILinkableEntity {
  id: string;
  objectId: string;
  name: string;
  type: LinkableEntityType;
}

export enum LinkableEntityType {
  COACHEE = 'COACHEE',
  TEAM = 'TEAM',
  COMPANY = 'COMPANY',
}

export default class LinkableEntity implements ILinkableEntity {
  private readonly _id: string;
  private readonly _objectId: string;
  private readonly _name: string;
  private readonly _type: LinkableEntityType;
  
  constructor(id: string, objectId: string, name: string, type: LinkableEntityType) {
    this._id = id;
    this._objectId = objectId;
    this._name = name;
    this._type = type;
  }
  
  get id(): string {
    return this._id;
  }
  
  get objectId(): string {
    return this._objectId;
  }
  
  get name(): string {
    return this._name;
  }
  
  get type(): LinkableEntityType {
    return this._type;
  }
}
