import React from 'react';
import AddSkill from "module/skill/domain/model/AddSkill";
import Skill from "module/skill/domain/model/Skill";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import TagDisplayer from "asset/component/tag/TagDisplayer";
import {useTranslation} from "react-i18next";
import {Tag} from "asset/component/tag/Tag";
import buildTagsFromSkills from "module/skill/view/BuildTagsFromSkills";
import InputEnter from "asset/component/form/inputs/InputEnter";
import Checkmark from "asset/icon/Checkmark";
import {getFilteredPredefinedSkills} from "module/skill/view/PredefinedSkills";
import ModuleSectionLayout from "asset/component/module/ModuleSectionLayout";

const SkillsContainer = ({coacheeId}: { coacheeId: string }) => {
  const dispatch = useDispatch<Dispatch>();
  const {t} = useTranslation('skill');
  
  const skills = useSelector(
    (rootState: RootState) => rootState.SkillStore?.getters(rootState).getSkillsByCoacheeId(coacheeId)
  );
  
  const fetchSkillsState = useLoadingAndError(
    () => dispatch.SkillStore.fetchSkills({coacheeId}), [], coacheeId
  );
  
  const [isAddSkillLoading, addSkillErrorName, dispatchAddSkill] =
    usePromiseLoadingAndError((title) =>
      dispatch.SkillStore.addSkill({
        addSkill: new AddSkill(title),
        coacheeId
      })
    );
  
  const [isRemoveSkillLoading, removeSkillErrorName, dispatchRemoveSkill] =
    usePromiseLoadingAndError((skill: Skill) =>
      dispatch.SkillStore.deleteSkill({
        skillId: skill.id,
        coacheeId
      })
    );
  
  const skillTags: Tag[] = buildTagsFromSkills(skills, true);
  const predefinedSkillsTags: Tag[] = getFilteredPredefinedSkills(skills);
  
  return (
    <ModuleSectionLayout
      title={t('titles.skills')}
      isLoading={isAddSkillLoading || isRemoveSkillLoading || fetchSkillsState.isLoading}
      errorName={fetchSkillsState.errorName || addSkillErrorName || removeSkillErrorName}
      theme="cream"
    >
      <TagDisplayer
        skipEditModeStep={false}
        tags={skillTags}
        predefinedTags={predefinedSkillsTags}
        addTag={(tag) => dispatchAddSkill(tag.name)}
        removeTag={(tag) => dispatchRemoveSkill(tag)}
        input={<InputEnter placeholder={t('input.placeholderSkill')}
                           errorMessage={t('input.errorMessageSkill')}
                           setValue={(value) => dispatchAddSkill(value)}
                           isOnNewLine={true}/>}
        icon={<Checkmark />}
      />
    </ModuleSectionLayout>
  );
};

export default SkillsContainer;
