export default class Company {
  private readonly _id: string;
  private readonly _name: string;
  private readonly _teamIds: string[];
  
  constructor(id: string, name: string, teamIds: string[]) {
    this._id = id;
    this._name = name;
    this._teamIds = teamIds;
  }
  
  get id(): string {
    return this._id;
  }
  
  get name(): string {
    return this._name;
  }
  
  get teamIds(): string[] {
    return this._teamIds;
  }
}
