import {Tag} from "asset/component/tag/Tag";
import i18n from "i18next";

export default class TagServices<TagNames extends string> {
  private readonly _translationKey: string;
  private readonly _tagNames: TagNames[];
  
  constructor(translationKey, tagNames: TagNames[]) {
    this._translationKey = translationKey;
    this._tagNames = tagNames;
  }
  
  public buildFilteredPredefinedTagNames = (actualTagNames: TagNames[]) => {
    return this.buildTags(this._tagNames.filter((tagName) => {
      return !actualTagNames.some((actualTagName) => actualTagName === tagName);
    }));
  };
  
  public buildTags = (tagNames: TagNames[], isSelected: boolean = false) => {
    return tagNames.map(tagName => this.buildTag(tagName, isSelected));
  };
  
  private buildTag = (tagName: TagNames, isSelected: boolean) => {
    return {
      id: tagName,
      name: this.getTranslationTagNames(tagName),
      isSelected: isSelected
    } as Tag;
  };
  
  private getTranslationTagNames = (predefinedTagNames) => {
    return i18n.t(`${this._translationKey}.${predefinedTagNames}`);
  };
}
