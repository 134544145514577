import React, {useEffect, useState} from "react";
import AddTeamFormComponent, {AddTeamStateProps} from "module/team/view/component/AddTeamFormComponent";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import {useNavigate} from "react-router-dom";
import {Routes} from "router/Routes";
import AddTeam from "module/team/domain/model/AddTeam";
import {InputOption} from "asset/component/form/inputs/InputSelect";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import BodySectionLayout from "asset/component/layout/BodySectionLayout";
import AddSomething from "asset/component/form/AddSomething";
import FormHeader from "asset/component/form/FormHeader";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import HadAccess from "module/authorization/HadAccess";
import {Accesses} from "module/authorization/domain/model/accesses";

const AddTeamFormContainer = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState("");
  
  const [companyOptions, setCompanyOptions] = useState<InputOption[]>([]);
  
  const {companies} = useSelector(
    (rootState: RootState) => rootState.CompanyStore);
  
  const companyState = useLoadingAndError(
    () => dispatch.CompanyStore.fetchCompanies()
  );
  
  const addTeamState = {
    name,
    setName,
    companyName,
    setCompanyName,
    companyId,
    setCompanyId
  } as AddTeamStateProps;
  
  const [addTeamLoading, addTeamErrorName, addTeamDispatch] =
    usePromiseLoadingAndError((isCompleted, text) =>
      dispatch.TeamStore.addTeam(
        new AddTeam(
          name,
          companyName,
          companyId
        )).then((addedTeam) => {
        addedTeam && navigate(Routes.team.profile.path({teamId: addedTeam.id, companyId: addedTeam.companyId})
        );
      })
    );
  
  useEffect(() => {
    setCompanyOptions(companies.map(company => ({
      value: company.id,
      label: company.name
    })));
  }, [companies]);
  
  return (<>
    <HadAccess accesses={[Accesses.COACHEES_MANAGEMENT]}>
      <BodySectionLayout>
        <FormHeader />
        <AddSomething />
        <AddTeamFormComponent addTeam={addTeamDispatch}
                              addTeamState={addTeamState}
                              companyOptions={companyOptions}
                              isLoading={addTeamLoading}
                              errorName={addTeamErrorName} />
      </BodySectionLayout>
    </HadAccess>
  </>);
};

export default AddTeamFormContainer;
