import React, {useState} from "react";
import {ModuleCard, ModuleHeader} from "asset/component";
import {useTranslation} from "react-i18next";
import TeamNeedsComponent from "module/need/view/team/component/TeamNeedsComponent";
import {NeedsTeam} from "module/objective/domain/model/need/NeedsTeam";
import needTeamPyramidEn from "module/need/view/asset/image/needTeamPyramidEn.png";
import TeamObjectivesNeedContainer from "module/objective/view/container/team/TeamObjectivesNeedContainer";
import styles from "module/need/view/asset/team/TeamNeedContainer.module.scss";
import {ModuleTheme} from "asset/component/module/ModuleCard";
import needTeamPyramidFr from "module/need/view/asset/image/needTeamPyramidFr.png";
import {TeamSequence} from "module/onboarding/domain/model/SeedData/OnboardingSequencesSeed";

const TeamNeedsContainer = ({teamId}: { teamId: string }) => {
  const {t, i18n} = useTranslation('need');
  const [selectedNeed, setSelectedNeed] = useState<NeedsTeam>();
  
  const onNeedClick = (need: NeedsTeam) => {
    setSelectedNeed(need);
  };
  
  const unSelectNeed = () => {
    setSelectedNeed(undefined);
  };
  
  const getNeedTeamPyramid = () => {
    return i18n.language === 'fr' ? needTeamPyramidFr : needTeamPyramidEn;
  };
  
  const teamModalText = <>
    <p>{t('infoTeam.text')}</p>
    <p>{t('infoTeam.text2')}</p>
    <img src={getNeedTeamPyramid()} width={'100%'} alt="team needs pyramid" />
  </>;
  
  return <ModuleCard theme={selectedNeed && ModuleTheme.WHITE}>
    <div className={styles.teamNeedContainer}>
      <ModuleHeader title={t('moduleTitle')}
                    id={TeamSequence.steps[1].anchorID}
                    subtitle={
                      <>
                        <div>{t('moduleSubtitle')}</div>
                        <div>{t('moduleSubtitle2')}</div>
                      </>
                    }
                    modalInfo={{title: t('infoTeam.title'), text: teamModalText}} />
      {
        !selectedNeed ?
          <TeamNeedsComponent onNeedClick={onNeedClick} teamId={teamId} /> :
          <TeamObjectivesNeedContainer need={selectedNeed} teamId={teamId} onClose={unSelectNeed} />
      }
    </div>
  </ModuleCard>;
};

export default TeamNeedsContainer;
