import * as React from 'react';
import {useState} from 'react';
import type {Dispatch} from "store";
import {useDispatch} from "react-redux";
import RegisterFormComponent, {RegisterFormProps} from "module/authentication/view/component/RegisterFormComponent";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import LanguageManager from "core/helper/LanguageManager";

export default function RegisterContainer() {
  const dispatch = useDispatch<Dispatch>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  
  
  const [registerLoading, registerErrorName, registerDispatch, registerCompleted] =
    usePromiseLoadingAndError(() =>
      dispatch.AuthenticationStore.register(
        {email, firstName, lastName, password, "locale":LanguageManager.getCurrentLanguage()}
      )
    );
  
  const RegisterFormProps = {
    formState: {
      email,
      setEmail,
      password,
      setPassword,
      firstName,
      setFirstName,
      lastName,
      setLastName
    },
    register: {
      dispatch: registerDispatch,
      errorName: registerErrorName,
      isLoading: registerLoading
    }
  } as RegisterFormProps;
  
  return <RegisterFormComponent {...RegisterFormProps} />;
}
