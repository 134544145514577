import {Models} from "@rematch/core";
import {AuthenticationStore} from "module/authentication/AuthenticationStore";
import {CoacheeStore} from "module/coachee/CoacheeStore";
import {CompanyStore} from "module/company/CompanyStore";
import {TeamStore} from "module/team/TeamStore";
import {ObjectiveCoacheeStore} from "module/objective/ObjectiveCoacheeStore";
import {FollowUpCoacheeStore} from "module/followUp/FollowUpCoacheeStore";
import {ObjectiveTeamStore} from "module/objective/ObjectiveTeamStore";
import {FollowUpTeamStore} from "module/followUp/FollowUpTeamStore";
import {CoacheeMotivatorStore} from "module/motivator/coachee/CoacheeMotivatorStore";
import {CoacheeDemotivatorStore} from "module/demotivator/coachee/CoacheeDemotivatorStore";
import {UserStore} from "module/user/UserStore";
import {TeamMotivatorStore} from "module/motivator/team/TeamMotivatorStore";
import {TeamDemotivatorStore} from "module/demotivator/team/TeamDemotivatorStore";
import {MandateStore} from "module/mandate/MandateStore";
import {ObjectiveMandateStore} from "module/objective/ObjectiveMandateStore";
import {SkillStore} from "module/skill/SkillStore";
import {InformalRoleStore} from "module/skill/InformalRoleStore";
import {LinkableEntityStore} from "module/mandate/LinkableEntityStore";
import {CultureTagStore} from "module/culture/CultureTagStore";
import {OnboardingStore} from "module/onboarding/OnboardingStore";
import {ProfileStore} from "module/profile/ProfileStore";
import {SubscriptionStore} from "module/subscription/SubscriptionStore";
import {CommunicationLanguageStore} from "module/user/CommunicationLanguageStore";

export interface RootModel extends Models<RootModel> {
  AuthenticationStore: typeof AuthenticationStore;
  UserStore: typeof UserStore;
  SubscriptionStore: typeof SubscriptionStore;
  CoacheeStore: typeof CoacheeStore;
  CompanyStore: typeof CompanyStore;
  TeamStore: typeof TeamStore;
  ObjectiveCoacheeStore: typeof ObjectiveCoacheeStore;
  ObjectiveTeamStore: typeof ObjectiveTeamStore;
  ObjectiveMandateStore: typeof ObjectiveMandateStore;
  FollowUpCoacheeStore: typeof FollowUpCoacheeStore;
  FollowUpTeamStore: typeof FollowUpTeamStore;
  CoacheeMotivatorStore: typeof CoacheeMotivatorStore;
  TeamMotivatorStore: typeof TeamMotivatorStore;
  CoacheeDemotivatorStore: typeof CoacheeDemotivatorStore;
  TeamDemotivatorStore: typeof TeamDemotivatorStore;
  MandateStore: typeof MandateStore;
  SkillStore: typeof SkillStore;
  InformalRoleStore: typeof InformalRoleStore;
  LinkableEntityStore: typeof LinkableEntityStore;
  CultureTagStore: typeof CultureTagStore;
  OnboardingStore: typeof OnboardingStore;
  ProfileStore: typeof ProfileStore;
  CommunicationLanguageStore: typeof CommunicationLanguageStore;
}

export const rootModel: RootModel = {
  AuthenticationStore,
  UserStore,
  SubscriptionStore,
  CoacheeStore,
  CompanyStore,
  TeamStore,
  ObjectiveCoacheeStore: ObjectiveCoacheeStore,
  ObjectiveTeamStore: ObjectiveTeamStore,
  ObjectiveMandateStore: ObjectiveMandateStore,
  FollowUpCoacheeStore: FollowUpCoacheeStore,
  FollowUpTeamStore: FollowUpTeamStore,
  CoacheeMotivatorStore: CoacheeMotivatorStore,
  TeamMotivatorStore: TeamMotivatorStore,
  CoacheeDemotivatorStore: CoacheeDemotivatorStore,
  TeamDemotivatorStore: TeamDemotivatorStore,
  MandateStore: MandateStore,
  SkillStore: SkillStore,
  InformalRoleStore: InformalRoleStore,
  LinkableEntityStore: LinkableEntityStore,
  CultureTagStore: CultureTagStore,
  OnboardingStore: OnboardingStore,
  ProfileStore: ProfileStore,
  CommunicationLanguageStore: CommunicationLanguageStore,
};
