import {IMotivator} from "module/motivator/coachee/domain/model/CoacheeMotivator";

export default class CoacheeDemotivator implements IMotivator {
  private readonly _id: string;
  private readonly _title: string;
  private readonly _coacheeId: string;
  
  constructor(id: string, title: string, coacheeId: string) {
    this._id = id;
    this._title = title;
    this._coacheeId = coacheeId;
  }
  
  get id(): string {
    return this._id;
  }
  
  get title(): string {
    return this._title;
  }
  
  get coacheeId(): string {
    return this._coacheeId;
  }
}
