import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import {RootState} from "store";
import OnboardingSequences from "module/onboarding/domain/model/OnboardingSequences";
import {Cookies} from 'react-cookie';

const COMPLETED_STEPS_COOKIE_NAME = 'onboarding';
const cookies = new Cookies();

export const OnboardingStore = createModel<RootModel>()({
  state: {
    onboardingSequences: {} as OnboardingSequences,
    getters: (state: RootState) => ({
      getSequence: () => {
        return state.OnboardingStore.onboardingSequences;
      },
    })
  },
  reducers: {
    setOnboardingSequences(
      state,
      sequences: OnboardingSequences
    ) {
      return {
        ...state,
        onboardingSequences: sequences
      };
    }
  },
  effects: (dispatch) => ({
    async addCompletedStep(payload: { sequenceName: string, stepName: string }) {
      const step = `${payload.sequenceName}.${payload.stepName}`;
      const steps = cookies.get(COMPLETED_STEPS_COOKIE_NAME);
      const completedSteps = steps ? [...steps, step] : [step];
      cookies.set(COMPLETED_STEPS_COOKIE_NAME, completedSteps);
      dispatch.OnboardingStore.setOnboardingSequences(new OnboardingSequences(completedSteps));
    },
    async fetchCompletedSteps() {
      const completedSteps = cookies.get(COMPLETED_STEPS_COOKIE_NAME);
      dispatch.OnboardingStore.setOnboardingSequences(new OnboardingSequences(completedSteps));
    },
  })
});
