import {createTheme} from "asset/component";
import "./types";
import breakpoint from "./breakpointTheme";
import palette from "./PaletteTheme";
import typography from "./TypographyTheme";
import form from "./FormTheme";
import link from "./LinkTheme";
import button from "./ButtonTheme";
import Dialog from "./DialogTheme";
import alert from "./AlertTheme";
import muiSwitch from "./SwitchTheme";

const CustomTheme = createTheme({
  ...breakpoint,
  ...palette,
  ...typography,
  components: {
    ...form,
    ...link,
    ...button,
    ...alert,
    ...Dialog,
    ...muiSwitch
  }
});

export default CustomTheme;
