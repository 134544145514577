import React from "react";
import {Grid, Typography} from "asset/component";
import {TextValidator} from "asset/validator";
import {InputValidator} from "asset/validator/InputValidator";
import {useTranslation} from "react-i18next";
import usePaletteColors from "style/usePaletteColors";
import {MuiInputColors} from "style/props/MuiInputColors";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import {InputLabel} from "@mui/material";

const UpdateCoacheeJobTitleFormComponent = ({
  jobTitle,
  setJobTitle
}:
  {
    jobTitle: string,
    setJobTitle: (jobTitle: string) => void
  }) => {
  const {t} = useTranslation('coachee');
  const palette = usePaletteColors();
  
  return <div role="form">
    <InputLabel htmlFor={"jobTitle"} sx={{transform: "none", opacity: "1"}}>
      <Typography component="p" variant={MuiTypographyVariants.XS} color={palette.secondary.dark}>
        {t('form.update.jobTitleLabel')}
      </Typography>
    </InputLabel>
    <TextValidator
      required
      fullWidth
      type="text"
      data-testid="job-title-input"
      id="jobTitle"
      placeholder={t('form.jobTitlePlaceholder')}
      name="jobTitle"
      value={jobTitle}
      onChange={(event) => setJobTitle(event.target.value)}
      validators={[InputValidator.REQUIRED]}
      color={MuiInputColors.SECONDARY}
      errorMessages={[t('form.validation.jobTitleRequired')]}
      role="textbox"
    />
  </div>;
};

export default UpdateCoacheeJobTitleFormComponent;
