import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import Team from "module/team/domain/model/Team";
import {RootState} from "store";
import AppError from "core/error/AppError";
import ReducerServices from "store/service/ReducerServices";
import AddTeam from "module/team/domain/model/AddTeam";
import UpdateTeam from "module/team/domain/model/UpdateTeam";

export const TeamStore = createModel<RootModel>()({
  state: {
    teams: [] as Team[],
    getters: (state: RootState) => ({
      getTeamById: (id: string) => {
        return state.TeamStore.teams.find(team => team.id == id);
      },
      getTeamsByCompanyId: (id: string) => {
        return state.TeamStore.teams.filter(team => team.companyId == id);
      },
      getTeamsByIds: (ids: string[]) => {
        return state.TeamStore.teams.filter(team => ids.includes(team.id));
      }
    })
  },
  reducers: {
    setTeams(state, teams: Team[]) {
      return {
        ...state,
        teams: ReducerServices.setItemsToList<Team>(state.teams,
          teams)
      };
    },
    setTeam(state, team: Team) {
      return {
        ...state,
        teams: ReducerServices.setItemToList<Team>(state.teams, team)
      };
    },
    removeTeamById(
      state,
      teamId: string
    ) {
      return {
        ...state,
        teams: ReducerServices.removeItemFromList<Team>(
          state.teams,
          teamId
        )
      };
    },
  },
  effects: (dispatch) => ({
    async fetchTeamById(id: string) {
      await clients.Team
        .fetchTeamById(id)
        .then(
          (team: Team) => {
            dispatch.TeamStore.setTeam(team);
          })
        .catch((error: AppError) => {
          throw error.name;
        });
    },
    async fetchTeamsByCompanyId(id: string) {
      await clients.Team
        .fetchTeamsByCompanyId(id)
        .then(
          (teams: Team[]) => {
            dispatch.TeamStore.setTeams(teams);
          })
        .catch((error: AppError) => {
          throw error.name;
        });
    },
    async fetchTeamsByCoacheeId(id: string) {
      await clients.Team.fetchTeamsByCoacheeId(id)
        .then(
          (teams: Team[]) => {
            dispatch.TeamStore.setTeams(teams);
          })
        .catch((error: AppError) => {
          throw error.name;
        });
    },
    async addTeam(addTeam: AddTeam) {
      return await clients.Team
        .addTeam(addTeam)
        .then((addedTeam) => {
          dispatch.TeamStore.setTeam(addedTeam);
          
          return addedTeam;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async updateTeam(updateTeam: UpdateTeam) {
      return await clients.Team
        .updateTeam(updateTeam)
        .then((updatedTeam) => {
          dispatch.TeamStore.setTeam(updatedTeam);
          
          return updatedTeam;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteTeam(payload: { teamId: string }) {
      return await clients.Team
        .deleteTeam(payload.teamId)
        .then(() => {
          dispatch.TeamStore.removeTeamById(payload.teamId);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
  })
});
