import React from 'react';
import styles from 'module/need/view/asset/coachee/CoacheeNeed.module.scss';
import {Typography} from "asset/component";
import usePaletteColors from "style/usePaletteColors";
import {NeedsCoachee} from "module/objective/domain/model/need/NeedsCoachee";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";

const CoacheeNeedTag = ({
  needsCoachee,
  onClick,
  coacheeId
}: {
  needsCoachee: NeedsCoachee,
  onClick: (needsCoachee: NeedsCoachee) => void,
  coacheeId: string
}) => {
  const dispatch = useDispatch<Dispatch>();
  const palette = usePaletteColors();
  const {t} = useTranslation('need');
  
  const [isFilled, setIsFilled] = React.useState<boolean>(false);
  const [isDone, setIsDone] = React.useState<boolean>(false);
  
  const needStats = useSelector((rootState: RootState) =>
    rootState.ObjectiveCoacheeStore?.getters(rootState).getStatsForNeed(needsCoachee));
  
  useLoadingAndError(() => dispatch.ObjectiveCoacheeStore.fetchNeedStats({coacheeId: coacheeId, need: needsCoachee}), [], coacheeId);
  
  React.useEffect(() => {
    if (!needStats) return;
    const isFilled = needStats.objectiveCount > 0;
    const isDone = isFilled && needStats.objectiveCount === needStats.completedObjectiveCount;
    
    setIsFilled(isFilled);
    setIsDone(isDone);
  }, [needStats]);
  
  return <div className={styles.coacheeNeedContainer}>
    <div data-testid="tag" onClick={() => onClick(needsCoachee)} className={styles.coacheeNeed}>
      <div className={`${styles.circle} ${isFilled && styles.filled} ${isDone && styles.done}`} data-testid="circle">
        <Typography color={palette.secondary.veryDark}
                    fontWeight={600}
                    className={styles.text}
                    component="span"
                    variant="xs">
          {t(`tag.${needsCoachee}`)}
        </Typography>
      </div>
    </div>
  </div>;
};

export default CoacheeNeedTag;
