import {MuiColors} from "style/props/MuiColors";

enum CustomMuiButtonColors {
  WHITE = "white",
  PRIMARY_LIGHT = "primaryLight",
  PRIMARY_DARK = "primaryDark",
  SECONDARY_DARK = "secondaryDark",
  SECONDARY_VERY_DARK = "secondaryVeryDark"
}

export const MuiButtonColors = {...MuiColors, ...CustomMuiButtonColors};

export type MuiButtonColors = MuiColors | CustomMuiButtonColors;
