import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";
import {Typography} from "@mui/material";
import ArrowIcon, {ArrowDirection} from "asset/icon/ArrowIcon";
import {Button} from "asset/component";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import {MuiButtonColors} from "style/props/MuiButtonColors";
import useDateTranslation from "asset/component/hook/useDateTranslation";
import {useTranslation} from "react-i18next";
import {IntervalMovement, StateHistoryInterval} from "module/objective/view/component/ObjectiveStateHistoryModal";

const ObjectiveStateHistoryControls = ({
  intervalSelected,
  moveInterval,
  intervalTypeSelected,
  setIntervalType
}: {
  intervalSelected: Date,
  moveInterval: (movement: IntervalMovement) => void,
  intervalTypeSelected: StateHistoryInterval,
  setIntervalType: (interval: StateHistoryInterval) => void
}) => {
  const {t} = useTranslation("objective");
  
  const currentTranslatedInterval = () => {
    if (intervalTypeSelected === StateHistoryInterval.MONTH) {
      return useDateTranslation().getMonthAndYearFromDate(intervalSelected);
    } else return t("stateHistoryModal.intervalNotSupported");
  };
  
  const isIntervalTypeButtonSelected = (interval: StateHistoryInterval) => {
    if (interval == intervalTypeSelected) {
      return MuiButtonVariants.CONTAINED
    } else return MuiButtonVariants.OUTLINED
  }
  
  const getButtonForIntervalType = (interval: StateHistoryInterval) => {
    return <Button variant={isIntervalTypeButtonSelected(interval)}
            color={MuiButtonColors.PRIMARY}
            size={"small"}
            onClick={() => setIntervalType(interval)}>
      <Typography fontSize={10} color={"black"}>{t('common:' + interval.toString())}</Typography>
    </Button>
  }
  
  return <div className={styles.controls}>
    <div className={styles.intervalNavigation}>
      <div className={styles.arrowIcon} onClick={() => moveInterval(IntervalMovement.BACKWARD)}>
        <ArrowIcon direction={ArrowDirection.LEFT}/>
      </div>
      <div className={styles.intervalText}>
        <Typography fontSize={14} align={"center"}>{currentTranslatedInterval()}</Typography>
      </div>
      <div className={styles.arrowIcon} onClick={() => moveInterval(IntervalMovement.FORWARD)}>
        <ArrowIcon direction={ArrowDirection.RIGHT}/>
      </div>
    </div>
    <div className={styles.intervalSelection}>
      {getButtonForIntervalType(StateHistoryInterval.WEEK)}
      {getButtonForIntervalType(StateHistoryInterval.MONTH)}
      {getButtonForIntervalType(StateHistoryInterval.YEAR)}
    </div>
  </div>
}

export default ObjectiveStateHistoryControls;
