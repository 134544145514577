import AddSkill from "module/skill/domain/model/AddSkill";

export default interface AddSkillRequest {
  name: string;
}

export function createAddSkillRequest(addSkill: AddSkill): AddSkillRequest {
  return {
    name: addSkill.name,
  } as AddSkillRequest;
}
