import {Cookies} from 'react-cookie';
import i18n from 'translation/config';

export default class LanguageManager {
  private static readonly COOKIES = new Cookies();
  private static readonly LANGUAGE_COOKIE_NAME = 'language';
  public static readonly FRENCH_LANGUAGE_KEY = 'fr';
  public static readonly ENGLISH_LANGUAGE_KEY = 'en';
  
  public static initLanguage(): void {
    const languageCookie = LanguageManager.COOKIES.get<string>(LanguageManager.LANGUAGE_COOKIE_NAME);
    
    if (languageCookie) {
      i18n.changeLanguage(languageCookie);
    } else {
      LanguageManager.changeLanguage(
        navigator.language.includes(LanguageManager.ENGLISH_LANGUAGE_KEY)
          ? LanguageManager.ENGLISH_LANGUAGE_KEY
          : LanguageManager.FRENCH_LANGUAGE_KEY,
      );
    }
  }
  
  public static getCurrentLanguage(): string {
    return LanguageManager.COOKIES.get(LanguageManager.LANGUAGE_COOKIE_NAME);
  }
  
  public static changeToNextLanguage(): void {
    let language;
    
    if (i18n.language === LanguageManager.ENGLISH_LANGUAGE_KEY) {
      language = LanguageManager.FRENCH_LANGUAGE_KEY;
    } else {
      language = LanguageManager.ENGLISH_LANGUAGE_KEY;
    }
    
    LanguageManager.changeLanguage(language);
  }
  
  public static changeLanguage(language: string): void {
    i18n.changeLanguage(language);
    LanguageManager.setLanguageCookie(language);
  }
  
  private static setLanguageCookie(language: string): void {
    LanguageManager.COOKIES.set(LanguageManager.LANGUAGE_COOKIE_NAME, language);
  }
}
