import React from 'react';
import styles from "module/mandate/view/asset/add/AddMandateConfirmationComponent.module.scss";
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {NavigationPreviousButton} from "asset/component/form/NavigationFormButtons";
import {Routes} from "router/Routes";
import {useNavigate} from "react-router-dom";

const AddMandateConfirmationButtons = ({
  previousSectionFormId
}: {
  previousSectionFormId: string
}) => {
  const {t} = useTranslation('mandate');
  const navigate = useNavigate();
  
  return (
    <div className={styles.buttons}>
      <NavigationPreviousButton previousSectionId={previousSectionFormId} />
      <Button variant="contained" color="primary" type="submit" onClick={() => navigate(Routes.mandate.path())}>
        {t('form.add.confirmation.next')}
      </Button>
    </div>
  );
};

export default AddMandateConfirmationButtons;
