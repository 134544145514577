import alert from "style/variable/_alert.scss";

export default {
  MuiAlert: {
    styleOverrides: {
      root: {
        color: alert.alertTextColor,
        fontSize: "16px",
        width: 'initial',
        marginBottom: '20px',
        padding: '10px 38px',
        '.MuiAlert-message': {
          textAlign: 'start',
          marginLeft: '20px'
        },
        '&.MuiAlert-root': {
          position: 'relative'
        }
      },
      standardError: {
        backgroundColor: "#FFC7B5",
        padding: '10px 50px',
        '.MuiAlert-icon': {
          display: 'none',
        },
        '&::before': {
          content: '""',
          backgroundSize: 'cover',
          width: alert.alertErrorIconWidth,
          height: alert.alertErrorIconHeight,
          top: "50%",
          transform: "translateY(-50%)",
          position: 'absolute',
          left: "17px"
        }
      }
    },
  }
};
