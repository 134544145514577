import React from 'react';
import {Button} from "asset/component/index";
import {VisibilityOffOutlinedIcon, VisibilityOutlinedIcon} from "asset/component/Icons";
import {MuiButtonColors} from "style/props/MuiButtonColors";
import {useTranslation} from "react-i18next";

const HideListedItemsButton = ({
  showItems,
  setShowItems,
  injectedStyle
}: {
  showItems: boolean,
  setShowItems: (boolean) => void,
  injectedStyle?: Record<string, string>
}) => {
  const {t} = useTranslation();
  
  const showCompletedItemsButton =
    <Button
      data-testid="show-completed-items-button"
      onClick={() => setShowItems(true)}
      variant="text"
      color={MuiButtonColors.SECONDARY_DARK}
      size="small"
      endIcon={<VisibilityOutlinedIcon />}
    >
      {t('showListingItems')}
    </Button>;
  
  const hideCompletedItemsButton =
    <Button
      data-testid="hide-completed-items-button"
      onClick={() => setShowItems(false)}
      variant="text"
      color={MuiButtonColors.SECONDARY_DARK}
      size="small"
      endIcon={<VisibilityOffOutlinedIcon />}
    >
      {t('hideListingItems')}
    </Button>;
  
  return <div className={injectedStyle?.hideListedItemsButton}>{showItems ? hideCompletedItemsButton : showCompletedItemsButton}</div>;
};

export default HideListedItemsButton;
