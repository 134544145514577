import type RESTClient from "core/client/RESTClient";
import CultureTagRepository from "module/culture/domain/CultureTagRepository";
import CultureTagResponse, {cultureTagResponseToModel, cultureTagsResponseToModel} from "module/culture/dal/dto/CultureTagResponse";
import CultureTag from "module/culture/domain/model/CultureTag";
import {createAddCultureTagRequest} from "module/culture/dal/dto/AddCultureTagRequest";
import AddCultureTag from "module/culture/domain/model/AddCultureTag";

export default class RESTCultureTagRepository implements CultureTagRepository {
  private static readonly BASE_URL: string = "/api/teams";
  private static readonly SKILL_URL: string = "/cultureTags";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchCultureTags(teamId: string): Promise<CultureTag[]> {
    const cultureTagsResponse = await this.client.apiGet<CultureTagResponse[]>(
      `${RESTCultureTagRepository.BASE_URL}` +
      `/${teamId}` +
      `${RESTCultureTagRepository.SKILL_URL}`
    );
    
    return cultureTagsResponseToModel(cultureTagsResponse, teamId);
  }
  
  public async addCultureTag(addCultureTag: AddCultureTag, teamId: string): Promise<CultureTag> {
    const cultureTagResponse = await this.client.apiPost<CultureTagResponse>(
      `${RESTCultureTagRepository.BASE_URL}` +
      `/${teamId}` +
      `${RESTCultureTagRepository.SKILL_URL}`,
      createAddCultureTagRequest(addCultureTag)
    );
    
    return cultureTagResponseToModel(cultureTagResponse, teamId);
  }
  
  public async deleteCultureTag(cultureTagId: string, teamId: string): Promise<void> {
    await this.client.apiDelete<void>(
      `${RESTCultureTagRepository.BASE_URL}` +
      `/${teamId}` +
      `${RESTCultureTagRepository.SKILL_URL}` +
      `/${cultureTagId}`
    );
  }
}
