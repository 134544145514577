import AddObjective from "module/objective/domain/model/AddObjective";
import {NeedsCoachee} from "module/objective/domain/model/need/NeedsCoachee";
import {NeedsTeam} from "module/objective/domain/model/need/NeedsTeam";

export default interface AddObjectiveRequest {
  name: string;
  needs: NeedsCoachee[] | NeedsTeam[];
}

export function createAddObjectiveRequest(addObjective: AddObjective): AddObjectiveRequest {
  return {
    name: addObjective.name,
    needs: addObjective.needs || []
  } as AddObjectiveRequest;
}
