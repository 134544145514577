import React from 'react';
import AddLinkedEntitiesFormComponent from "module/mandate/view/component/add/AddLinkedEntitiesFormComponent";
import useNavigationFormSections from "asset/component/form/hook/useNavigationFormSections";
import {linkedEntityManager} from "module/mandate/view/container/MandateMethods";

const AddLinkedEntitiesFormContainer = ({
  mandateId = "",
  anchorId,
  useNavigation,
  nextSectionFormId,
  previousSectionFormId
}: {
  mandateId?: string,
  anchorId: string,
  useNavigation: typeof useNavigationFormSections.arguments,
  nextSectionFormId: string,
  previousSectionFormId: string
}) => {
  const {
    linkableEntities,
    isLinkableEntitiesLoading,
    errorLinkableEntities,
    isAddLinkedEntityLoading,
    addLinkedEntityErrorName,
    isRemoveLinkedEntityLoading,
    removeLinkedEntityErrorName,
    linkedEntities,
    isLinkedEntitiesLoading,
    errorLinkedEntities,
    addLinkedEntity,
    removeLinkedEntity
  } = linkedEntityManager(mandateId);
  
  return (
    <AddLinkedEntitiesFormComponent removeLinkedEntity={removeLinkedEntity}
                                    addLinkedEntity={addLinkedEntity}
                                    linkedEntities={linkedEntities}
                                    linkableEntities={linkableEntities}
                                    isLoading={isLinkableEntitiesLoading || isAddLinkedEntityLoading || isLinkedEntitiesLoading || isRemoveLinkedEntityLoading}
                                    errorName={errorLinkableEntities || addLinkedEntityErrorName || errorLinkedEntities || removeLinkedEntityErrorName}
                                    anchorId={anchorId}
                                    useNavigation={useNavigation}
                                    nextSectionFormId={nextSectionFormId}
                                    previousSectionFormId={previousSectionFormId}
    />
  );
};

export default AddLinkedEntitiesFormContainer;
