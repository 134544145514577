import React from 'react';
import {Typography} from "@mui/material";
import usePaletteColors from "style/usePaletteColors";
import DashboardNavigationCards from "module/dashboardNavigation/view/component/DashboardNavigationCards";
import styles from "module/dashboardNavigation/view/asset/DashboardNavigationComponent.module.scss";
import {useTranslation} from "react-i18next";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";

const DashboardNavigationComponent = () => {
  const palette = usePaletteColors();
  const {t} = useTranslation("dashboardNavigation");
  
  return (
    <div className={styles.container}>
      <Typography variant={MuiTypographyVariants.H6} color={palette.primary.light}>{t('subtitle')}</Typography>
      <DashboardNavigationCards />
    </div>
  );
};

export default DashboardNavigationComponent;
