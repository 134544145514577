import React, {useEffect} from 'react';
import TagDisplayer from "asset/component/tag/TagDisplayer";
import {Tag} from "asset/component/tag/Tag";
import {useSelector} from "react-redux";
import {RootState} from "store";
import Company from "module/company/domain/model/Company";

const MandateLinkedCompaniesContainer = ({
  companyIds
}: {
  companyIds: string[]
}) => {
  const [linkedCompanies, setLinkedCompanies] = React.useState<Tag[]>([]);
  
  const companies = useSelector(
    (rootState: RootState) =>
      rootState.CompanyStore?.getters(rootState).getCompaniesByIds(companyIds)
  );
  
  const convertCompaniesToTags = (companies: Company[]): Tag[] => {
    return companies.map((company) => ({
      id: company.id,
      name: company.name,
      isSelected: true,
    }));
  };
  
  useEffect(() => {
    setLinkedCompanies(convertCompaniesToTags(companies));
  }, [companyIds]);
  
  return (
    <TagDisplayer tags={linkedCompanies} />
  );
};

export default MandateLinkedCompaniesContainer;
