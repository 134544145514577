import AddObjectiveState from "module/objective/domain/model/AddObjectiveState";

export default interface AddObjectiveStateRequest {
  status: string;
}

export function createAddObjectiveStateRequest(addObjectiveState: AddObjectiveState): AddObjectiveStateRequest {
  return {
    status: addObjectiveState.status
  } as AddObjectiveStateRequest;
}
