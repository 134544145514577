import OnboardingSequence from "module/onboarding/domain/model/OnboardingSequence";
import OnboardingStep from "module/onboarding/domain/model/OnboardingStep";
import {ArrowPositions} from "asset/component/global/dialogs/Tooltip";
import {OnboardingSequenceNames} from "module/onboarding/domain/model/SeedData/OnboardingSequenceNames";
import {Routes} from "router/Routes";

const HomeSequence = new OnboardingSequence(
  [
    new OnboardingStep({
      name: "coachees",
      sequenceName: OnboardingSequenceNames.HOME,
      anchorID: "coachees-anchor",
      arrowPosition: ArrowPositions.TOP.MIDDLE,
      adjustments: {x: -10, y: -100},
      link: Routes.coachees.path
    }),
    new OnboardingStep({
      name: "mandates",
      sequenceName: OnboardingSequenceNames.HOME,
      anchorID: "mandates-anchor",
      arrowPosition: ArrowPositions.TOP.MIDDLE,
      adjustments: {x: -10, y: -100},
      link: Routes.mandate.path
    }),
    new OnboardingStep({
      name: "parameters",
      sequenceName: OnboardingSequenceNames.HOME,
      anchorID: "parameters-anchor",
      arrowPosition: ArrowPositions.TOP.RIGHT,
      adjustments: {x: 36.5, y: 0}
    }),
  ]
);

export default HomeSequence;
