import {Environments, initializePaddle, Paddle} from '@paddle/paddle-js';
import {useState} from 'react';
import i18n from "i18next";

const usePaddle = () => {
  const TOKEN = process.env.REACT_APP_PADDLE_TOKEN as string;
  const MONTHLY_PRICE_ID = process.env.REACT_APP_PADDLE_MONTHLY_PRICE_ID as string;
  const YEARLY_PRICE_ID = process.env.REACT_APP_PADDLE_YEARLY_PRICE_ID as string;
  const DEBUG_MODE = process.env.REACT_APP_PADDLE_DEBUG_MODE as unknown as boolean;
  const ENVIRONMENT = process.env.REACT_APP_PADDLE_ENVIRONMENT as Environments;
  
  const [paddle, setPaddle] = useState<Paddle>();
  const [email, setEmail] = useState<string>("");
  
  const initialize = (
    email: string,
    successCallback: () => void
  ) => {
    setEmail(email);
    
    initializePaddle({
      environment: ENVIRONMENT,
      token: TOKEN,
      debug: DEBUG_MODE,
      eventCallback: successCallback
    }).then(
      paddleInstance => paddleInstance && setPaddle(paddleInstance)
    );
  };
  
  const checkoutMonthly = (workspaceId: string) => checkout(workspaceId, MONTHLY_PRICE_ID);
  const checkoutYearly = (workspaceId: string) => checkout(workspaceId, YEARLY_PRICE_ID);
  const checkout = (workspaceId: string, priceId: string) => {
    paddle?.Checkout.open({
      settings: {
        allowLogout: false,
        locale: i18n.language,
      },
      items: [
        {
          priceId: priceId,
          quantity: 1
        }
      ],
      customer: {
        email: email
      },
      customData: {
        workspaceId: workspaceId
      }
    });
  };
  
  return {initialize, checkoutMonthly, checkoutYearly};
};

export default usePaddle;
