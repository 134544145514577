import {useState} from 'react';

const useHideListedItem = <TYPE extends any>(
  property: string,
  defaultValue: boolean = false
): {
  showCompletedItem: boolean,
  setShowCompletedItem: (value: () => boolean) => void,
  filterList: (items: TYPE[]) => TYPE[],
} => {
  const [showCompletedItem, setShowCompletedItem] = useState<boolean>(defaultValue);
  
  const sortItems = (items: TYPE[]) =>
    items.sort((itemA, itemB) =>
      Number(itemA[property]) - Number(itemB[property]));
  
  const hideItems = (items: TYPE[]) => items.filter((item,) => !item[property]);
  
  const filterList = (items: TYPE[]) => {
    const itemsToDisplay = sortItems(items);
    
    if (!showCompletedItem) {
      return hideItems(items);
    }
    
    return itemsToDisplay;
  };
  
  return {showCompletedItem, setShowCompletedItem, filterList};
};

export default useHideListedItem;
