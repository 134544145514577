export default class ReducerServices {
  public static removeItemFromList = <T extends {
    id: string
  }>(stateListing: T[], id: string): T[] => {
    const updatedStateListing = [...stateListing];
    
    updatedStateListing.splice(updatedStateListing.findIndex(item => item.id === id), 1);
    
    return updatedStateListing;
  };
  
  public static setItemsToList = <T extends {
    id: string
  }>(stateListing: T[], updatedItems: T[]): T[] => {
    const updatedStateListing = [...stateListing];
    
    updatedItems.forEach(updatedItem => ReducerServices.addOrUpdateItem(updatedStateListing, updatedItem));
    
    return updatedStateListing;
  };
  
  public static setItemToList = <T extends {
    id: string
  }>(stateListing: T[], updatedItem: T): T[] => {
    const updatedStateListing = [...stateListing];
    
    ReducerServices.addOrUpdateItem(updatedStateListing, updatedItem);
    
    return updatedStateListing;
  };
  
  private static getItemIndex = <T extends {
    id: string
  }>(list: T[], item: T): number => {
    return list.findIndex(o => o.id === item.id);
  };
  
  private static addOrUpdateItem = <T extends {
    id: string
  }>(updatedStateListing: T[], updatedItem: T): void => {
    const existingIndex = ReducerServices.getItemIndex(updatedStateListing, updatedItem);
    
    if (existingIndex >= 0) {
      updatedStateListing[existingIndex] = updatedItem;
    } else {
      updatedStateListing.push(updatedItem);
    }
  };
}
