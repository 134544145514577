import React from "react";
import {Alert, CircularProgress, Container} from "asset/component";
import Team from "module/team/domain/model/Team";
import styles from "module/team/view/asset/teamProfile/TeamProfileHeaderComponent.module.scss";
import TeamProfileThumbnailComponent from "module/team/view/component/TeamProfileThumbnailComponent";
import Company from "module/company/domain/model/Company";
import NavigationSlider from "asset/component/global/slider/NavigationSlider";
import {Routes} from "router/Routes";
import {useNavigate} from "react-router-dom";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import {ErrorNames} from "core/error/ErrorNames";

const TeamProfileHeaderComponent = ({
  team,
  teams,
  company,
  isLoading,
  errorName
}:
  {
    team?: Team,
    teams?: Team[],
    isLoading: boolean,
    errorName?: ErrorNames,
    company?: Company,
  }) => {
  const navigate = useNavigate();
  const {getErrorMessage} = useErrorTranslation();
  
  const navigateOnClick = (id: string, companyId?: string) => {
    navigate(Routes.team.profile.path({teamId: id, companyId: companyId}));
  };
  
  const sliderTeams = () => {
    let teamsToRender: Team[] = [];
    
    if (teams && teams.length > 0) {
      teamsToRender = teams;
    } else if (team) {
      teamsToRender.push(team);
    }
    
    return teamsToRender.map((childProps) => ({
      'data-testid': `team_${childProps.id}`,
      id: childProps.id,
      team: childProps,
      companyId: company?.id,
      companyName: company?.name,
      navigateOnClick: () => navigateOnClick(childProps.id, company?.id),
      isSelected: team?.id === childProps.id,
      isClone: false
    }));
  };
  
  const teamsSlider = team && <NavigationSlider
      Component={TeamProfileThumbnailComponent}
      componentsProps={sliderTeams()}
      selectedId={team.id}
  />;
  
  const teamLoading = <CircularProgress data-testid="loading_team" />;
  
  const teamError =
    <Alert severity="error" data-testid="error_team">
      {errorName && getErrorMessage(errorName)}
    </Alert>;
  
  const renderTeamProfile = () => {
    if (errorName) {
      return teamError;
    } else if (isLoading && !team) {
      return teamLoading;
    } else {
      return teamsSlider;
    }
  };
  
  return (
    <Container id={styles.TeamProfileHeaderComponentContainer}
               data-testid="team_profile_header_component">
      {renderTeamProfile()}
    </Container>
  );
};

export default TeamProfileHeaderComponent;
