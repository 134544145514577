import React from 'react';
import {TextValidator} from "asset/validator";
import TextValidatorProps from "asset/component/form/inputs/TextValidatorProps";
import styles from "asset/component/asset/form/inputs/ghost/GhostInput.module.scss";
import MuiInputSizes from "style/props/MuiInputSizes";

const GhostInput = ({
  textValidatorProps,
  value = "",
  onBlur,
  onChange
}: {
  textValidatorProps: TextValidatorProps,
  value: string,
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  const maxLength = textValidatorProps.inputProps?.maxLength || null;
  
  return (
    <div className={styles.ghostContainer}>
      <div className={styles.labels} data-testid="ghost-input-label">
        {textValidatorProps.label ? textValidatorProps.label : ""}
        {textValidatorProps.required ? " *" : ""}
        {maxLength &&
            <div className={styles.lengthCount} data-testid="ghost-input-counter">{value.length}/{maxLength}</div>}
      </div>
      <TextValidator data-testid="ghost-input"
                     required={textValidatorProps.required || false}
                     type={textValidatorProps.type || "text"}
                     id={textValidatorProps.id || "ghost-input"}
                     placeholder={textValidatorProps.placeholder || ""}
                     name={textValidatorProps.name || "ghost-input"}
                     value={value || ""}
                     validators={textValidatorProps.validators || []}
                     errorMessages={textValidatorProps.errorMessages || []}
                     autoFocus={textValidatorProps.autoFocus || false}
                     className={`${styles.ghostInput} ${textValidatorProps.styles || ""}`}
                     onBlur={onBlur}
                     onChange={onChange}
                     inputProps={textValidatorProps.inputProps || {}}
                     fullWidth={textValidatorProps.fullWidth || false}
                     maxRows={textValidatorProps.maxRows || 1}
                     minRows={textValidatorProps.minRows || 1}
                     multiline={textValidatorProps.multiline || false}
                     size={textValidatorProps.size || MuiInputSizes.MEDIUM}
                     autoComplete={textValidatorProps.autoComplete || "off"}
      />
    </div>
  );
};

export default GhostInput;
