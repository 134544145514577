import React from 'react';
import MandateSidebarComponent from "module/mandate/view/component/edit/MandateSidebarComponent";
import Mandate from "module/mandate/domain/model/Mandate";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {useDispatch} from "react-redux";
import {Dispatch} from "store";
import MandateObjectivesContainer from "module/objective/view/container/mandate/MandateObjectivesContainer";
import MandateLinkedEntitiesContainer from "module/mandate/view/container/edit/MandateLinkedEntitiesContainer";

const MandateSidebarContainer = ({
  mandate,
  isSidebarOpen = false,
  close,
  deleteMandate
}: {
  mandate?: Mandate | null,
  isSidebarOpen?: boolean,
  close: () => void,
  deleteMandate: () => void
}) => {
  const dispatch = useDispatch<Dispatch>();
  
  const [isUpdateMandateInfoLoading, updateMandateInfoErrorName, dispatchUpdateMandateInfo] =
    usePromiseLoadingAndError(({mandate}: {
        mandate: Mandate
      }) =>
        dispatch.MandateStore.updateMandate({mandate: mandate})
    );
  
  return (
    <MandateSidebarComponent mandate={mandate}
                             isSidebarOpen={isSidebarOpen}
                             close={close}
                             isLoading={isUpdateMandateInfoLoading}
                             errorNames={updateMandateInfoErrorName}
                             deleteMandate={deleteMandate}
                             updateMandate={(mandate) => dispatchUpdateMandateInfo({mandate: mandate})}
                             objectives={mandate ? <MandateObjectivesContainer mandateId={mandate.id} /> : <></>}
                             linkedOrgs={mandate ? <MandateLinkedEntitiesContainer mandateId={mandate.id} /> : <></>}
    />
  );
};

export default MandateSidebarContainer;
