import React from 'react';

const Hourglass = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.77777 0.7C7.01634 0.7 4.77777 1.28335 4.77777 4.1C4.77777 6.49579 5.55468 7.70991 8.20296 9.43464C8.58157 9.68121 8.57817 10.2537 8.19776 10.4974C5.62775 12.1437 4.70117 13.5027 4.70117 15.9C4.70117 18.7167 6.93975 19.3 9.70117 19.3C12.4626 19.3 14.7012 18.7167 14.7012 15.9C14.7012 13.5042 13.9243 12.2901 11.276 10.5654C10.8974 10.3188 10.9008 9.74627 11.2812 9.50259C13.8512 7.85631 14.7778 6.4973 14.7778 4.1C14.7778 1.28335 12.5392 0.7 9.77777 0.7Z"
            fill="#FFE8CE" />
      <path d="M6.96067 6.49976C6.96067 5.89977 8.07115 6.49976 9.80381 6.49976C11.5365 6.49976 12.745 5.89977 12.745 6.49976C12.745 6.79643 12.1697 7.38648 10.8296 7.98183C10.7997 7.99511 10.7703 8.01053 10.7439 8.03009C10.5377 8.1829 10.3147 8.47206 10.0739 8.69397C9.91868 8.83702 9.69005 8.83504 9.53686 8.68973C9.28642 8.45219 9.05613 8.14154 8.84463 8.00188C7.45087 7.29976 6.96067 7.09977 6.96067 6.49976Z"
            fill="#FFBF73" />
      <path d="M13.3332 15.7464C13.3332 17.779 11.7092 18.2 9.70577 18.2C7.70238 18.2 6.07832 17.779 6.07832 15.7464C6.07832 15.3458 6.82572 15.156 8.93021 14.026C9.41372 13.7663 9.99423 13.7716 10.4755 14.0355C12.6976 15.2539 13.3332 15.2528 13.3332 15.7464Z"
            fill="#FFBF73" />
      <path d="M3.5293 17.6C3.5293 17.3791 3.70487 17.2 3.92145 17.2H15.4901C15.7067 17.2 15.8822 17.3791 15.8822 17.6V19.6C15.8822 19.8209 15.7067 20 15.4901 20H3.92145C3.70487 20 3.5293 19.8209 3.5293 19.6V17.6Z"
            fill="#9AB59F" />
      <path d="M15.8822 2.4C15.8822 2.62091 15.7067 2.8 15.4901 2.8L4.11753 2.8C3.90095 2.8 3.72538 2.62091 3.72538 2.4L3.72538 0.4C3.72538 0.179086 3.90095 -5.43782e-09 4.11753 0L15.4901 2.85536e-07C15.7067 2.90974e-07 15.8822 0.179086 15.8822 0.4V2.4Z"
            fill="#9AB59F" />
    </svg>
  );
};

export default Hourglass;
