import * as React from "react";
import {AccordionDetails, AccordionSummary, MUIAccordion, Typography, Button} from "asset/component/index";
import {ArrowIcon, ExpandIcon} from "asset/component/Icons";
import styles from 'asset/component/asset/global/Accordion.module.scss';
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import {CoacheesSequence} from "module/onboarding/domain/model/SeedData/OnboardingSequencesSeed";

export enum AccordionColorVariants {
  LIGHT = "light",
  DARK = "flash"
}

export enum AccordeonSizeVariants {
  SMALL = "small",
  LARGE = "large"
}

const Accordion = ({
  title,
  buttonClick,
  buttonText,
  colorVariant = AccordionColorVariants.DARK,
  sizeVariant = AccordeonSizeVariants.SMALL,
  children,
  expanded,
  teamId,
  companyId
}: {
  title: string,
  buttonClick?: () => void,
  buttonText?: string,
  colorVariant?: AccordionColorVariants,
  sizeVariant?: AccordeonSizeVariants,
  children: JSX.Element | JSX.Element[],
  expanded?: boolean,
  teamId?: string,
  companyId?: string
}) => {
  return (
    expanded ?
      <MUIAccordion defaultExpanded={expanded}
                    data-testid={"mui-accordion"}
                    className={colorVariant == AccordionColorVariants.DARK ? styles.accordion : styles.accordionLight}>
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          className={sizeVariant == AccordeonSizeVariants.LARGE ? styles.summaryLarge : styles.summarySmall}
        >
          <Typography className={styles.title} component="h5" variant="xs">
            {title}
          </Typography>
          {
            buttonClick &&
              <Button data-testid="accordion-button"
                      id={expanded ? CoacheesSequence.steps[2].anchorID : ""}
                      data-param-id={JSON.stringify({teamId, companyId: companyId})}
                      className={styles.button}
                      onClick={buttonClick}
                      variant={MuiButtonVariants.OUTLINED}
                      endIcon={<ArrowIcon />}>
                  <span className={styles.buttonText}>{buttonText}</span>
              </Button>
          }
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </MUIAccordion>
      :
      <MUIAccordion
        className={colorVariant == AccordionColorVariants.DARK ? styles.accordion : styles.accordionLight}>
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          className={sizeVariant == AccordeonSizeVariants.LARGE ? styles.summaryLarge : styles.summarySmall}
        >
          <Typography className={styles.title} component="h5" variant="xs">
            {title}
          </Typography>
          {
            buttonClick &&
              <Button data-testid="accordion-button"
                      className={styles.button}
                      onClick={buttonClick}
                      variant={MuiButtonVariants.OUTLINED}
                      endIcon={<ArrowIcon />}>
                <span id={expanded ? CoacheesSequence.steps[2].anchorID : ""}
                      className={styles.buttonText}>{buttonText}</span>
              </Button>
          }
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </MUIAccordion>
  );
};

export default Accordion;
