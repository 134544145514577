import AddCompany from "module/company/domain/model/AddCompany";

export default interface AddCompanyRequest {
  name: string;
}

export function createAddCompanyRequest(addCompany: AddCompany): AddCompanyRequest {
  return {
    name: addCompany.name,
  } as AddCompanyRequest;
}
