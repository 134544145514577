import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";
import ObjectiveState from "module/objective/domain/model/ObjectiveState";
import StatusIcon from "module/objective/view/component/StatusIcon";
import React from "react";
import {useTranslation} from "react-i18next";
import {CircularProgress, Typography} from "@mui/material";
import useDateTranslation from "asset/component/hook/useDateTranslation";
import ObjectiveStateHistoryComment from "module/objective/view/component/ObjectiveStateHistoryComment";
import {StateStatus} from "module/objective/domain/model/StateStatus";
import {ErrorNames} from "core/error/ErrorNames";
import ObjectiveStateComment from "module/objective/domain/model/ObjectiveStateComment";
import ObjectiveStateHistoryModalErrorMessage
  from "module/objective/view/component/ObjectiveStateHistoryModalErrorMessage";
import ObjectiveStateHistoryCommentInput from "module/objective/view/component/ObjectiveStateHistoryCommentInput";

const ObjectiveStateHistoryCommentSection = ({
  objectiveState,
  addObjectiveStateComment,
  isAddCommentLoading,
  addCommentError,
  nextDate
} : {
  objectiveState? : ObjectiveState,
  nextDate : Date,
  addObjectiveStateComment?: (id: string, comment: string) => void,
  isAddCommentLoading?: boolean,
  addCommentError?: ErrorNames
}) => {
  const {t} = useTranslation("objective");
  
  const beginningDate = objectiveState?.createdAt.getDate();
  const endDate = nextDate.getDate();
  const translatedMonthAndYear = objectiveState && useDateTranslation().getMonthAndYearFromDate(objectiveState?.createdAt);
  const translatedDate = `${beginningDate} ${t('stateHistoryModal.toDate')} ${endDate} ${translatedMonthAndYear}`;
  
  const listOfComment = (objectiveStateComments: ObjectiveStateComment[]) => {
    return objectiveStateComments.map((comment, index) => {
      return <ObjectiveStateHistoryComment comment={comment} key={index}/>
    });
  }
  
  const commentSection = () => {
    return objectiveState ?
    <div className={styles.commentSection}>
      <div className={styles.selectedState}>
        <StatusIcon status={objectiveState.status} />
        <div className={styles.selectedStateText}>
          <Typography fontSize={16}>{t(`state.${objectiveState.status}`)}</Typography>
        </div>
        <div className={styles.selectedStateDateLength}>
          <Typography fontSize={14}>{translatedDate}</Typography>
        </div>
      </div>
      <div className={styles.commentList}>
        {objectiveState.comments && listOfComment(objectiveState.comments)}
      </div>
      <ObjectiveStateHistoryCommentInput
        objectiveStateDisplayed={objectiveState}
        addObjectiveStateComment={addObjectiveStateComment}
        isAddCommentLoading={isAddCommentLoading}
        addCommentError={addCommentError}
      />
    </div>
    : <></>
  };
  
  const renderObjectiveComment = () => {
    if (objectiveState && objectiveState.status !== StateStatus.NONE) {
      return commentSection()
    }
    else return <></>
  };
  
  return renderObjectiveComment();
}

export default ObjectiveStateHistoryCommentSection;
