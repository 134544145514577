const HOME_PATH = '/';
const ADD_SOMETHING_PATH = '/add';
const LOGIN_PATH = '/login';
const REGISTER_PATH = '/register';
const COACHEE_PATH = '/coachee';
const COACHEES_PATH = '/coachees';
const TEAM_PATH = '/team';
const COMPANY_PATH = '/company';
const MANDATE_PATH = '/mandates';
const SETTINGS_PATH = '/settings';

const COACHEE_PROFILE_PATH = (coacheeId?: string) => (coacheeId ? `/${coacheeId}` : '/:coacheeId');
const COACHEE_PROFILE_TEAM_PATH = (
  coacheeId?: string,
  teamId?: string
) => (coacheeId ? `/${coacheeId}/team/${teamId}` : '/:coacheeId/team/:teamId');
const TEAM_PROFILE_PATH = (
  teamId?: string,
  companyId?: string
) => (teamId ? `/${companyId}/team/${teamId}` : '/:companyId/team/:teamId');

const MANDATE_SELECTED_PATH = (mandateId?: string) => `/${mandateId}`;
const MANDATE_REGISTER_PATH = (mandateId?: string) => (mandateId ? `/${mandateId}` : '/:mandateId');

type Route = {
  path: (...args: any[]) => string;
};

type HomeRoute = Route & {
  newUser: Route;
};

type MandateRoute = Route & {
  edit: Route;
};

type AddRoute = Route & {
  coachee: Route;
  team: Route;
  company: Route;
  mandate: Route;
};

type CoacheeRoute = Route & {
  profile: Route;
  profileTeam: Route;
};

type TeamRoute = Route & {
  profile: Route;
};

type UserRoutes = Route & {
  settings: Route;
};

export const Routes = {
  home: {
    path: () => HOME_PATH,
  },
  coachees: {
    path: () => COACHEES_PATH,
  },
  mandate: {
    path: () => MANDATE_PATH,
    edit: {
      path: (args?: { mandateId?: string }) => MANDATE_PATH + MANDATE_REGISTER_PATH(args?.mandateId),
    }
  },
  login: {
    path: () => LOGIN_PATH,
  },
  register: {
    path: () => REGISTER_PATH,
  },
  settings: {
    path: () => SETTINGS_PATH
  },
  add: {
    coachee: {
      path: () => ADD_SOMETHING_PATH + COACHEE_PATH
    },
    team: {
      path: () => ADD_SOMETHING_PATH + TEAM_PATH
    },
    company: {
      path: () => ADD_SOMETHING_PATH + COMPANY_PATH
    },
    mandate: {
      path: () => ADD_SOMETHING_PATH + MANDATE_PATH
    },
  },
  coachee: {
    profile: {
      path: (args?: { coacheeId?: string }) => COACHEE_PATH + COACHEE_PROFILE_PATH(args?.coacheeId),
    },
    profileTeam: {
      path: (args?: { coacheeId?: string, teamId?: string }) => COACHEE_PATH + COACHEE_PROFILE_TEAM_PATH(args?.coacheeId, args?.teamId),
    },
  },
  team: {
    profile: {
      path: (args?: { teamId?: string, companyId?: string }) => TEAM_PROFILE_PATH(args?.teamId, args?.companyId),
    }
  }
} as {
  home: HomeRoute;
  coachees: Route;
  mandate: MandateRoute;
  login: Route;
  register: Route;
  settings: Route;
  add: AddRoute;
  coachee: CoacheeRoute;
  team: TeamRoute;
};
