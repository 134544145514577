enum Env {
  DEV = 'dev',
  PROD = 'prod',
  TEST = 'test'
}

export default class EnvManager {
  private static readonly REACT_APP_MODE = process.env.REACT_APP_MODE;
  
  public static isDevMode(): boolean {
    return this.REACT_APP_MODE === Env.DEV;
  }
  
  public static isProdMode(): boolean {
    return this.REACT_APP_MODE === Env.PROD;
  }
  
  public static isTestMode(): boolean {
    return this.REACT_APP_MODE === Env.TEST;
  }
}
