import React from "react";
import {Typography} from "asset/component";
import {TextValidator} from "asset/validator";
import {InputValidator} from "asset/validator/InputValidator";
import {useTranslation} from "react-i18next";
import usePaletteColors from "style/usePaletteColors";
import {MuiInputColors} from "style/props/MuiInputColors";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import styles from "module/coachee/view/asset/UpdateCoacheeFormComponent.module.scss";
import { InputLabel } from '@mui/material';


const UpdateCoacheeDescriptionJobTitleFormComponent = ({
  descriptionJobTitle,
  setDescriptionJobTitle
}:
  {
    descriptionJobTitle: string,
    setDescriptionJobTitle: (jobTitle: string) => void
  }) => {
  const {t} = useTranslation('coachee');
  const palette = usePaletteColors();
  
  const maxCharacterLength = 100;
  
  return <div>
    
    <div className={styles.descriptionLabel}>
      <InputLabel htmlFor={"descriptionJobTitle"} sx={{transform: "none", opacity: "1"}}>
        <Typography component="p" variant={MuiTypographyVariants.XS} color={palette.secondary.dark}>
          {t('form.update.descriptionJobTitleLabel')}
        </Typography>
      </InputLabel>
      <div className={styles.lengthCount}>{descriptionJobTitle.length}/{maxCharacterLength}</div>
    </div>
    
    <TextValidator
      required
      fullWidth
      type="text"
      data-testid="descritpion-job-title-input"
      id="descriptionJobTitle"
      placeholder={t('form.descriptionJobTitlePlaceholder')}
      name="descriptionJobTitle"
      value={descriptionJobTitle}
      onChange={(event) => setDescriptionJobTitle(event.target.value)}
      validators={[InputValidator.REQUIRED]}
      color={MuiInputColors.SECONDARY}
      errorMessages={[t('form.validation.descriptionJobTitleRequired')]}
      multiline={true}
      rows={3}
      inputProps={{maxLength: maxCharacterLength}}
      role="textarea"
    />
  </div>;
};

export default UpdateCoacheeDescriptionJobTitleFormComponent;
