import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import ObjectiveStateHistoryTable from "module/objective/view/component/ObjectiveStateHistoryTable";
import {useEffect, useState} from "react";
import {StateStatus} from "module/objective/domain/model/StateStatus";
import ObjectiveStateHistoryCommentSection from "module/objective/view/component/ObjectiveStateHistoryCommentSection";
import ObjectiveStateHistoryControls from "module/objective/view/component/ObjectiveStateHistoryControls";
import {useSelector} from "react-redux";
import {RootState} from "store";
import ObjectiveState from "module/objective/domain/model/ObjectiveState";
import {ErrorNames} from "core/error/ErrorNames";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import ObjectiveStateHistoryModalErrorMessage
  from "module/objective/view/component/ObjectiveStateHistoryModalErrorMessage";

export enum StateHistoryInterval {
  WEEK = "week",
  MONTH = "month",
  YEAR = "year"
}

export enum IntervalMovement {
  FORWARD,
  BACKWARD
}

const ObjectiveStateHistoryModal = ({
  objectiveStateHistory,
  fetchHistoryError,
  addObjectiveStateComment,
  isAddCommentLoading,
  addCommentError,
  isStateHistoryLoading
}: {
  objectiveStateHistory: ObjectiveState[],
  fetchHistoryError?: ErrorNames,
  addObjectiveStateComment?: (id: string, comment: string) => void,
  isAddCommentLoading?: boolean,
  addCommentError?: ErrorNames,
  isStateHistoryLoading?: boolean
}) => {
  const {t} = useTranslation("objective");
  const {getErrorMessage} = useErrorTranslation();
  const [intervalTypeSelected, setHistoryIntervalType] = useState(StateHistoryInterval.MONTH);
  const [dateSelected, setDateSelected] = useState(new Date(Date.now()));
  const [filteredHistory, setFilteredHistory] = useState<ObjectiveState[]>([]);
  
  const isCreatedInMonth = (state: ObjectiveState, dateToFilter: Date): boolean => {
    return state.createdAt.getMonth() === dateToFilter.getMonth();
  };
  
  const isEndedInMonth = (state: ObjectiveState, dateToFilter: Date): boolean | null => {
    return state.endedAt && state.endedAt.getMonth() >= dateToFilter.getMonth() && state.endedAt.getFullYear() >= dateToFilter.getFullYear();
  };
  
  const isOngoingInMonth = (state: ObjectiveState, dateToFilter: Date): boolean => {
    return !state.endedAt && state.createdAt.getMonth() <= dateToFilter.getMonth() && state.createdAt.getFullYear() <= dateToFilter.getFullYear();
  };
  
  const filterHistoryByMonth = (dateToFilter: Date): ObjectiveState[] => {
    return objectiveStateHistory
      .filter(state => state.status !== StateStatus.NONE)
      .filter(state => isCreatedInMonth(state, dateToFilter) || isEndedInMonth(state, dateToFilter) || isOngoingInMonth(state, dateToFilter));
  };
  
  const [objectiveStateDisplayed, setObjectiveStateDisplayed] =
    useState(objectiveStateHistory.at(objectiveStateHistory.length - 1));
  
  useEffect(() => {
    setFilteredHistory(filterHistoryByMonth(dateSelected))
  }, [dateSelected])
  
  const moveInterval = (movement: IntervalMovement) => {
    let newDate = new Date(dateSelected);
    switch (intervalTypeSelected) {
      case StateHistoryInterval.MONTH:
        if (movement === IntervalMovement.FORWARD) {
          newDate.setMonth(newDate.getMonth() + 1);
        } else newDate.setMonth(newDate.getMonth() - 1);
        break;
      case StateHistoryInterval.WEEK:
        if (movement === IntervalMovement.FORWARD) {
          newDate.setDate(newDate.getDate() + 7);
        } else newDate.setDate(newDate.getDate() - 7);
        break;
      case StateHistoryInterval.YEAR:
        if (movement === IntervalMovement.FORWARD) {
          newDate.setFullYear(newDate.getFullYear() + 1);
        } else newDate.setFullYear(newDate.getFullYear() - 1);
        break;
    }
    setDateSelected(newDate);
  }
  
  const selectObjectiveState = (id: string) => {
    const selectedState = objectiveStateHistory.find(state => state.id === id);
    if (selectedState) {
      setObjectiveStateDisplayed(selectedState);
    }
  }
  
  const errorMessage = <ObjectiveStateHistoryModalErrorMessage error={fetchHistoryError}/>
  
  const modalComponent =
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography fontSize={18} align={"center"}>{t("stateHistoryModal.containerTitle")}</Typography>
      </div>
      <ObjectiveStateHistoryControls
        intervalSelected={dateSelected}
        moveInterval={moveInterval}
        intervalTypeSelected={intervalTypeSelected}
        setIntervalType={setHistoryIntervalType} />
      <ObjectiveStateHistoryTable currentDate={dateSelected}
                                  stateHistoryForCurrentInterval={filteredHistory}
                                  fetchHistoryError={fetchHistoryError}
                                  isStateHistoryLoading={isStateHistoryLoading}
                                  selectObjectiveState={selectObjectiveState}
                                  objectiveStateDisplayed={objectiveStateDisplayed}
      />
      <ObjectiveStateHistoryCommentSection objectiveState={objectiveStateDisplayed}
                                           addObjectiveStateComment={addObjectiveStateComment}
                                           isAddCommentLoading={isAddCommentLoading}
                                           addCommentError={addCommentError}
                                           nextDate={new Date(Date.now())} />
    </div>
  
  const renderModal = () => {
    return fetchHistoryError ?  errorMessage : modalComponent;
  }
  
  return renderModal();
}

export default ObjectiveStateHistoryModal;
