import breakpoints from "style/variable/_breakpoints.scss";

export default {
  breakpoints: {
    values: {
      xs: Number(breakpoints.breakpointXs),
      sm: Number(breakpoints.breakpointSm),
      md: Number(breakpoints.breakpointMd),
      lg: Number(breakpoints.breakpointLg),
      xl: Number(breakpoints.breakpointXl)
    }
  },
};
