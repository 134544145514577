import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store";
import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import Toggle from "component/Toggle";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import CommunicationLanguage from "module/user/domain/model/CommunicationLanguage";

const CommunicationLanguageContainer = () => {
  const dispatch = useDispatch();
  
  const {t} = useTranslation('user');
  useLoadingAndError(() => dispatch.CommunicationLanguageStore.fetchCommunicationLanguage());
  
  const communicationLanguage = useSelector(
    (rootState: RootState) => rootState.CommunicationLanguageStore?.getters(rootState).getCommunicationLanguage()
  );
  
  const initializeIsChecked = useCallback(() => {
    return communicationLanguage?.name !== 'EN';
  }, [communicationLanguage]);
  
  const [isChecked, setIsChecked] = useState(initializeIsChecked);
  
  useEffect(() => {
    setIsChecked(initializeIsChecked());
  }, [initializeIsChecked]);
  
  useEffect(() => {
    const newLanguage = isChecked ? "FR" : "EN";
    dispatch.CommunicationLanguageStore.updateCommunicationLanguage(new CommunicationLanguage(newLanguage));
  }, [isChecked, dispatch]);
  
  const handleToggle = () => {
    setIsChecked(prevState => !prevState);
  };
  
  return <Toggle
    handleToggle={handleToggle}
    isChecked={isChecked}
    mainLabel={t('user:communicationLanguage:label')}
    rightLabel={"Français"}
    leftLabel={"English"} />;
};

export default CommunicationLanguageContainer;
