import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import CommunicationLanguage from "module/user/domain/model/CommunicationLanguage";
import {RootState} from "store";
import clients from "module/common/client";
import AppError from "core/error/AppError";

export const CommunicationLanguageStore = createModel<RootModel>()({
  state: {
    communicationLanguage: undefined as CommunicationLanguage | undefined,
    getters: (state: RootState) => ({
      getCommunicationLanguage: () => {
        return state.CommunicationLanguageStore.communicationLanguage;
      }
    })
  },
  reducers: {
    setCommunicationLanguage(state, communicationLanguage: CommunicationLanguage) {
      return {
        ...state,
        communicationLanguage: communicationLanguage
      };
    }
  },
  effects: (dispatch) => ({
    async fetchCommunicationLanguage() {
      await clients.CommunicationLanguage.fetchCommunicationLanguage().then((communicationLanguage: CommunicationLanguage) => {
        dispatch.CommunicationLanguageStore.setCommunicationLanguage(communicationLanguage);
      }).catch((error: AppError) => {
        throw error.name;
      });
    },
    async updateCommunicationLanguage(updateCommunicationLanguage: CommunicationLanguage) {
      return await clients.CommunicationLanguage
        .updateCommunicationLanguage(updateCommunicationLanguage)
        .then((updatedCommunicationLanguage: CommunicationLanguage) => {
          dispatch.CommunicationLanguageStore.setCommunicationLanguage(updatedCommunicationLanguage);
          
          return updatedCommunicationLanguage;
        })
        .catch((error: AppError) => {
          throw error.name;
        });
    }
  })
});
