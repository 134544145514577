import * as React from "react";
import {Button, Typography} from "asset/component/index";
import ClosableSection from "asset/component/global/ClosableSection";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import {ArrowIcon} from "asset/component/Icons";
import trialImage from "asset/image/trial.png";
import MuiButtonSizes from "style/props/MuiButtonSizes";
import usePaletteColors from "style/usePaletteColors";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import styles from "asset/component/asset/global/TrialComponent.module.scss";
import {useNavigate} from "react-router-dom";
import {Routes} from "router/Routes";
import {useTranslation} from "react-i18next";
import TrialServices from "asset/service/TrialServices";

const TrialComponent = () => {
  const {t} = useTranslation('common');
  const palette = usePaletteColors();
  const navigate = useNavigate();
  const showTrial = TrialServices.showTrialComponent();
  
  const closeTrialComponent = () => {
    TrialServices.showedTrialComponent();
  };
  
  return showTrial ?
    <ClosableSection onClose={closeTrialComponent}>
      <div className={styles.trialContainer}>
        <img className={styles.image} src={trialImage} alt={t('trial.title')} />
        
        <Typography className={styles.title}
                    color={palette.secondary.dark}
                    variant={MuiTypographyVariants.H2}>
          {t('trial.title')}
        </Typography>
        <Typography className={styles.text}
                    color={palette.secondary.dark}
                    variant={MuiTypographyVariants.H4}>
          {t('trial.text')}
        </Typography>
        <Button
          data-testid=""
          variant={MuiButtonVariants.CONTAINED}
          endIcon={<ArrowIcon />}
          size={MuiButtonSizes.LARGE}
          onClick={() => {
            closeTrialComponent();
            navigate(Routes.add.coachee.path());
          }}
        >
          {t('trial.button')}
        </Button>
      </div>
    </ClosableSection> : <></>;
};

export default TrialComponent;
