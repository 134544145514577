import * as React from "react";
import {Provider} from "react-redux";
import {store} from "store";
import {createRoot} from "react-dom/client";
import 'style/index.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import Router from "router/Router";
import 'translation/config';
import {ThemeProvider} from "asset/component";
import CustomTheme from "style/theme/CustomTheme";
import { PermifyProvider as PProv } from '@permify/react-role';
const PermifyProvider = PProv as any;

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <ThemeProvider theme={CustomTheme}>
    <Provider store={store}>
      <PermifyProvider>
        <Router />
      </PermifyProvider>
    </Provider>
  </ThemeProvider>
);
