export interface IAddTeamMotivator {
  title: string;
  teamId: string;
}

export default class AddTeamMotivator implements IAddTeamMotivator {
  private readonly _title: string;
  private readonly _teamId: string;
  
  constructor(title: string, teamId: string) {
    this._title = title;
    this._teamId = teamId;
  }
  
  get title(): string {
    return this._title;
  }
  
  get teamId(): string {
    return this._teamId;
  }
}
