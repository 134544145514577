import NeedStats from "module/objective/domain/model/need/NeedStats";

export default interface ObjectiveNeedsStatsResponse {
  objectiveCount: number;
  completedObjectiveCount: number;
}

export function objectiveNeedsStatsResponseToModel(objectiveNeedsStatsResponse: ObjectiveNeedsStatsResponse): NeedStats {
  return new NeedStats(
    objectiveNeedsStatsResponse.objectiveCount,
    objectiveNeedsStatsResponse.completedObjectiveCount,
  );
}
