import React, {ReactElement} from 'react';
import NavbarWrapper from "module/common/navbar/component/NavbarWrapper";
import LanguageManager from "core/helper/LanguageManager";
import {useDispatch} from "react-redux";
import {Dispatch} from "store";

function Navbar(): ReactElement {
  const dispatch = useDispatch<Dispatch>();
  
  return <NavbarWrapper changeLanguage={LanguageManager.changeToNextLanguage}
                        logout={dispatch.AuthenticationStore.logout} />;
}

export default Navbar;
