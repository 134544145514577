import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Alert, Checkbox, Container, FormControlLabel, Grid, LoadingButton, Recaptcha} from "asset/component";
import {useTranslation} from "react-i18next";
import {TextValidator, useFormValidation, ValidatorForm} from "asset/validator";
import styles from 'module/authentication/view/asset/RegisterFormComponent.module.scss';
import {InputValidator} from "asset/validator/InputValidator";
import {ArrowIcon} from "asset/component/Icons";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import MuiButtonSizes from "style/props/MuiButtonSizes";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";
import {ErrorNames} from "core/error/ErrorNames";
import MuiCheckboxColors from "style/props/MuiCheckboxColors";
import {Link, Typography} from "@mui/material";

export interface RegisterFormProps {
  formState: {
    email: string,
    setEmail: (email: string) => void,
    password: string,
    setPassword: (password: string) => void,
    firstName: string,
    setFirstName: (firstName: string) => void,
    lastName: string,
    setLastName: (lastName: string) => void,
  },
  register: {
    dispatch: () => void,
    errorName?: ErrorNames,
    isLoading?: boolean
  }
}

export default function RegisterFormComponent({
  formState,
  register,
}: RegisterFormProps) {
  const {t} = useTranslation('authentication');
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [confirm, setConfirm] = useState(false);
  const {getErrorMessage} = useErrorTranslation('authentication');
  const formRef = useRef();
  const [isCaptchaResolved, setIsCaptchaResolved] = useState(false);
  const [showCaptchaRequired, setShowCaptchaRequired] = useState(false);
  const {isFormValid} = useFormValidation(formRef, [formState.email, formState.password]);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    
    if (isCaptchaResolved) {
      setShowCaptchaRequired(false);
      
      register.dispatch();
    } else {
      setShowCaptchaRequired(true);
    }
  };
  
  const handleCaptchaResolved = (value) => {
    setIsCaptchaResolved(value);
  };
  
  useEffect(() => {
    ValidatorForm.addValidationRule(InputValidator.IS_PASSWORD_MATCHING, (value) => {
      return value === formState.password;
    });
  }, [formState.password]);
  
  return (
    <Container id={styles.registerFormContainer}>
      <ValidatorForm id={styles.registerForm}
                     ref={formRef}
                     data-testid="register-form"
                     onSubmit={handleSubmit}>
        {register.errorName &&
            <Alert severity="error" data-testid="register-error-message">
              {getErrorMessage(register.errorName)}
            </Alert>}
        <TextValidator
          required
          fullWidth
          data-testid="email-input"
          id="email"
          placeholder={t('form.email')}
          name="email"
          value={formState.email}
          onChange={(event) => formState.setEmail(event.target.value)}
          validators={[InputValidator.REQUIRED, InputValidator.IS_EMAIL]}
          errorMessages={[t('form.validation.emailRequired'), t('form.validation.emailIsValid')]}
          autoFocus
        />
        <Grid container columnSpacing={1}>
          <Grid item xs={6}>
            <TextValidator
              required
              fullWidth
              type="text"
              data-testid="first-name-input"
              id="firstName"
              placeholder={t('form.firstName')}
              name="firstName"
              value={formState.firstName}
              onChange={(event) => formState.setFirstName(event.target.value)}
              validators={[InputValidator.REQUIRED]}
              errorMessages={[t('form.validation.firstNameRequired')]}
            />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              required
              fullWidth
              type="text"
              data-testid="last-name-input"
              id="lastName"
              placeholder={t('form.lastName')}
              name="lastName"
              value={formState.lastName}
              onChange={(event) => formState.setLastName(event.target.value)}
              validators={[InputValidator.REQUIRED]}
              errorMessages={[t('form.validation.lastNameRequired')]}
            />
          </Grid>
        </Grid>
        <TextValidator
          required
          fullWidth
          name="password"
          placeholder={t('form.password')}
          type="password"
          id="password"
          data-testid="password-input"
          value={formState.password}
          onChange={(event) => formState.setPassword(event.target.value)}
          validators={[InputValidator.REQUIRED]}
          errorMessages={[t('form.validation.passwordRequired')]}
        />
        <TextValidator
          required
          fullWidth
          name="confirmPassword"
          placeholder={t('form.confirmPassword')}
          type="password"
          id="confirmPassword"
          data-testid="confirm-password-input"
          value={passwordConfirmation}
          onChange={(event) => setPasswordConfirmation(event.target.value)}
          validators={[InputValidator.REQUIRED, InputValidator.IS_PASSWORD_MATCHING]}
          errorMessages={[
            t('form.validation.passwordConfirmationRequired'),
            t('form.validation.passwordConfirmationMatching')
          ]}
        />
        <div>
          <FormControlLabel
            className={styles.confirmationCheckbox}
            label={
              <Typography variant="xs">
                {t('form.confirmation')}{' '}
                <Link component={Link}
                      href={t('form.policyUrl')}
                      target="_blank">
                  {t('form.conditions')}
                </Link>
              </Typography>
              
            }
            control={<Checkbox className={styles.checkbox} color={MuiCheckboxColors.PRIMARY} />}
            checked={confirm}
            onChange={
              event =>
                setConfirm((event as React.ChangeEvent<HTMLInputElement>).target.checked)
            }
          />
        </div>
        <Recaptcha onCaptchaResolved={handleCaptchaResolved} showRequired={showCaptchaRequired} />
        <LoadingButton
          data-testid="register-button"
          variant={MuiButtonVariants.CONTAINED}
          endIcon={<ArrowIcon />}
          size={MuiButtonSizes.LARGE}
          loading={register.isLoading}
          disabled={register.isLoading || !isFormValid || !confirm}
          type="submit"
        >
          {t('form.registerButton')}
        </LoadingButton>
      </ValidatorForm>
    </Container>
  );
}
