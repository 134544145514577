import CoacheeMotivator from "module/motivator/coachee/domain/model/CoacheeMotivator";

export default interface CoacheeMotivatorResponse {
  id: string;
  title: string;
  coacheeId: string;
}

export function coacheeMotivatorResponseToModel(
  motivatorResponse: CoacheeMotivatorResponse,
  coacheeId: string
): CoacheeMotivator {
  return new CoacheeMotivator(
    motivatorResponse.id,
    motivatorResponse.title,
    coacheeId,
  );
}

export function coacheeMotivatorsResponseToModel(
  motivatorResponse: CoacheeMotivatorResponse[],
  coacheeId: string
): CoacheeMotivator[] {
  return motivatorResponse.map(motivatorResponse => coacheeMotivatorResponseToModel(motivatorResponse, coacheeId));
}
