import AddCoacheeDemotivator from "module/demotivator/coachee/domain/model/AddCoacheeDemotivator";

export default interface AddCoacheeDemotivatorRequest {
  title: string;
  coacheeId: string;
}

export function createAddCoacheeDemotivatorRequest(addDemotivator: AddCoacheeDemotivator): AddCoacheeDemotivatorRequest {
  return {
    title: addDemotivator.title,
    coacheeId: addDemotivator.coacheeId,
  } as AddCoacheeDemotivatorRequest;
}
