import React, {useEffect} from 'react';
import Hourglass from "asset/icon/Hourglass";
import {Typography} from "@mui/material";
import Mandate from "module/mandate/domain/model/Mandate";
import {useTranslation} from "react-i18next";
import styles from "module/mandate/view/asset/listing/MandateCardContent.module.scss";
import FinishedTag from "module/mandate/view/component/listing/FinishedTag";
import useDateTranslation from "asset/component/hook/useDateTranslation";

const MandateDeadlineDisplay = ({
  mandate,
  isFinished
}: {
  mandate: Mandate,
  isFinished: boolean
}) => {
  const {t, i18n} = useTranslation('mandate');
  const {formatDateWithMonthName} = useDateTranslation();
  const [deadline, setDeadline] = React.useState<string | null>(null);
  
  useEffect(() => {
    setDeadline(formatDateWithMonthName(mandate.deadline));
  }, [i18n.language, mandate.deadline]);
  
  return (
    <div className={styles.container}>
      <div className={styles.deadline}>
        <Hourglass />
        <Typography variant="subtitle2" component="p">
          {deadline
            ? deadline : t('common:months.none')}
        </Typography>
      </div>
      {isFinished && <FinishedTag />}
    </div>
  );
};

export default MandateDeadlineDisplay;
