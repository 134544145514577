import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import Subscription from "module/subscription/domain/model/Subscription";
import {RootState} from "store";
import AppError from "core/error/AppError";

export const SubscriptionStore = createModel<RootModel>()({
  state: {
    subscription: undefined as Subscription | undefined,
    getters: (state: RootState) => ({
      getSubscription: () => {
        return state.SubscriptionStore.subscription;
      }
    })
  },
  reducers: {
    setSubscription(state, subscription: Subscription) {
      return {
        ...state,
        subscription: subscription
      };
    }
  },
  effects: (dispatch) => ({
    async fetchSubscription() {
      await clients.Subscription.fetchSubscription().then((subscription: Subscription) => {
        dispatch.SubscriptionStore.setSubscription(subscription);
      }).catch((error: AppError) => {
        throw error.name;
      });
    },
    async redirectToCancelUrlSubscription() {
      await clients.Subscription.fetchCancelUrlSubscription().then((cancelUrl: string) => {
        window.open(cancelUrl, '_self');
      }).catch((error: AppError) => {
        throw error.name;
      });
    },
  })
});
