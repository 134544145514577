export default class ObjectiveStateComment {
  private readonly _comment: string;
  private readonly _createdAt: Date;
  
  constructor(
    comment: string,
    createdAt: string
  ) {
    this._comment = comment;
    this._createdAt = new Date(createdAt);
  }
  
  get comment(): string {
    return this._comment;
  }
  
  get createdAt(): Date {
    return this._createdAt;
  }
}
