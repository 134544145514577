export interface IAddCoacheeMotivator {
  title: string;
  coacheeId: string;
}

export default class AddCoacheeMotivator implements IAddCoacheeMotivator {
  private readonly _title: string;
  private readonly _coacheeId: string;
  
  constructor(title: string, coacheeId: string) {
    this._title = title;
    this._coacheeId = coacheeId;
  }
  
  get title(): string {
    return this._title;
  }
  
  get coacheeId(): string {
    return this._coacheeId;
  }
}
