import form from "style/variable/_form.scss";

type MuiInputLabelSize = 'small' | 'normal' | undefined;
type MuiInputSize = 'small' | 'medium' | undefined;

export default {
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: form.textFieldTextColor,
        fontSize: form.textFieldPlaceHolderFontSize,
        opacity: form.textFieldPlaceHolderOpacity,
        '&.MuiInputLabel-shrink': {
          display: form.displayLabelOnFocus
        }
      },
    },
  },
  MuiInputLabel: {
    defaultProps: {
      size: "small" as MuiInputLabelSize
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        'textarea::placeholder, input::placeholder': {
          fontSize: form.textFieldPlaceHolderFontSize,
          opacity: form.textFieldPlaceHolderOpacity,
        },
        'img.icon-option::before': {
          display: 'none'
        }
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        color: form.textFieldTextColor,
        backgroundColor: form.textFieldBackgroundColor,
        borderRadius: form.textFieldBorderRadius,
        fieldset: {
          span: {display: form.displayLabelOnFocus},
          border: 'none',
          top: 0,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: form.textFieldBorderFocus,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: form.textFieldForderError,
        },
        '&.Mui-error::before': {
          content: '""',
          width: form.inputErrorIconWidth,
          height: form.inputErrorIconHeight,
          position: 'absolute',
          right: form.inputErrorIconRightPosition
        },
        '&.MuiInputBase-multiline.Mui-error::before': {
          top: "5px",
          right: "5px"
        },
        '&.MuiInputBase-colorSecondary': {
          backgroundColor: form.textFieldBackgroundColorSecondary,
        },
        '&.MuiInputBase-colorTransparent': {
          border: form.textFieldBorderTransparent,
          color: form.textFieldColorTransparent,
          backgroundColor: form.textFieldBackgroundColorTransparent,
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }
      },
    },
    defaultProps: {
      size: "small" as MuiInputSize
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        '&.Mui-error': {
          color: form.textHelperErrorColor,
          margin: '10px 2px 0'
        },
        fontSize: form.textHelperErrorFontSize
      }
    }
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        fieldset: {
          '&.MuiOutlinedInput-notchedOutline': {
            top: '-5px'
          },
        }
      },
    },
  }
};
