import React, {useEffect} from 'react';
import {ModuleCard, ModuleHeader} from "asset/component";
import {ModuleTheme} from "asset/component/module/ModuleCard";
import TagDisplayer from "asset/component/tag/TagDisplayer";
import Checkmark from "asset/icon/Checkmark";
import Plaque, {PlaqueTheme} from "asset/component/global/Plaque";
import {CloseIcon, CoacheeIcon, EditIcon} from "asset/component/Icons";
import {useTranslation} from "react-i18next";
import {Tag} from "asset/component/tag/Tag";
import styles from "module/mandate/view/asset/edit/MandateLinkedEntitiesComponent.module.scss";
import InputAutoComplete from "asset/component/form/inputs/InputAutoComplete";
import DoneButton from "asset/component/global/DoneButton";
import IconButton from "asset/component/global/buttons/IconButton";
import SearchAutoCompleteCategories, {InputCategoryOptions} from "asset/component/search/SearchAutoCompleteCategories";
import LinkableEntity, {LinkableEntityType} from "module/mandate/domain/model/LinkableEntity";
import ModuleListing from "asset/component/module/ModuleListing";
import {ErrorNames} from "core/error/ErrorNames";
import TeamLinkedEntityContainer from "module/mandate/view/container/edit/TeamLinkedEntityContainer";
import {
  convertLinkableEntitiesToOptionsAndTags,
  getLinkedCompaniesAsTags
} from "module/mandate/view/container/LinkableEntitiesMethods";
import LinkedEntity from "module/mandate/domain/model/LinkedEntity";
import usePaletteColors, {AddOpacity} from "style/usePaletteColors";
import ModuleSectionLayout from 'asset/component/module/ModuleSectionLayout';

const MandateLinkedEntitiesComponent = ({
  linkableEntities,
  linkedEntities,
  addLinkedEntity,
  removeLinkedEntity,
  isLoading,
  errorName
}: {
  linkableEntities: LinkableEntity[],
  linkedEntities: LinkedEntity[],
  addLinkedEntity: (id: string) => void,
  removeLinkedEntity: (name: string) => void,
  isLoading?: boolean,
  errorName?: ErrorNames
}) => {
  const {t} = useTranslation('mandate');
  const palette = usePaletteColors();
  
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [currentValue, setCurrentValue] = React.useState<string>('');
  const [searchValue, setSearchValue] = React.useState<InputCategoryOptions>();
  const [companies, setCompanies] = React.useState<Tag[]>([]);
  const [linkedCompanies, setLinkedCompanies] = React.useState<Tag[]>([]);
  const [linkableEntitiesOptions, setLinkableEntitiesOptions] = React.useState<InputCategoryOptions[]>([]);
  const [companiesAreInEditMode, setCompaniesAreInEditMode] = React.useState(false);
  
  useEffect(() => {
    const {options, companies} = convertLinkableEntitiesToOptionsAndTags(linkableEntities, t);
    
    setLinkableEntitiesOptions(options);
    setCompanies(companies);
    setLinkedCompanies(getLinkedCompaniesAsTags(linkedEntities));
  }, [linkableEntities, linkedEntities, t]);
  
  const resetSearch = (id: string) => {
    addLinkedEntity(id);
    setSearchValue(undefined);
  };
  
  const getCompany = () => {
    const company = companies.find(company => company.name === currentValue);
    if (!company) return;
    
    addLinkedEntity(company.id);
    setCurrentValue('');
  };
  
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    setCompaniesAreInEditMode(false);
  };
  
  const getPlaque = (entity: LinkedEntity) => {
    switch (entity.type) {
      case LinkableEntityType.COACHEE:
        return <Plaque id={entity.id}
                       data-testid={`${'plaque-' + entity.id}`}
                       key={entity.id}
                       label={entity.name}
                       icon={<CoacheeIcon />}
                       actionIcon={isEditMode ? <CloseIcon /> : <></>}
                       onClick={removeLinkedEntity}
                       isEditMode={isEditMode}
                       theme={PlaqueTheme.LIGHT} />;
      case LinkableEntityType.TEAM:
        return <TeamLinkedEntityContainer isEditMode={isEditMode}
                                          entity={entity}
                                          action={removeLinkedEntity} />;
      default:
        return <></>;
    }
  };
  
  const getHoverBackgroundColor = () => {
    return AddOpacity(palette.primary.middle, 40);
  };
  
  const iconButton =
    <IconButton className={styles.editButton}
                buttonSize={"36px"}
                iconSize={"20px"}
                onClick={() => setCompaniesAreInEditMode(!companiesAreInEditMode)}
                iconColor={companiesAreInEditMode ? palette.primary.dark : "transparent"}
                hoverIconColor={palette.primary.dark}
                hoverBackgroundColor={getHoverBackgroundColor()}
                backgroundColor={companiesAreInEditMode ? getHoverBackgroundColor() : "transparent"}
                icon={companiesAreInEditMode ? <Checkmark /> : <EditIcon />
                } />;
  
  const inputAutoComplete =
    <InputAutoComplete show={isEditMode && companiesAreInEditMode}
                       tags={companies}
                       value={currentValue}
                       onChange={(value) => setCurrentValue(value)}
                       endIcon={
                         <DoneButton text={t('form.add.add')} onClick={() => getCompany()} />}
    />;
  
  const getTagDisplayerIcon = () => {
    return isEditMode ? iconButton : <></>;
  };
  
  const getTagDisplayerInput = () => {
    return companiesAreInEditMode && isEditMode ? <>{getTagDisplayerIcon()} {inputAutoComplete}</> : <>{getTagDisplayerIcon()}</>;
  };
  
  return (
    <ModuleCard theme={ModuleTheme.WHITE} injectedStyles={{padding: '3px', paddingTop: '30px', paddingBottom: '30px'}}>
      <div className={styles.container}>
        <ModuleHeader title={t('linkedOrgs.title')}
                      subtitle={t('linkedOrgs.subtitle')}
                      textAlign={'left'}
                      endIcon={
                        <IconButton className={styles.editButton}
                                    buttonSize={"36px"}
                                    iconSize={"20px"}
                                    onClick={() => toggleEditMode()}
                                    iconColor={isEditMode ? palette.primary.dark : "transparent"}
                                    hoverIconColor={palette.primary.dark}
                                    hoverBackgroundColor={getHoverBackgroundColor()}
                                    backgroundColor={isEditMode ? getHoverBackgroundColor() : "transparent"}
                                    icon={isEditMode ? <Checkmark /> : <EditIcon />
                                    } />
                      } />
        <ModuleSectionLayout isLoading={false} injectedStyles={{padding: '0'}}>
          <TagDisplayer tags={linkedCompanies}
                        removeDialog={true}
                        removeConfirmationText={t('form.edit.removeConfirmationText')}
                        removeTag={(tag) => removeLinkedEntity(tag.id)}
                        icon={<Checkmark />}
                        input={getTagDisplayerInput()}
          />
        </ModuleSectionLayout>
        
        {isEditMode && <SearchAutoCompleteCategories options={linkableEntitiesOptions}
                                                     placeholder={t('form.edit.search.placeholder')}
                                                     noOptionsText={t('form.edit.search.noResults')}
                                                     value={searchValue}
                                                     onChange={(id: string) => resetSearch(id)} />}
        <ModuleListing elements={linkedEntities}
                       data-testid="module-listing"
                       isLoading={isLoading}
                       errorName={errorName}
                       injectedStyle={styles}>
          {
            (element: LinkedEntity) => getPlaque(element)
          }
        </ModuleListing>
      </div>
    </ModuleCard>
  );
};

export default MandateLinkedEntitiesComponent;
