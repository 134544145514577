import * as React from "react";

const AddIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 40 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0007 5C20.9211 5 21.6673 5.74619 21.6673 6.66667V33.3333C21.6673 34.2538 20.9211 35 20.0007 35C19.0802 35 18.334 34.2538 18.334 33.3333V6.66667C18.334 5.74619 19.0802 5 20.0007 5Z"
            fill="currentColor" />
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M35 20C35 20.9205 34.2538 21.6667 33.3333 21.6667L6.66667 21.6667C5.74619 21.6667 5 20.9205 5 20C5 19.0796 5.74619 18.3334 6.66667 18.3334L33.3333 18.3334C34.2538 18.3334 35 19.0796 35 20Z"
            fill="currentColor" />
    </svg>
  
  );
};
export default AddIcon;
