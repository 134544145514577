const useDateUtils = () => {
  const getAmountOfDaysInMonth = (year: number, month: number) => {
    const date = new Date(year, month);
    date.setMonth(date.getMonth() + 1);
    date.setDate( date.getDate() - 1);
    let lastDayOfTheMonth = date.getDate();
    return lastDayOfTheMonth;
  }
  
  return {getAmountOfDaysInMonth}
}

export default useDateUtils;
