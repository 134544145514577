import {Tag} from "asset/component/tag/Tag";
import CoacheeDemotivator from "module/demotivator/coachee/domain/model/CoacheeDemotivator";
import TeamDemotivator from "./team/domain/model/TeamDemotivator";

const convertDemotivatorsToTags = (demotivators: CoacheeDemotivator[] | TeamDemotivator[]): Tag[] => {
  return demotivators ? demotivators.map(
    (demotivator: CoacheeDemotivator | TeamDemotivator) => {
      return {
        id: demotivator.id,
        name: demotivator.title,
        isSelected: true
      };
    }) : [];
};

export default convertDemotivatorsToTags;
