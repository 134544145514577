import styles from "module/objective/view/asset/ObjectiveStateCreation.module.scss"
import {StateStatus} from "module/objective/domain/model/StateStatus";
import React from "react";
import {useTranslation} from "react-i18next";
import StatusCircle from "module/objective/view/component/StatusIcon";
import {Typography} from "@mui/material";
import useDateTranslation from "asset/component/hook/useDateTranslation";
import {IconButton, InputAutoUpdate} from "asset/component";
import {CloseIcon} from "asset/component/Icons";

const ObjectiveStateCreationComponent = ({
  status,
  onClose,
  onSave
} : {
  status : StateStatus,
  onClose : () => void,
  onSave?: (comment: string) => void
}) => {
  const {t} = useTranslation("objective");
  
  const currentTranslatedDate = () => {
    const currentDate = new Date(Date.now());
    return useDateTranslation().formatDateWithMonthName(currentDate);
  };
  
  const getStatusColor = (status: StateStatus) => {
    switch (status) {
      case StateStatus.FINE: return "dark-creme";
      case StateStatus.IDLE: return "very-light-green";
      case StateStatus.OPTIMAL: return "flash-creme";
      case StateStatus.MONITORING : return "lightest-green"
    }
  }
  
  return(
    <div className={`${styles.container} ${getStatusColor(status)}`}>
      <div className={styles.content}>
        <StatusCircle status={status}/>
        <IconButton
            backgroundColor={"transparent"}
            iconColor={"black"}
            className={styles.closeIcon}
            icon={<CloseIcon />}
            buttonSize={"20px"}
            iconSize={"15px"}
            data-testid="close-button"
            onClick={onClose}
          />
        </div>
        <Typography fontSize={14} align={"center"}>{t(`state.${status}`)}</Typography>
        <Typography fontSize={12} align={"center"}>{currentTranslatedDate()}</Typography>
      <InputAutoUpdate
        initialValue={""}
        saveAction={(value) => onSave!(value)}
        placeholder={t("objectiveStateComment")}
        rows={4}
        withOnBlur={false}/>
  </div>
  )
}

export default ObjectiveStateCreationComponent;
