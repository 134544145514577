import React, {useEffect, useRef} from 'react';
import {Alert} from "asset/component";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import {ErrorNames} from "core/error/ErrorNames";
import AddMandateInfoFormComponent from "module/mandate/view/component/add/AddMandateInfoFormComponent";
import {ValidatorForm} from 'asset/validator';
import AddMandateConfirmationComponent from "module/mandate/view/component/add/AddMandateConfirmationComponent";
import useNavigationFormSections from "asset/component/form/hook/useNavigationFormSections";
import LoadingLayout from "module/common/layout/container/LoadingLayout";
import {Moment} from "moment/moment";
import AddMandate from "module/mandate/domain/model/AddMandate";
import AddLinkedEntitiesFormContainer from "module/mandate/view/container/add/AddLinkedEntitiesFormContainer";

const INFO_FORM_ID = "info-form";
const LINKED_ENTITIES_FORM_ID = "linked-entities-form";
const CONFIRM_FORM_ID = "confirm-form";

export interface AddMandateStateProps {
  title: string;
  setTitle: ((title: string) => void);
  description: string;
  setDescription: ((description: string) => void);
  startDate: Moment | null;
  setStartDate: ((date: Moment | null) => void);
  deadline: Moment | null;
  setDeadline: ((date: Moment | null) => void);
}

const AddMandateFormComponent = ({
  errorName,
  isLoading,
  addMandateState,
  submitForm,
  currentMandateId
}: {
  errorName?: ErrorNames;
  isLoading?: boolean;
  addMandateState: AddMandateStateProps;
  submitForm: (newMandate: AddMandate) => void;
  currentMandateId?: string;
}) => {
  const {getErrorMessage} = useErrorTranslation();
  const useNavigation = useNavigationFormSections();
  const formRef = useRef<HTMLFormElement>();
  const {
    title,
    setTitle,
    description,
    setDescription,
    startDate,
    setStartDate,
    deadline,
    setDeadline
  }: AddMandateStateProps = addMandateState;
  const [nextIsDisabled, setNextIsDisabled] = React.useState<boolean>(false);
  
  useEffect(() => {
    useNavigation.activateSection(INFO_FORM_ID);
  }, []);
  
  const submit = () => {
    if (currentMandateId) return;
    submitForm(new AddMandate(title, description, startDate ? new Date(startDate.toISOString()) : undefined, deadline ? new Date(deadline.toISOString()) : undefined));
    setNextIsDisabled(true);
    
    setTimeout(() => {
      useNavigation.activateSection(LINKED_ENTITIES_FORM_ID);
    }, 300);
  };
  
  const addLinkedEntities = () => {
    useNavigation.activateSection(CONFIRM_FORM_ID);
  };
  
  return (
    <div>
      {errorName &&
          <Alert
              severity="error">
            {errorName && getErrorMessage(errorName)}
          </Alert>}
      <ValidatorForm
        onSubmit={submit}
        data-testid="add-mandate-form"
        ref={formRef}>
        <AddMandateInfoFormComponent
          title={title}
          setTitle={setTitle}
          description={description}
          setDescription={setDescription}
          deadline={deadline}
          setDeadline={setDeadline}
          startDate={startDate}
          setStartDate={setStartDate}
          anchorId={INFO_FORM_ID}
          useNavigation={useNavigation}
          nextSectionFormId={LINKED_ENTITIES_FORM_ID}
          disabledButton={nextIsDisabled} />
      </ValidatorForm>
      <ValidatorForm
        onSubmit={addLinkedEntities}
        data-testid="add-mandate-entities-form"
        ref={formRef}
      >
        {currentMandateId && <AddLinkedEntitiesFormContainer mandateId={currentMandateId}
                                                             anchorId={LINKED_ENTITIES_FORM_ID}
                                                             useNavigation={useNavigation}
                                                             nextSectionFormId={CONFIRM_FORM_ID}
                                                             previousSectionFormId={INFO_FORM_ID} />}
      </ValidatorForm>
      
      {isLoading ?
        <LoadingLayout /> :
        <AddMandateConfirmationComponent
          useNavigation={useNavigation}
          anchorId={CONFIRM_FORM_ID}
          previousSectionFormId={LINKED_ENTITIES_FORM_ID} />}
    </div>
  );
};

export default AddMandateFormComponent;