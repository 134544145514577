import {useState} from "react";
import {ErrorNames} from "core/error/ErrorNames";

export const usePromiseLoadingAndError = (
  promiseFunction: (...args: any[]) => Promise<any>
): [boolean, ErrorNames | undefined, (...args: any[]) => void, boolean] => {
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorNames | undefined>(undefined);
  
  const dispatch = (...args: any[]) => {
    setIsLoading(true);
    setError(undefined);
    setIsCompleted(false);
    
    promiseFunction(...args)
      .then(() => {
        setIsLoading(false);
        setIsCompleted(true);
      })
      .catch((errorName: ErrorNames) => {
        setError(errorName);
        setIsLoading(false);
      });
  };
  
  return [isLoading, error, dispatch, isCompleted];
};
