import MandateRepository from "module/mandate/domain/MandateRepository";
import RESTClient from "core/client/RESTClient";
import MandateResponse, {mandateResponseToModel, mandatesResponseToModel} from "module/mandate/dal/dto/MandateResponse";
import Mandate from "module/mandate/domain/model/Mandate";
import AddMandate from "module/mandate/domain/model/AddMandate";
import {createAddMandateRequest} from "module/mandate/dal/dto/AddMandateRequest";

export default class RESTMandateRepository implements MandateRepository {
  private static readonly BASE_URL: string = "api";
  private static readonly MANDATES_URL: string = "mandates";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchMandatesForTenant(): Promise<Mandate[]> {
    const mandatesResponse = await this.client.apiGet<MandateResponse[]>(
      `/${RESTMandateRepository.BASE_URL}` +
      `/${RESTMandateRepository.MANDATES_URL}`
    );
    
    return mandatesResponseToModel(mandatesResponse);
  }
  
  public async fetchMandateById(mandateId: string): Promise<Mandate> {
    const mandateResponse = await this.client.apiGet<MandateResponse>(
      `/${RESTMandateRepository.BASE_URL}` +
      `/${RESTMandateRepository.MANDATES_URL}` +
      `/${mandateId}`
    );
    
    return mandateResponseToModel(mandateResponse);
  }
  
  public async addMandate(addMandate: AddMandate): Promise<Mandate> {
    const mandateResponse = await this.client.apiPost<MandateResponse>(
      `/${RESTMandateRepository.BASE_URL}` +
      `/${RESTMandateRepository.MANDATES_URL}`,
      createAddMandateRequest(addMandate)
    );
    
    return mandateResponseToModel(mandateResponse);
  }
  
  public async updateMandate(mandate: Mandate): Promise<Mandate> {
    const updatedMandate = new AddMandate(mandate.title, mandate.description, mandate.startDate, mandate.deadline);
    const mandateResponse = await this.client.apiPut<MandateResponse>(
      `/${RESTMandateRepository.BASE_URL}` +
      `/${RESTMandateRepository.MANDATES_URL}` +
      `/${mandate.id}`,
      createAddMandateRequest(updatedMandate)
    );
    
    return mandateResponseToModel(mandateResponse);
  }
  
  public async deleteMandate(mandateId: string): Promise<void> {
    await this.client.apiDelete(
      `/${RESTMandateRepository.BASE_URL}` +
      `/${RESTMandateRepository.MANDATES_URL}` +
      `/${mandateId}`
    );
  }
}
