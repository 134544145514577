import TeamDemotivator from "module/demotivator/team/domain/model/TeamDemotivator";

export default interface TeamDemotivatorResponse {
  id: string;
  title: string;
  teamId: string;
}

export function teamDemotivatorResponseToModel(
  demotivatorResponse: TeamDemotivatorResponse,
  teamId: string
): TeamDemotivator {
  return new TeamDemotivator(
    demotivatorResponse.id,
    demotivatorResponse.title,
    teamId,
  );
}

export function teamDemotivatorsResponseToModel(
  demotivatorResponse: TeamDemotivatorResponse[],
  teamId: string
): TeamDemotivator[] {
  return demotivatorResponse.map(demotivatorResponse => teamDemotivatorResponseToModel(demotivatorResponse, teamId));
}
