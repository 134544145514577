import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import {RootState} from "store";
import AppError from "core/error/AppError";
import ReducerServices from "store/service/ReducerServices";
import FollowUpCoachee from "module/followUp/domain/model/FollowUpCoachee";
import AddFollowUp from "module/followUp/domain/model/AddFollowUp";
import UpdateFollowUp from "module/followUp/domain/model/UpdateFollowUp";

export const FollowUpCoacheeStore = createModel<RootModel>()({
  state: {
    followUpsCoachee: [] as FollowUpCoachee[],
    getters: (state: RootState) => ({
      getFollowUpsByCoacheeId: (id: string) => {
        return state.FollowUpCoacheeStore.followUpsCoachee.filter(
          followUp => followUp.coacheeId == id);
      }
    })
  },
  reducers: {
    removeFollowUpCoacheeById(
      state,
      followUpId: string
    ) {
      return {
        ...state,
        followUpsCoachee: ReducerServices.removeItemFromList<FollowUpCoachee>(
          state.followUpsCoachee,
          followUpId)
      };
    },
    setFollowUpsCoachee(
      state,
      followUpsCoachee: FollowUpCoachee[]
    ) {
      return {
        ...state,
        followUpsCoachee: ReducerServices.setItemsToList<FollowUpCoachee>(
          state.followUpsCoachee,
          followUpsCoachee)
      };
    },
    setFollowUpCoachee(
      state,
      followUpCoachee: FollowUpCoachee
    ) {
      return {
        ...state,
        followUpsCoachee: ReducerServices.setItemToList<FollowUpCoachee>(
          state.followUpsCoachee,
          followUpCoachee)
      };
    },
  },
  effects: (dispatch) => ({
    async fetchFollowUpsByCoacheeId(id: string) {
      await clients.FollowUpCoachee.fetchFollowUpsByCoacheeId(id).then(
        (followUpsCoachee: FollowUpCoachee[]) => {
          dispatch.FollowUpCoacheeStore.setFollowUpsCoachee(
            followUpsCoachee);
        }).catch((error: AppError) => {
        throw error.name;
      });
    },
    async addFollowUpForCoachee(payload: { addFollowUp: AddFollowUp, coacheeId: string }) {
      return await clients.FollowUpCoachee
        .addFollowUpForCoachee(payload.addFollowUp, payload.coacheeId)
        .then((addedFollowUp) => {
          dispatch.FollowUpCoacheeStore.setFollowUpCoachee(addedFollowUp);
          
          return addedFollowUp;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async updateFollowUpForCoachee(payload: {
      updateFollowUp: UpdateFollowUp,
      followUpId: string,
      coacheeId: string
    }) {
      return await clients.FollowUpCoachee
        .updateFollowUpForCoachee(payload.updateFollowUp, payload.followUpId, payload.coacheeId)
        .then((updatedFollowUp) => {
          dispatch.FollowUpCoacheeStore.setFollowUpCoachee(updatedFollowUp);
          
          return updatedFollowUp;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteFollowUpForCoachee(payload: { followUpId: string, coacheeId: string }) {
      return await clients.FollowUpCoachee
        .deleteFollowUpForCoachee(payload.followUpId, payload.coacheeId)
        .then(() => {
          dispatch.FollowUpCoacheeStore.removeFollowUpCoacheeById(payload.followUpId);
        }).catch((error: AppError) => {
          throw error.name;
        });
    }
  })
});
