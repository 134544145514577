import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import {useNavigate} from 'react-router-dom';

export interface BreadcrumbLink {
  name: string;
  path?: string;
  selected?: boolean;
}

const Breadcrumb = ({links}: { links?: BreadcrumbLink[] }) => {
  const navigate = useNavigate();
  
  const onBreadcrumbClick = (path) => {
    navigate(path);
  };
  
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {links?.map((link, index) => (
        <Link
          className={`${link.selected && 'selected'}`}
          data-testid={`link-${link.name}`}
          onClick={() => onBreadcrumbClick(link.path)}
          key={index}
          underline={link.path ? 'hover' : 'none'}
        >
          {link.name}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
