import React from "react";
import {IconButton, Typography} from "asset/component";
import {useTranslation} from "react-i18next";
import styles from "asset/component/asset/form/FormHeader.module.scss";
import {useNavigate} from "react-router-dom";
import {Routes} from "router/Routes";
import {CloseIcon} from "asset/component/Icons";
import usePaletteColors, {AddOpacity} from "style/usePaletteColors";

const FormHeader = ({
  navigateTo
}: {
  navigateTo?: string;
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const palette = usePaletteColors();
  
  const navigateOnClose = () => {
    if (navigateTo)
      navigate(navigateTo);
    else
      navigate(Routes.home.path());
  };
  
  return (
    <div className={styles.header}>
      <Typography component="h2"
                  variant="h2"
                  className="font-color-light-regular">
        {t('common:add')}
      </Typography>
      <IconButton data-testid="navigation-button"
                  onClick={navigateOnClose}
                  backgroundColor="transparent"
                  hoverBackgroundColor={AddOpacity(palette.primary.dark, 40)}
                  buttonSize={"50px"}
                  iconSize={"35px"}
                  icon={<CloseIcon />}
      />
    </div>
  );
};
export default FormHeader;
