import CommunicationLanguage from "module/user/domain/model/CommunicationLanguage";

export default interface CommunicationLanguageResponse {
  communicationLanguage: string;
}

export function communicationLanguageResponseToModel(
  communicationLanguageResponse: CommunicationLanguageResponse
): CommunicationLanguage {
  return new CommunicationLanguage(
    communicationLanguageResponse.communicationLanguage
  );
}
