import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import ObjectiveComponent from "module/objective/view/component/ObjectiveComponent";
import SubObjective from "module/objective/domain/model/SubObjective";
import AddSubObjective from "module/objective/domain/model/AddSubObjective";
import ObjectiveMandate from "module/objective/domain/model/ObjectiveMandate";
import UpdateObjectiveMandate from "module/objective/domain/model/UpdateObjectiveMandate";
import MandateSubObjectiveContainer from "module/objective/view/container/mandate/MandateSubObjectiveContainer";
import AddObjectiveState from "module/objective/domain/model/AddObjectiveState";
import UpdateObjectiveStateComment from "module/objective/domain/model/UpdateObjectiveStateComment";
import AddObjectiveStateComment from "module/objective/domain/model/AddObjectiveStateComment";

const MandateObjectiveContainer = ({
  objectiveMandate,
  isEditable = true
}: {
  objectiveMandate: ObjectiveMandate,
  isEditable?: boolean
}) => {
  const dispatch = useDispatch<Dispatch>();
  
  const completedSubObjectiveCount = useSelector((rootState: RootState) =>
    rootState.ObjectiveMandateStore?.getters(rootState).getCompletedSubObjectivesMandatesCount(objectiveMandate.id));
  
  const objectiveStateHistory = useSelector((rootState: RootState) =>
    rootState.ObjectiveMandateStore?.getters(rootState).getObjectiveStateHistory()
  );
  
  const [isDeleteObjectiveLoading, deleteObjectiveErrorName, dispatchDeleteObjective] =
    usePromiseLoadingAndError(() =>
      dispatch.ObjectiveMandateStore.deleteObjectiveForMandate({
        objectiveId: objectiveMandate.id,
        mandateId: objectiveMandate.mandateId,
      })
    );
  
  const [isUpdateObjectiveLoading, updateObjectiveErrorName, dispatchUpdateObjective] =
    usePromiseLoadingAndError(({name, text}) =>
      dispatch.ObjectiveMandateStore.updateObjectiveForMandate({
        objectiveId: objectiveMandate.id,
        mandateId: objectiveMandate.mandateId,
        updateObjectiveMandate: new UpdateObjectiveMandate(
          name || objectiveMandate.name,
          text || objectiveMandate.text,
        )
      })
    );
  
  const [isAddSubObjectiveLoading, addSubObjectiveErrorName, dispatchAddSubObjective] =
    usePromiseLoadingAndError((name) =>
      dispatch.ObjectiveMandateStore.addSubObjectiveForMandate({
        objectiveId: objectiveMandate.id,
        mandateId: objectiveMandate.mandateId,
        addSubObjective: new AddSubObjective(name)
      })
    );
  
  const [isAddObjectiveStateLoading, addObjectiveStateErrorName, dispatchAddObjectiveState] =
    usePromiseLoadingAndError((status) =>
      dispatch.ObjectiveMandateStore.addObjectiveState({
        addObjectiveState: new AddObjectiveState(status),
        objectiveId: objectiveMandate.id,
        mandateId: objectiveMandate.mandateId,
      })
    );
  
  const [isUpdateObjectiveStateLoading, updateObjectiveStateErrorName, dispatchUpdateObjectiveState] =
    usePromiseLoadingAndError((comment) => {
      let lastObjectiveState = objectiveStateHistory[objectiveStateHistory.length - 1];
      let lastObjectiveStateAlreadyHaveComment = lastObjectiveState.comments.length > 0;
      return lastObjectiveStateAlreadyHaveComment ?
        dispatch.ObjectiveMandateStore.updateObjectiveStateComment({
          updateObjectiveStateComment: new UpdateObjectiveStateComment(comment),
          objectiveId: objectiveMandate.id,
          mandateId: objectiveMandate.mandateId
        }) :
        dispatch.ObjectiveMandateStore.addObjectiveStateComment({
          addObjectiveStateComment: new AddObjectiveStateComment(comment),
          objectiveId: objectiveMandate.id,
          mandateId: objectiveMandate.mandateId,
          objectiveStateId: lastObjectiveState.id
        });
      }
    );
  
  const [isHistoryLoading, fetchHistoryError, fetchObjectiveStateHistory] =
    usePromiseLoadingAndError(() =>
      dispatch.ObjectiveMandateStore.fetchObjectiveStateHistory({
        mandateId: objectiveMandate.mandateId,
        objectiveId: objectiveMandate.id
      })
    );
  
  const [isAddCommentLoading, addCommentErrorName, dispatchAddComment] =
    usePromiseLoadingAndError((id: string, comment: string) => {
      return dispatch.ObjectiveMandateStore.addObjectiveStateComment({
        addObjectiveStateComment: new AddObjectiveStateComment(comment),
        objectiveId: objectiveMandate.id,
        mandateId: objectiveMandate.mandateId,
        objectiveStateId: id
      })
    });
  
  return (
    <ObjectiveComponent key={objectiveMandate.id}
                        data-testid={`objective_${objectiveMandate.id}`}
                        objective={objectiveMandate}
                        objectiveStateHistory={objectiveStateHistory}
                        completedSubObjectiveCount={completedSubObjectiveCount}
                        isEditable={isEditable}
                        isLoading={isDeleteObjectiveLoading}
                        isUpdateLoading={isUpdateObjectiveLoading}
                        errorName={deleteObjectiveErrorName || updateObjectiveErrorName}
                        setName={(name) => dispatchUpdateObjective({name})}
                        setText={(text) => dispatchUpdateObjective({text})}
                        addSubObjective={dispatchAddSubObjective}
                        isAddSubObjectiveLoading={isAddSubObjectiveLoading}
                        addSubObjectiveErrorName={addSubObjectiveErrorName}
                        deleteObjective={dispatchDeleteObjective}
                        addObjectiveState={dispatchAddObjectiveState}
                        updateObjectiveState={dispatchUpdateObjectiveState}
                        fetchObjectiveStateHistory={fetchObjectiveStateHistory}
                        isStateHistoryLoading={isHistoryLoading}
                        fetchHistoryError={fetchHistoryError}
                        addObjectiveStateComment={dispatchAddComment}
                        isAddCommentLoading={isAddCommentLoading}
                        addCommentError={addCommentErrorName}>
      {
        (subObjective: SubObjective) => <MandateSubObjectiveContainer key={subObjective.id}
                                                                      mandateId={objectiveMandate.mandateId}
                                                                      isEditable={isEditable}
                                                                      objectiveId={objectiveMandate.id}
                                                                      subObjective={subObjective} />
      }
    </ObjectiveComponent>
  );
};

export default MandateObjectiveContainer;
