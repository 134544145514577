import ObjectiveStateComment from "module/objective/domain/model/ObjectiveStateComment";

export default interface ObjectiveStateCommentResponse {
  comment: string;
  createdAt: string;
}

export function objectiveStateCommentResponseToModel(
  objectiveStateCommentResponse: ObjectiveStateCommentResponse
): ObjectiveStateComment {
  return new ObjectiveStateComment(
    objectiveStateCommentResponse.comment,
    objectiveStateCommentResponse.createdAt
  )
}

export function objectiveStateCommentsResponseToModel(
  objectiveStateCommentsResponse: ObjectiveStateCommentResponse[]
): ObjectiveStateComment[] {
  return objectiveStateCommentsResponse !== undefined ? objectiveStateCommentsResponse
    .map(objectiveStateCommentResponse => objectiveStateCommentResponseToModel(objectiveStateCommentResponse)) : [];
}
