import {Moment} from "moment/moment";

export interface IAddMandate {
  title: string;
  description: string;
  startDate: Date | undefined;
  deadline: Date | undefined;
}

export default class AddMandate implements IAddMandate {
  private readonly _title: string;
  private readonly _description: string;
  private  readonly _startDate: Date | undefined;
  private readonly _deadline: Date | undefined;
  
  constructor(title: string, description: string, startDate: Date | undefined, deadline: Date | undefined) {
    this._title = title;
    this._description = description;
    this._startDate = startDate;
    this._deadline = deadline;
  }
  
  get title(): string {
    return this._title;
  }
  
  get description(): string {
    return this._description;
  }
  
  get startDate(): Date | undefined {
    return this._startDate;
  }
  
  get deadline(): Date | undefined {
    return this._deadline;
  }
}
