import Skill from "module/skill/domain/model/Skill";

export default interface SkillResponse {
  id: string;
  name: string;
}

export function skillResponseToModel(skillResponse: SkillResponse, coacheeId: string): Skill {
  return new Skill(
    skillResponse.id,
    coacheeId,
    skillResponse.name,
  );
}

export function skillsResponseToModel(skillsResponse: SkillResponse[], coacheeId: string): Skill[] {
  return skillsResponse.map(skillResponse => skillResponseToModel(skillResponse, coacheeId));
}
