import * as React from "react";

const InfoIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0003 5.36292C10.7798 5.36292 11.4116 5.99478 11.4116 6.77421C11.4116 7.55365 10.7798 8.1855 10.0003 8.1855C9.22089 8.1855 8.58904 7.55365 8.58904 6.77421C8.58904 5.99478 9.22089 5.36292 10.0003 5.36292ZM11.882 13.8979C11.882 14.1206 11.7015 14.3011 11.4788 14.3011H8.52183C8.29915 14.3011 8.11861 14.1206 8.11861 13.8979V13.0914C8.11861 12.8687 8.29915 12.6882 8.52183 12.6882H8.92506V10.5377H8.52183C8.29915 10.5377 8.11861 10.3571 8.11861 10.1344V9.32798C8.11861 9.1053 8.29915 8.92475 8.52183 8.92475H10.6724C10.8951 8.92475 11.0756 9.1053 11.0756 9.32798V12.6882H11.4788C11.7015 12.6882 11.882 12.8687 11.882 13.0914V13.8979Z" fill="currentColor"/>
    </svg>
  );
};
export default InfoIcon;
