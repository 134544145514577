import React from 'react';
import styles from "asset/component/asset/global/Plaque.module.scss";
import {Stack, Switch, Typography} from "@mui/material";

const Toggle = ({
  isChecked,
  handleToggle,
  mainLabel,
  leftLabel,
  rightLabel,
  id
}:
  {
    handleToggle: () => void,
    isChecked: boolean,
    mainLabel: string,
    leftLabel?: string,
    rightLabel: string,
    id?: string
  }) => {
  
  return (
    <div className={styles.toggler}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{mainLabel}</Typography>
        {leftLabel && rightLabel && (
          <Typography>{leftLabel}</Typography>
        )}
        <Switch
          checked={isChecked}
          className={styles.switch}
          onChange={handleToggle}
        />
        {rightLabel && (
          <Typography>{rightLabel}</Typography>
        )}
      </Stack>
    
    </div>
  );
};

export default Toggle;
