import {Tag} from "asset/component/tag/Tag";
import CultureTag from "module/culture/domain/model/CultureTag";

const buildTagsFromCultureTags = (cultureTags: CultureTag[], defaultSelectedValue: boolean = false): Tag[] => {
  return cultureTags ? cultureTags.map(
    (cultureTag: CultureTag) => {
      return {
        id: cultureTag.id,
        name: cultureTag.title,
        isSelected: defaultSelectedValue
      };
    }) : [];
};

export default buildTagsFromCultureTags;
