import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";
import HorizontalSeparatorLine, {SeparatorColor} from "asset/component/module/HorizontalSeparatorLine";
import useDateUtils from "asset/component/hook/useDateUtils";
import ObjectiveState from "module/objective/domain/model/ObjectiveState";
import {StateStatus} from "module/objective/domain/model/StateStatus";
import {StateStatusColor} from "module/objective/domain/model/StateStatusColor";
import {Alert, CircularProgress, Typography} from "@mui/material";
import ObjectiveStateHistoryStartTrace from "module/objective/view/component/ObjectiveStateHistoryStartTrace";
import ObjectiveStateHistoryStartNoTrace from "module/objective/view/component/ObjectiveStateHistoryStartNoTrace";
import ObjectiveStateHistoryMiddleTrace from "module/objective/view/component/ObjectiveStateHistoryMiddleTrace";
import ObjectiveStateHistoryEndTrace from "module/objective/view/component/ObjectiveStateHistoryEndTrace";
import ObjectiveStateHistoryMiddleTraceChatBubble
  from "module/objective/view/component/ObjectiveStateHistoryMiddleTraceChatBubble";
import ObjectiveStateHistoryEndTraceChatBubble
  from "module/objective/view/component/ObjectiveStateHistoryEndTraceChatBubble";
import {ErrorNames} from "core/error/ErrorNames";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import {useState} from "react";

const ObjectiveStateHistoryTable = ({
  currentDate,
  stateHistoryForCurrentInterval,
  fetchHistoryError,
  isStateHistoryLoading,
  selectObjectiveState,
  objectiveStateDisplayed
}: {
  currentDate: Date,
  stateHistoryForCurrentInterval: ObjectiveState[],
  fetchHistoryError?: ErrorNames,
  isStateHistoryLoading?: boolean,
  selectObjectiveState?: (id: string) => void,
  objectiveStateDisplayed?: ObjectiveState
}) => {
  const numberOfDays = useDateUtils().getAmountOfDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());
  const arraysOfDays = Array.from(Array(numberOfDays).keys());
  
  const {getErrorMessage} = useErrorTranslation();
  
  const getAllDatesInMonth = (date: Date): Date[] => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const numberOfDays = new Date(year, month + 1, 0).getDate();
    const dates: Date[] = [];
    
    for (let day = 1; day <= numberOfDays; day++) {
      dates.push(new Date(year, month, day));
    }
    
    return dates;
  };
  
  const arrayOfFullDates : Date[] = getAllDatesInMonth(currentDate);
  
  const datesBottomOfTable = () =>
    arraysOfDays.map((item, index) =>
      <div className={styles.tableCell} key={index}>
        <Typography align={"center"}>
          {item + 1}
        </Typography>
      </div>
    );
  
  const filterStateHistoryByType = (stateHistory: ObjectiveState[], statusType: StateStatus): ObjectiveState[] => {
    return stateHistory
      .filter(state => state.status !== StateStatus.NONE)
      .filter(state => state.status === statusType)
      .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
  };
  
  const checkIfStateHasComment = (isCommentAdded: boolean, state: ObjectiveState): boolean => {
    return !isCommentAdded && state.comments.length > 0;
  }
  
  const addOneDayToDate = (date: Date): Date => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    return newDate;
  }
  
  const currentCellDateMatchesStateCreatedAt = (currentState: ObjectiveState, cellDate: Date) => {
    return currentState.createdAt.toLocaleDateString() === cellDate.toLocaleDateString();
  };
  
  const stateIsActiveOnCellDate = (currentState: ObjectiveState, cellDate: Date, actualDate: Date) => {
    return (!currentState.endedAt && addOneDayToDate(cellDate) <= actualDate) ||
      (currentState.endedAt && addOneDayToDate(cellDate) <= currentState.endedAt);
  };
  
  const stateEndsOnCellDate = (currentState: ObjectiveState, cellDate: Date, actualDate: Date) => {
    return (currentState.endedAt && currentState.endedAt.toLocaleDateString() === cellDate.toLocaleDateString()) ||
      (!currentState.endedAt && actualDate.toLocaleDateString() === cellDate.toLocaleDateString());
  };
  
  const stateIsOngoingOnCellDate = (currentState: ObjectiveState, cellDate: Date, actualDate: Date) => {
    return currentState.createdAt < cellDate &&
      ((!currentState.endedAt && cellDate < actualDate) ||
        (currentState.endedAt && cellDate < currentState.endedAt));
  };
  
  const getCellsOfRow = (statusType: StateStatus, statusColor: StateStatusColor) => {
    const actualDate = new Date();
    const statesByType = filterStateHistoryByType(stateHistoryForCurrentInterval, statusType);
    let isCommentAdded = false;
    let additionalStyles = "";
    
    return arrayOfFullDates.map((cellDate, index) => {
      for (let currentState of statesByType) {
        additionalStyles = objectiveStateDisplayed && objectiveStateDisplayed.id !== currentState.id ? styles.notSelected : "";
        
        if (currentCellDateMatchesStateCreatedAt(currentState, cellDate) && stateIsActiveOnCellDate(currentState, cellDate, actualDate)) {
          return <ObjectiveStateHistoryStartTrace
            statusType={statusType}
            statusColor={statusColor}
            index={index}
            objectiveStateId={currentState.id}
            selectObjectiveState={selectObjectiveState}
            className={additionalStyles}
          />;
        } else if (currentCellDateMatchesStateCreatedAt(currentState, cellDate)) {
          return <ObjectiveStateHistoryStartNoTrace
            statusType={statusType}
            index={index}
            objectiveStateId={currentState.id}
            selectObjectiveState={selectObjectiveState}
            className={additionalStyles}
          />;
        } else if (stateEndsOnCellDate(currentState, cellDate, actualDate)) {
          if (checkIfStateHasComment(isCommentAdded, currentState)) {
            isCommentAdded = true;
            return <ObjectiveStateHistoryEndTraceChatBubble
                statusColor={statusColor}
                index={index}
                objectiveStateId={currentState.id}
                selectObjectiveState={selectObjectiveState}
                className={additionalStyles}
              />
          }
          return <ObjectiveStateHistoryEndTrace
            statusColor={statusColor}
            index={index}
            objectiveStateId={currentState.id}
            selectObjectiveState={selectObjectiveState}
            className={additionalStyles}
          />;
        } else if (stateIsOngoingOnCellDate(currentState, cellDate, actualDate)) {
          if (checkIfStateHasComment(isCommentAdded, currentState)) {
            isCommentAdded = true;
            return <ObjectiveStateHistoryMiddleTraceChatBubble
              statusColor={statusColor}
              index={index}
              objectiveStateId={currentState.id}
              selectObjectiveState={selectObjectiveState}
              className={additionalStyles}
            />
          }
          return <ObjectiveStateHistoryMiddleTrace
              statusColor={statusColor} index={index}
              objectiveStateId={currentState.id}
              selectObjectiveState={selectObjectiveState}
              className={additionalStyles}
            />;
        }
      }
      
      return <div className={styles.tableCell} key={index} />;
    });
  };
  
  const errorMessage = <Alert severity="error"
                              data-testid="objective-error-message">{fetchHistoryError && getErrorMessage(fetchHistoryError)}</Alert>
  
  const tableWithCells = <div className={styles.mainTable}>
    <div className={styles.verticalLine}>
      <div className={styles.row}>
        {getCellsOfRow(StateStatus.OPTIMAL, StateStatusColor.OPTIMAL)}
      </div>
      <div className={styles.row}>
        {getCellsOfRow(StateStatus.FINE, StateStatusColor.FINE)}
      </div>
      <div className={styles.row}>
        {getCellsOfRow(StateStatus.IDLE, StateStatusColor.IDLE)}
      </div>
      <div className={styles.row}>
        {getCellsOfRow(StateStatus.MONITORING, StateStatusColor.MONITORING)}
      </div>
    </div>
    <HorizontalSeparatorLine color={SeparatorColor.BLACK} />
    <div className={styles.timeInterval}>
      {datesBottomOfTable()}
    </div>
  </div>;
  
  const renderTable = () => {
    if (isStateHistoryLoading) {
      return <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    } else if (fetchHistoryError) {
      return errorMessage;
    } else {
      return tableWithCells;
    }
  }
  
  return renderTable()
};

export default ObjectiveStateHistoryTable;
