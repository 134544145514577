import SubObjective from "./SubObjective";
import Objective from "./Objective";
import {NeedsTeam} from "module/objective/domain/model/need/NeedsTeam";
import {StateStatus} from "module/objective/domain/model/StateStatus";

export default class ObjectiveCoachee extends Objective {
  private readonly _needs: NeedsTeam[];
  private readonly _teamId: string;
  
  constructor(
    id: string,
    teamId: string,
    name: string,
    text: string,
    createdAt: string,
    state: StateStatus,
    subObjectives: SubObjective[],
    needs?: NeedsTeam[]
  ) {
    super(id, name, text, createdAt, state, subObjectives);
    this._teamId = teamId;
    this._needs = needs || [];
  }
  
  get teamId(): string {
    return this._teamId;
  }
  
  get needs(): NeedsTeam[] {
    return this._needs;
  }
}
