import * as React from "react";

const ExpandIcon = () => {
  return (
    <svg width="100%"
         height="100%"
         viewBox="0 0 15 9"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M13.4004 1.80005L7.40039 7.40005L1.40039 1.80005"
            stroke="#20463D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round" />
    </svg>
  );
};

export default ExpandIcon;
