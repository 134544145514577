export default class UpdateObjective {
  private readonly _text: string;
  private readonly _completed: boolean;
  
  constructor(text: string, completed: boolean) {
    this._text = text;
    this._completed = completed;
  }
  
  get text(): string {
    return this._text;
  }
  
  get completed(): boolean {
    return this._completed;
  }
}
