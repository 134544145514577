import React, {useEffect, useRef} from 'react';
import Mandate from "module/mandate/domain/model/Mandate";
import GhostInput from "asset/component/form/inputs/ghost/GhostInput";
import {InputValidator} from "asset/validator/InputValidator";
import {useFormValidation, ValidatorForm} from "asset/validator";
import TextValidatorProps from "asset/component/form/inputs/TextValidatorProps";
import MuiInputSizes from "style/props/MuiInputSizes";
import styles from "module/mandate/view/asset/edit/MandateSidebarInfo.module.scss";
import {useTranslation} from "react-i18next";

const MandateSidebarInfo = ({
  mandate,
  updateInfo
}: {
  mandate?: Mandate | null,
  updateInfo: (title: string, description: string) => void
}) => {
  const {t} = useTranslation('mandate');
  
  const formRef = useRef<HTMLFormElement>();
  const {isFormValid} = useFormValidation(formRef, []);
  const [title, setTitle] = React.useState<string>(mandate?.title || "");
  const [description, setDescription] = React.useState<string>(mandate?.description || "");
  
  const TITLE_INPUT_PROPS: TextValidatorProps = {
    id: "mandate-title",
    name: "mandate-title",
    validators: [InputValidator.REQUIRED],
    errorMessages: [t('form.edit.title.errorMessage')],
    label: t('form.edit.title.label'),
    required: true,
    inputProps: {maxLength: 100},
    multiline: true,
    minRows: 1,
    maxRows: 3,
    size: MuiInputSizes.LARGE,
    fullWidth: true,
    styles: styles.titleEdit
  };
  
  const DESCRIPTION_INPUT_PROPS: TextValidatorProps = {
    id: "mandate-description",
    name: "mandate-description",
    validators: [InputValidator.REQUIRED],
    errorMessages: [t('form.edit.title.errorMessage')],
    label: t('form.edit.description.label'),
    required: true,
    inputProps: {maxLength: 200},
    multiline: true,
    minRows: 1,
    maxRows: 3,
    size: MuiInputSizes.LARGE,
    fullWidth: true,
    styles: styles.descriptionEdit
  };
  
  useEffect(() => {
    setTitle(mandate?.title || "");
  }, [mandate]);
  
  useEffect(() => {
    setDescription(mandate?.description || "");
  }, [mandate]);
  
  const manageBlur = () => {
    if (title === "") setTitle(mandate?.title || "");
    
    if (description === "") setDescription(mandate?.description || "");
    
    return isFormValid && formRef?.current?.props.onSubmit();
  };
  
  return (
    <ValidatorForm ref={formRef}
                   onSubmit={() => updateInfo(title, description)}>
      <GhostInput data-testid="ghost-input-title"
                  textValidatorProps={TITLE_INPUT_PROPS}
                  value={title}
                  onChange={(value) => setTitle(value.target.value)}
                  onBlur={() => manageBlur()} />
      <GhostInput data-testid="ghost-input-description"
                  textValidatorProps={DESCRIPTION_INPUT_PROPS}
                  value={description}
                  onChange={(value) => setDescription(value.target.value)}
                  onBlur={() => manageBlur()} />
    
    </ValidatorForm>
  );
};

export default MandateSidebarInfo;
