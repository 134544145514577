import * as React from "react";

const ProgressionCircleEmpty = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" transform="rotate(-90 20 20)" fill="currentColor" />
    </svg>
  );
};
export default ProgressionCircleEmpty;
