import React from 'react';
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import AddCoacheeDemotivator from "module/demotivator/coachee/domain/model/AddCoacheeDemotivator";
import CoacheeDemotivator from "module/demotivator/coachee/domain/model/CoacheeDemotivator";
import {useTranslation} from "react-i18next";
import TagDisplayer from "asset/component/tag/TagDisplayer";
import {Tag} from "asset/component/tag/Tag";
import convertDemotivatorsToTags from "module/demotivator/ConvertDemotivatorsToTags";
import InputEnter from "asset/component/form/inputs/InputEnter";
import ModuleSectionLayout from "asset/component/module/ModuleSectionLayout";

const CoacheeDemotivatorContainer = ({coacheeId}: { coacheeId: string }) => {
  const dispatch = useDispatch<Dispatch>();
  const {t} = useTranslation('motivator');
  
  const demotivators = useSelector(
    (rootState: RootState) =>
      rootState.CoacheeDemotivatorStore?.getters(rootState).getDemotivatorsByCoacheeId(coacheeId)
  );
  
  const fetchDemotivatorsByCoacheeIdState = useLoadingAndError(
    () =>
      dispatch.CoacheeDemotivatorStore.fetchDemotivatorsByCoacheeId(coacheeId), [], coacheeId
  );
  
  const [isAddDemotivatorLoading, addDemotivatorErrorName, dispatchAddDemotivator] =
    usePromiseLoadingAndError((title) =>
      dispatch.CoacheeDemotivatorStore.addDemotivator({
        addDemotivator: new AddCoacheeDemotivator(title, coacheeId)
      })
    );
  
  const [isRemoveDemotivatorLoading, removeDemotivatorErrorName, dispatchRemoveDemotivator] =
    usePromiseLoadingAndError((demotivator: CoacheeDemotivator) =>
      dispatch.CoacheeDemotivatorStore.deleteDemotivator({
        demotivatorId: demotivator.id,
        coacheeId
      })
    );
  
  const demotivatorTags: Tag[] = convertDemotivatorsToTags(demotivators);
  
  return (
    <ModuleSectionLayout
      title={t('titles.demotivators')}
      isLoading={isAddDemotivatorLoading || isRemoveDemotivatorLoading || fetchDemotivatorsByCoacheeIdState.isLoading}
      errorName={fetchDemotivatorsByCoacheeIdState.errorName || addDemotivatorErrorName || removeDemotivatorErrorName}
    >
      <TagDisplayer
        tags={demotivatorTags}
        removeTag={(tag) => dispatchRemoveDemotivator(tag)}
        input={<InputEnter placeholder={t('input.placeholderDemotivator')}
                           errorMessage={t('input.errorMessageDemotivator')}
                           setValue={(value) => dispatchAddDemotivator(value)}
                           isOnNewLine={true}
                           data-testid="input-enter-tag" />}
      
      />
    </ModuleSectionLayout>
  );
};

export default CoacheeDemotivatorContainer;
