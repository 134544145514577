import React, {useEffect, useState} from "react";
import Team from "module/team/domain/model/Team";
import UpdateDialog from "asset/component/global/dialogs/UpdateDialog";
import UpdateTeamFormComponent, {UpdateTeamStateProps} from "module/team/view/component/UpdateTeamFormComponent";
import {InputOption} from "asset/component/form/inputs/InputSelect";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {Routes} from "router/Routes";
import UpdateTeam from "module/team/domain/model/UpdateTeam";
import {useNavigate} from "react-router-dom";

const UpdateTeamDialogContainer = ({
  team
}: {
  team: Team
}) => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  
  const [name, setName] = useState(team.name);
  const [companyId, setCompanyId] = useState(team.companyId);
  
  const [companyOptions, setCompanyOptions] = useState<InputOption[]>([]);
  
  const {companies} = useSelector(
    (rootState: RootState) => rootState.CompanyStore);
  
  const updateTeamState = {
    name,
    setName,
    companyId,
    setCompanyId,
    currentCompanyId: companyId
  } as UpdateTeamStateProps;
  
  const [updateTeamLoading, UpdateTeamErrorName, updateTeamDispatch, isUpdateCompleted] =
    usePromiseLoadingAndError(() =>
      dispatch.TeamStore.updateTeam(
        new UpdateTeam(
          team.id,
          name,
          companyId,
          team.note
        )).then((updatedTeam) => {
        navigate(Routes.team.profile.path({teamId: updatedTeam.id, companyId: updatedTeam.companyId}));
      })
    );
  
  const [deleteTeamLoading, deleteTeamErrorName, deleteTeamDispatch] =
    usePromiseLoadingAndError(() =>
      dispatch.TeamStore.deleteTeam({
        teamId: team.id,
      }).then(() => {
        navigate(Routes.home.path());
      })
    );
  
  useLoadingAndError(() => dispatch.CompanyStore.fetchCompanies());
  
  useEffect(() => {
    setCompanyOptions(companies.map(company => ({
      value: company.id,
      label: company.name
    })));
  }, [companies]);
  
  return (
    <UpdateDialog updateAction={updateTeamDispatch}
                  errorName={UpdateTeamErrorName || deleteTeamErrorName}
                  isLoading={updateTeamLoading}
                  isCompleted={isUpdateCompleted}
                  deleteAction={deleteTeamDispatch}
    >
      <UpdateTeamFormComponent
        updateTeamState={updateTeamState}
        companyOptions={companyOptions}
      />
    </UpdateDialog>
  );
};

export default UpdateTeamDialogContainer;
