import LinkableEntity, {LinkableEntityType} from "module/mandate/domain/model/LinkableEntity";
import {InputCategoryOptions} from "asset/component/search/SearchAutoCompleteCategories";
import {Tag} from "asset/component/tag/Tag";
import {TFunction} from "i18next";
import LinkedEntity from "module/mandate/domain/model/LinkedEntity";

const convertLinkableEntitiesToOptionsAndTags = (entities: LinkableEntity[], t: TFunction) => {
  const options: InputCategoryOptions[] = [];
  const companies: Tag[] = [];
  
  entities.forEach(entity => {
    if (entity.type === LinkableEntityType.COMPANY) {
      if (companies.find(company => company.name === entity.name))
        return;
      
      const tag: Tag = {
        id: entity.id,
        name: entity.name,
        isSelected: true
      };
      
      companies.push(tag);
    } else {
      const option: InputCategoryOptions = {
        value: entity.id,
        label: entity.name,
        category: t(`linkableEntity.${entity.type}`),
      };
      
      options.push(option);
    }
  });
  
  return {options, companies};
};

const getLinkedCompaniesAsTags = (entities: LinkedEntity[]) => {
  const linkedCompanies: Tag[] = [];
  
  entities.forEach(entity => {
    if (entity.type === LinkableEntityType.COMPANY) {
      const tag: Tag = {
        id: entity.id,
        name: entity.name,
        isSelected: true
      };
      
      linkedCompanies.push(tag);
    }
  });
  
  return linkedCompanies;
};

export {convertLinkableEntitiesToOptionsAndTags, getLinkedCompaniesAsTags};
