import AddCoacheeMotivator from "module/motivator/coachee/domain/model/AddCoacheeMotivator";

export default interface AddCoacheeMotivatorRequest {
  title: string;
  coacheeId: string;
}

export function createAddCoacheeMotivatorRequest(addMotivator: AddCoacheeMotivator): AddCoacheeMotivatorRequest {
  return {
    title: addMotivator.title,
    coacheeId: addMotivator.coacheeId,
  } as AddCoacheeMotivatorRequest;
}
