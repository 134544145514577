import React from 'react';
import AddCultureTag from "module/culture/domain/model/AddCultureTag";
import CultureTag from "module/culture/domain/model/CultureTag";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import TagDisplayer from "asset/component/tag/TagDisplayer";
import {useTranslation} from "react-i18next";
import {Tag} from "asset/component/tag/Tag";
import buildTagsFromCultureTags from "module/culture/view/BuildTagsFromCultureTags";
import InputEnter from "asset/component/form/inputs/InputEnter";
import Checkmark from "asset/icon/Checkmark";
import {getFilteredPredefinedCultureTags} from "module/culture/view/PredefinedCultureTags";
import ModuleSectionLayout from 'asset/component/module/ModuleSectionLayout';

const CultureTagsContainer = ({teamId}: { teamId: string }) => {
  const dispatch = useDispatch<Dispatch>();
  const {t} = useTranslation('culture');
  
  const cultureTags = useSelector(
    (rootState: RootState) => rootState.CultureTagStore?.getters(rootState).getCultureTagsByTeamId(teamId)
  );
  
  const fetchCultureTagsState = useLoadingAndError(
    () => dispatch.CultureTagStore.fetchCultureTags({teamId}), [], teamId
  );
  
  const [isAddCultureTagLoading, addCultureTagErrorName, dispatchAddCultureTag] =
    usePromiseLoadingAndError((title) =>
      dispatch.CultureTagStore.addCultureTag({
        addCultureTag: new AddCultureTag(title),
        teamId
      })
    );
  
  const [isRemoveCultureTagLoading, removeCultureTagErrorName, dispatchRemoveCultureTag] =
    usePromiseLoadingAndError((cultureTag: CultureTag) =>
      dispatch.CultureTagStore.deleteCultureTag({
        cultureTagId: cultureTag.id,
        teamId
      })
    );
  
  const cultureTagTags: Tag[] = buildTagsFromCultureTags(cultureTags, true);
  const predefinedCultureTagsTags: Tag[] = getFilteredPredefinedCultureTags(cultureTags);
  
  return (
    <ModuleSectionLayout
      isLoading={isAddCultureTagLoading || isRemoveCultureTagLoading || fetchCultureTagsState.isLoading}
      errorName={fetchCultureTagsState.errorName || addCultureTagErrorName || removeCultureTagErrorName}
      injectedStyles={{padding: 0}}
    >
      <TagDisplayer
        skipEditModeStep={false}
        tags={cultureTagTags}
        predefinedTags={predefinedCultureTagsTags}
        addTag={(tag) => dispatchAddCultureTag(tag.name)}
        removeTag={(tag) => dispatchRemoveCultureTag(tag)}
        input={<InputEnter placeholder={t('input.placeholderCultureTag')}
                           errorMessage={t('input.errorMessageCultureTag')}
                           setValue={(value) => dispatchAddCultureTag(value)}
                           isOnNewLine={true}/>}
        icon={<Checkmark />}
      />
    </ModuleSectionLayout>
  );
};

export default CultureTagsContainer;
