import axios, {AxiosInstance} from 'axios';
import AxiosInterceptor from "core/client/AxiosInterceptor";

export default class AxiosInstanceFactory {
  private static readonly DEFAULT_BASE_URL: string = process.env.REACT_APP_BASE_URL as string;
  
  private static defaultAxiosInstance?: AxiosInstance;
  
  public static create(): AxiosInstance;
  public static create(baseUrl: string): AxiosInstance;
  public static create(
    baseUrl: string,
    axiosRequestInterceptors: AxiosInterceptor[],
    axiosResponseInterceptors: AxiosInterceptor[]
  ): AxiosInstance;
  public static create(
    baseUrl?: string,
    axiosRequestInterceptors?: AxiosInterceptor[],
    axiosResponseInterceptors?: AxiosInterceptor[]
  ): AxiosInstance {
    
    let axiosInstance: AxiosInstance;
    
    if (!baseUrl || baseUrl === this.DEFAULT_BASE_URL) {
      this.defaultAxiosInstance = this.defaultAxiosInstance
        ? this.defaultAxiosInstance
        : this.createAxiosInstance(baseUrl);
      axiosInstance = this.defaultAxiosInstance;
    }
    
    axiosInstance = this.createAxiosInstance(baseUrl);
    
    if (axiosRequestInterceptors) {
      axiosRequestInterceptors.map((axiosInterceptor) =>
        axiosInstance.interceptors.request.use(axiosInterceptor.onFulfilled(),
          axiosInterceptor.onRejected(),
          axiosInterceptor.options()));
    }
    
    if (axiosResponseInterceptors) {
      axiosResponseInterceptors.map((axiosInterceptor) =>
        axiosInstance.interceptors.response.use(axiosInterceptor.onFulfilled(),
          axiosInterceptor.onRejected(),
          axiosInterceptor.options()));
    }
    
    return axiosInstance;
  }
  
  private static createAxiosInstance(baseUrl: string | undefined): AxiosInstance {
    return axios.create({
      baseURL: baseUrl,
      headers: {
        Accept: 'application/json',
      },
    });
  }
}
