import React from "react";
import {CheckboxButton} from "asset/component";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {useDispatch} from "react-redux";
import {Dispatch} from "store";
import UpdateFollowUp from "module/followUp/domain/model/UpdateFollowUp";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import FollowUpCoachee from "module/followUp/domain/model/FollowUpCoachee";
import {MuiButtonVariants} from "style/props/MuiButtonVariants";

const CoacheeFollowUpContainer = ({followUpCoachee}: {
  followUpCoachee: FollowUpCoachee,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const {getErrorMessage} = useErrorTranslation();
  
  const [isUpdateFollowUpLoading, updateFollowUpErrorName, dispatchUpdateFollowUp] =
    usePromiseLoadingAndError((isCompleted, text) =>
      dispatch.FollowUpCoacheeStore.updateFollowUpForCoachee({
        followUpId: followUpCoachee.id,
        coacheeId: followUpCoachee.coacheeId,
        updateFollowUp: new UpdateFollowUp(text || followUpCoachee.text, isCompleted)
      })
    );
  
  const [isDeleteFollowUpLoading, deleteFollowUpErrorName, dispatchDeleteFollowUp] =
    usePromiseLoadingAndError(() =>
      dispatch.FollowUpCoacheeStore.deleteFollowUpForCoachee({
        followUpId: followUpCoachee.id,
        coacheeId: followUpCoachee.coacheeId,
      })
    );
  
  return (
    <CheckboxButton data-testid={`${followUpCoachee.id}-checkbox-button`}
                    value={followUpCoachee.completed}
                    setValue={dispatchUpdateFollowUp}
                    deleteElement={dispatchDeleteFollowUp}
                    label={followUpCoachee.text}
                    date={followUpCoachee.createdAt}
                    isLoading={isUpdateFollowUpLoading || isDeleteFollowUpLoading}
                    errorMessage={
                      updateFollowUpErrorName && getErrorMessage(updateFollowUpErrorName) ||
                      deleteFollowUpErrorName && getErrorMessage(deleteFollowUpErrorName)
                    }
                    variant={MuiButtonVariants.OUTLINED}
    />
  );
};

export default CoacheeFollowUpContainer;
