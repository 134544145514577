import {useTranslation} from "react-i18next";
import {ErrorNames} from "core/error/ErrorNames";

const useErrorTranslation = (
  nameSpace: string | undefined = 'common'
) => {
  const {t} = useTranslation(nameSpace);
  const getErrorMessage = (errorName: ErrorNames) => {
    
    return t(`error.${errorName}`);
  };
  
  return {getErrorMessage};
};

export default useErrorTranslation;
