import OnboardingSequence from "module/onboarding/domain/model/OnboardingSequence";
import OnboardingStep from "module/onboarding/domain/model/OnboardingStep";
import {ArrowPositions} from "asset/component/global/dialogs/Tooltip";
import {OnboardingSequenceNames} from "module/onboarding/domain/model/SeedData/OnboardingSequenceNames";

const AffectationsSequence = new OnboardingSequence(
  [
    new OnboardingStep({
      name: "addUser",
      sequenceName: OnboardingSequenceNames.AFFECTATIONS,
      anchorID: "affectations-add-user-anchor",
      arrowPosition: ArrowPositions.TOP.MIDDLE
    }),
    new OnboardingStep({
      name: "userOptions",
      sequenceName: OnboardingSequenceNames.AFFECTATIONS,
      anchorID: "affectations-user-options-anchor",
      arrowPosition: ArrowPositions.TOP.MIDDLE
    }),
  ]
);

export default AffectationsSequence;
