import React from 'react';
import MandatesComponent from "module/mandate/view/component/listing/MandatesComponent";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import MandatesDisplayerContainer from "module/mandate/view/container/listing/MandatesDisplayerContainer";
import BodySectionLayout from "asset/component/layout/BodySectionLayout";
import {Box} from "@mui/material";
import {CONTENT_ID} from "router/Router";
import {Accesses} from "module/authorization/domain/model/accesses";
import HadAccess from "module/authorization/HadAccess";

const MandatesContainer = () => {
  const dispatch = useDispatch<Dispatch>();
  const [displayFinished, setDisplayFinished] = React.useState<boolean>(true);
  const [searchValue, setSearchValue] = React.useState<string>("");
  
  const mandates = useSelector(
    (rootState: RootState) =>
      rootState.MandateStore?.getters(rootState).getMandates(displayFinished, true, searchValue)
  );
  
  const fetchMandatesForTenantState = useLoadingAndError(
    () =>
      dispatch.MandateStore.fetchMandatesForTenant()
  );
  
  useLoadingAndError(
    () =>
      dispatch.CompanyStore.fetchCompanies()
  );
  
  return (
    <Box id={CONTENT_ID}>
      <HadAccess accesses={[Accesses.MANDATES_MANAGEMENT]}>
        <BodySectionLayout padding={"small"}>
          <MandatesComponent
            displayFinished={displayFinished}
            toggleFinished={() => setDisplayFinished(!displayFinished)}
            onSearch={(value: string) => setSearchValue(value)}>
            <MandatesDisplayerContainer
              mandates={mandates}
              isLoading={fetchMandatesForTenantState.isLoading}
              error={fetchMandatesForTenantState.errorName} />
          </MandatesComponent>
        </BodySectionLayout>
      </HadAccess>
    </Box>
  );
};

export default MandatesContainer;
