import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import AddObjective from "module/objective/domain/model/AddObjective";
import {ModuleListing} from "asset/component";
import {useTranslation} from "react-i18next";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import CoacheeObjectiveContainer from "module/objective/view/container/coachee/CoacheeObjectiveContainer";
import ObjectiveCoachee from "module/objective/domain/model/ObjectiveCoachee";
import ObjectivesListingComponentStyles from "module/objective/view/asset/ObjectivesListingComponent.module.scss";
import {NeedsCoachee} from "module/objective/domain/model/need/NeedsCoachee";
import ModuleSubHeader from "asset/component/module/ModuleSubHeader";
import styles from "module/objective/view/asset/ObjectivesContainer.module.scss";
import InputEnter from "asset/component/form/inputs/InputEnter";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";

const CoacheeObjectivesNeedContainer = ({coacheeId, onClose, need}: {
  coacheeId: string,
  need: NeedsCoachee
  onClose?: () => void
}) => {
  const {t} = useTranslation('need');
  const dispatch = useDispatch<Dispatch>();
  const {getErrorMessage} = useErrorTranslation();
  
  const coacheeObjectives = useSelector((rootState: RootState) =>
    rootState.ObjectiveCoacheeStore?.getters(rootState).getObjectivesCoacheeByNeed(coacheeId, need));
  
  const fetchObjectivesByCoacheeIdState =
    useLoadingAndError(() =>
      dispatch.ObjectiveCoacheeStore.fetchObjectivesByCoacheeId({id: coacheeId, need: need}), [], coacheeId);
  
  const [isAddObjectiveLoading, addObjectiveErrorName, dispatchAddObjective] =
    usePromiseLoadingAndError((name: string) =>
      dispatch.ObjectiveCoacheeStore.addObjectiveForCoachee({
        addObjective: new AddObjective(name, [need]),
        coacheeId,
      })
    );
  
  return (
    <>
      <ModuleSubHeader title={t(`coacheeNeeds.${need}`)} subtitle={t('moduleSubHeader')} onClose={onClose} />
      <ModuleListing<ObjectiveCoachee>
        data-testid="listing-objective"
        elements={coacheeObjectives}
        injectedStyle={ObjectivesListingComponentStyles}
        isAddElementLoading={isAddObjectiveLoading}
        isLoading={fetchObjectivesByCoacheeIdState.isLoading}
        errorName={fetchObjectivesByCoacheeIdState.errorName || addObjectiveErrorName}
      >
        {
          (objective: ObjectiveCoachee) => <CoacheeObjectiveContainer key={objective.id} objectiveCoachee={objective} />
        }
      </ModuleListing>
      <div className={styles.input}>
        <InputEnter data-testid="add-objective"
                    placeholder={t('addObjective')}
                    errorMessage={addObjectiveErrorName && getErrorMessage(addObjectiveErrorName)}
                    setValue={(value) => dispatchAddObjective(value)}
        />
      </div>
    </>
  );
};

export default CoacheeObjectivesNeedContainer;
