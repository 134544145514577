import AuthRESTClient from "module/authentication/dal/AuthRESTClient";
import AxiosInterceptor from "core/client/AxiosInterceptor";

export default class AuthRequestInterceptor implements AxiosInterceptor {
  private readonly authRESTClient: AuthRESTClient;
  
  constructor(authRESTClient: AuthRESTClient) {
    this.authRESTClient = authRESTClient;
  }
  
  public onFulfilled(): any {
    return async (config) => {
      const token = await this.authRESTClient.getToken();
      
      if (token) {
        // @ts-ignore
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      
      return config;
    };
  }
  
  public onRejected(): any {
  }
  
  public options(): any {
  }
}
