import React from "react";
import {useTranslation} from "react-i18next";
import styles from "module/team/view/asset/teamProfile/TeamProfileBodyComponent.module.scss";
import TitleRecord from "asset/component/global/typography/TitleRecord";
import Team from "module/team/domain/model/Team";
import {Container, Masonry, Typography} from "asset/component";
import TeamObjectivesContainer from "module/objective/view/container/team/TeamObjectivesContainer";
import TeamFollowUpsContainer from "module/followUp/view/container/team/TeamFollowUpsContainer";
import usePaletteColors from "style/usePaletteColors";
import CoacheesListingByTeamContainer from "module/coachee/view/container/CoacheesListingByTeamContainer";
import TeamNeedsContainer from "module/need/view/team/container/TeamNeedsContainer";
import TeamNoteContainer from "module/team/view/container/TeamNoteContainer";
import TeamMotivatorModuleContainer from "module/motivator/team/view/container/TeamMotivatorModuleContainer";
import TeamLinkedMandateListContainer from "module/mandate/view/container/listing/TeamLinkedMandateListContainer";
import CultureTagsModuleContainer from "module/culture/view/container/CultureTagsModuleContainer";

const TeamProfileBodyComponent = ({team}: { team?: Team }) => {
  const {t} = useTranslation('team');
  const {i18n} = useTranslation();
  const isFrenchLanguage = i18n.language === "fr";
  const palette = usePaletteColors();
  
  return (
    <>
      <Container id={styles.TeamProfileBodyComponentContainer}
                 data-testid="team_profile_body_component">
        <TitleRecord title={t('teamProfile.titleSectionSeparatorBody')} />
        {
          team && <div className={styles.linkedMandateList}>
                <TeamLinkedMandateListContainer teamId={team?.id} data-testid="linked-mandate-list" />
            </div>
        }
        {
          team &&
            <div className={styles.noteContainer}>
                <TeamNoteContainer team={team} />
            </div>
        }
        <div className={styles.moduleContainer}>
          {team &&
              <Masonry columns={{xs: 1, md: 2, lg: 3}} spacing={3}>
                  <CultureTagsModuleContainer teamId={team?.id} />
                  <TeamObjectivesContainer data-testid="TeamObjectivesContainer"
                                           teamId={team?.id} />
                  <TeamFollowUpsContainer data-testid="TeamFollowUpsContainer"
                                          teamId={team?.id} />
                  <TeamNeedsContainer teamId={team?.id} />
                  <TeamMotivatorModuleContainer teamId={team?.id} />
              </Masonry>
          }
        </div>
      </Container>
      
      <Container id={styles.coacheeListing}>
        {team &&
            <div className={styles.moduleContainer}>
                <Typography className={styles.title} component="span" variant="h2" color={palette.primary.veryLight}>
                  {t('teamProfile.teamMembers', {teamName: team.name})}
                </Typography>
                <CoacheesListingByTeamContainer teamId={team.id} />
            </div>
        }
      </Container>
    </>
  )
    ;
};

export default TeamProfileBodyComponent;
