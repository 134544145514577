import React from 'react';
import Mandate from "module/mandate/domain/model/Mandate";
import styles from "module/mandate/view/asset/listing/MandateCardsComponent.module.scss";
import MandateCard from "module/mandate/view/component/listing/MandateCard";
import {MandatesSequence} from "module/onboarding/domain/model/SeedData/OnboardingSequencesSeed";

const MandatesCardsComponent = ({
  mandates,
  displayMandate
}: {
  mandates: Mandate[],
  displayMandate: (id: string) => void
}) => {
  return (
    <div className={styles.cardContainer} data-testid="mandate-cards-component">
      {mandates.map((mandate, index) => (
        index === 0 ?
          <MandateCard key={mandate.id}
                       id={MandatesSequence.steps[2].anchorID}
                       dataParamId={mandate.id}
                       mandate={mandate}
                       displayMandate={() => displayMandate(mandate.id)} />
          :
          <MandateCard key={mandate.id}
                       mandate={mandate}
                       displayMandate={() => displayMandate(mandate.id)} />
      ))}
    </div>
  );
};

export default MandatesCardsComponent;
