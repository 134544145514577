import {NavigationArrow} from "asset/component/Icons";
import 'asset/component/asset/global/slider/SliderArrows.scss';
import React from "react";

export function NextArrow(props) {
  const {onClick, className} = props;
  
  return (<span data-testid="next-arrow"
                className={`${className} slick-arrow slick-next`}
                onClick={onClick}>
        <NavigationArrow />
      </span>);
}

export function PreviousArrow(props) {
  const {onClick, className} = props;
  
  return (<span data-testid="previous-arrow"
                className={`${className} slick-arrow slick-prev`}
                onClick={onClick}>
        <NavigationArrow />
      </span>);
}
