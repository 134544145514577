import type RESTClient from "core/client/RESTClient";
import TeamRepository from "module/team/domain/TeamRepository";
import TeamResponse, {teamResponseToModel, teamsResponseToModel} from "module/team/dal/dto/TeamResponse";
import Team from "module/team/domain/model/Team";
import AddTeam from "module/team/domain/model/AddTeam";
import {createAddTeamRequest} from "module/team/dal/dto/AddTeamRequest";
import {createUpdateTeamRequest} from "module/team/dal/dto/UpdateTeamRequest";
import UpdateTeam from "module/team/domain/model/UpdateTeam";

export default class RESTTeamRepository implements TeamRepository {
  private static readonly BASE_URL: string = "api";
  private static readonly TEAMS_URL: string = "teams";
  private static readonly COMPANIES_URL: string = "companies";
  private static readonly COACHEES_URL: string = "coachees";
  private readonly client: RESTClient;
  
  constructor(client: RESTClient) {
    this.client = client;
  }
  
  public async fetchTeamById(teamId: string): Promise<Team> {
    const teamResponse = await this.client.apiGet<TeamResponse>(
      `/${RESTTeamRepository.BASE_URL}` +
      `/${RESTTeamRepository.TEAMS_URL}` +
      `/${teamId}`
    );
    
    return teamResponseToModel(teamResponse);
  }
  
  public async fetchTeamsByCompanyId(id: string): Promise<Team[]> {
    const teamsResponse = await this.client.apiGet<TeamResponse[]>(
      `/${RESTTeamRepository.BASE_URL}` +
      `/${RESTTeamRepository.COMPANIES_URL}` +
      `/${id}` +
      `/${RESTTeamRepository.TEAMS_URL}`
    );
    
    return teamsResponseToModel(teamsResponse);
  }
  
  public async fetchTeamsByCoacheeId(id: string): Promise<Team[]> {
    const teamsResponse = await this.client.apiGet<TeamResponse[]>(
      `/${RESTTeamRepository.BASE_URL}` +
      `/${RESTTeamRepository.COACHEES_URL}` +
      `/${id}` +
      `/${RESTTeamRepository.TEAMS_URL}`
    );
    
    return teamsResponseToModel(teamsResponse);
  }
  
  public async addTeam(addTeam: AddTeam): Promise<Team> {
    const teamResponse = await this.client.apiPost<TeamResponse>(
      `/${RESTTeamRepository.BASE_URL}` +
      `/${RESTTeamRepository.TEAMS_URL}`,
      createAddTeamRequest(addTeam)
    );
    
    return teamResponseToModel(teamResponse);
  }
  
  public async updateTeam(updateTeam: UpdateTeam): Promise<Team> {
    const teamResponse = await this.client.apiPut<TeamResponse>(
      `/${RESTTeamRepository.BASE_URL}` +
      `/${RESTTeamRepository.TEAMS_URL}` +
      `/${updateTeam.id}`,
      createUpdateTeamRequest(updateTeam)
    );
    
    return teamResponseToModel(teamResponse);
  }
  
  public async deleteTeam(teamId: string): Promise<void> {
    await this.client.apiDelete<void>(
      `/${RESTTeamRepository.BASE_URL}` +
      `/${RESTTeamRepository.TEAMS_URL}` +
      `/${teamId}`
    );
  }
}
