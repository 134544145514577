import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import LinkedEntity from "module/mandate/domain/model/LinkedEntity";
import TeamLinkedEntityComponent from "module/mandate/view/component/edit/TeamLinkedEntityComponent";

const TeamLinkedEntityContainer = ({
  entity,
  isEditMode,
  action,
}: {
  entity: LinkedEntity;
  isEditMode?: boolean;
  action: (name: string) => void;
}) => {
  const dispatch = useDispatch<Dispatch>();
  
  const coachees = useSelector((rootState: RootState) =>
    rootState.CoacheeStore?.getters(rootState).getCoacheesByTeamId(entity.objectId));
  
  const fetchCoacheeState =
    useLoadingAndError(() =>
      dispatch.CoacheeStore.fetchCoacheesByTeamId(entity.objectId), [], entity.objectId);
  
  return (
    <TeamLinkedEntityComponent entity={entity}
                               action={action}
                               coachees={coachees}
                               isCoacheesLoading={fetchCoacheeState.isLoading}
                               errorCoachees={fetchCoacheeState.errorName}
                               isEditMode={isEditMode} />
  );
};

export default TeamLinkedEntityContainer;
