export enum ErrorNames {
  BAD_REQUEST = 'BadRequestError',
  CONFLICT = 'ConflictError',
  DEFAULT_CLIENT = 'DefaultClientError',
  FORBIDDEN = 'ForbiddenError',
  NOT_FOUND = 'NotFoundError',
  SERVER_IS_DOWN = 'ServerIsDownError',
  UNAUTHORIZED = 'UnauthorizedError',
  UNPROCESSABLE_ENTITY = 'UnprocessableEntityError',
  TYPE_ERROR = 'TypeError'
}
