import React from "react";
import {CheckboxButton} from "asset/component";
import SubObjective from "module/objective/domain/model/SubObjective";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import {useDispatch} from "react-redux";
import {Dispatch} from "store";
import UpdateSubObjective from "module/objective/domain/model/UpdateSubObjective";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";

const TeamSubObjectiveContainer = ({subObjective, objectiveId, teamId}: {
  subObjective: SubObjective,
  objectiveId: string,
  teamId: string
}) => {
  const dispatch = useDispatch<Dispatch>();
  const {getErrorMessage} = useErrorTranslation();
  const [isUpdateSubObjectiveLoading, updateSubObjectiveErrorName, dispatchUpdateSubObjective] =
    usePromiseLoadingAndError((isCompleted) =>
      dispatch.ObjectiveTeamStore.updateSubObjectiveForTeam({
        subObjectiveId: subObjective.id,
        objectiveId: objectiveId,
        teamId: teamId,
        updateSubObjective: new UpdateSubObjective(isCompleted)
      })
    );
  
  const [isDeleteSubObjectiveLoading, deleteSubObjectiveErrorName, dispatchDeleteSubObjective] =
    usePromiseLoadingAndError(() =>
      dispatch.ObjectiveTeamStore.deleteSubObjectiveForTeam({
        subObjectiveId: subObjective.id,
        objectiveId: objectiveId,
        teamId: teamId,
      })
    );
  
  return (
    <CheckboxButton data-testid={`${subObjective.id}-checkbox-button`}
                    value={subObjective.completed}
                    setValue={dispatchUpdateSubObjective}
                    deleteElement={dispatchDeleteSubObjective}
                    label={subObjective.text}
                    isLoading={isUpdateSubObjectiveLoading || isDeleteSubObjectiveLoading}
                    errorMessage={
                      updateSubObjectiveErrorName && getErrorMessage(updateSubObjectiveErrorName) ||
                      deleteSubObjectiveErrorName && getErrorMessage(deleteSubObjectiveErrorName)
                    }
    />
  );
};

export default TeamSubObjectiveContainer;
