import AddObjectiveStateComment from "module/objective/domain/model/AddObjectiveStateComment";

export default interface AddObjectiveStateCommentRequest {
  comment: string;
}

export function createAddObjectiveStateCommentRequest(addObjectiveStateComment: AddObjectiveStateComment): AddObjectiveStateCommentRequest {
  return {
    comment: addObjectiveStateComment.comment
  } as AddObjectiveStateCommentRequest;
}
