export default class User {
  private readonly _email: string;
  private readonly _firstName: string;
  private readonly _lastName: string;
  
  constructor(
    email: string,
    firstName: string,
    lastName: string,
  ) {
    this._email = email;
    this._firstName = firstName;
    this._lastName = lastName;
  }
  
  get email(): string {
    return this._email;
  }
  
  get firstName(): string {
    return this._firstName;
  }
  
  get lastName(): string {
    return this._lastName;
  }
}
