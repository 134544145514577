import React from 'react';
import {Autocomplete, autocompleteClasses, Popper, TextField} from "@mui/material";
import {Tag} from "asset/component/tag/Tag";
import styles from "asset/component/asset/form/inputs/InputAutoComplete.module.scss";
import {styled} from '@mui/material/styles';

export const StyledPopper = styled(Popper)({
  [`&[data-popper-placement="top"] .${autocompleteClasses.paper}`]: {
    transform: "translateY(-6px)",
    border: "none",
    zIndex: "1",
  },
  [`&[data-popper-placement="bottom"] .${autocompleteClasses.paper}`]: {
    transform: "translateY(6px)",
    border: "none",
    zIndex: "1",
  },
});

const InputAutoComplete = ({
  tags,
  label = "",
  endIcon,
  show,
  value,
  onChange
}: {
  tags: Tag[],
  label?: string,
  endIcon?: JSX.Element,
  show?: boolean,
  value?: string,
  onChange: (value: string) => void
}) => {
  return (
    <div className={`${styles.container} ${show && styles.show}`}>
      <Autocomplete
        freeSolo
        id="autocomplete"
        data-testid="autocomplete"
        disableClearable
        PopperComponent={StyledPopper}
        options={tags.map((tag) => tag.name)}
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            data-testid={"input-text-field"}
            className={styles.input}
            label={label}
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
      {endIcon}
    </div>
  );
};

export default InputAutoComplete;
