export default class UpdateObjectiveMandate {
  private readonly _name: string;
  private readonly _text: string;
  
  constructor(name: string, text: string) {
    this._name = name;
    this._text = text;
  }
  
  get name(): string {
    return this._name;
  }
  
  get text(): string {
    return this._text;
  }
}
