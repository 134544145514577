import React, {ReactElement} from 'react';
import styles from "module/common/layout/component/assets/PageContent.module.scss";

function PageContent({children}: {
  children: JSX.Element | JSX.Element[]
}): ReactElement {
  return (
    <div id={styles.pageContent}>{children}</div>
  );
}

export default PageContent;
