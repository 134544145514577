import CoacheeDemotivator from "module/demotivator/coachee/domain/model/CoacheeDemotivator";

export default interface CoacheeDemotivatorResponse {
  id: string;
  title: string;
  coacheeId: string;
}

export function coacheeDemotivatorResponseToModel(
  demotivatorResponse: CoacheeDemotivatorResponse,
  coacheeId: string
): CoacheeDemotivator {
  return new CoacheeDemotivator(
    demotivatorResponse.id,
    demotivatorResponse.title,
    coacheeId,
  );
}

export function coacheeDemotivatorsResponseToModel(
  demotivatorResponse: CoacheeDemotivatorResponse[],
  coacheeId: string
): CoacheeDemotivator[] {
  return demotivatorResponse.map(demotivatorResponse => coacheeDemotivatorResponseToModel(demotivatorResponse, coacheeId));
}
