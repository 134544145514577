import Role from "module/profile/domain/model/Role";

export default class Profile {
  private readonly _id: string;
  private readonly _tenantId: string;
  private readonly _roles: Role[];
  
  constructor(
    tenantId: string,
    roles: Role[],
  ) {
    this._id = tenantId;
    this._tenantId = tenantId;
    this._roles = roles;
  }
  
  get id(): string {
    return this._id;
  }
  
  get tenantId(): string {
    return this._tenantId;
  }
  
  get defaultRole(): Role {
    return this._roles?.[0];
  }
}
