import {UserMetadata} from "@firebase/auth";

const isSameDay = (creationTime, lastLoginTime) => {
  return creationTime.getFullYear() === lastLoginTime.getFullYear() &&
    creationTime.getMonth() === lastLoginTime.getMonth() &&
    creationTime.getDate() === lastLoginTime.getDate();
};

const isNewUser = (userMetadata: UserMetadata) => {
  if (userMetadata.creationTime && userMetadata.lastSignInTime) {
    return isSameDay(new Date(userMetadata.creationTime), new Date(userMetadata.lastSignInTime));
  }
  
  return false;
};

export {isNewUser};
