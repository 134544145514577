import React from 'react';
import styles from 'module/need/view/asset/team/TeamNeed.module.scss';
import {Typography} from "asset/component";
import usePaletteColors from "style/usePaletteColors";
import {NeedsTeam} from "module/objective/domain/model/need/NeedsTeam";
import {useTranslation} from "react-i18next";
import Checkmark from "asset/icon/Checkmark";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";

const TeamNeedTag = ({
  needsTeam,
  onClick,
  teamId
}: {
  needsTeam: NeedsTeam,
  onClick: (needsTeam: NeedsTeam) => void,
  teamId: string
}) => {
  const dispatch = useDispatch<Dispatch>();
  const palette = usePaletteColors();
  const {t} = useTranslation('need');
  
  const [isFilled, setIsFilled] = React.useState<boolean>(false);
  const [isDone, setIsDone] = React.useState<boolean>(false);
  
  const needStats = useSelector((rootState: RootState) =>
    rootState.ObjectiveTeamStore?.getters(rootState).getStatsForNeed(needsTeam));
  
  useLoadingAndError(() => dispatch.ObjectiveTeamStore.fetchNeedStats({teamId: teamId, need: needsTeam}), [], teamId);
  
  React.useEffect(() => {
    if (!needStats) return;
    
    const isFilled = needStats.objectiveCount > 0;
    const isDone = isFilled && needStats.objectiveCount === needStats.completedObjectiveCount;
    
    setIsFilled(isFilled);
    setIsDone(isDone);
  }, [needStats]);
  
  return <div className={styles.teamNeedContainer}>
    <div data-testid="tag"
         onClick={() => onClick(needsTeam)}
         className={`${styles.teamNeed} ${needsTeam} ${isFilled && styles.filled} ${isDone && styles.done}`}>
      <Typography color={palette.secondary.veryDark}
                  fontWeight={600}
                  className={styles.text}
                  component="span"
                  variant="sm">
        <>
          {t(`common:teamNeeds.${needsTeam}`)}
          {isDone && <Checkmark />}
        </>
      </Typography>
    </div>
  </div>;
};

export default TeamNeedTag;
