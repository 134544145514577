import ILinkableEntityResponse from "module/mandate/dal/dto/LinkableEntityResponse";
import LinkedEntity from "module/mandate/domain/model/LinkedEntity";

export function linkedEntityResponseToModel(
  linkedEntityResponse: ILinkableEntityResponse,
  mandateId: string
): LinkedEntity {
  return new LinkedEntity(
    linkedEntityResponse.objectId,
    linkedEntityResponse.objectId,
    linkedEntityResponse.name,
    linkedEntityResponse.type,
    mandateId
  );
}

export function linkedEntitiesResponseToModel(
  linkedEntitiesResponse: ILinkableEntityResponse[],
  mandateId: string
): LinkedEntity[] {
  return linkedEntitiesResponse.map(linkedEntity => linkedEntityResponseToModel(linkedEntity, mandateId));
}
