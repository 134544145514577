import React from 'react';
import {Typography} from "asset/component/index";
import styles from 'asset/component/asset/module/EditableTitleSeparator.module.scss';
import EditableTitle from "asset/component/form/inputs/EditableTitle";
import {ErrorNames} from "core/error/ErrorNames";
import {MuiTypographyVariants} from "style/props/MuiTypographyVariants";
import {MuiInputColors} from "style/props/MuiInputColors";
import usePaletteColors from "style/usePaletteColors";
import HorizontalSeparatorLine, {SeparatorColor} from "asset/component/module/HorizontalSeparatorLine";

const EditableTitleSeparator = ({
  title,
  setTitle,
  deleteAction,
  deleteConfirmationText,
  deleteConfirmButtonText,
  editIsLoading,
  errorName
}: {
  title: string,
  setTitle?: (title: string) => void,
  deleteAction?: () => void,
  deleteConfirmationText?: string,
  deleteConfirmButtonText?: string,
  editIsLoading?: boolean,
  errorName?: ErrorNames
}) => {
  const palette = usePaletteColors();
  
  return (
    <div className={styles.separatorContainer}>
      <EditableTitle title={title}
                     setTitle={setTitle}
                     deleteAction={deleteAction}
                     deleteConfirmationText={deleteConfirmationText}
                     deleteConfirmButtonText={deleteConfirmButtonText}
                     editIsLoading={editIsLoading}
                     errorName={errorName}
                     inputColor={MuiInputColors.TRANSPARENT}
                     classes={{
                   editIcon: styles.editIcon,
                   deleteIcon: styles.deleteIcon,
                   updateIcon: styles.updateIcon,
                   titleContainer: styles.titleContainer,
                   iconContainer: styles.iconContainer
                 }}
                     iconsProps={{
                   editIcon: {
                     iconColor: palette.white.main,
                     hoverBackgroundColor: palette.secondary.dark
                   },
                   deleteIcon: {
                     hoverBackgroundColor: palette.secondary.dark,
                     buttonSize: "34px",
                     iconSize: "24px"
                   },
                   updateIcon: {
                     iconColor: palette.white.main,
                     hoverBackgroundColor: palette.secondary.dark,
                     buttonSize: "34px",
                     iconSize: "24px"
                   }
                 }}>
        <Typography component={"h4"}
                    variant={MuiTypographyVariants.H4}
                    className={styles.textContent}>
          {title}
        </Typography>
      </EditableTitle>
      <HorizontalSeparatorLine color={SeparatorColor.MIDDLE_CREAM}/>
    </div>
  );
};

export default EditableTitleSeparator;
