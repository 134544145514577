const HELP_URL_EN = "https://panoramix.app/en/help/";
const HELP_URL_FR = "https://panoramix.app/fr/help/";
const FEEDBACK_URL_FR = "https://us21.list-manage.com/survey?u=c3c5bbf7e8c0bb79b863420e4&id=7a230c0f5f";
const FEEDBACK_URL_EN = "https://us21.list-manage.com/survey?u=c3c5bbf7e8c0bb79b863420e4&id=d7b0b8afc0";

export {
  HELP_URL_EN,
  HELP_URL_FR,
  FEEDBACK_URL_FR,
  FEEDBACK_URL_EN
};
