import Mandate from "module/mandate/domain/model/Mandate";
import dayjs from "dayjs";
import moment from "moment/moment";
import {removeDiacritics} from "asset/search/SearchMethods";
import i18n from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "store";
import useLoadingAndError from "asset/component/hook/useLoadingAndError";
import {usePromiseLoadingAndError} from "asset/component/hook/usePromiseLoadingAndError";
import UpdateLinkableEntity from "module/mandate/domain/model/UpdateLinkableEntity";

export const orderByDeadlineDescending = (mandates: Mandate[]) => {
  return mandates.sort((a, b) => {
    if (a.deadline === null) return 1;
    if (b.deadline === null) return -1;
    return moment.utc(a.deadline).isBefore(moment.utc(b.deadline)) ? 1 : -1;
  });
};

export const filterTitle = (mandate: Mandate, searchValue: string) => {
  return removeDiacritics(mandate.title).includes(removeDiacritics(searchValue));
};

export const filterDescription = (mandate: Mandate, searchValue: string) => {
  return removeDiacritics(mandate.description).includes(removeDiacritics(searchValue));
};

export const filterDeadline = (mandate: Mandate, searchValue: string) => {
  return mandate.deadline !== null && moment.utc(mandate.deadline).format("DD/MM/YYYY").includes(searchValue)
    || (mandate.deadline !== null && removeDiacritics(moment.utc(mandate.deadline).locale(i18n.language || "en").format("MMMM")).includes(searchValue));
};

export const getFilteredMandates = (mandates: Mandate[], searchValue: string) => {
  return mandates.filter((mandate) => {
    return filterTitle(mandate, searchValue) || filterDescription(mandate, searchValue) || filterDeadline(mandate, searchValue);
  });
};

export const applyDisplayFinished = (mandates: Mandate[]) => {
  return mandates.filter((mandate) => mandate.deadline === null || dayjs(mandate.deadline).isAfter(dayjs()));
};

export const linkedEntityManager = (mandateId: string) => {
  const dispatch = useDispatch<Dispatch>();
  
  const linkableEntities = useSelector((rootState: RootState) =>
    rootState.LinkableEntityStore?.getters(rootState).getLinkableEntities());
  
  const fetchLinkableEntitiesForMandateState =
    useLoadingAndError(() =>
      dispatch.LinkableEntityStore.fetchLinkableEntitiesForMandate({mandateId: mandateId}), [], mandateId);
  
  const [isAddLinkedEntityLoading, addLinkedEntityErrorName, dispatchAddLinkedEntity] =
    usePromiseLoadingAndError((linkableEntity: UpdateLinkableEntity) =>
      dispatch.LinkableEntityStore.addLinkableEntity({entity: linkableEntity, mandateId: mandateId})
    );
  
  const [isRemoveLinkedEntityLoading, removeLinkedEntityErrorName, dispatchRemoveLinkedEntity] =
    usePromiseLoadingAndError((linkableEntity: UpdateLinkableEntity) =>
      dispatch.LinkableEntityStore.removeLinkableEntity({entity: linkableEntity, mandateId: mandateId})
    );
  
  const linkedEntities = useSelector((rootState: RootState) =>
    rootState.LinkableEntityStore?.getters(rootState).getLinkedEntitiesByMandateId(mandateId));
  
  const fetchLinkedEntitiesForMandateState =
    useLoadingAndError(() =>
      dispatch.LinkableEntityStore.fetchLinkedEntitiesForMandate({mandateId: mandateId}), [], mandateId);
  
  const addLinkedEntity = (id: string) => {
    const linkedEntity = linkableEntities.find(linkableEntity => linkableEntity.id === id);
    if (!linkedEntity) return;
    
    dispatchAddLinkedEntity(new UpdateLinkableEntity(linkedEntity.id, linkedEntity.type));
  };
  
  const removeLinkedEntity = (id: string) => {
    const linkedEntity = linkedEntities.find(linkedEntity => linkedEntity.id === id);
    
    if (!linkedEntity) return;
    
    dispatchRemoveLinkedEntity(new UpdateLinkableEntity(linkedEntity.id, linkedEntity.type));
  };
  
  return {
    linkableEntities,
    isLinkableEntitiesLoading: fetchLinkableEntitiesForMandateState.isLoading,
    errorLinkableEntities: fetchLinkableEntitiesForMandateState.errorName,
    isAddLinkedEntityLoading,
    addLinkedEntityErrorName,
    isRemoveLinkedEntityLoading,
    removeLinkedEntityErrorName,
    linkedEntities,
    isLinkedEntitiesLoading: fetchLinkedEntitiesForMandateState.isLoading,
    errorLinkedEntities: fetchLinkedEntitiesForMandateState.errorName,
    addLinkedEntity,
    removeLinkedEntity
  };
};
