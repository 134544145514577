import {useMediaQuery} from "@mui/material";
import {Theme} from "@mui/system/createTheme";
import {MuiBreakpoints} from "style/props/MuiBreakPoints";

const useMediaBreakpoints = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up(MuiBreakpoints.MD));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(MuiBreakpoints.MD));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between(MuiBreakpoints.SM, MuiBreakpoints.MD));
  
  return {
    isDesktop,
    isMobile,
    isTablet
  };
};

export default useMediaBreakpoints;
