import {createModel} from "@rematch/core";
import {RootModel} from "store/RootModel";
import clients from "module/common/client";
import Company from "module/company/domain/model/Company";
import {RootState} from "store";
import AppError from "core/error/AppError";
import ReducerServices from "store/service/ReducerServices";
import AddCompany from "module/company/domain/model/AddCompany";
import UpdateCompany from "module/company/domain/model/UpdateCompany";

export const CompanyStore = createModel<RootModel>()({
  state: {
    companies: [] as Company[],
    getters: (state: RootState) => ({
      getCompanyById: (id: string) => {
        return state.CompanyStore.companies.find(company => company.id == id);
      },
      getCompaniesByIds: (ids: string[]) => {
        return state.CompanyStore.companies.filter(company => ids.includes(company.id));
      }
    })
  },
  reducers: {
    setCompanies(state, companies: Company[]) {
      return {
        ...state,
        companies: ReducerServices.setItemsToList<Company>(state.companies,
          companies)
      };
    },
    setCompany(state, company: Company) {
      return {
        ...state,
        companies: ReducerServices.setItemToList<Company>(state.companies,
          company)
      };
    },
    removeCompanyById(
      state,
      companyId: string
    ) {
      return {
        ...state,
        companies: ReducerServices.removeItemFromList<Company>(
          state.companies,
          companyId
        )
      };
    },
  },
  effects: (dispatch) => ({
    async fetchCompanies() {
      await clients.Company.fetchCompanies().then((companies: Company[]) => {
        dispatch.CompanyStore.setCompanies(companies);
      }).catch((error: AppError) => {
        throw error.name;
      });
    },
    async fetchCompanyById(id: string) {
      await clients.Company.fetchCompanyById(id).then((company: Company) => {
        dispatch.CompanyStore.setCompany(company);
      }).catch((error: AppError) => {
        throw error.name;
      });
    },
    async addCompany(addCompany: AddCompany) {
      return await clients.Company
        .addCompany(addCompany)
        .then((addedCompany) => {
          dispatch.CompanyStore.setCompany(addedCompany);
          
          return addedCompany;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async updateCompany(updateCompany: UpdateCompany) {
      return await clients.Company
        .updateCompany(updateCompany)
        .then((updatedCompany) => {
          dispatch.CompanyStore.setCompany(updatedCompany);
          
          return updatedCompany;
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
    async deleteCompany(payload: { companyId: string }) {
      return await clients.Company
        .deleteCompany(payload.companyId)
        .then(() => {
          dispatch.CompanyStore.removeCompanyById(payload.companyId);
        }).catch((error: AppError) => {
          throw error.name;
        });
    },
  })
});
