import * as React from "react";
import styles from "asset/component/asset/BodySectionLayout.module.scss";
import {Container} from "asset/component";

const BodySectionLayout = (
  {
    id,
    size = "normal",
    color = "primary",
    padding = "normal",
    children
  }: {
    id?: string,
    size?: "normal" | "small",
    color?: "primary" | "secondary",
    padding?: "normal" | "small",
    children: JSX.Element | JSX.Element[],
  }) => {
  return (
    <Container id={id} className={`${styles.container} ${size} ${color} ${padding + 'Padding'}`}>
      <div className={styles.layoutContainer}>
        {children}
      </div>
    </Container>
  );
};

export default BodySectionLayout;
