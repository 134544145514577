import * as React from "react";
import {useSelector} from "react-redux";
import {RootState} from "store";
import PersonalInformationsContainer from "module/user/view/container/PersonalInformationsContainer";
import {CircularProgress} from "asset/component";
import ChangePasswordContainer from "module/user/view/container/ChangePasswordContainer";
import CommunicationLanguageContainer from "container/CommunicationLanguageContainer";

const ProfileAndSettingSectionContainer = () => {
  const currentUser = useSelector(
    (rootState: RootState) => rootState.UserStore?.getters(rootState)
      .getCurrentUser());
  
  return currentUser ? <>
      <PersonalInformationsContainer user={currentUser} />
      <CommunicationLanguageContainer />
      <ChangePasswordContainer />
    </> :
    <CircularProgress />;
};

export default ProfileAndSettingSectionContainer;
